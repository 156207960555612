<template>
  <div class="box">
    <div
      class="item"
      v-for="(info, index) in activityList"
      :key="index"
      :class="{ 'img-end': info.status == 1 }"
      @click="gotoInfo(info.activityId, info.siteId)"
    >
      <div class="img">
        <img :src="info.poster" alt="封面" />
      </div>
      <i v-if="info.status" class="icon-ui icon-finish"></i>
      <p class="title text-two" :title="info.name">
        {{ info.name }}
      </p>
      <p class="info">
        {{ info.startTime }}
        {{ info.week }}
      </p>
      <p class="info text-one" :title="info.venueName">
        {{ info.city }}
        {{ info.area }} ·
        {{ info.venueName }}
      </p>
      <p class="price">{{ info.ticketPrice }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "activityItem",
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    // 详情
    gotoInfo(activityId, siteId) {
      this.$router.push({
        path: "/live-info",
        query: { aid: activityId, site: siteId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.item {
  position: relative;
  width: 20%;
  margin: 0 0 30px 35px;
  .img {
    & > img {
      width: 100%;
      height: 250px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .icon-finish {
    position: absolute;
    top: 90px;
    right: 25%;
    width: 50% !important;
    height: 50px !important;
  }
  .title {
    height: 40px;
    margin: 5px 0;
    line-height: 1.4;
    font-weight: 600;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  .info {
    margin-bottom: 7px;
    font-size: 12px;
    color: #999;
    cursor: pointer;
  }
  .price {
    font-size: 14px;
    font-weight: bold;
    color: #e02020;
  }
}
.img-end {
  .img {
    height: 70.5%;
    border-radius: 8px;
    background-color: #000;
    & > img {
      opacity: 0.5;
      filter: alpha(opacity=30);
    }
  }
  & > p {
    color: #999 !important;
  }
}
</style>
