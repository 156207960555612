<template>
  <div id="app">
    <router-view></router-view>
    <!-- cookies协议 -->
    <div class="cookies-box" v-if="is_cookie == 0">
      <div class="cookies-tip">
        <span style="margin-right: 100px;">
          我们使用cookies为您提供更好的网站体验。如果您继续浏览且不改变您的cookie设置，表示您同意我们在本设备上使用cookies。
        </span>
        <el-button size="small" @click="$router.push('/privacy')">更多信息</el-button>
        <el-button size="small" class="btn-style" @click="acceptCookie">接受</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      is_cookie: null,
    };
  },
  created() {
    let path = this.$route.path;
    let arr = ["/legal/terms", "/privacy"];
    let status = arr.includes(path);
    if (this.isMobile() && !status) {
      // window.location.href = "https://m.rockguys.cn/#/";
    } else {
      // console.log("pc");
    }
    // 获取状态
    if (this.getCookie("is_cookie") == null) {
      this.is_cookie = 0;
    } else {
      this.is_cookie = this.getCookie("is_cookie");
    }
  },
  methods: {
    // 检测手机
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 获取指定cookie
    getCookie(name) {
      return (
        (name = (document.cookie + ";").match(new RegExp(name + "=.*;"))) &&
        name[0].split(/=|;/)[1]
      );
    },
    // 同意
    acceptCookie() {
      this.is_cookie = 1;
      document.cookie = "is_cookie=1;"
    },
  },
};
</script>

<style>
/* html,
body,
#app {
  height: 100% !important;
} */

.cookies-box {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
	max-height: auto;
  margin: 0 auto;
  line-height: 50px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.06);
}
.cookies-tip {
  text-align: center;
  font-size: 12px;
}
.cookies-tip > .el-button {
  width: 100px;
}

/* 单行超出 */
.text-one {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 两行超出 */
.text-two {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
}
/* button 红色渐变 */
.btn-style {
  color: #fff !important;
  border: none !important;
  background: linear-gradient(223deg, #ef4033 0%, #e70030 100%) !important;
}
/* show loading */
.el-loading-mask {
  z-index: 999;
}
/* loading || no data */
.bottom-style {
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: #333;
  line-height: 30px;
  text-align: center;
}
.bottom-style > i {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}

/* 自定义图片字体 */
.icon-ui {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  vertical-align: middle;
  background-size: 100% 100% !important;
}
.icon-authenticate {
  background: url("../src/assets/images/icon/authenticate.png") no-repeat;
}
.icon-data {
  background: url("../src/assets/images/icon/data.png") no-repeat;
}
.icon-foot {
  background: url("../src/assets/images/icon/foot.png") no-repeat;
}
.icon-group {
  background: url("../src/assets/images/icon/group.png") no-repeat;
}
.icon-msg {
  background: url("../src/assets/images/icon/msg.png") no-repeat;
}
.icon-star {
  background: url("../src/assets/images/icon/star.png") no-repeat;
}
.icon-seal {
  background: url("../src/assets/images/icon/seal.png") no-repeat;
}
.icon-finish {
  background: url("../src/assets/images/icon/finish.png") no-repeat;
}
.icon-notice {
  background: url("../src/assets/images/icon/notice.png") no-repeat;
}
.icon-card {
  background: url("../src/assets/images/icon/card.png") no-repeat;
}
/* 返回按钮 */
.btn-back {
  margin-right: 30px;
  font-weight: bold;
  font-size: 18px;
  color: #ef4033;
  cursor: pointer;
}
/* 暂无数据 */
.no-data-ui {
  text-align: center;
  line-height: 20vh;
  font-size: 16px;
  color: #999;
}
</style>
