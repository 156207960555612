<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div class="fixed-top" v-if="isFixed">
            <i class="el-icon-back" @click="$router.back(-1)"></i>
            <span>{{ nickname }}</span>
            <el-button type="text" @click="following">
              {{ is_follow ? "正在关注" : "关注" }}
            </el-button>
          </div>
          <div
            class="header-box"
            :style="{ background: 'url( ' + backgroundImage + ')' }"
          >
            <div class="edit-box">
              <div class="btn-edit" @click="following">
                {{ is_follow ? "正在关注" : "关注" }}
              </div>
            </div>
            <div class="info-box">
              <img class="avatar" :src="avatar" alt="" />
              <div class="box">
                <div class="header">
                  <p>
                    <span class="nick">{{ nickname }}</span>
                    <span
                      v-if="stype == 1"
                      class="icon-ui icon-authenticate"
                    ></span>
                  </p>
                  <p class="text-two" :title="remark">{{ remark }}</p>
                </div>
                <div class="info">
                  <div>
                    <p>
                      <span v-if="stype != 0">
                        <i class="iconfont icon-shijian"></i>成立于{{
                          createTime
                        }}
                      </span>
                      <span v-if="stype == 0">
                        <i class="iconfont icon-yinle"></i>
                        {{ music_type }}
                      </span>
                      <span
                        class="text-one"
                        style="display: inline-block; width: 200px;"
                        :title="address[1] ? address[1] : address[2]"
                      >
                        <i class="el-icon-location-outline"></i>
                        {{ address[0] }} ·
                        {{ address[1] ? address[1] : address[2] }}
                      </span>
                    </p>
                    <p class="flex-right">
                      <span><i class="el-icon-document"></i>详细介绍</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                    <p class="flex-right" v-if="stype == 1">
                      <span
                        ><i class="iconfont icon-yonghu"></i>旗下艺术家</span
                      >
                      <i class="el-icon-arrow-right"></i>
                    </p>
                    <p class="flex-right" v-if="stype == 0">
                      <span><i class="el-icon-date"></i>演出日历</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                    <p class="flex-right" v-if="stype == 2">
                      <span><i class="el-icon-date"></i>档期日历</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                    <p class="flex-right" v-if="stype == 2">
                      <span><i class="iconfont icon-shebei"></i>设备清单</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>
                </div>
                <div class="my-content"></div>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div class="nav-box">
              <div
                :class="{ active: n_id == index }"
                v-for="(item, index) in navList"
                :key="index"
                @click="saveIndex(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="live-box" v-if="n_id == 0">
              <a-item :activityList="liveList"></a-item>
              <!-- 分页 -->
              <div class="pagination" v-if="liveList.length">
                <el-pagination
                  background
                  layout="
                      prev,
                      pager,
                      jumper,
                      next"
                  :total="total"
                  :page-size="page_count"
                  prev-text="前一页"
                  next-text="下一页"
                  @current-change="currentChange"
                  @prev-click="prevClick"
                  @next-click="nextClick"
                >
                </el-pagination>
              </div>
              <div class="no-data" v-else>
                暂无演出
              </div>
            </div>
            <div v-else>
              <div class="no-data">
                暂无留言
              </div>
            </div>
          </div>
        </div>
        <hot-right style="margin-top: 50px;" show="1"></hot-right>
      </div>
    </section>
  </div>
</template>

<script>
// 裁切
export default {
  name: "setting",
  data() {
    return {
      navId: null, // 侧边导航
      stype: null, // 0 艺术家 || 1 厂牌 || 2 场馆
      n_id: 0, // 顶部
      navList: ["即将演出", "留言讨论"],
      nickname: "",
      avatar: "",
      backgroundImage: "",
      follow_count: 0,
      is_follow: null, // 是否关注
      remark: "",
      music_type: "",
      createTime: "",
      address: [],

      liveList: [], // 演出列表
      messageList: [], // 留言列表

      studioId: null,
      other_id: null,
      other_type: null,
      token: "",

      total: 0,
      page: 1,
      page_count: 20,

      isFixed: false,
      offsetTop: 0,
    };
  },
  mounted() {
    // 获取用户信息
    let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
    if (userinfo) {
      this.user_id = userinfo.userId;
    }
    this.other_id = this.$route.query.id;
    this.other_type = this.$route.query.type;
    this.token = localStorage.getItem("token");
    // 获取资料
    this.getStudioInfo();
    // 获取演出
    this.getStudioActivity();
    // 获取scroll位置
    window.addEventListener("scroll", this.initHeight);
    this.$nextTick(() => {
      this.offsetTop = document.querySelector(".edit-box").offsetTop;
    });
  },
  methods: {
    // 获取资料
    getStudioInfo() {
      this.$http({
        url: "/api/authentication/v1.0/getStudio",
        params: {
          studioId: this.other_id,
          type: this.other_type,
          userId: this.user_id || "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.stype = data.type;
          this.nickname = data.name;
          this.avatar = data.photo;
          this.backgroundImage = data.backgroundImage;
          this.is_follow = parseInt(data.follow);
          this.follow_count = data.followCount;
          this.remark = data.remark;
          this.music_type = data.musicStyle;
          this.createTime = data.createTime;
          this.address[0] = data.province;
          this.address[1] = data.city;
          this.address[2] = data.area;
        }
      });
    },
    // 获取演出
    getStudioActivity() {
      this.$showLoading("loading...", "section");
      this.$http({
        url: "/api/authentication/v1.0/getStudioActivity",
        params: {
          studioId: this.other_id,
          pageNumber: this.page,
          pageSize: this.page_count,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.liveList = res.data.rows;
        } else {
          console.log(res.data.data);
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$hideLoading();
      });
    },
    // 上一页
    prevClick(e) {
      this.page = e;
      this.getStudioActivity();
    },
    // 下一页
    nextClick(e) {
      this.page = e;
      this.getStudioActivity();
    },
    // 页码
    currentChange(e) {
      // 上下重复调用
      if (this.page == e) {
        console.log("重复");
      } else {
        this.page = e;
        this.getStudioActivity();
      }
    },
    // 获取scroll
    initHeight() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
    // 关注
    following() {
      if (!this.token) {
        this.$message.warning("请先登录!");
        return;
      }
      this.is_follow = !this.is_follow;
      let type = null;
      if (this.is_follow) {
        type = 1;
      } else {
        type = 0;
      }
      this.$http({
        url: "/api/authentication/v1.0/studioFollow",
        method: "POST",
        data: {
          follow: type,
          studioId: this.other_id,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data);
        } else {
          console.log("关注", res.data);
        }
      });
    },
    //
    saveIndex(id) {
      this.n_id = id;
    },
    // 详情
    gotoInfo(activityId, siteId) {
      this.$router.push({
        path: "/live-info",
        query: { aid: activityId, site: siteId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: calc(100vh - 48px);
    max-height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      width: 855px;
      height: 100%;
      margin-right: 15px;
      overflow: auto;
      box-sizing: border-box;
      background-color: #ffffff;
      border-right: 1px solid #eaeaea;
      .fixed-top {
        z-index: 999;
        position: fixed;
        display: flex;
        align-items: center;
        width: 855px;
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: #fff;
        border-right: 2px solid #eaeaea;
        .el-icon-back {
          color: #ef4033;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
        }
        & > span {
          margin-left: 20px;
          font-size: 16px;
          font-weight: bold;
        }
        .el-button {
          padding: 5px 15px;
          margin-left: auto;
          color: #ef4033;
          border-radius: 20px;
          border: 1px solid #ef4033;
        }
      }
      .header-box {
        position: relative;
        width: 100%;
        height: 273px;
        padding-top: 195px;
        box-sizing: border-box;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        .edit-box {
          position: absolute;
          right: 30px;
          top: 90px;
          width: 200px;
          text-align: right;
          .btn-edit {
            display: inline-block;
            width: 110px;
            height: 35px;
            margin-bottom: 10px;
            line-height: 35px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            border-radius: 20px;
            background-color: #040d11;
            cursor: pointer;
          }
          p {
            font-size: 16px;
            color: #fff;
          }
        }
        .info-box {
          position: relative;
          display: flex;
          width: 100%;
          box-sizing: border-box;
          .avatar {
            position: absolute;
            width: 125px;
            height: 125px;
            margin-left: 40px;
            border-radius: 50%;
            border: 5px solid #fff;
          }
          .box {
            width: 100%;
            box-sizing: border-box;
            .header {
              width: 100%;
              padding: 20px 20px 0;
              box-sizing: border-box;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.25) 100%
              );
              & > p {
                padding-bottom: 10px;
                margin-left: 180px;
                color: #fff;

                &:first-child {
                  font-size: 18px;
                  font-weight: 600;
                }
                &:last-child {
                  font-size: 16px;
                  max-height: 26px;
                  line-height: 18px;
                }
                .nick {
                  margin-right: 10px;
                }
                .icon-authenticate {
                  vertical-align: text-top;
                }
              }
            }
            .info {
              padding: 15px 20px;
              margin-left: 180px;
              box-sizing: border-box;
              & > div {
                & > p {
                  margin-bottom: 20px;
                  font-size: 14px;
                  span {
                    margin-right: 50px;
                    color: rgba(0, 0, 0, 0.65);
                    i {
                      margin-right: 8px;
                    }
                  }
                }
                .flex-right {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 500px;
                  & > span {
                    & > i {
                      width: 16px;
                      height: 16px;
                    }
                  }
                  .icon-yonghu {
                    font-weight: bold;
                  }
                  .el-icon-arrow-right {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .content-box {
        width: 100%;
        height: 100%;
        margin-top: 150px;
        .nav-box {
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 30px 120px 20px;
          box-sizing: border-box;
          border-bottom: 2px solid #f1f1f1;
          & > div {
            font-size: 18px;
            font-weight: 500;
            color: #818181;
            cursor: pointer;
          }
          .active {
            position: relative;
            color: #000;
            &::after {
              content: "";
              position: absolute;
              bottom: -21px;
              left: -35px;
              width: 140px;
              border-bottom: 2px solid #ef4033;
            }
          }
        }
        .live-box {
          width: 100%;
          padding: 30px 0;
          box-sizing: border-box;
          .title {
            margin: 10px 0;
            font-weight: 600;
            color: #333;
            cursor: pointer;
          }
          .info {
            margin-bottom: 5px;
            font-size: 12px;
            color: #999;
            cursor: pointer;
          }
          .price {
            font-size: 14px;
            font-weight: bold;
            color: #e02020;
          }
        }
        .no-data {
          display: inline-block;
          width: 100% !important;
          padding-top: 120px;
          font-size: 16px;
          text-align: center;
          color: #333;
        }
      }
    }
    .right-box {
      width: 350px;
      height: 400px;
      background-color: #fff;
    }
  }
}
.pagination {
  margin-top: 80px;
  text-align: center;
}
</style>
