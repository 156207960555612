<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/profile' }">
              个人资料
            </el-breadcrumb-item>
            <el-breadcrumb-item> 编辑资料 </el-breadcrumb-item>
          </el-breadcrumb>
          <el-upload
            :limit="1"
            ref="upload"
            class="avatar"
            name="multipartFile"
            :show-file-list="false"
            :http-request="uploadSuccess"
            :before-upload="uploadPreview"
            action="none"
            accept="image/png,image/jpg,image/jpeg"
          >
            <el-avatar :src="avatar" shape="circle" :size="140"></el-avatar>
          </el-upload>
          <upload-cropper
            ref="cropper"
            :cropper="cropper"
            @getAvatar="getChildInfo"
          ></upload-cropper>

          <p class="nickname">
            <el-input
              class="input-name"
              v-model="nickname"
              size="small"
              v-show="!readonly"
            ></el-input>
            <span v-show="readonly">{{ nickname }}</span>
            <el-button type="text" @click="saveNickname">
              {{ readonly ? "修改" : "确定" }}
            </el-button>
          </p>
          <h4>账号资料</h4>
          <div class="item">
            <span>性别：</span>
            <div>
              <el-radio v-model="sex" label="男"></el-radio>
              <el-radio v-model="sex" label="女"></el-radio>
            </div>
          </div>
          <div class="item">
            <span>生日：</span>
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="birthday"
              value-format="yyyy-MM-dd"
              size="small"
              style="width: 35%"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="item">
            <span>地区：</span>
            <el-cascader
              v-model="address"
              :options="area_format"
              :props="areaProps"
              size="small"
              ref="myCascader"
              style="width: 35%"
              @change="changeMine"
            ></el-cascader>
          </div>
          <div class="item">
            <span>简介：</span>
            <el-input
              v-model="remark"
              size="small"
              maxlength="20"
              style="width: 60%"
              placeholder="一句话认识你，不超过20个字"
            >
            </el-input>
          </div>
          <h4>联系方式</h4>
          <div class="item">
            <span>手机：</span>
            <el-input
              v-model="mobile"
              size="small"
              maxlength="11"
              style="width: 35%"
            >
            </el-input>
          </div>
          <div class="item">
            <span>邮箱：</span>
            <el-input v-model="email" size="small" style="width: 35%">
            </el-input>
          </div>
          <el-button size="small" class="btn-style" @click="saveInformation"
            >保存</el-button
          >
          <h4>地址管理</h4>
          <div class="address-box">
            <div v-for="(item, index) in placeList" :key="index">
              <p>
                <span>{{ item.name }}</span>
                {{ item.mobile }}
              </p>
              <p class="text-two">
                {{ item.province }}
                {{ item.city }}
                {{ item.area }}
                {{ item.address }}
              </p>
              <div>
                <el-button
                  type="text"
                  size="mini"
                  :class="{ default: item.flag }"
                  @click="setDefault(item, index)"
                  :disabled="Boolean(item.flag)"
                  style="color: #999"
                >
                  {{ item.flag ? "默认地址" : "设为默认" }}
                </el-button>
                <p>
                  <el-button
                    type="text"
                    style="margin-right: 10px;"
                    size="mini"
                    @click="upAddress(item, index)"
                  >
                    编辑
                  </el-button>
                  <el-popconfirm
                    icon="el-icon-info"
                    icon-color="red"
                    @confirm="delAddress(item.id, index)"
                    title="是否确定删除此地址？"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      slot="reference"
                      style="color: #f00"
                    >
                      删除
                    </el-button>
                  </el-popconfirm>
                </p>
              </div>
            </div>
          </div>
          <el-button
            style="margin-left: 40px"
            size="small"
            plain
            icon="el-icon-plus"
            @click="addArea()"
            >新增地址</el-button
          >
          <h4>实名认证</h4>
          <div class="real-box">
            <div v-for="(item, index) in idCardList" :key="index">
              <div>
                <span>{{ item.name }}</span>
                <span>身份证</span>
                <span>{{ item.cardNo }}</span>
              </div>
              <div>
                <el-button
                  type="text"
                  size="small"
                  @click="editCard(item, index)"
                  >编辑</el-button
                >
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  @confirm="delIdcard(item.id, index)"
                  title="是否确定删除此认证信息？"
                >
                  <el-button
                    type="text"
                    size="small"
                    slot="reference"
                    style="color: #ef4033;margin-left: 10px;"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            </div>
          </div>
          <el-button
            style="margin-left: 40px"
            size="small"
            plain
            icon="el-icon-plus"
            @click="addVerified"
            >新增证件</el-button
          >
        </div>
        <hot-right></hot-right>
      </div>
    </section>
    <div class="mask" v-show="is_dialog">
      <div class="mask-box">
        <div class="header">
          <h4>{{ edit_type ? "编辑收货地址" : "实名认证" }}</h4>
          <i class="el-icon-close" @click="is_dialog = false"></i>
        </div>
        <div class="edit-div" v-if="edit_type">
          <div>
            <p>收货人</p>
            <el-input
              size="small"
              v-model="shop_name"
              maxlength="15"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
            <p>手机号</p>
            <el-input
              v-model="shop_mobile"
              maxlength="11"
              size="small"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
            <p>所在地区</p>
            <el-cascader
              v-model="shop_address"
              :options="area_format"
              :props="areaProps"
              size="small"
              ref="myCascader1"
              style="width: 60%"
              @change="changeShop"
            ></el-cascader>
          </div>
          <div>
            <p>详细地址</p>
            <el-input
              v-model="shop_ainfo"
              style="width: 100%"
              size="small"
              maxlength="100"
              placeholder="详细地址"
            ></el-input>
          </div>
          <el-button
            class="btn-style"
            type="danger"
            size="small"
            @click="saveAddressInfo"
            >保存地址</el-button
          >
        </div>
        <div class="edit-div" v-else>
          <div>
            <p>姓名</p>
            <el-input
              v-model="card_name"
              maxlength="15"
              size="small"
            ></el-input>
          </div>
          <div>
            <p>证件类型</p>
            <el-select size="small" v-model="card_type" placeholder="请选择">
              <el-option
                v-for="item in cardList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <p>证件号码</p>
            <el-input
              v-model="idcard_num"
              style="width: 60%"
              size="small"
            ></el-input>
          </div>
          <el-button
            class="btn-style"
            type="danger"
            size="small"
            @click="saveIdcard"
            >保存</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import area_format from "../../assets/area.json";
// 裁切
import uploadCropper from "../../components/uploadCropper";
export default {
  name: "edit",
  components: {
    "upload-cropper": uploadCropper,
  },
  data() {
    return {
      navId: 3, // 侧边导航
      readonly: true,
      avatar: "",
      nickname: "",
      sex: 0,
      birthday: "",
      // 设置日期限制为当天
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      area_format: [],
      areaProps: {
        value: "code",
        label: "name",
        children: "areaList",
      },
      address: "",
      addressInfo: [],
      remark: "",

      mobile: "",
      email: "",

      placeList: [], // 收货地址

      // 地址管理
      shop_name: "",
      shop_mobile: "",
      shop_address: "",
      shop_address_info: [],
      shop_ainfo: "",
      shop_flag: 0,
      save_id: "",
      edit_id: "", // 修改的下标

      cardList: [
        {
          label: "身份证",
          value: 1,
        },
      ],
      card_type: "", // 证件类型
      idCardList: [], // 身份证
      card_name: "",
      idcard_num: "",

      is_dialog: false,
      edit_type: false, // 地址 || 实名
      user_id: "",

      //
      cropper: {
        temp_name: "",
        is_cropper: "",
        temp_img: "",
      },
    };
  },
  mounted() {
    // 地址
    this.area_format = area_format;
    // 获取信息
    let info = JSON.parse(localStorage.getItem("userinfo"));
    if (info) {
      this.user_id = info.userId;
    }
    this.getInformation(this.user_id); // 获取信息
    this.getAddress(); // 获取地址
    this.getCard(); // 获取身份证信息
  },
  methods: {
    saveIndex(id) {
      this.n_id = id;
    },
    // 获取资料
    getInformation(id, type) {
      this.$http({
        url: "/api/information/v1.0/getInformation",
        params: {
          userId: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.avatar = res.data.data.photo;
          this.nickname = res.data.data.nickName;
          this.sex = res.data.data.sex;
          this.birthday = res.data.data.birthday;
          this.address = res.data.data.areaId;
          this.remark = res.data.data.remark;
          this.mobile = res.data.data.mobile;
          this.email = res.data.data.email;
          if (type) {
            // 储存信息
            let data = res.data.data;
            data.userId = this.user_id;
            let userinfo = JSON.stringify(data);
            localStorage.setItem("userinfo", userinfo);
          }
        } else {
          console.log("资料获取", res.data.msg);
        }
      });
    },
    // 选择地址
    changeMine() {
      let address = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
      if (
        address[1] == "市辖区" ||
        address[1] == "省辖区" ||
        address[1] == "辖区"
      ) {
        this.addressInfo[0] = address[0];
        this.addressInfo[1] = "";
        this.addressInfo[2] = address[2];
      } else {
        this.addressInfo = address;
      }
      console.log(this.addressInfo);
    },
    // 保存名字
    saveNickname() {
      this.readonly = !this.readonly;
      if (this.readonly) {
        if (!this.nickname.trim().length) {
          this.$message.warning({
            message: "请输入名称！",
            duration: 2000,
          });
          this.readonly = false;
          return false;
        }
        this.saveInfo();
      }
    },
    // 保存资料
    saveInformation() {
      if (!this.nickname.trim()) {
        this.$message.warning({
          message: "请输入用户名！",
          duration: 2000,
        });
        return;
      }
      if (!this.nickname.trim().length > 15) {
        console.log(this.nickname.trim().length);
        this.$message.warning({
          message: "用户名长度不得超出15字符！",
          duration: 2000,
        });
        return;
      }
      if (!this.address.length) {
        this.$message.warning({
          message: "请选择地址！",
          duration: 2000,
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message.warning({
          message: "请输入正确手机号！",
          duration: 2000,
        });
        return;
      }
      if (
        !/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(
          this.email
        )
      ) {
        this.$message.warning({
          message: "请输入正确邮箱！",
          duration: 2000,
        });
        return;
      }
      this.$http({
        url: "/api/information/v1.0/addInformation",
        method: "POST",
        data: {
          photo: this.avatar,
          nickName: this.nickname,
          sex: this.sex,
          birthday: this.birthday,

          province: this.addressInfo[0],
          city: this.addressInfo[1],
          area: this.addressInfo[2],
          areaId:
            this.address.constructor === Array ? this.address[2] : this.address,

          remark: this.remark,
          mobile: this.mobile,
          email: this.email,

          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success({
            message: "保存成功！",
            duration: 2000,
          });
          this.getInformation(this.user_id, true);
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 保存单个资料
    saveInfo() {
      this.$http({
        url: "/api/information/v1.0/addInformation",
        method: "POST",
        data: {
          photo: this.avatar,
          nickName: this.nickname,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          console.log("save success");
        }
        this.getInformation(this.user_id, true);
      });
    },

    // 获取我的地址
    getAddress() {
      this.$http({
        url: "/api/information/v1.0/getAddress",
        params: {
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.placeList = res.data.data;
          let pat = /(\d{3})\d*(\d{4})/;
          this.placeList.map((item) => {
            item.mobile = item.mobile.replace(pat, "$1****$2");
          });
        } else {
          console.log(res.data);
        }
      });
    },
    // 选择收货地址
    changeShop() {
      let address = this.$refs.myCascader1.getCheckedNodes()[0].pathLabels;
      if (
        address[1] == "市辖区" ||
        address[1] == "省辖区" ||
        address[1] == "辖区"
      ) {
        this.shop_address_info[0] = address[0];
        this.shop_address_info[1] = "";
        this.shop_address_info[2] = address[2];
      } else {
        this.shop_address_info = address;
      }
      console.log(this.shop_address_info);
    },

    // 保存收货地址
    saveAddressInfo() {
      if (!this.shop_name.trim()) {
        this.$message.warning({
          message: "请输入收货人姓名！",
          duration: 2000,
        });
        return;
      }
      if (!this.shop_name.trim().length > 15) {
        console.log(this.nickname.trim().length);
        this.$message.warning({
          message: "收货人名称长度不得超出15字符！",
          duration: 2000,
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.shop_mobile)) {
        this.$message.warning({
          message: "请输入正确手机号！",
          duration: 2000,
        });
        return;
      }
      if (!this.shop_address.length) {
        this.$message.warning({
          message: "请选择地址！",
          duration: 2000,
        });
        return;
      }
      if (this.shop_ainfo.trim().length < 5) {
        this.$message.warning({
          message: "详细地址长度需要在5-120字符！",
          duration: 2000,
        });
        return;
      }
      let data = {
        name: this.shop_name,
        address: this.shop_ainfo,
        province: this.shop_address_info[0],
        city: this.shop_address_info[1],
        area: this.shop_address_info[2],
        areaId:
          this.shop_address.constructor === Array
            ? this.shop_address[2]
            : this.shop_address,
        flag: this.shop_flag,
        mobile: this.shop_mobile,
        id: this.save_id,
        userId: this.user_id,
      };
      // 上传接口
      this.$http({
        url: "/api/information/v1.0/addUpAddress",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success({
            message: "添加成功！",
            duration: 2000,
          });
          // 根据id判断是否是修改
          if (this.save_id) {
            this.placeList[this.edit_id] = data;
          } else {
            data.id = res.data.msg;
            console.log(data);
            this.placeList.push(data);
          }
          this.is_dialog = false;
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 添加地址
    addArea() {
      this.is_dialog = true;
      this.edit_type = true;
      this.shop_name = "";
      this.shop_ainfo = "";
      this.shop_address_info = [];
      this.shop_address = "";
      this.shop_flag = 0;
      this.shop_mobile = "";
      this.save_id = "";
    },
    // 编辑收货地址
    upAddress(item, index) {
      this.shop_name = item.name;
      this.shop_ainfo = item.address;
      this.shop_address_info[0] = item.province;
      this.shop_address_info[1] = item.city;
      this.shop_address_info[2] = item.area;
      this.shop_address = item.areaId;
      this.shop_flag = item.flag;
      this.shop_mobile = item.mobile;
      this.save_id = item.id;
      this.is_dialog = true;
      this.edit_type = true;

      // 修改的index
      this.edit_id = index;
    },
    // 删除收货地址
    delAddress(id, index) {
      this.$http({
        url: "/api/information/v1.0/delAddress",
        method: "POST",
        data: {
          id: id,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.placeList.splice(index, 1);
          this.$message.info({
            message: "删除成功!",
            duration: 2000,
          });
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 设为默认
    setDefault(item, index) {
      item.flag = 1;
      for (let k in item) {
        if (k == "createTime") {
          delete item[k];
        }
        if (k == "updateTime") {
          delete item[k];
        }
      }
      let data = item;
      // 上传接口
      this.$http({
        url: "/api/information/v1.0/addUpAddress",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.code == 200) {
          this.placeList.forEach((val, key) => {
            if (key == index) {
              val.flag = 1;
            } else {
              val.flag = 0;
            }
          });
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },

    // 获取身份证
    getCard() {
      this.$http({
        url: "/api/information/v1.0/getCard",
        params: {
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.idCardList = res.data.data;
          this.idCardList.forEach((val) => {
            let temp = val.cardNo.substr(4, 10);
            val.cardNo = val.cardNo.replace(temp, "***********");
          });
        } else {
          console.log(res.data);
        }
      });
    },
    // 添加实名
    addVerified() {
      this.is_dialog = true;
      this.edit_type = false;
      this.card_name = "";
      this.card_type = "";
      this.idcard_num = "";
    },
    // 保存实名
    saveIdcard() {
      if (!this.card_name.trim()) {
        this.$message.warning({
          message: "请输入姓名！",
          duration: 2000,
        });
        return;
      }
      if (!this.card_name.trim().length > 15) {
        console.log(this.nickname.trim().length);
        this.$message.warning({
          message: "姓名长度不得超出15字符！",
          duration: 2000,
        });
        return;
      }
      if (!this.card_type) {
        this.$message.warning({
          message: "请选择证件类型！",
          duration: 2000,
        });
        return;
      }
      if (
        !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.idcard_num
        )
      ) {
        this.$message.warning({
          message: "请输入正确证件号！",
          duration: 2000,
        });
        return;
      }
      this.$http({
        url: "/api/information/v1.0/addUpCard",
        method: "POST",
        data: {
          id: this.card_id,
          cardNo: this.idcard_num,
          flag: 0,
          name: this.card_name,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = JSON.parse(res.config.data);
          data.id = res.data.msg;
          data.cardNo = data.cardNo.replace(
            data.cardNo.substr(4, 10),
            "***********"
          );
          this.is_dialog = false;
          this.idCardList.push(data);
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 编辑实名
    editCard(item, index) {
      console.log(item, index);
      this.is_dialog = true;
      this.edit_type = false;
      this.card_name = item.name;
      this.card_type = 1;
      this.idcard_num = item.cardNo;
    },
    // 删除实名
    delIdcard(id, index) {
      this.$http({
        url: "/api/information/v1.0/delCard",
        method: "POST",
        data: {
          id: id,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.idCardList.splice(index, 1);
          this.$message.info({
            message: "删除成功!",
            duration: 2000,
          });
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },

    // 选择文件 进行判断
    uploadPreview(file) {
      const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      const SIZE = file.size / 1024 / 1024 < 2;
      if (!TYPE) {
        this.$message.error({
          message: "请选择正确的图片！",
          center: true,
          duration: 2000,
        });
        return TYPE;
      }
      if (!SIZE) {
        this.$message.warning({
          message: "上传头像图片大小不能超过 2MB!",
          center: true,
          duration: 2000,
        });
        return SIZE;
      }
      return TYPE && SIZE;
    },
    // 获取上传信息
    uploadSuccess(data) {
      this.$showLoading("loading", ".el-upload");
      this.cropper.is_cropper = true;
      this.cropper.temp_name = data.file.name;
      this.cropper.temp_img = URL.createObjectURL(data.file);
      this.$refs.cropper.getInfo(this.cropper);
    },
    // 获取头像链接
    getChildInfo(val) {
      this.$refs.upload.clearFiles();
      if (val == "cancel") {
        console.log("cancel");
      } else {
        this.avatar = val;
        this.saveInfo();
      }
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      width: 855px;
      padding: 15px 20px;
      margin-right: 15px;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
      .avatar {
        width: 140px;
        margin: 0 auto;
      }
      .nickname {
        margin: 10px 10px 0 0;
        text-align: center;
        .input-name {
          width: 20%;
          padding: 5px;
          margin-right: 10px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
        & > span {
          margin-right: 10px;
        }
        .el-button {
          color: #d30404;
        }
      }
      h4 {
        margin: 40px 0 20px;
        font-weight: bold;
      }
      .item {
        display: flex;
        align-items: center;
        margin-top: 20px;
        ::v-deep.el-radio__input.is-checked .el-radio__inner {
          border-color: #ef4033;
          background-color: #ef4033;
        }
        ::v-deep.el-radio__inner:hover {
          border-color: #ef4033;
        }
        ::v-deep.el-radio__input.is-checked + .el-radio__label {
          color: #ef4033;
        }
        & > span {
          flex-shrink: 0;
          margin-right: 15px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .btn-style {
        width: 88px;
        margin: 40px 0 20px;
      }
      .address-box {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 40px;
        box-sizing: border-box;
        & > div {
          width: 290px;
          height: 130px;
          padding: 20px 25px;
          margin: 0 20px 20px 0;
          color: rgba(0, 0, 0, 0.6);
          border-radius: 8px;
          box-sizing: border-box;
          border: 1px solid #bababa;
          & > p:first-child {
            font-size: 14px;
            & > span {
              margin-right: 10px;
              font-weight: 500;
            }
          }
          & > p:nth-child(2) {
            height: 46px;
            margin: 10px 0;
            line-height: 23px;
            font-size: 12px;
          }
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .default {
            padding: 2px 5px !important;
            color: #faad14 !important;
            border: 1px solid #ffe58f !important;
            background-color: #fffbe6 !important;
          }
        }
      }
      .real-box {
        width: 80%;
        padding: 20px 40px;
        box-sizing: border-box;
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            margin-right: 10px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask-box {
  width: 45%;
  margin: 20vh auto 0;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 50px;
    border-bottom: 1px solid #e5e5e5;
    & > h4 {
      font-weight: bold;
    }
    & > i {
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .edit-div {
    width: 100%;
    padding: 30px 50px;
    box-sizing: border-box;
    & > div {
      p {
        margin: 20px 0 10px;
        font-size: 14px;
        color: #000;
      }
      .el-input {
        width: 40%;
      }
      .el-select {
        width: 40%;
      }
    }
    .btn-style {
      width: 88px;
      margin-top: 30px;
    }
  }
}
</style>
