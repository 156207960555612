<template>
	<div>
		<navs-top></navs-top>
		<section>
			<div class="main">
				<div class="left">
					<nav-left :classId="navId"></nav-left>
				</div>
				<div class="content">
					<div
						class="header-box"
						:style="{
							background: backgroundImage
								? 'url( ' + backgroundImage + ')'
								: '#d5d5d5',
						}"
					>
						<div class="edit-box">
							<div class="btn-edit" @click="$router.push('profile/edit')">
								编辑资料
							</div>
							<p v-if="0">
								{{ follow_count }} 关注了你
								<i
									class="iconfont icon-fenxiang_2"
									style="padding-left: 10px"
								></i>
							</p>
							<el-upload
								:limit="1"
								ref="upload"
								name="multipartFile"
								:show-file-list="false"
								:http-request="uploadSuccess"
								:before-upload="uploadPreview"
								action="none"
								accept="image/png,image/jpg,image/jpeg"
							>
								<!-- <span>change</span> -->
							</el-upload>
							<upload-cropper
								ref="cropper"
								:cropper="cropper"
								@getAvatar="getChildInfo"
							></upload-cropper>
						</div>
						<div class="info-box">
							<el-avatar
								class="avatar"
								shape="circle"
								:src="avatar"
								:size="125"
							></el-avatar>
							<div class="box">
								<div class="header">
									<p>
										<span class="nick">{{ nickname }}</span>
										<span
											v-if="stype == 1"
											class="icon-ui icon-authenticate"
										></span>
									</p>
									<p>{{ remark }}</p>
								</div>
								<div class="info">
									<div>
										<p>
											<span v-if="stype != 0">
												<i class="iconfont icon-shijian"></i>成立于{{
													createTime
												}}
											</span>
											<span v-if="stype == 0">
												<i class="iconfont icon-yinle"></i>{{ music_type }}
											</span>
											<span
												class="text-one"
												style="display: inline-block; width: 200px;"
												:title="address[1] ? address[1] : address[2]"
											>
												<i class="el-icon-location-outline"></i>
												{{ address[0] }} ·
												{{ address[1] ? address[1] : address[2] }}
											</span>
										</p>
										<p class="flex-right" @click="handleClose">
											<span><i class="el-icon-document"></i>详细介绍</span>
											<i class="el-icon-arrow-right"></i>
										</p>
										<p class="flex-right"  @click="handleClose1" v-if="stype == 1">
											<span
												><i class="iconfont icon-yonghu"></i>旗下艺术家</span
											>
											<i class="el-icon-arrow-right"></i>
										</p>
										<p class="flex-right" v-if="stype == 0">
											<span><i class="el-icon-date"></i>演出日历</span>
											<i class="el-icon-arrow-right"></i>
										</p>
										<p class="flex-right" v-if="stype == 2">
											<span><i class="el-icon-date"></i>档期日历</span>
											<i class="el-icon-arrow-right"></i>
										</p>
										<p class="flex-right" v-if="stype == 2">
											<span><i class="iconfont icon-shebei"></i>设备清单</span>
											<i class="el-icon-arrow-right"></i>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="content-box">
						<div class="nav-box">
							<div
								:class="{ active: n_id == index }"
								v-for="(item, index) in navList"
								:key="index"
								@click="saveIndex(index)"
							>
								{{ item }}
							</div>
						</div>
						<div class="live-box" v-if="n_id == 0">
							<a-item :activityList="liveList"></a-item>
							<!-- 分页 -->
							<div class="pagination" v-if="liveList.length">
								<el-pagination
									background
									layout="
                      prev,
                      pager,
                      jumper,
                      next"
									:total="total"
									:page-size="page_count"
									prev-text="前一页"
									next-text="下一页"
									@current-change="currentChange"
									@prev-click="prevClick"
									@next-click="nextClick"
								>
								</el-pagination>
							</div>
							<div class="no-data-ui" v-else>暂无演出</div>
						</div>
						<div class="msg-box" v-else>
							<div class="no-data-ui">暂无讨论</div>
						</div>
					</div>
				</div>
				<hot-right></hot-right>
			</div>
		</section>
		<!-- 详细介绍 -->
		<el-dialog
			title="详细介绍"
			:visible.sync="is_info"
			fullscreen
			:before-close="handleClose"
		>
			<div v-html="introduce"></div>
		</el-dialog>
		<!-- 艺术家 -->
		<el-dialog
			title="艺术家"
			:visible.sync="is_artists"
			fullscreen
			:before-close="handleClose1"
		>
			<div v-html="introduce"></div>
		</el-dialog>
	</div>
</template>

<script>
// 裁切
import uploadCropper from "../../../components/uploadCropper";
export default {
	name: "setting",
	components: {
		"upload-cropper": uploadCropper,
	},
	data() {
		return {
			navId: 1, // 侧边导航
			stype: null, // 0 艺术家 || 1 厂牌 || 2 场馆
			n_id: 0, // 顶部
			navList: ["即将演出", "留言讨论"],
			nickname: "用户名",
			remark: "这个家伙很懒，啥都没写",
			avatar: "",
			backgroundImage: "",
			follow_count: 0,
			music_type: "",
			createTime: "",
			address: [],
			introduce: "",
			is_info: false,
			is_artists: false,

			total: 0,
			page: 1,
			page_count: 20,
			liveList: [], // 演出列表
			messageList: [], // 留言列表

			studioId: null,

			cropper: {}
		};
	},
	mounted() {
		// 获取id
		this.stype = localStorage.getItem("stype");
		this.studioId = localStorage.getItem("sid");
		let info = JSON.parse(localStorage.getItem("userinfo"));
		if (info) {
			this.user_id = info.userId;
		}

		// 获取资料
		this.getStudioInfo();
		// 获取演出
		this.getStudioActivity();
	},
	methods: {
		// 获取资料
		getStudioInfo() {
			this.$http({
				url: "/api/authentication/v1.0/getStudio",
				params: {
					studioId: this.studioId,
					type: this.stype,
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let data = res.data.data;
					this.nickname = data.name;
					this.avatar = data.photo;
					this.backgroundImage = data.backgroundImage;
					this.follow_count = data.followCount;
					this.remark = data.remark;
					this.music_type = data.musicStyle;
					this.createTime = data.createTime;
					this.address[0] = data.province;
					this.address[1] = data.city;
					this.address[2] = data.area;
					this.introduce = data.introduce;
				}
			});
		},
		// 获取演出
		getStudioActivity() {
			this.$showLoading("loading...", ".main");
			this.$http({
				url: "/api/authentication/v1.0/getStudioActivity",
				params: {
					studioId: localStorage.getItem("sid"),
					pageNumber: this.page,
					pageSize: this.page_count,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.liveList = res.data.rows;
					this.total = res.data.total;
				} else {
					console.log(res.data.data);
				}
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
				this.$hideLoading();
			});
		},
		// 选择文件 进行判断
		uploadPreview(file) {
			const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
			const SIZE = file.size / 1024 / 1024 < 2;
			if (!TYPE) {
				this.$message.error({
					message: "请选择正确的图片！",
					center: true,
					duration: 2000,
				});
				return TYPE;
			}
			if (!SIZE) {
				this.$message.warning({
					message: "上传头像图片大小不能超过 2MB!",
					center: true,
					duration: 2000,
				});
				return SIZE;
			}
			return TYPE && SIZE;
		},
		// 获取上传信息
		uploadSuccess(data) {
			this.$showLoading("loading", ".header-box");
			this.cropper.is_cropper = true;
			this.cropper.temp_name = data.file.name;
			this.cropper.fixedNumber = [10, 3.5];
			this.cropper.autoCropWidth = 855;
			this.cropper.autoCropHeight = 270;
			this.cropper.temp_img = URL.createObjectURL(data.file);
			this.$refs.cropper.getInfo(this.cropper);
		},
		// 获取图片链接
		getChildInfo(val) {
			this.$refs.upload.clearFiles();
			if (val == "cancel") {
				console.log("cancel");
			} else {
				this.backgroundImage = val;
				this.changeBgimg();
			}
		},
		// 更换背景图
		changeBgimg() {
			this.$http({
				url: "/api/authentication/v1.0/upBackgroundImage",
				method: "POST",
				data: {
					image: this.backgroundImage,
					pid: localStorage.getItem("sid"),
				},
			}).then((res) => {
				console.log(res);
			});
		},
		saveIndex(id) {
			this.n_id = id;
		},
		// 上一页
		prevClick(e) {
			this.page = e;
			this.getStudioActivity();
		},
		// 下一页
		nextClick(e) {
			this.page = e;
			this.getStudioActivity();
		},
		// 页码
		currentChange(e) {
			// 上下重复调用
			if (this.page == e) {
				console.log("重复");
			} else {
				this.page = e;
				this.getStudioActivity();
			}
		},
		handleClose() {
			this.is_info = !this.is_info;
		},
		handleClose1() {
			this.is_artists = !this.is_artists;
		},
		// 详情
		gotoInfo(activityId, siteId) {
			this.$router.push({
				path: "/live-info",
				query: { aid: activityId, site: siteId },
			});
		},
	},
};
</script>
<style lang="less" scoped>
section {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 48px);
	margin-top: 48px;
	scroll-behavior: smooth;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		margin: 0 auto;
		box-sizing: border-box;
		.left {
			width: 210px;
		}
		.content {
			width: 855px;
			min-height: calc(100vh - 48px);
			height: 100%;
			margin-right: 15px;
			box-sizing: border-box;
			border-right: 1px solid #dadada;
			overflow-y: scroll;
			.header-box {
				position: relative;
				width: 100%;
				height: 270px;
				padding-top: 215px;
				box-sizing: border-box;
				background-repeat: no-repeat !important;
				background-size: 100% auto !important;
				background-color: #d5d5d5;
				.edit-box {
					position: absolute;
					right: 30px;
					top: 50px;
					width: 200px;
					text-align: right;
					.btn-edit {
						display: inline-block;
						width: 110px;
						height: 30px;
						margin-bottom: 10px;
						line-height: 30px;
						text-align: center;
						font-size: 16px;
						color: #ef4033;
						border-radius: 20px;
						border: 1px solid #ef4033;
						background-color: transparent;
						background: rgba(255, 255, 255, 0.1);
						cursor: pointer;
					}
					p {
						font-size: 16px;
						color: #fff;
					}
				}
				.info-box {
					position: relative;
					display: flex;
					width: 100%;
					box-sizing: border-box;
					.avatar {
						position: absolute;
						width: 125px;
						height: 125px;
						margin-left: 40px;
						border-radius: 50%;
						border: 5px solid #fff;
					}
					.box {
						width: 100%;
						box-sizing: border-box;
						.header {
							width: 100%;
							padding: 0 20px;
							box-sizing: border-box;
							background: linear-gradient(
								180deg,
								rgba(0, 0, 0, 0) 0%,
								rgba(0, 0, 0, 0.25) 100%
							);
							& > p {
								padding-bottom: 10px;
								margin-left: 180px;
								color: #fff;

								&:first-child {
									font-size: 18px;
									font-weight: 600;
								}
								&:last-child {
									font-size: 16px;
								}
								.nick {
									margin-right: 10px;
								}
								.icon-authenticate {
									vertical-align: text-top;
								}
							}
						}
						.info {
							padding: 15px 20px;
							margin-left: 180px;
							box-sizing: border-box;
							& > div {
								& > p {
									margin-bottom: 20px;
									font-size: 14px;
									span {
										margin-right: 50px;
										color: rgba(0, 0, 0, 0.65);
										i {
											font-size: 15px;
											height: 15px;
											margin-right: 8px;
										}
									}
								}
								.flex-right {
									display: flex;
									align-items: center;
									justify-content: space-between;
									width: 500px;
									.icon-yonghu {
										font-weight: bold;
									}
									.el-icon-arrow-right {
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}
		}
		.content-box {
			width: 100%;
			margin-top: 140px;
			.nav-box {
				width: 100%;
				display: flex;
				justify-content: space-around;
				padding: 30px 120px 20px;
				box-sizing: border-box;
				border-bottom: 2px solid #f1f1f1;
				& > div {
					font-size: 18px;
					font-weight: 500;
					color: #818181;
					cursor: pointer;
				}
				.active {
					position: relative;
					color: #000;
					&::after {
						content: "";
						position: absolute;
						bottom: -21px;
						left: -35px;
						width: 140px;
						border-bottom: 2px solid #ef4033;
					}
				}
			}
			.live-box {
				width: 100%;
				padding: 30px 0;
				box-sizing: border-box;
				.title {
					margin: 10px 0;
					font-weight: 600;
					color: #333;
					cursor: pointer;
				}
				.info {
					margin-bottom: 5px;
					font-size: 12px;
					color: #999;
					cursor: pointer;
				}
				.price {
					font-size: 14px;
					font-weight: bold;
					color: #e02020;
				}
			}
			.msg-box {
				padding: 30px;
				box-sizing: border-box;
			}
		}
		.right-box {
			width: 350px;
			height: 400px;
			background-color: #fff;
		}
	}
}
.pagination {
	margin-top: 80px;
	text-align: center;
}
/deep/.el-dialog .el-dialog__body {
	height: 80vh;
	color: #000;
	font-size: 14px;
	line-height: 22px;
	overflow-y: scroll;
}
</style>
