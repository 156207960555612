<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <h3>通知设置</h3>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "notice",
  data() {
    return {
      navId: 6,
    };
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      margin: 0 15px;
      padding: 40px 25px;
      box-sizing: border-box;
      background-color: #ffffff;
    }
  }
}
</style>
