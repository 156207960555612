<template>
	<div>
		<navs-top></navs-top>
		<section>
			<div class="main">
				<div class="left">
					<nav-left :classId="navId"></nav-left>
				</div>
				<div class="main-content">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '../activity' }"
							>活动管理</el-breadcrumb-item
						>
						<el-breadcrumb-item>创建演出活动</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- 顶部进度条 -->
					<div class="header-step" v-if="stepNum == 0">
						<div class="step active-ing">
							<div>1</div>
							<p>门票属性</p>
						</div>
						<span class="border-ui"></span>
						<div class="step">
							<div>2</div>
							<p>演出信息</p>
						</div>
						<span class="border-ui"></span>
						<div class="step">
							<div>3</div>
							<p>场次信息</p>
						</div>
					</div>
					<div class="header-step" v-else-if="stepNum == 1">
						<div class="step active">
							<div><i class="el-icon-check"></i></div>
							<p>门票属性</p>
						</div>
						<span class="border-ui-active"></span>
						<div class="step active-ing">
							<div>2</div>
							<p>演出信息</p>
						</div>
						<span class="border-ui"></span>
						<div class="step">
							<div>3</div>
							<p>场次信息</p>
						</div>
					</div>
					<div class="header-step" v-else>
						<div class="step active">
							<div><i class="el-icon-check"></i></div>
							<p>门票属性</p>
						</div>
						<span class="border-ui-active"></span>
						<div class="step active">
							<div><i class="el-icon-check"></i></div>
							<p>演出信息</p>
						</div>
						<span class="border-ui-active"></span>
						<div class="step active-ing">
							<div>3</div>
							<p>场次信息</p>
						</div>
					</div>
					<!-- 门票类型 -->
					<div v-show="stepNum == 0">
						<div class="type-box">
							<h4>设置门票属性</h4>
							<div class="type-sel">
								<div class="item">
									<p>演出类别</p>
									<el-select
										v-model="live_type"
										size="small"
										placeholder="请选择"
										@change="saveLivetype"
									>
										<el-option
											v-for="item in liveType"
											:key="item.dictValue"
											:label="item.dictLabel"
											:value="item.dictValue"
										>
										</el-option>
									</el-select>
								</div>
								<div class="item">
									<p>门票种类</p>
									<el-select
										v-model="tickets_type"
										size="small"
										placeholder="请选择"
										@change="saveTicketype"
									>
										<el-option
											v-for="item in ticketsType"
											:key="item.dictValue"
											:label="item.dictLabel"
											:value="item.dictValue"
											:disabled="item.dictValue > 1"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="type-sel" v-if="tickets_type != 1">
								<div class="item">
									<p>邮寄费用</p>
									<el-input
										size="small"
										type="number"
										v-model="post_cost"
										placeholder="请填写"
									>
										<template slot="prepend">￥</template>
									</el-input>
								</div>
								<div class="item">
									<p>纸质票制票</p>
									<el-select
										v-model="invoice_type"
										size="small"
										placeholder="请选择"
									>
										<el-option
											v-for="item in invoiceList"
											:key="item.dictValue"
											:label="item.dictLabel"
											:value="item.dictValue"
										>
										</el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div class="type-info">
							<div>
								<el-checkbox v-model="is_real">实名制购票</el-checkbox>
							</div>
							<div>
								<el-checkbox v-model="is_invoice">提供电子发票</el-checkbox>
							</div>
							<div class="message-tips">
								<i class="el-icon-warning" style="color: #faad14"></i>
								<div>
									<p>
										1.请您确保所填写为真实有效信息，并拥有合理的相关权利，如有侵权可能会被追究相关法律责任。
									</p>
									<p>
										2.我方深知你的身份信息拥有高度敏感性和保密性，我方将确保您的敏感信息只作用我方鉴赏您身份的真实性
									</p>
									<p>
										3.工作室资料部分内容可在审核通过后继续更改，并可以为您的工作室添加更多管理人员，超级管理员信息审核通过后不可以更改/转让。
									</p>
									<p>
										4.审核期间您可能收到我方的审核电话，审核周期将在几分钟或几小时内。
									</p>
								</div>
								<i
									class="el-icon-close"
									style="color: #999; cursor: pointer"
								></i>
							</div>
							<el-button
								type="danger"
								size="small"
								class="btn-style"
								@click="nextClick(1)"
								>下一步
							</el-button>
						</div>
					</div>
					<!-- 演出信息 -->
					<div v-show="stepNum == 1">
						<!-- 演出信息 -->
						<div class="show-info">
							<p class="title">设置名称海报</p>
							<div class="show-name">
								<p>演出名称</p>
								<el-input
									v-model="show_name"
									size="small"
									placeholder="请填写演出名称"
									maxlength="30"
									show-word-limit
								></el-input>
							</div>
						</div>
						<!-- 海报 -->
						<div class="poster">
							<p>上传海报</p>
							<div class="poster-box">
								<el-upload
									action="/api/public/v1.0/upload"
									name="multipartFile"
									ref="upload"
									:before-upload="beforeAvatarUpload"
									:http-request="photoSuccess"
									:limit="1"
									:show-file-list="false"
								>
									<img v-if="imageUrl" :src="imageUrl" class="avatar" />
									<div v-else class="upload">
										<i class="el-icon-plus"></i>
										<p>上传照片</p>
									</div>
								</el-upload>
								<div class="img-tip">
									<i
										class="el-icon-warning"
										style="color: rgb(250, 173, 20)"
									></i>
									<span>
										仅支持竖幅3:4比例海报，超出部分自行裁剪
									</span>
								</div>
							</div>
						</div>
						<!-- 搜索并添加艺术家 -->
						<div class="artist-box">
							<p class="title">搜索并添加艺术家</p>
							<div class="artist-list">
								<div
									class="item"
									v-for="(item, index) in artistList"
									:key="index"
								>
									<img :src="item.photo" alt="" />
									<p class="text-one">{{ item.name }}</p>
									<i @click="delectArtis(index)" class="el-icon-close"></i>
								</div>
							</div>
							<!-- 搜索框 -->
							<div class="search-box">
								<el-input
									prefix-icon="el-icon-search"
									v-model="searchVal"
									size="small"
									@keyup.enter.native="searchPeople"
									placeholder="搜索并添加艺术家账号"
								></el-input>
								<el-button size="small" @click="searchPeople">检索</el-button>
							</div>
							<!-- 搜索列表 -->
							<div
								class="search-list people-list"
								v-show="searchList.length && show_list"
							>
								<div class="header-close">
									<span>艺术家名称</span>
									<i class="el-icon-close" @click="show_list = false"></i>
								</div>
								<div
									class="item"
									v-for="(item, index) in searchList"
									:key="index"
								>
									<img :src="item.photo" alt="" />
									<span class="user-name text-one">{{ item.name }}</span>
									<span class="btn-join" @click="addNowActive(item, 0, index)">
										添加
									</span>
								</div>
							</div>
							<div class="join-list" v-show="searchVal">
								<p>没检索到“{{ searchVal }}”?</p>
								<el-button size="mini" @click="goJoinPeople">
									直接添加
								</el-button>
							</div>
							<!-- 宣传片 -->
							<div class="video-box" v-if="0">
								<h4>演出宣传片（可选）</h4>
								<div class="link">
									<span>腾讯视频链接</span>
									<el-input
										v-model="video_link"
										size="small"
										placeholder="链接地址，仅支持腾讯视频"
									></el-input>
								</div>
								<!-- 上传 -->
								<div class="upload-video">
									<h4>或者点击上传（二选一即可）</h4>
									<div>
										<el-upload
											action="/api/public/v1.0/upload"
											:headers="headers"
											name="multipartFile"
											:show-file-list="false"
											:before-upload="beforeUpload"
											:limit="1"
											:on-success="videoSuccess"
										>
											<video src="" v-if="video_src"></video>
											<div class="upload" v-else>
												<i class="el-icon-plus"></i>
												<p>点击上传</p>
											</div>
										</el-upload>
									</div>
								</div>
							</div>
							<!-- 演出补充 -->
							<div class="live-info">
								<h4>演出图文介绍</h4>
								<div id="editor-container">
									<quillEditor
										id="div1"
										ref="myQuillEditor"
										:content="content"
										:options="editorOption"
										@paste.native.stop="imagePaste"
										@change="onEditorChange($event)"
									></quillEditor>
								</div>
								<div>
									<el-button
										@click="previous(0)"
										size="small"
										:disabled="Boolean(ticketPerformanceId)"
									>
										上一步
									</el-button>
									<el-button
										class="btn-style"
										size="small"
										@click="nextClick(2)"
									>
										下一步
									</el-button>
								</div>
							</div>
						</div>
					</div>
					<!-- 场次信息 -->
					<div v-show="stepNum == 2">
						<!-- 场次列表 -->
						<div class="session-box">
							<div class="header-top">
								<h4>场次列表</h4>
								<el-button
									size="mini"
									plain
									icon="el-icon-plus"
									@click="addSession"
									>添加场次
								</el-button>
							</div>
							<div class="session-list">
								<div
									v-for="(item, index) in sessionList"
									class="session-item"
									:key="index"
								>
									<div
										class="simplify"
										@click="clickSession(index, item.siteId)"
										v-show="!item.is_own"
									>
										<div class="btn text-one">{{ item.siteName }}</div>
										<span class="venue-name text-one" :title="item.venueName">
											{{ item.venueName }}
										</span>
										<div class="text-one spec-list" v-if="item.specList.length">
											<span
												class="bolden lightGray"
												v-for="(sp, sindex) in item.specList"
												:key="sindex"
											>
												{{ sp.specificationsName }}
											</span>
										</div>
										<div class="lightGray" v-else>(未添加票种)</div>
										<span
											class="text-one"
											style="width: 100px"
											:title="item.specName"
											>{{ item.specName }}</span
										>
										<span class="success" v-if="item.status == 0">已发布</span>
										<span class="info" v-else-if="item.status == 1"
											>已结束</span
										>
										<span class="info" v-else-if="item.status == 2"
											>已取消</span
										>
										<span class="info" v-else-if="item.status == 3"
											>待审核</span
										>
										<span class="success" v-else-if="item.status == 4"
											>已通过</span
										>
										<span class="danger" v-else-if="item.status == 5"
											>人工审核</span
										>
										<span class="danger" v-else-if="item.status == 6"
											>未通过</span
										>
										<span class="info" v-else>待提交</span>
									</div>
									<div class="own" v-show="item.is_own">
										<div class="name">
											<span>{{ item.siteName }}</span>
										</div>
										<div class="info">
											<div class="header" @click="clickSession(index)">
												<span
													class="venue-name text-one"
													:title="item.venueName"
												>
													场馆：{{ item.venueName }}
												</span>
												<span>
													演出时间：{{ item.startTime }} -- {{ item.endTime }}
												</span>
												<span class="flex-right">
													<i
														class="iconfont icon-qianbi-"
														@click.stop="infoSite(item.siteId)"
													></i>
													<i
														class="el-icon-close"
														@click.stop="delSite(item.siteId)"
													></i>
												</span>
											</div>
											<div class="box">
												<div
													class="ticket-item add-ticket"
													@click="addTicket(index)"
												>
													<p>添加票种</p>
													<i class="el-icon-circle-plus-outline"></i>
												</div>
												<div
													class="ticket-item"
													v-for="(titem, tindex) in item.ticketList"
													:key="tindex"
												>
													<div class="ticketh">
														<p class="text-one" :title="titem.name">
															{{ titem.name }}
														</p>
														<span style="flex-shrink: 0">
															<i
																@click.stop="infoSpec(titem)"
																class="iconfont icon-qianbi-"
															></i>
															<i
																class="el-icon-close"
																@click.stop="delSpec(item.siteId, titem.id)"
															></i>
														</span>
													</div>
													<p>价格：￥{{ titem.price }}</p>
													<p>限购：{{ titem.limitTicket }}张/人</p>
													<p>核销次数：{{ titem.frequency }}次/张</p>
													<p>库存：{{ titem.initStock }}张</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 审核按钮 -->
						<div class="btn-bottom">
							<el-button size="small" @click="previous(1)" plain
								>上一步</el-button
							>
							<el-button
								type="danger"
								size="small"
								class="btn-style"
								:loading="is_auditVerification"
								@click="auditVerification"
								>提交审核</el-button
							>
						</div>
					</div>
				</div>
			</div>
			<!-- 场次信息 -->
			<el-dialog
				title="场次信息"
				:visible.sync="show_session"
				width="60%"
				:close-on-click-modal="false"
				custom-class="dialog-box"
			>
				<div class="session-hbox">
					<div>
						<p>场次名称</p>
						<el-input
							v-model="session_name"
							size="small"
							maxlength="6"
							show-word-limit
							placeholder="例：北京站"
						></el-input>
					</div>
					<div>
						<p>演出时间</p>
						<div class="date-box">
							<el-date-picker
								:picker-options="pickerOptions"
								v-model="showTime[0]"
								type="datetime"
								size="small"
								value-format="yyyy-MM-dd HH:mm"
								placeholder="选择日期时间"
							>
							</el-date-picker>
							<i class="el-icon-sort-down"></i>
							<el-date-picker
								:picker-options="pickerOptions1"
								v-model="showTime[1]"
								type="datetime"
								size="small"
								value-format="yyyy-MM-dd HH:mm"
								placeholder="选择结束时间"
							>
							</el-date-picker>
						</div>
					</div>
				</div>
				<div style="display: flex">
					<!-- 添加场馆 -->
					<div class="place-hbox" @click="closePathList($event)">
						<p>演出场馆</p>
						<!-- 已经添加场馆 -->
						<div class="place-list">
							<div class="item" v-show="placeList.name">
								<img :src="placeList.photo" alt="" />
								<p class="text-two" :title="placeList.name">
									{{ placeList.name }}
								</p>
								<i @click="delectPlace" class="el-icon-minus"></i>
							</div>
						</div>
						<!-- 搜索框 -->
						<div class="search-box">
							<el-input
								prefix-icon="el-icon-search"
								v-model="session_area"
								size="small"
								placeholder="搜索并添加场馆"
								@change="is_session_list = true"
								@keyup.enter.native="searchSession"
							></el-input>
							<el-button size="mini" @click="searchSession"> 检索 </el-button>
							<!-- 搜索列表 -->
							<div
								class="search-list area_list_id"
								v-show="is_session_list && selPlaceList.length"
							>
								<div
									class="list"
									v-for="(item, index) in selPlaceList"
									:key="index"
									@click="addNowActive(item, 1)"
								>
									<img :src="item.photo" alt="" />
									<span class="user-name text-one" :title="item.name">{{
										item.name
									}}</span>
								</div>
							</div>
						</div>
						<!-- 直接添加 -->
						<div class="add-directly">
							没有搜索到“{{ session_area }}”?
							<el-link type="primary" :underline="false" @click="is_map = true">
								从地图内添加
							</el-link>
						</div>
					</div>
					<!-- 演出批文 -->
					<div class="mt20 poster">
						<p>演出批文</p>
						<div class="poster-box mt20">
							<el-upload
								action="/api/public/v1.0/upload"
								name="multipartFile"
								ref="upload"
								:before-upload="beforeAvatarUpload"
								:http-request="photoSuccess"
								:limit="1"
								:show-file-list="false"
							>
								<img v-if="official_url" :src="official_url" class="avatar" />
								<div v-else class="upload">
									<i class="el-icon-plus"></i>
									<p>上传照片</p>
								</div>
							</el-upload>
							<div class="img-tip">
								<i class="el-icon-warning" style="color: rgb(250, 173, 20)"></i>
								<span>
									请上传本场演出批文照片，不上传演出批文的活动可能会被限流展示
								</span>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" class="btn-style" @click="saveSession">
						保存
					</el-button>
				</span>
			</el-dialog>
			<!-- 票种信息 -->
			<el-dialog
				title="票种信息"
				:visible.sync="show_ticket"
				width="60%"
				:close-on-click-modal="false"
				custom-class="dialog-box"
			>
				<div class="ticket-info">
					<div>
						<p>票种名称</p>
						<el-input
							size="small"
							style="width: 60%"
							v-model="ticket_name"
							maxlength="20"
							show-word-limit
							placeholder="例：预售票"
						></el-input>
					</div>
					<div>
						<p>门票价格</p>
						<el-input
							size="small"
							style="width: 20%"
							v-model="ticket_price"
							placeholder="数值"
						></el-input>
						<span>元</span>
					</div>
					<div>
						<p>开售时间</p>
						<el-date-picker
							:picker-options="pickerOptions"
							v-model="byTime[0]"
							type="datetime"
							size="small"
							style="width: 60%"
							value-format="yyyy-MM-dd HH:mm"
							placeholder="选择开售时间"
						>
						</el-date-picker>
					</div>
					<div>
						<p>停售时间</p>
						<el-date-picker
							:picker-options="pickerOptions"
							v-model="byTime[1]"
							type="datetime"
							size="small"
							style="width: 60%"
							value-format="yyyy-MM-dd HH:mm"
							placeholder="选择停售时间"
						>
						</el-date-picker>
					</div>
					<div>
						<p>限购数量</p>
						<el-input-number
							v-model="max_num"
							size="small"
							controls-position="right"
							:min="0"
							placeholder="数值"
						></el-input-number>
						<span>张/人</span>
						<span style="font-size: 12px; color: #999">(0为不限购)</span>
					</div>
					<div>
						<p>库存数量</p>
						<el-input-number
							v-model="inventory_num"
							size="small"
							controls-position="right"
							:min="0"
							placeholder="数值"
						></el-input-number>
						<span>张/人</span>
					</div>
					<div>
						<p>核销次数</p>
						<el-input-number
							v-model="verification_num"
							size="small"
							controls-position="right"
							:min="1"
							disabled
							placeholder="数值"
						></el-input-number>
						<span>张/人</span>
					</div>
					<div>
						<p>备注说明</p>
						<el-input
							size="small"
							style="width: 80%"
							v-model="tips_info"
							placeholder="请输入"
							maxlength="50"
							show-word-limit
						></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" class="btn-style" @click="saveTicket">
						保存
					</el-button>
				</span>
			</el-dialog>
			<!-- 地图 -->
			<el-dialog class="map-box" width="60%" :visible.sync="is_map" center>
				<p class="area-view">地址：{{ map_address_info }}</p>
				<mapView @getAddress="getAddressInfo"></mapView>
				<div slot="footer">
					<el-button @click="is_map = false">取消</el-button>
					<el-button type="primary" @click="changeMask">确定</el-button>
				</div>
			</el-dialog>
			<!-- 裁切 -->
			<upload-cropper
				ref="cropper"
				:cropper="cropper"
				@getAvatar="getChildInfo"
			></upload-cropper>
			<!-- 富文本上传图片隐藏 -->
			<el-upload
				class="disnone"
				action="/api/public/v1.0/upload"
				name="multipartFile"
				ref="rich"
				:headers="headers"
				:before-upload="beforeAvatarUpload"
				:on-success="richUpload"
				:limit="1"
				:show-file-list="false"
			>
			</el-upload>
		</section>
	</div>
</template>

<script>
// 地图
import mapView from "../../../components/mapView";
// 裁切
import uploadCropper from "../../../components/uploadCropper";

// require styles
// import Quill from 'quill';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 富文本
import { quillEditor } from "vue-quill-editor";

export default {
	name: "activityedit",
	components: {
		mapView,
		"upload-cropper": uploadCropper,
		quillEditor,
	},
	data() {
		return {
			navId: 2,
			stepNum: 0, // 0 门票属性 || 1 演出信息 || 2 场次信息

			// step1
			live_type: "", // 演出类别
			live_name: "",
			liveType: [],
			tickets_type: "", // 门票种类
			tickets_name: "",
			ticketsType: [],
			post_cost: "", // 邮寄费用
			invoice_type: "", // 纸发票
			invoiceList: [],
			is_real: false, // 实名制购票
			is_invoice: false, // 提供电子发票
			//

			// step2
			show_name: "", // 演出名称
			imageUrl: "", // 海报图片
			statusFlag: true, // 海报违规状态
			searchVal: "", // 搜索的值
			artistList: [], // 已经添加的艺术家
			searchList: [], // 搜索的艺术家
			show_list: false, //
			no_search: false,
			video_src: "", // 视频链接
			video_link: "", // 演出宣传视频链接
			htmlInfo: "", // 演出信息
			contentFormat: "", // 演出信息app格式
			//

			// step3
			// 场次
			siteId: "", // 场次id
			show_session: false, // 显示场次添加
			sessionList: [], // 场次列表
			session_name: "", // 场次的名称
			is_session_list: false, // 搜索场馆俩表
			session_area: "", // 演出场Area地
			sessionAreaList: [], // 演出场地列表
			placeList: {}, // 场地列表
			selPlaceList: [], // 搜索的场地列表
			official_url: "", // 批文图片
			showTime: [], // 演出时间
			// 设置日期限制为当天
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now();
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					// let stime = this.showTime[0];
					// if (stime) {
					//   return time.getTime() < new Date(stime).getTime();
					// } else {
					return time.getTime() < Date.now();
					// }
				},
			},
			// 修改
			upSiteId: null, // 场次id
			upVenueId: null, // 场馆标识id
			specId: null, // 规格id

			// 地图
			is_map: false,
			map_info: 0, // 地图信息
			map_address_info: "", // 详细地址

			// 规格
			show_ticket: false, // 显示票中添加弹框
			ticketList: [],
			ticket_name: "", // 票种名称
			ticket_price: null, // 门票价格
			byTime: [], // 开售周期
			max_num: null, // 限购数量
			inventory_num: null, // 库存数量
			verification_num: null, // 核销数量
			tips_info: "", // 备注说明

			is_auditVerification: false,
			headers: {
				Authorization: localStorage.getItem("token"),
			},
			ticketPerformanceId: "", // 活动id
			cropper: {},
			user_id: "", // 用户id

			content: "",
			editorOption: {
				modules: {
					toolbar: {
						container: [
							"bold",
							"italic",
							"image",
							{ header: [1, 2, 3, false] },
						],
						handlers: {
							image: function(value) {
								console.log(value);
								if (value) {
									document.querySelector(".disnone .el-upload__input").click();
								} else {
									this.quill.format("image", false);
								}
							},
						},
					},
				},
				placeholder: "填写演出介绍",
				theme: "snow",
			},
		};
	},
	created() {
		// 如果有活动id 且没有离开编辑页面进入二步骤
		let aid = localStorage.getItem("aid");
		if (aid) {
			this.stepNum = 2;
			this.getSesstionList();
		}
	},
	mounted() {
		// 获取用户信息
		let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
		if (userinfo) {
			this.user_id = userinfo.userId;
		}

		// this.initEditBox();

		let aid = localStorage.getItem("aid");
		if (!aid) {
			// 获取演出类别
			this.getStudioType("show_type", 1);
			// 获取门票类型
			this.getStudioType("ticket_type", 2);
			// 质票方
			this.getStudioType("ticket_maker_type", 3);
		}
	},
	methods: {
		// 富文本复制图片上传文件
		imagePaste(event) {
			let items;
			items = event.clipboardData && event.clipboardData.items;
			var file = null;
			if (items && items.length) {
				// 检索剪切板items
				for (var i = 0; i < items.length; i++) {
					if (items[i].type.indexOf("image") !== -1) {
						if (items[i].kind === "file" && items[i].type.match(/^image\//i)) {
							event.preventDefault();
							file = items[i].getAsFile();
						}
						break;
					}
				}
			}
			if (file) {
				this.$showLoading();
				var formData = new FormData();
				formData.append("multipartFile", file);
				this.uploadimg(formData);
			}
		},
		// 上传图片
		uploadimg(formData) {
			this.$http({
				url: "/api/public/v1.0/upload",
				method: "POST",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				data: formData,
			})
				.then((res) => {
					if (res.data.code == 200) {
						console.log(res);
						this.richUpload(res.data);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		// 富文本保存信息
		onEditorChange(e) {
			console.log(e.html);
			let code = JSON.stringify(e.quill.getContents().ops);
			// app文本格式
			this.contentFormat = code
				.replace(
					/{"insert":{"image":/g,
					'{"insert":"​","attributes":{"embed":{"type":"image","source":'
				)
				.replace(/"}}/g, '"}}}')
				.replace(/bold/g, "b")
				.replace(/italic/g, "i")
				.replace(/header/g, "heading");
			this.htmlInfo = e.html;
		},
		// 富文本上传图片
		richUpload(res, file) {
			if (res.code == 200) {
				this.$hideLoading();
				let index = this.$refs.myQuillEditor.quill.getSelection().index;
				this.$refs.myQuillEditor.quill.insertEmbed(index, "image", res.msg);
				this.$refs.rich.clearFiles();
			} else {
				this.$hideLoading();
				this.$message.warning("图片上传失败请重试");
				console.log(res, file);
			}
		},
		// 获取各种类型
		getStudioType(type, id) {
			this.$http({
				url: "/api/public/v1.0/getStudioType",
				params: {
					type: type,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					if (id == 1) {
						this.liveType = res.data.data;
					} else if (id == 2) {
						this.ticketsType = res.data.data;
					} else if (id == 3) {
						this.invoiceList = res.data.data;
					}
				}
			});
		},
		// 保存演出类型
		saveLivetype(e) {
			this.live_name = this.liveType[e].dictLabel;
		},
		// 保存票类型
		saveTicketype(e) {
			if (e == 1) {
				this.post_cost = "";
				this.invoice_type = "";
			}
			this.tickets_name = this.ticketsType[e].dictLabel;
		},
		// 选择文件 进行判断
		beforeAvatarUpload(file) {
			const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
			const SIZE = file.size / 1024 / 1024 < 10;
			if (!TYPE) {
				this.$message.error({
					message: "请选择正确的图片！",
					center: true,
					duration: 2000,
				});
				return TYPE;
			}
			if (!SIZE) {
				this.$message.warning({
					message: "上传头像图片大小不能超过 10MB!",
					center: true,
					duration: 2000,
				});
				return SIZE;
			}
			if (SIZE && TYPE) {
				this.$showLoading("loading");
			}
			return TYPE && SIZE;
		},
		// 上传成功
		photoSuccess(data) {
			this.$showLoading("loading");
			this.cropper.is_cropper = true;
			this.cropper.autoCropWidth = 300;
			this.cropper.autoCropHeight = 400;
			this.cropper.temp_name = data.file.name;
			this.cropper.fixedNumber = [3, 4];
			this.cropper.temp_img = URL.createObjectURL(data.file);
			this.$refs.cropper.getInfo(this.cropper);
		},

		// 获取头像链接
		getChildInfo(val) {
			this.$refs.upload.clearFiles();
			if (val == "cancel") {
				console.log("cancel");
			} else if (val == "违规") {
				this.statusFlag = false;
			} else {
				if (this.stepNum == 1) {
					this.imageUrl = val;
				} else if (this.stepNum == 2) {
					this.official_url = val;
				}
			}
		},
		// 上传视频
		beforeUpload(file) {
			console.log(file);
			const TYPE = [
				"video/avi",
				"video/mpg",
				"video/mpeg",
				"video/mp4",
			].includes(file.type);
			const SIZE = file.size / 1024 / 1024 < 30;
			if (!TYPE) {
				this.$message.error({
					message: "请选择正确的视频！",
					center: true,
					duration: 2000,
				});
				return TYPE;
			}
			if (!SIZE) {
				this.$message.warning({
					message: "上传视频大小不能超过 30MB!",
					center: true,
					duration: 2000,
				});
				return SIZE;
			}
			return TYPE && SIZE;
		},
		// 视频
		videoSuccess(res) {
			if (res.code == 200) {
				this.video_src = res.msg;
			} else {
				this.$message({
					message: res.msg,
					type: "warning",
				});
			}
		},
		// 搜索艺术家
		searchPeople() {
			if (!this.searchVal.trim()) {
				this.$message.warning({
					message: "请填写艺术家名称！",
					duration: 2000,
				});
				return false;
			}
			this.searchList = [];
			this.getActivityMessage(this.searchVal, 0);
			this.$showLoading("loading...", ".people-list,.search-box");
		},
		// 直接添加艺术家
		goJoinPeople() {
			this.$prompt("请输入艺名", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			}).then(({ value }) => {
				if (value) {
					let data = {
						artistId: "",
						flag: 1,
						name: value,
						photo:
							"http://rockguys.oss-cn-beijing.aliyuncs.com/1608629544582?Expires=1923989545&OSSAccessKeyId=LTAIa7gW7U6KUAJm&Signature=bQAk7TyIjG5muXOUzcONuKYDCDY%3D",
						ticketPerformanceId: "",
					};
					this.artistList.push(data);
				} else {
					this.$message.warning("请输入艺术家名称!");
				}
			});
		},
		// 删除艺术家
		delectArtis(id) {
			this.artistList.splice(id, 1);
		},
		// 搜索场馆
		searchSession() {
			if (!this.session_area.trim()) {
				this.$message.warning({
					message: "请填场馆名称！",
					duration: 2000,
				});
				return false;
			}
			this.getActivityMessage(this.session_area.trim(), 2);
			this.$showLoading("loading...", ".area_list_id");
		},
		// 获取  艺术家 || 场馆
		getActivityMessage(name, type) {
			this.$http({
				url: "/api/activity/v1.0/getActivityMessage",
				params: {
					name: name,
					type: type,
				},
			}).then((res) => {
				this.$hideLoading();
				if (res.data.code == 200) {
					if (!res.data.data.length) {
						this.no_search = true;
						this.$notify({
							title: "提示",
							message: "暂未搜索到相关信息！",
							type: "warning",
						});
					}
					if (type == 0) {
						this.searchList = res.data.data;
						this.show_list = true;
					} else {
						this.selPlaceList = res.data.data;
						this.is_session_list = true;
					}
				} else {
					this.$notify({
						title: "提示",
						message: "暂未搜索到相关信息！",
						type: "warning",
					});
				}
			});
		},
		// 添加存在的艺术家 || 场馆
		addNowActive(info, type, index) {
			console.log(info);
			info.flag = 0;
			if (type == 0) {
				info.artistId = info.id;
				this.artistList.push(info);
				this.searchList.splice(index, 1);
			} else if (type == 1) {
				info.venueStudioId = info.id;
				this.is_session_list = false;
				this.placeList = info; // 场馆信息
			}
		},

		// 下一步
		nextClick(id) {
			if (this.stepNum == 0) {
				if (!(parseInt(this.live_type) + 1)) {
					this.$message.error({
						message: "请选择演出类型！",
						duration: 2000,
					});
					return;
				}
				if (!this.tickets_type) {
					this.$message.error({
						message: "请选择门票种类！",
						duration: 2000,
					});
					return;
				}
				if (this.tickets_type != 1) {
					if (!this.post_cost) {
						this.$message.error({
							message: "请填写邮寄费用！",
							duration: 2000,
						});
						return;
					}
					if (!this.invoice_type) {
						this.$message.error({
							message: "请选择纸质发票！",
							duration: 2000,
						});
						return;
					}
				}
				// 纸质发票方
				if (this.is_real) {
					this.is_real = 1;
				} else {
					this.is_real = 0;
				}
				// 是否提供电子发票
				if (this.is_invoice) {
					this.is_invoice = 1;
				} else {
					this.is_invoice = 0;
				}
				this.stepNum = id;
				// this.$nextTick(() => {
				// 	this.initEditBox();
				// });

				// console.log(
				// 	this.live_type,
				// 	this.tickets_type,
				// 	this.post_cost,
				// 	this.invoice_type,
				// 	this.is_real,
				// 	this.is_invoice
				// );
				this.stepNum = 1;
			} else if (this.stepNum == 1) {
				if (!this.show_name.trim()) {
					this.$message.error({
						message: "请填写演出名称！",
						duration: 2000,
					});
					return;
				}
				if (!this.imageUrl.length) {
					this.$message.error({
						message: "请上传海报！",
						duration: 2000,
					});
					return;
				}
				if (!this.artistList.length) {
					this.$message.error({
						message: "请添加艺术家！",
						duration: 2000,
					});
					return;
				}
				// if (this.video_link.length || this.video_src.length) {
				//   this.$message.warning({
				//     message: "请添加宣传视频！",
				//     duration: 2000,
				//   });
				//   return;
				// }
				// if (!this.htmlInfo.trim()) {
				// 	this.$message.error({
				// 		message: "请添加演出图文介绍！",
				// 		duration: 2000,
				// 	});
				// 	return;
				// }
				let temp_aid = this.ticketPerformanceId;
				// 添加门票属性 演出信息
				this.$showLoading();
				this.addTicketPerform(temp_aid);
			}
		},

		// 添加门票属性 演出信息
		addTicketPerform(id) {
			if (this.invoice_type) {
				var ticketMakerTypeName =
					this.invoiceList[this.invoice_type].dictLabel || "";
			}
			this.$http({
				url: "/api/newActivity/v1.0/addTicketPerform",
				method: "POST",
				data: {
					artist: this.artistList,
					showType: this.live_type,
					showTypeName: this.live_name,
					ticketType: this.tickets_type,
					ticketTypeName: this.tickets_name,
					ticketMakerType: this.invoice_type,
					ticketMakerTypeName: ticketMakerTypeName,
					mailingFee: this.post_cost,
					isRealName: this.is_real,
					isElectronicInvoice: this.is_invoice,

					name: this.show_name,
					poster: this.imageUrl,
					introduce: this.htmlInfo,
					contentFormat: this.contentFormat,

					id: id,
					statusFlag: this.statusFlag,
					userId: this.user_id,
					studioId: localStorage.getItem("sid"),
				},
			}).then((res) => {
				if (res.data.code == 200) {
					localStorage.setItem("aid", res.data.msg);
					// 获取场次信息
					// this.getTicketsInfo(res.data.msg);
					// this.ticketPerformanceId = res.data.msg;
					this.stepNum = 2;
				} else {
					this.$message.warning({
						message: res.data.msg,
						duration: 2000,
					});
				}
				this.$hideLoading();
			});
		},

		// 获取场次
		getSesstionList() {
			this.$http({
				url: "/api/newActivity/v1.0/getSiteList",
				params: {
					activityId: localStorage.getItem("aid"),
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.sessionList = res.data.data;
					this.sessionList.forEach((item) => {
						item.is_own = false;
						item.startTime = item.startTime.slice(0, -7).replace(/-/g, "/");
						item.endTime = item.endTime.slice(0, -7).replace(/-/g, "/");
					});
				} else {
					this.$message.warning(res.data.msg);
				}
			});
		},
		// 添加场次
		addSession() {
			this.show_session = true;
			this.session_name = "";
			this.showTime = [];
			this.placeList = {};
			this.upVenueId = "";
			this.upSiteId = "";
		},
		// 获取场馆地图地址
		getAddressInfo(data) {
			this.map_info = data || 0;
			this.map_address_info =
				this.map_info.province +
				this.map_info.city +
				this.map_info.district +
				this.map_info.address +
				this.map_info.name;
		},
		// 地图弹框
		changeMask() {
			if (this.map_info == 0) {
				this.$alert("请输入场馆地址！", "提示", {
					confirmButtonText: "确定",
				});
				return;
			}
			let data = {
				flag: 1,
				name: this.map_info.name,
				photo:
					"http://rockguys.oss-cn-beijing.aliyuncs.com/1609239990883?Expires=1924599991&OSSAccessKeyId=LTAIa7gW7U6KUAJm&Signature=ZvBWBk0PGQ863kuUi2bFy%2Fprhmc%3D",
				ticketPerformanceId: "",
				venueStudioId: "",
				latitude: this.map_info.pois.lat,
				longitude: this.map_info.pois.lng,
				province: this.map_info.province,
				city: this.map_info.city,
				area: this.map_info.district,
				areaId: this.map_info.areaid,
				address: this.map_info.address,
			};
			this.is_map = false;
			this.placeList = data;
			console.log(data);
		},
		// 删除场馆
		delectPlace() {
			this.placeList = {};
		},
		// 获取场次详细信息
		infoSite(id) {
			this.show_session = true;
			this.$http({
				url: "/api/newActivity/v1.0/getSite",
				params: {
					siteId: id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let data = res.data.data;
					this.session_name = data.siteName;
					this.showTime[0] = data.startTime;
					this.showTime[1] = data.endTime;

					this.placeList.flag = data.venueFlag ? data.venueFlag : 0;
					this.placeList.photo = data.photo;
					this.placeList.address = data.address;
					this.placeList.province = data.province;
					this.placeList.city = data.city;
					this.placeList.area = data.area;
					this.placeList.areaId = data.areaId;
					this.placeList.latitude = data.latitude;
					this.placeList.longitude = data.longitude;
					this.placeList.name = data.venueName;
					this.placeList.venueStudioId = data.venueStudioId;
					this.official_url = data.approvalImage;

					this.upVenueId = data.venueId;
					this.upSiteId = data.siteId;
				}
			});
		},
		// 保存场次
		saveSession() {
			if (!this.session_name.trim()) {
				this.$message.warning("请输入场次名称!");
				return;
			}
			if (!this.showTime[0]) {
				this.$message.warning("请选择开始时间!");
				return;
			}
			if (!this.showTime[1]) {
				this.$message.warning("请选择结束时间!");
				return;
			}
			if (!this.placeList.name) {
				this.$message.warning("请选择演出场馆!");
				return;
			}
			let data = {
				siteName: this.session_name,

				startTime: this.showTime[0],
				endTime: this.showTime[1],

				photo: this.placeList.photo,
				address: this.placeList.address,
				province: this.placeList.province,
				city: this.placeList.city,
				area: this.placeList.area,
				areaId: this.placeList.areaId,
				latitude: this.placeList.latitude,
				longitude: this.placeList.longitude,
				venueFlag: this.placeList.flag,
				venueName: this.placeList.name,
				venueStudioId: this.placeList.venueStudioId,
				approvalImage: this.official_url,

				venueId: this.upVenueId,

				userId: this.user_id,
				siteId: this.upSiteId,
				activityId: localStorage.getItem("aid"),
			};
			this.$showLoading();
			if (this.upSiteId) {
				// 修改
				this.upSite(data);
			} else {
				// 添加
				this.upSessionInfo(data);
			}
		},
		// 上传场次
		upSessionInfo(data) {
			this.$http({
				url: "/api/newActivity/v1.0/addSite",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					window.location.reload();
				} else {
					this.$message.warning(res.data.msg);
				}
				this.$hideLoading();
			});
		},
		// 修改上传场次
		upSite(data) {
			this.$http({
				url: "/api/newActivity/v1.0/upSite",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					window.location.reload();
				} else {
					this.$message.warning(res.data.msg);
				}
				this.$hideLoading();
			});
		},
		// 删除场次
		delSite(id) {
			this.$confirm("此操作将永久删除该场次, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
				center: true,
			})
				.then(() => {
					this.$http({
						url: "/api/newActivity/v1.0/delSite",
						method: "POST",
						params: {
							siteId: id,
						},
					}).then((res) => {
						if (res.data.code == 200) {
							this.$message.info("删除成功！");
							setTimeout(() => {
								window.location.reload();
							}, 2000);
						} else {
							this.$message.warning(res.data.msg);
						}
					});
				})
				.catch(() => {
					console.log("取消！");
				});
		},

		// 关闭场馆列表
		closePathList(e) {
			if (e.target.className == "place-hbox") {
				this.is_session_list = false;
			}
		},

		// 选择场次 || 获取规格
		clickSession(index, id) {
			this.siteId = id;
			this.sessionList[index].is_own = !this.sessionList[index].is_own;
			if (this.sessionList[index].is_own && id) {
				this.getTickets(index, id);
			}
			this.$forceUpdate();
		},

		// 添加票种规格
		addTicket(index) {
			// 添加票种时的场次id
			this.siteId = this.sessionList[index].siteId;
			this.show_ticket = true;
			this.ticket_name = "";
			this.ticket_price = 0;
			this.byTime = [];
			this.max_num = 0;
			this.inventory_num = 1;
			this.verification_num = 1;
			this.tips_info = "";
			this.tips_info = "";
			this.specId = "";
		},
		// 获取规格
		getTickets(index, id) {
			this.$http
				.get("/api/newActivity/v1.0/getSpecList", { params: { siteId: id } })
				.then((res) => {
					if (res.data.code == 200) {
						this.sessionList[index].ticketList = res.data.data;
						this.$forceUpdate();
					} else {
						this.$message.warning(res.data.msg);
					}
				});
		},
		// 票种详情
		infoSpec(info) {
			// console.log(info);
			this.show_ticket = true;
			this.ticket_name = info.name;
			this.ticket_price = info.price;
			this.byTime[0] = info.startTime;
			this.byTime[1] = info.endTime;
			this.max_num = info.limitTicket;
			this.inventory_num = info.stock;
			this.verification_num = info.frequency;
			this.tips_info = info.remark;
			this.siteId = info.siteId;
			this.specId = info.id;
		},
		// 保存票种
		saveTicket() {
			if (!this.ticket_name.trim()) {
				this.$message.warning("请输入票种名称!");
				return;
			}
			if (
				!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(this.ticket_price)
			) {
				this.$message.warning("请输入正确的门票价格!");
				return;
			}
			if (!this.byTime[0]) {
				this.$message.warning("请选择开售时间!");
				return;
			}
			if (!this.byTime[1]) {
				this.$message.warning("请选择停售时间!");
				return;
			}
			let data = {
				specificationsName: this.ticket_name,
				price: this.ticket_price,
				specStartTime: this.byTime[0],
				specEndTime: this.byTime[1],
				limitTicket: this.max_num,
				stock: this.inventory_num,
				frequency: this.verification_num,
				remark: this.tips_info,
				siteId: this.siteId,
				specId: this.specId,
				activityId: localStorage.getItem("aid"),
			};
			if (this.specId) {
				this.upSpec(data);
			} else {
				// 添加
				this.upTicketInfo(data);
			}
		},
		// 上传票种
		upTicketInfo(data) {
			console.log(data);
			this.$http({
				url: "/api/newActivity/v1.0/addSpec",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					this.$message.success("添加成功");
					window.location.reload();
				} else {
					this.$message.warning(res.data.msg);
				}
			});
		},
		// 修改票种规格
		upSpec(data) {
			this.$http({
				url: "/api/newActivity/v1.0/upSpec",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					window.location.reload();
				} else {
					this.$message.warning(res.data.msg);
				}
			});
		},
		// 删除票种规格
		delSpec(id, sid) {
			this.$confirm("此操作将永久删除该票种, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
				center: true,
			})
				.then(() => {
					this.$http({
						url: "/api/newActivity/v1.0/delSpec",
						method: "POST",
						params: {
							siteId: id,
							specId: sid,
						},
					}).then((res) => {
						if (res.data.code == 200) {
							this.$message.info("删除成功！");
							window.location.reload();
						} else {
							this.$message.warning(res.data.msg);
						}
					});
				})
				.catch(() => {
					console.log("取消！");
				});
		},

		// 获取门票属性 演出信息
		getTicketsInfo() {
			this.$showLoading();
			this.$http({
				url: "/api/activity/v1.0/getTickerAttribute",
				params: {
					ticketPerformanceId: localStorage.getItem("aid"),
				},
			}).then((res) => {
				if (res.data.code == 200) {
					// console.log(res);
					let data = res.data.data.data;
					this.live_type = data.showType;
					this.live_name = data.showTypeName;
					this.tickets_type = data.ticketType;
					this.tickets_name = data.ticketTypeName;
					this.is_real = data.isRealName;
					this.is_invoice = data.isElectronicInvoice;
					this.post_cost = data.mailingFee;

					this.artistList = data.artist;

					this.show_name = data.name;
					this.imageUrl = data.poster;
					this.htmlInfo = data.introduce;
					this.content = data.introduce;
					this.ticketPerformanceId = data.id;
					// this.initEditBox();

					// 获取艺术家
					// this.getSiteArtist();
				} else {
					console.log(res);
				}
				this.$hideLoading();
			});
		},

		// 获取艺术家
		getSiteArtist(siteid) {
			this.$http({
				url: "/api/newActivity/v1.0/getSiteArtist",
				params: {
					siteId: siteid,
				},
			}).then((res) => {
				console.log(res);
			});
		},

		// 上一步
		previous(step) {
			this.stepNum = step;
			if (step == 1) {
				// 获取场次信息
				this.getTicketsInfo();
			}
		},

		// 提交审核
		auditVerification() {
			// status: null 待提交 || 0 已发布 || 1 已结束 || 2 已取消 || 3 待审核 || 4 已通过 || 5 人工审核 || 6 未通过
			// let is_spec = "";
			if (!this.sessionList.length) {
				this.$message.error("请先添加场次！");
				return false;
			}
			let arr = this.sessionList.map((item) => {
				return item.specList.length;
			});
			if (arr.indexOf(0) > -1) {
				this.$message.error("请先添加票种！");
				return false;
			}
			this.is_auditVerification = true;
			this.$http({
				url: "/api/activity/v1.0/upStatus",
				method: "POST",
				params: {
					activityId: localStorage.getItem("aid"),
					status: 1,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.$message.success({
						message: "操作成功，请耐心等待审核！",
						duration: 2000,
					});
					setTimeout(() => {
						this.$router.replace("/studio/verifying");
					}, 2000);
				} else {
					this.$message.warning({
						message: res.data.msg,
						duration: 3000,
					});
				}
				setTimeout(() => {
					this.is_auditVerification = false;
				}, 1500);
			});
		},
	},
};
</script>
<style lang="less" scoped>
section {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 48px);
	margin-top: 48px;
	background-color: #fff;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		height: 100%;
		min-height: calc(100vh - 48px);
		margin: 0 auto;
		box-sizing: border-box;
		background-color: #f5f5f5;
	}
	.left {
		width: 210px;
	}
	.main-content {
		width: 1190px;
		padding: 15px 25px;
		margin: 0 15px;
		box-sizing: border-box;
		background-color: #fff;
		.el-breadcrumb {
			margin-bottom: 40px;
		}
		.header-step {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 90%;
			margin-bottom: 30px;
			.border-ui {
				display: inline-block;
				width: 31%;
				margin: 0 20px;
				border: 1px dotted #000;
			}
			.border-ui-active {
				display: inline-block;
				width: 31%;
				margin: 0 20px;
				border: 1px solid #d30404;
			}
			.step {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				& > div {
					width: 30px;
					height: 30px;
					margin-right: 10px;
					text-align: center;
					line-height: 30px;
					color: #bababa;
					border-radius: 50%;
					box-sizing: border-box;
					border: 1px solid #bababa;
				}
				& > p {
					font-size: 16px;
					color: #bababa;
					font-weight: 400;
				}
			}
			.active {
				& > div {
					color: #d30404 !important;
					border: 1px solid #d30404 !important;
				}
				& > p {
					font-size: 16px !important;
					color: #000 !important;
					font-weight: 500 !important;
				}
			}
			.active-ing {
				& > div {
					color: #fff !important;
					border: none !important;
					background-color: #d30404 !important;
				}
				& > p {
					color: #000 !important;
					font-weight: 600 !important;
				}
			}
		}
		.type-box {
			width: 100%;
			& > h4 {
				font-weight: bold;
			}
		}
		.type-info {
			& > div {
				margin-bottom: 24px;
			}
			::v-deep.el-checkbox__inner:hover {
				border-color: #d30404;
			}
			::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
				color: #d30404;
			}
			::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
			::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				border-color: #d30404;
				background-color: #d30404;
			}
			::v-deep.el-checkbox__input.is-focus .el-checkbox__inner {
				border-color: #d30404;
			}
			.message-tips {
				display: flex;
				align-items: baseline;
				justify-content: space-around;
				width: 55%;
				padding: 10px 0;
				border-radius: 10px;
				border: 1px solid #ffe58f;
				background-color: #fffbe6;
				& > div {
					width: 90%;
					font-size: 14px;
					font-weight: 500;
					color: #666;
					& > p {
						margin-top: 2px;
						line-height: 20px;
						letter-spacing: 1px;
					}
				}
			}
		}
		// 演出信息
		.show-info {
			.title {
				font-size: 16px;
				font-weight: 600;
				color: #000;
			}
			.show-name {
				margin: 25px 0;
				p {
					margin-bottom: 10px;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.85);
				}
				.el-input {
					width: 90%;
				}
			}
		}
		.artist-box {
			margin-top: 25px;
			.artist-list {
				display: flex;
				flex-wrap: wrap;
				margin: 20px 0;
				.item {
					position: relative;
					margin: 0 30px 10px 0;
					& > img {
						width: 65px;
						height: 65px;
						border-radius: 50%;
					}
					& > p {
						width: 70px;
						padding-top: 10px;
						font-size: 12px;
						text-align: center;
						color: rgba(0, 0, 0, 0.55);
					}
					& > i {
						position: absolute;
						top: 0;
						right: 0;
						font-size: 13px;
						color: rgba(0, 0, 0, 0.65);
						border-radius: 50%;
						border: 1px solid #000;
						cursor: pointer;
					}
				}
			}
			.video-box {
				margin: 30px 0;
				.link {
					display: flex;
					align-items: center;
					margin: 10px 0;
					& > span {
						font-size: 13px;
					}
					.el-input {
						width: 50%;
						margin-left: 20px;
						border: none !important;
					}
					::v-deep.el-input__inner {
						color: #666;
						font-size: 13px;
						border: none !important;
					}
				}
				.upload-video {
					& > div {
						margin: 20px 0;
						video {
							width: 220px;
							height: 125px;
							border-radius: 5px;
						}
						.upload {
							width: 220px;
							height: 125px;
							padding-top: 35px;
							text-align: center;
							border-radius: 5px;
							box-sizing: border-box;
							font-size: 14px;
							color: rgba(0, 0, 0, 0.45);
							background-color: rgba(0, 0, 0, 0.02);
							border: 1px dashed rgba(0, 0, 0, 0.15);
							i {
								font-weight: bold;
								font-size: 16px;
								padding-bottom: 10px;
							}
						}
					}
				}
			}
			.live-info {
				margin-top: 20px;
				.preview {
					margin: 20px 0;
				}
			}
			#editor-container {
				all: initial;
				margin: 0 !important;
				padding: 0 !important;
				background-color: #fafafa !important;
				#div1 {
					margin: 20px 0;
					font-size: 14px !important;
					font-family: "微软雅黑" !important;
					h1 {
						font-size: 2em;
						margin: 0.67em 0;
					}
					h2 {
						font-size: 1.5em;
						margin: 0.75em 0;
					}
					h3 {
						font-size: 1.17em;
						margin: 0.83em 0;
					}
				}
			}
		}
		// 输入 选择 框 布局
		.type-sel {
			display: flex;
			margin: 30px 0;
			.item {
				width: 50%;
				::v-deep.el-select {
					width: 80%;
				}
				.el-input {
					width: 80%;
				}
				& > p {
					margin-bottom: 10px;
					font-size: 14px;
					color: #000;
				}
			}
		}
		// 顶部效果
		.header-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 90%;
			h4 {
				font-weight: 600;
			}
		}

		.session-box {
			width: 100%;
			.session-list {
				display: flex;
				flex-wrap: wrap;
				.session-item {
					width: 90%;
					margin: 20px 0;
					border-radius: 10px;
					box-sizing: border-box;
					box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
					.simplify {
						display: flex;
						align-items: center;
						padding: 30px;
						font-size: 16px;
						color: #777;
						cursor: pointer;
						& > div,
						& > span {
							flex-shrink: 0;
							margin-right: 20px;
							font-weight: bold !important;
						}
						.btn {
							width: 80px;
							height: 30px;
							padding: 0 10px;
							line-height: 30px;
							text-align: center;
							border-radius: 20px;
							box-sizing: border-box;
							background-color: #f1f1f3;
						}
						.success {
							margin-left: auto;
							color: #67c23a;
						}
						.danger {
							margin-left: auto;
							color: #f56c6c;
						}
						.info {
							margin-left: auto;
							color: #909399;
						}
					}
					.venue-name {
						flex-shrink: 0;
						width: 300px;
					}
					.spec-list {
						width: 35%;
					}
					.own {
						display: flex;
						width: 100%;
						height: 280px;
						.name {
							display: flex;
							flex-direction: column;
							justify-content: center;
							flex-shrink: 0;
							width: 80px;
							height: 100%;
							text-align: center;
							border-radius: 10px 0 0 10px;
							background-color: #f1f1f3;
							& > span {
								display: inline-block;
								width: 20px;
								margin: 0 auto;
								line-height: 22px;
								font-size: 16px;
								font-weight: bold;
								white-space: normal;
								word-break: break-all;
								color: #666;
							}
						}
						.info {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							width: calc(100% - 80px);
							padding: 25px 30px;
							box-sizing: border-box;
						}
						.header {
							display: flex;
							align-items: center;
							justify-content: space-evenly;
							width: 100%;
							cursor: pointer;
							& > span {
								// flex-shrink: 0;
								font-size: 14px;
								color: #333;
							}
							.flex-right {
								// margin-left: auto;
								& > i {
									padding: 5px;
									margin-left: 20px;
									color: #9ea4ab;
									font-weight: bold;
									border-radius: 50%;
									background-color: #f1f1f3;
									cursor: pointer;
								}
								.el-icon-close {
									font-size: 18px;
								}
							}
						}
						.box {
							flex-shrink: 0;
							display: flex;
							flex-wrap: nowrap;
							width: 100%;
							overflow-x: scroll;
							&::-webkit-scrollbar {
								display: inline-block !important;
								width: 5px;
								height: 5px;
								opacity: 0;
							}
							.ticket-item {
								flex-shrink: 0;
								width: 160px;
								height: 190px;
								padding: 20px 15px;
								margin-right: 15px;
								border-radius: 5px;
								box-sizing: border-box;
								background-color: #fafafa;
								& > p {
									font-size: 13px;
									line-height: 30px;
									color: #797979;
								}
							}
							.ticketh {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-bottom: 15px;
								& > p {
									font-size: 14px;
									font-weight: bold;
									color: #333;
								}
								& > span {
									i {
										margin-right: 5px;
										font-size: 16px;
										font-weight: bold;
										color: #9ea4ab;
										cursor: pointer;
									}
								}
							}
							.add-ticket {
								text-align: center;
								color: #d9dadb;
								& > p {
									margin-top: 50px;
									font-size: 17px;
									font-weight: bold !important;
									cursor: pointer;
									&:hover {
										color: #d5d5d5;
									}
								}
								& > i {
									margin-top: 10px;
									font-size: 20px;
									font-weight: bold !important;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
		.btn-bottom {
			width: 90%;
			margin: 80px 0 40px;
			text-align: center;
			.el-button {
				margin: 0 30px;
			}
		}
	}

	// 场馆
	.place-list {
		display: flex;
		flex-wrap: wrap;
		margin: 20px 0;
		.item {
			position: relative;
			margin: 0 30px 10px 0;
			& > img {
				width: 65px;
				height: 65px;
				border-radius: 50%;
			}
			& > p {
				width: 70px;
				padding-top: 10px;
				font-size: 12px;
				text-align: center;
				color: rgba(0, 0, 0, 0.55);
			}
			& > i {
				position: absolute;
				top: 0;
				right: 5px;
				padding: 1px;
				font-size: 13px;
				color: #fff;
				border-radius: 50%;
				background-color: #ef4033;
				cursor: pointer;
			}
		}
	}
	// 搜索艺人场馆的样式
	.search-box {
		display: flex;
		.el-input {
			width: 460px;
			margin-right: 20px;
		}
	}

	// 艺术家列表
	.people-list {
		width: 460px;
		max-height: 400px;
		padding: 0 20px;
		overflow-y: scroll;
		box-sizing: border-box;
		border: 1px solid #f2f2f2;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		.header-close {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding-top: 10px;
			font-size: 14px;
			color: #999;
			box-sizing: border-box;
			& > i {
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
	// 列表
	.search-list {
		z-index: 999;
		.item {
			display: flex;
			align-items: center;
			margin: 20px 0;
			font-weight: 500;
			& > img {
				width: 35px;
				height: 35px;
				margin-right: 10px;
				border-radius: 50%;
			}
			.user-name {
				width: 300px;
				font-size: 12px;
				color: rgba(0, 0, 0, 0.55);
			}
			.btn-join {
				margin-left: auto;
				font-size: 13px;
				color: #d30404;
				cursor: pointer;
			}
		}
	}
	.join-list {
		& > p {
			font-size: 13px;
			margin: 10px 0;
			color: rgba(0, 0, 0, 0.65);
		}
	}

	.map-box {
		.area-view {
			margin: 10px 0 20px;
		}
		::v-deep.el-dialog__header {
			padding: 0;
		}
		.el-input {
			width: 40%;
			margin-right: 20px;
		}
	}
}
/deep/ .dialog-box {
	border-radius: 15px;
	.el-dialog__header {
		padding: 15px 40px;
		border-bottom: 1px solid #e5e5e5;
		& > span {
			color: #333;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.el-dialog__body {
		padding: 20px 40px;
	}
	.el-dialog__footer {
		text-align: center;
	}
	.el-dialog__close {
		color: #000;
		font-size: 18px;
		font-weight: bold;
		&:hover {
			color: #333;
		}
	}
	.session-hbox {
		display: flex;
		& > div {
			width: 50%;
			p {
				color: #333;
				margin-bottom: 10px;
			}
			.el-input {
				width: 80%;
			}
			.date-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				& > i {
					transform: rotate(270deg);
					color: #999;
				}
				.el-date-editor {
					width: 45%;
				}
			}
		}
	}
	.place-hbox {
		width: 50%;
		height: 250px;
		margin-top: 25px;
		& > p {
			margin-bottom: 10px;
		}
		.search-box {
			position: relative;
			width: 85%;
			.search-list {
				position: absolute;
				top: 34px;
				width: 15vw;
				height: 200px;
				border-radius: 10px;
				overflow-y: scroll;
				background-color: #fff;
				box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
					0px 6px 16px 0px rgba(0, 0, 0, 0.08),
					0px 3px 6px -4px rgba(0, 0, 0, 0.12);
				.list {
					display: flex;
					align-items: center;
					padding: 0 25px;
					height: 37px;
					line-height: 37px;
					box-sizing: border-box;
					cursor: pointer;
					&:hover {
						background-color: rgba(0, 0, 0, 0.05);
					}
					& > img {
						width: 20px;
						height: 20px;
						margin-right: 10px;
						border-radius: 50%;
					}
					& > span {
						font-size: 14px;
						color: rgba(0, 0, 0, 0.65);
					}
				}
			}
		}
	}
	.ticket-info {
		display: flex;
		flex-wrap: wrap;
		& > div {
			width: 50%;
			margin-bottom: 10px;
			& > p {
				margin: 10px 0;
				color: #000;
			}
			& > span {
				margin-left: 10px;
				font-size: 13px;
				color: #000;
			}
		}
		/deep/.el-input__inner {
			text-align: left;
		}
	}
}
.add-directly {
	margin-top: 30px;
	color: #000;
	.el-link {
		margin-left: 10px;
		color: #285af8;
		border-bottom: 1px solid #285af8;
		&:hover {
			color: #285af8;
		}
	}
}
.approval {
	img {
		width: 90px;
		margin-top: 20px;
	}
}
.flex-box {
	display: flex;
	align-items: flex-end;
}
.tag-box {
	display: flex;
	& > span {
		line-height: 20px;
	}
}
.poster {
	& > p {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);
	}
	.poster-box {
		display: flex;
		align-items: flex-end;
		margin: 10px 0;
		.avatar {
			width: 90px;
			height: 120px;
			border-radius: 5px;
		}
		.img-tip {
			display: flex;
			i {
				margin: 0 10px;
			}
			span {
				width: 260px;
				font-size: 14px;
				letter-spacing: 1px;
				line-height: 1.2;
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}
}
.upload {
	width: 90px;
	height: 120px;
	padding-top: 35px;
	text-align: center;
	border-radius: 5px;
	box-sizing: border-box;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.45);
	background-color: rgba(0, 0, 0, 0.02);
	border: 1px dashed rgba(0, 0, 0, 0.15);
	i {
		font-weight: bold;
		font-size: 16px;
		padding-bottom: 10px;
	}
}
</style>
