<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <!-- 财务记录 -->
          <div class="financial-records">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '../capital' }">
                资金管理
              </el-breadcrumb-item>
              <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>

            <!-- 选择条件 -->
            <div class="condition-box">
              <div>
                <p class="font12 m-color-3">
                  <span class="font26 mr5">
                    {{ up_date ? up_date.slice(5, 7) : up_date }}
                  </span>
                  月 / {{ up_date ? up_date.slice(0, 4) : up_date }}
                </p>
                <p class="mt15 mb20 font14 m-color-3">
                  <span class="mr30">
                    支出￥{{ capitalInfo.outlay ? capitalInfo.outlay : "0.00" }}
                  </span>
                  <span
                    >收入￥{{
                      capitalInfo.income ? capitalInfo.income : "0.00"
                    }}</span
                  >
                </p>
              </div>
              <el-dropdown @command="handleCommand" trigger="click">
                <span class="el-dropdown-link">
                  {{ text_tip ? text_tip : "全部交易类型" }}
                  <i class="el-icon-arrow-down el-icon--right bolden"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in typeList"
                    :key="index"
                    :command="index"
                  >
                    {{ item.dictLabel }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <!-- 列表 -->
            <div
              class="financial-list"
              v-infinite-scroll="loadMore"
              infinite-scroll-disabled="busy"
              infinite-scroll-distance="30"
            >
              <div class="item" v-for="(info, id) in merchantFlows" :key="id">
                <div
                  v-if="info.flowType == 1 || info.flowType == 2"
                  class="fin-info cursor-p"
                  @click="gotoInfo('/studio/capital/info', info.flowId)"
                >
                  <img
                    class="mr15"
                    src="../../assets/images/icon/Mask.png"
                    alt=""
                    v-if="info.businessType == 1"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/tuik.png"
                    alt=""
                    v-else-if="info.businessType == 3"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/mdown.png"
                    alt=""
                    v-else-if="info.businessType == 5"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/give.png"
                    alt=""
                    v-else-if="info.businessType == 6"
                  />
                  <div class="info-tit">
                    <p class="m-color-1 font16 text-two">
                      {{ info.businessTypeDes }}
                    </p>
                    <p class="font12 lightGray">{{ info.createTime }}</p>
                  </div>
                </div>
                <div class="fin-info" v-else>
                  <img
                    class="mr15"
                    src="../../assets/images/icon/qit.png"
                    alt=""
                  />
                  <div class="lineh20">
                    <p class="m-color-1 font16 text-two">
                      {{ info.businessTypeDes }}
                    </p>
                    <p class="font12 lightGray">{{ info.createTime }}</p>
                  </div>
                </div>
                <span class="font18 bolden m-color-3">
                  {{ info.flowType == 1 ? "+" : info.flowType == 2 ? "-" : "" }}
                  {{ info.amount }}
                </span>
              </div>
              <div class="no-data font14" v-if="!merchantFlows.length">
                暂无财务记录
              </div>
              <p
                class="bottom-style m-color-1"
                v-show="noMore && merchantFlows.length"
              >
                <i class="el-icon-hot-water"></i>没有更多了
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "capitalinfo",
  data() {
    return {
      navId: 3,

      page: 1,
      page_count: 10,
      busy: true,
      noMore: false,
      sel_tip: "",
      text_tip: "",
      up_date: null,
      typeList: [],

      capitalInfo: "",
      merchantFlows: [],
      user_id: "",
    };
  },
  mounted() {
    // 获取userid
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.userId;
    }
    this.up_date = this.$route.query.date;

    // 获取资金详情信息
    this.getFlowCny();
    // 获取交易类型
    this.getDicts();
  },
  methods: {
    // 获取资金信息
    getFlowCny() {
      this.$showLoading("loading...", ".content");
      this.$http({
        url: "/api/wallet/getFlowCny",
        params: {
          dateQuery: this.up_date,
          pageNo: this.page,
          pageSize: this.page_count,
          businessType: this.sel_tip,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.capitalInfo = res.data.data;
          if (this.merchantFlows.length) {
            this.merchantFlows = this.merchantFlows.concat(
              res.data.data.merchantFlows
            );
          } else {
            this.merchantFlows = res.data.data.merchantFlows;
          }
          if (res.data.data.merchantFlows.length) {
            this.busy = false;
          } else {
            this.busy = true;
            this.noMore = true;
          }
        } else {
          console.log(res);
        }
        this.$hideLoading();
      });
    },
    // 获取交易类型
    getDicts() {
      this.$http({
        url: "/api/public/v1.0/getDicts",
        params: {
          type: 1,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = {
            dictLabel: "全部",
            dictValue: "",
          };
          this.typeList = res.data.data;
          setTimeout(() => {
            this.typeList.push(data);
          }, 500);
        } else {
          console.log(res);
        }
      });
    },
    // 选择条件
    handleCommand(e) {
      this.text_tip = this.typeList[e].dictLabel;
      this.sel_tip = this.typeList[e].dictValue;
      this.busy = true;
      this.noMore = false;
      this.page = 1;
      this.merchantFlows = "";
      this.getFlowCny();
    },
    // 加载
    loadMore() {
      this.busy = true;
      this.page++;
      this.getFlowCny();
    },
    //
    gotoInfo(url, date) {
      this.$router.push({
        path: url,
        query: { date: date },
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      height: 100%;
      margin: 0 15px;
      box-sizing: border-box;
      background-color: #f5f5f5;

      .financial-records {
        width: 100%;
        height: calc(100vh - 50px);
        padding: 30px;
        box-sizing: border-box;
        background-color: #fff;
        .condition-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 10px;
          .btn-all {
            padding: 2px 5px;
            color: #fff;
            border-radius: 4px;
            background-color: #ef4033;
            cursor: pointer;
          }
          .el-dropdown-link {
            font-size: 16px;
            font-weight: 600;
            color: #111c60;
            cursor: pointer;
          }
        }
        .financial-list {
          height: calc(100vh - 200px);
          overflow-y: scroll;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            .fin-info {
              display: flex;
              & > img {
                width: 50px;
                height: 50px;
              }
              & > div {
                display: flex;
                flex-direction: column;
              }
            }
            .info-tit {
              width: 90%;
              line-height: 1.6;
            }
          }
        }
      }
    }
  }
}
.no-data {
  line-height: 10vh;
  text-align: center;
  color: #8287ab;
}
.el-select {
  ::v-deep.el-select-dropdown {
    width: 440px !important;
    left: -218px !important;
  }
}
::v-deep.el-dropdown-menu__item {
  padding: 0 35px;
  text-align: center;
}
::v-deep.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fdf2f2;
}

.btn-bank {
  display: inline-block;
  height: 30px;
  padding: 0 10px;
  margin-left: 15px;
  line-height: 30px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(223deg, #ef4033 0%, #e70030 100%);
}

.m-color-1 {
  color: #061058;
}
.m-color-2 {
  color: #8287ab;
}
.m-color-3 {
  color: #111c60;
}
.mt80 {
  margin-top: 80px;
}
</style>
