<template>
	<div>
		<div v-title :data-title="live_title"></div>
		<nav-top></nav-top>
		<section>
			<div class="main">
				<div class="fixed-box">
					<div class="left">
						<index-left :classId="navId"></index-left>
					</div>
				</div>
				<div class="content">
					<div class="header">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: '/' }">
								Live Music
							</el-breadcrumb-item>
							<el-breadcrumb-item> 演出页详情 </el-breadcrumb-item>
						</el-breadcrumb>
						<div class="live-info-header">
							<img :src="live_poster" alt="" />
							<div>
								<h3 class="text-two">{{ live_title }}</h3>
								<div class="info">
									<p>时间：{{ live_startTime }} {{ live_week }}</p>
									<p>
										场馆：{{ live_city ? live_city : live_province }}
										{{ live_area }} ·
										{{ live_venueName }}
									</p>
									<p>
										地址：{{ live_address }}
										<el-button
											type="text"
											size="small"
											@click="map_type = true"
										>
											查看地图
										</el-button>
									</p>
									<div class="ticket-box">
										<span>票品：</span>
										<div>
											<el-button
												v-for="(item, index) in ticketList"
												:class="{ active: index == sid }"
												size="mini"
												:key="index"
												plain
												@click="saveIndex(index)"
												>{{ item.price }}
												{{ item.specName }}
											</el-button>
										</div>
									</div>
									<p class="tips">
										<i class="el-icon-warning-outline"></i>
										<!-- 该票在 {{ sid ? ticketList[sid].specEndTime : live_endTime }} 停止售票 -->
										该票在 {{ live_endTime }} 停止售票
									</p>
									<el-button
										type="danger"
										size="small"
										class="btn-style"
										:class="{ 'btn-end': live_status }"
										@click="showQrcode"
										:disabled="Boolean(live_status)"
									>
										{{ live_status ? "售票结束" : "立即购买" }}
									</el-button>
								</div>
							</div>
						</div>
					</div>
					<div class="live-more">
						<div class="nav-tab" id="boxFixed" :class="{ fixtop: isFixed }">
							<span
								:class="{ active: nid == index }"
								v-for="(item, index) in tabList"
								:key="index"
								@click="saveNindex(index)"
								>{{ item }}
							</span>
						</div>
						<!-- 演出信息 -->
						<div class="live-msg" v-if="nid == 0">
							<h4 v-if="sessionList.length > 1">其他场次</h4>
							<div class="step-box" v-if="sessionList.length > 1">
								<i
									class="el-icon-arrow-left"
									v-if="sessionList.length > 8"
									@click="moreSesstion(0)"
								></i>
								<slider ref="slider" :options="options" class="step">
									<slideritem
										v-for="(item, index) in sessionList"
										:key="index"
										:class="{
											active: item.isStatus,
											actived: item.status == 1,
										}"
										@click.native="saveLindex(item.siteId, index)"
									>
										<span></span>
										<p class="info text-one" :title="item.name">
											{{ item.name }}
										</p>
										<p class="info">{{ item.time }}</p>
									</slideritem>
								</slider>
								<i
									class="el-icon-arrow-right"
									v-if="sessionList.length > 8"
									@click="moreSesstion(1)"
								></i>
							</div>
							<h4>主办方·场馆</h4>
							<div class="place-list">
								<div @click="gotoInfo(studioId, studioType, 'pass')">
									<img :src="studio_photo" alt="" />
									<p class="text-one" :title="studio_name">
										主办方：{{ studio_name }}
									</p>
								</div>
								<div @click="gotoInfo(venueId, 2)">
									<img :src="venuePhoto" alt="" />
									<p class="text-one" :title="live_venueName">
										场馆：{{ live_venueName }}
									</p>
								</div>
							</div>
							<h4>参演者</h4>
							<slider
								class="actor-box"
								ref="slider1"
								:options="options1"
								v-if="aritists.length"
							>
								<slideritem v-for="(item, index) in aritists" :key="index">
									<img
										:src="item.photo"
										@click.stop="gotoInfo(item.id, 0, item.flag)"
										alt=""
									/>
									<p class="a-name" :title="item.name">{{ item.name }}</p>
								</slideritem>
							</slider>
							<div v-else>暂无参演者，请及时添加！</div>
							<h4>演出介绍</h4>
							<div class="content-box" v-html="introduce"></div>
							<h4>购票须知</h4>
							<div class="ticket-info">
								<p class="title">电子票</p>
								<p>
									本演出仅支持电子票，请妥善保管好电子码，防止泄露；请在核销时出示电子码进行核销。
								</p>
								<p class="title" v-show="isRealName">实名制购票</p>
								<p v-show="isRealName">
									本演出为实名制购票演出，购票时请填写合法有效的身份信息。
								</p>
								<p class="title">退换票政策</p>
								<p>
									演出活动门票具有时效性、唯一性等特殊属性，一旦售出皆不可退换（不可抗力或演出主动取消、延期情况除外）。部分演出电子票支持转赠，转赠行为不可逆，请谨慎操作，请保护好个人信息和资产。请勿轻信第三方倒卖平台和不明渠道的门票，严禁黄牛炒票，谨防上当受骗。
								</p>
								<p class="title">限购政策</p>
								<p>
									部分演出会在购票时注明限购数量，限购政策将限定同一帐号在RockGuys全平台所购买的最大数量不超过所规定的限购数量。
								</p>
								<p class="title">疫情时期</p>
								<p>
									疫情期间，演出活动应遵守当地的相关规定开展，观演人群应配合主办方、场馆工作人员做好防疫措施；因疫情变化所带来的演出取消、延期，主办方应积极配合平台方及时完成自愿/非自愿退票退款工作。
								</p>
								<p class="title">发票</p>
								<p>本演出活动暂不支持开具发票。</p>
								<p class="title">座位信息</p>
								<p>本演出活动不提供坐席。</p>
								<p class="title">身障关怀</p>
								<p>本演出活动未提供身障关怀政策。</p>
							</div>
							<!-- <h4>ta也会参加</h4>
              <div class="actor-box">
                <div v-for="(item, index) in 2" :key="index">
                  <img src="../assets/images/bbb.jpg" alt="" />
                  <p>{{ item.city }}</p>
                </div>
              </div> -->
						</div>
						<div class="discuss-msg" v-else>
							<div class="no-data-ui">
								暂无留言
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<hot-right show="1"></hot-right>
				</div>
			</div>
		</section>
		<!-- 地图 -->
		<el-dialog
			class="map-box"
			title="场馆地图"
			width="70%"
			:visible.sync="map_type"
			center
		>
			<!-- <p class="area-view">
        地址：{{ live_city ? live_city : live_province }}
        {{ live_area }}
        {{ live_address }}
        {{ live_venueName }}
      </p> -->
			<mapView
				:centerInfo="centerList"
				:isSearch="false"
				:isWatch="true"
				:title="live_venueName"
			></mapView>
		</el-dialog>
		<!-- 二维码 -->
		<div class="mask-box" v-show="is_qrcode">
			<div class="qrcode-box">
				<i class="el-icon-close" @click="showQrcode"></i>
				<p>请使用RockGuys App 或微信扫码购票</p>
				<div class="qrcode" ref="qrcodeUrl"></div>
			</div>
		</div>
	</div>
</template>

<script>
// 地图
import mapView from "../components/mapView";
// 生成二维码
import QRCode from "qrcodejs2";
// import slider components
import { slider, slideritem } from "vue-concise-slider";
export default {
	name: "liveinfo",
	components: {
		mapView,
		slider,
		slideritem,
	},
	data() {
		return {
			navId: 0,
			live_title: "",
			live_poster: "",
			live_startTime: "",
			live_venueName: "",
			live_week: "",
			live_address: "",
			live_area: "",
			live_city: "",
			live_province: "",
			live_endTime: "",
			introduce: "",
			live_status: null,

			centerList: [], // 场馆地址坐标

			endTime: "",
			studio_photo: "",
			studio_name: "",
			studioId: null,
			studioType: null,
			venuePhoto: "",
			venueId: "",
			venueFlag: "",

			tabList: ["演出详情", "留言讨论"],
			options: {
				currentPage: 0,
				thresholdTime: 300,
				slidesToScroll: 4,
				loop: false,
				pagination: false,
			},
			options1: {
				currentPage: 0,
				infinite: 4,
				slidesToScroll: 4,
				loop: false,
				pagination: false,
			},
			nid: 0,

			is_qrcode: false,
			map_type: false,
			isRealName: false,

			sid: null,

			// 艺术家列表
			aritists: [],
			// 票规格
			ticketList: [],
			// 场次
			sessionList: [],

			lid: 1,
			siteId: null, // 场次id
			activityId: "", // 活动id

			user_id: "",

			isFixed: false,
			offsetTop: 0,
		};
	},
	mounted() {
		// 获取活动id
		this.siteId = this.$route.query.site;
		this.activityId = this.$route.query.aid;
		// 获取用户信息
		let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
		if (userinfo) {
			this.user_id = userinfo.userId;
		}
		// 获取演出详情
		this.getSessionInfo(this.activityId, this.siteId);

		// 获取scroll位置
		window.addEventListener("scroll", this.initHeight);
		this.$nextTick(() => {
			this.offsetTop = document.querySelector("#boxFixed").offsetTop;
		});
	},
	//离开当前页面后执行
	destroyed: function() {
		removeEventListener("scroll", this.initHeight);
	},
	methods: {
		// 票品
		saveIndex(id) {
			this.sid = id;
		},
		// 导航
		saveNindex(id) {
			this.nid = id;
		},
		// 场次
		saveLindex(id, index) {
			console.log(index);
			this.lid = index;
			if (this.$route.path == "/live-info") {
				this.$router.push({
					path: "/live-info",
					query: { aid: this.activityId, site: id },
				});
				window.location.reload();
			} else {
				this.$router.push({
					path: "/live-info",
					query: { aid: this.activityId, site: id },
				});
			}
		},
		initHeight() {
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			this.isFixed = scrollTop > this.offsetTop ? true : false;
		},
		// 获取演出详情
		getSessionInfo(aid, site) {
			this.$http({
				url: "/api/ticket/v1.0/getTicketDetails",
				params: {
					activityId: aid,
					siteId: site,
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					if (res.data.data == null) {
						this.$router.replace({
							path: "/noActivity",
						})
						return;
					}
					this.live_title = res.data.data.activityName;
					this.live_poster = res.data.data.poster;
					this.live_area = res.data.data.area;
					this.live_city = res.data.data.city;
					this.live_province = res.data.data.province;
					this.live_startTime = res.data.data.startTime;
					this.live_endTime = res.data.data.endTime.slice(0, -4);
					this.live_address = res.data.data.address;
					this.live_venueName = res.data.data.venueName;
					this.live_status = res.data.data.status;
					this.venuePhoto = res.data.data.venuePhoto;
					this.venueId = res.data.data.venueId;
					this.venueFlag = res.data.data.venueFlag;
					this.live_week = res.data.data.week;
					this.studioId = res.data.data.studioId;
					this.studioType = res.data.data.studioType;
					this.studio_name = res.data.data.studioName;
					this.studio_photo = res.data.data.studioPhoto;
					this.aritists = res.data.data.aritists;
					if (res.data.data.introduce) {
						this.introduce = res.data.data.introduce.replace(
							/<img/gi,
							'<img style="max-width:100%;height:auto"'
						);
					}
					this.centerList[0] = res.data.data.longitude;
					this.centerList[1] = res.data.data.latitude;
					this.isRealName = Number(res.data.data.isRealName);

					// 获取票规格
					this.ticketSpecifications(aid, site);
					// 获取场次
					this.moreTicket(aid, site);
				} else {
					// this.$message.warning({
					// 	message: res.data.msg,
					// 	duration: 2000,
					// });
					setTimeout(() => {
						this.$router.push("/");
					}, 2000);
				}
			});
		},
		// 获取票规格
		ticketSpecifications(aid, site) {
			this.$http({
				url: "/api/ticket/v1.0/ticketSpecifications",
				params: {
					activityId: aid,
					siteId: site,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.ticketList = res.data.data;
				} else {
					console.log("获取票规格失败！");
					// this.$message.error({
					// 	message: res.data.msg,
					// 	duration: 2000,
					// });
				}
			});
		},
		// 获取场次
		moreTicket(aid, site) {
			this.$http({
				url: "/api/ticket/v1.0/moreTicket",
				params: {
					activityId: aid,
					siteId: site,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.sessionList = res.data.data;
					let posi = this.sessionList.findIndex((val) => {
						return val.isStatus;
					});
					let num = parseInt(this.sessionList.length / 3);
					// console.log(posi, num);
					for (let i = 0; i < num; i++) {
						if (posi > 5 * i) {
							setTimeout(() => {
								this.$refs.slider.$emit("slideTo", i * 4);
							}, 500);
						}
					}
				} else {
					console.log("获取场次失败！");
					// this.$message.error({
					// 	message: res.data.msg,
					// 	duration: 2000,
					// });
				}
			});
		},
		moreSesstion(type) {
			if (type) {
				this.$refs.slider.$emit("slideNext");
			} else {
				this.$refs.slider.$emit("slidePre");
			}
		},
		// 显示二维码
		showQrcode() {
			this.is_qrcode = !this.is_qrcode;
			if (this.is_qrcode) {
				// let qrurl = `https://m.rockguys.cn/#/indexDetail?activityId=${this.activityId}&siteId=${this.siteId}&studioId=${this.studioId}`;
				let qrurl = `https://m.rockguys.cn/#/indexDetail?siteId=${this.siteId}`;
				this.qrcode(qrurl);
			} else {
				this.$refs.qrcodeUrl.innerHTML = "";
			}
		},
		// 生成二维码
		qrcode(text) {
			var qrcode = new QRCode(this.$refs.qrcodeUrl, { // eslint-disable-line no-unused-vars
				text: text, // 需要转换为二维码的内容
				width: 180,
				height: 180,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H,
			});
		},
		// 跳转详情
		gotoInfo(id, type, flag) {
			// console.log(id, type, flag);
			if (flag == "pass") {
				this.$router.push({
					path: "speople",
					query: { id: id, type: type },
				});
			}
			if (type == 0 && !flag) {
				console.log("直接添加艺术家，无法访问");
				return;
			}
			if (type == 2 && this.venueFlag != 1) {
				console.log("直接添加场馆，无法访问");
				return;
			}
			this.$router.push({
				path: "speople",
				query: { id: id, type: type },
			});
		},
	},
};
</script>

<style lang="less" scoped>
section {
	display: flex;
	width: 100%;
	margin-top: 48px;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		height: 100%;
		min-height: calc(100vh - 48px);
		margin: 0 auto;
		box-sizing: border-box;
		.left {
			position: relative;
			width: 210px;
		}
		.content {
			position: relative;
			width: 855px;
			height: 100%;
			min-height: calc(100vh - 48px);
			padding: 0 0 100px;
			margin-right: 15px;
			box-sizing: border-box;
			background-color: #fff;
			border-right: 1px solid rgba(0, 21, 41, 0.12);
			.fixtop {
				z-index: 999;
				position: fixed;
				top: 28px;
				width: 855px;
				height: 48px;
				background-color: #fff;
				border: 1px solid rgba(0, 21, 41, 0.12);
				border-width: 0 1px 1px 0;
			}
			.header {
				width: 100%;
				padding: 15px 25px;
				box-sizing: border-box;
			}
			.live-info-header {
				display: flex;
				width: 100%;
				margin: 20px 0;
				& > img {
					width: 165px;
					height: 220px;
					margin-right: 30px;
					border-radius: 10px;
				}
				& > div {
					// flex: 1;
					& > h3 {
						height: 50px;
						line-height: 25px;
						font-weight: 600;
					}
				}
				.info {
					line-height: 2;
					& > p {
						font-size: 14px;
						color: #666;
					}
					.ticket-box {
						display: flex;
						width: 100%;
						margin: 5px 0;
						& > span {
							flex-shrink: 0;
							font-size: 15px;
							color: #666;
						}
						& > div {
							display: flex;
							flex-wrap: wrap;
							.el-button {
								border-radius: 8px;
								margin: 0 15px 15px 0 !important;
							}
							.el-button + .el-button {
								margin: 0;
							}
							.el-button.is-plain:hover {
								color: #ff4d4f;
								border-color: #ff4d4f;
							}
							.active {
								color: #ff4d4f;
								border-color: #ff4d4f;
							}
						}
					}
					.tips {
						margin-bottom: 20px;
						font-size: 12px;
						color: #999;
					}
					.btn-end {
						color: #666 !important;
						background: #ddd !important;
					}
				}
			}
			.live-more {
				width: 100%;
				.nav-tab {
					display: flex;
					padding-left: 220px;
					margin: 20px 0;
					box-sizing: border-box;
					border-bottom: 1px solid #e5e5e5;
					span {
						position: relative;
						padding: 15px 0;
						margin-right: 80px;
						font-size: 16px;
						font-weight: 600;
						color: #666;
						cursor: pointer;
					}
					.active {
						color: #000;
						&::after {
							content: "";
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							border-bottom: 2px solid #000;
						}
					}
				}
				.live-msg {
					width: 100%;
					padding: 0 25px;
					box-sizing: border-box;
					h4 {
						margin: 60px 0 25px;
						font-size: 18px;
						font-weight: 600;
					}
					.step-box {
						display: flex;
						align-items: baseline;
						i {
							flex-shrink: 0;
							width: 20px;
							height: 20px;
							color: #d9d9d9;
							line-height: 20px;
							text-align: center;
							border-radius: 50%;
							border: 1px solid #d9d9d9;
							cursor: pointer;
						}
						.step {
							width: 100%;
							display: flex;
							margin: 0 20px;
							overflow-x: scroll;

							& > div {
								flex-shrink: 0;
								width: 85px;
								cursor: pointer;
								span {
									display: inline-block;
									position: relative;
									width: 8px;
									height: 8px;
									border-radius: 50%;
									border: 1px solid #999;
									&::after {
										content: "";
										position: absolute;
										left: 12px;
										top: 4px;
										width: 70px;
										height: 1px;
										background-color: #bababa;
									}
								}
								.info {
									width: 80px;
									margin-top: 7px;
									color: #666;
									font-size: 12px;
									font-weight: 600;
								}
							}
							.active {
								cursor: default;
								span {
									border: 1px solid #d30404 !important;
									&::after {
										background-color: #d30404 !important;
									}
								}
								p {
									color: #d30404 !important;
								}
							}
							.actived {
								opacity: 0.5;
								cursor: default;
							}
						}
					}
					.place-list {
						display: flex;
						width: 800px;
						white-space: nowrap;
						overflow-x: auto;
						& > div {
							flex-shrink: 0;
							width: 220px;
							height: 165px;
							padding: 0 20px;
							line-height: 2.2;
							margin-right: 25px;
							text-align: center;
							border-radius: 8px;
							box-sizing: border-box;
							background-color: #f8f8f8;
							img {
								width: 60px;
								height: 60px;
								margin-top: 30px;
								border-radius: 50%;
								cursor: pointer;
							}
							p {
								color: #333;
								font-size: 14px;
								font-weight: 600;
							}
						}
					}
					// 轮播
					.slider-wrapper {
						.slider-item {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							text-align: left;
							width: 90px;
							cursor: pointer;
						}
						.a-name {
							width: 60px;
							padding: 10px 0;
							font-size: 14px;
							color: #000;
							text-align: center;
							box-sizing: border-box;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
					.actor-box {
						display: flex;
						width: 800px;
						height: 100%;
						white-space: nowrap;
						overflow-x: auto;
						overflow-y: hidden;
						box-sizing: border-box;
						&::-webkit-scrollbar {
							display: none;
						}

						& > div {
							flex-shrink: 0;
							text-align: center;
							margin-right: 30px;
							box-sizing: border-box;
							cursor: move;

							img {
								width: 60px;
								height: 60px;
								border-radius: 50%;
							}
						}
					}
					.content-box {
						margin: 40px 0;
						letter-spacing: 1px;
						white-space: pre-wrap;
						word-break: break-all;
						/deep/iframe {
							width: 100%;
							height: auto;
						}
						/deep/h1 {
							font-size: 2em;
							margin: 0.67em 0;
						}
						/deep/h2 {
							font-size: 1.5em;
							margin: 0.75em 0;
						}
						/deep/h3 {
							font-size: 1.17em;
							margin: 0.83em 0;
						}
					}
					.ticket-info {
						.title {
							margin-bottom: 0 !important;
							font-weight: bold;
						}
						& > p {
							margin-bottom: 5px;
							font-size: 16px;
							line-height: 33px;
							color: #333;
						}
					}
				}
			}
		}
		.right {
			margin-top: 50px;
		}
	}
}
.mask-box {
	z-index: 1001;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	.qrcode-box {
		position: relative;
		width: 350px;
		height: 420px;
		padding: 30px 0;
		margin: 30vh auto 0;
		text-align: center;
		border-radius: 10px;
		box-sizing: border-box;
		background-color: #fff;
		& > p {
			margin: 60px 0 40px;
			font-size: 16px;
			font-weight: 600;
		}
		.qrcode {
			width: 200px;
			height: 200px;
			padding: 10px;
			margin: 0 auto;
			box-sizing: border-box;
		}
		.el-icon-close {
			position: absolute;
			top: -35px;
			right: -35px;
			padding: 5px;
			font-size: 20px;
			font-weight: 600;
			color: #fff;
			border-radius: 50%;
			border: 2px solid #fff;
			cursor: pointer;
		}
	}
}
/deep/.map-box {
	.el-dialog__header {
		padding: 10px 0;
	}
	.el-dialog__body {
		padding: 5px 20px 20px;
	}
	.el-input {
		width: 40%;
		margin-right: 20px;
	}
}
img {
	position: relative;
}
img:after {
	content: "";
	display: inline-block;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background-size: 100% 100%;
	background-color: rgba(237, 237, 239, 1);
	// background: url("../assets/images/initial.png") no-repeat;
}
</style>
