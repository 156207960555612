<template>
	<div style="display: flex;align-items: flex-start;">
		<div class="map-box-ch">
			<!-- <el-amap-search-box
        :search-option="searchOption"
        :on-search-result="onSearchResult"
      ></el-amap-search-box> -->
			<div class="search-box">
				<el-input
					size="medium"
					placeholder="请输入内容"
					v-model="address"
					@keyup.enter.native="onSearch"
					@focus="focusClick"
					@blur="blurClick"
					v-if="isSearch"
				>
					<el-button
						size="medium"
						slot="append"
						icon="el-icon-search"
						@click="onSearch"
					></el-button>
				</el-input>
				<div id="result" v-if="is_show">
					<div
						class="area-item"
						v-for="(item, index) in searchList"
						:key="index"
						@click="selArea(index, item)"
					>
						<div style="line-height: 19px;">
							<p class="font14">{{ item.name }}</p>
							<p class="font12 lightGray text-two">
								{{ item.district }}
								{{ item.address.length ? item.address : "" }}
							</p>
						</div>
						<i class="el-icon-check" v-show="sel_id == index"></i>
					</div>
					<p
						class="font13 mt30 lightGray"
						style="text-align: center;"
						v-if="no_date"
					>
						没有搜索到内容!
					</p>
				</div>
			</div>
			<el-amap
				class="amap-box"
				isHotspot="true"
				:plugin="plugin"
				:center="center"
				:zoom="zoom"
				:amap-manager="amapManager"
			>
				<el-amap-info-window
					:position="center"
					autoMove="true"
					:visible="is_window && isWatch"
				>
					{{ title }}
					<i
						class="el-icon-position"
						style="cursor: pointer;"
						@click="gotoMap"
					></i>
				</el-amap-info-window>
				<el-amap-marker
					v-for="(marker, index) in markers"
					:position="marker"
					:events="events"
					:key="index"
				></el-amap-marker>
			</el-amap>
		</div>
		<div class="info" v-if="isWatch">
			{{ myinfo.city }}
			{{ myinfo.district }}
			{{ myinfo.street }}
			<p>
				{{ myinfo.province }}
				{{ myinfo.city }}
				{{ myinfo.district }}
				{{ myinfo.street }}
				{{ myinfo.streetNumber }}
				{{ myinfo.township }}
				{{ title }}
			</p>
		</div>
	</div>
</template>

<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
	name: "mapview",
	props: {
		centerInfo: {
			type: Array,
			default: () => [],
		},
		isSearch: {
			type: Boolean,
			default: true,
		},
		isWatch: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
	},
	data() {
		let me = this;
		return {
			amapManager,
			center: [116.397428, 39.90923],
			markers: [],
			zoom: 10,
			is_window: true,
			is_show: false,
			Autocomplete: null,
			plugin: [
				{
					pName: "Scale",
					events: {},
				},
				{
					// 搜索
					pName: "Autocomplete",
					events: {
						// init(instance) {
						//   console.log(instance);
						// }
					},
				},
			],
			events: {
				click(e) {
					console.log(e);
					me.is_window = false;
					me.$nextTick(() => {
						me.is_window = true;
					});
				},
			},
			lng: "",
			lat: "",
			address: "",
			myinfo: {},
			// 搜索结果
			searchList: [],
			no_date: false,
			sel_id: null,
			addressInfo: "",
		};
	},
	mounted() {
		// 初始化搜索
		// setTimeout(() => {
		// 	this.Autocomplete = new AMap.Autocomplete({
		// 		datatype: "all",
		// 	});
		// }, 200);
		// 接受坐标初始化地图
		// console.log(this.centerInfo);
		if (this.centerInfo[0]) {
			this.center = this.centerInfo;
			this.markers.push(this.centerInfo);
			this.zoom = 15;
			let coordinate = {};
			coordinate.lng = this.centerInfo[0];
			coordinate.lat = this.centerInfo[1];

			this.getAddressInfo(coordinate).then((res) => {
				// console.log(res);
				let data = res.regeocode.addressComponent;
				this.myinfo.province = data.province;
				this.myinfo.city = data.city;
				this.myinfo.district = data.district;
				this.myinfo.street = data.street;
				this.myinfo.streetNumber = data.streetNumber;
				this.myinfo.township = data.township;
				this.$forceUpdate();
			});
		}
	},
	methods: {
		// 导航
		gotoMap() {
			let url = `https://amap.com/dir?from[adcode]=&from[name]=&from[id]=&from[poitype]=&from[lnglat]=&from[modxy]=&to[adcode]=&to[name]=${this.title}&to[id]=&to[poitype]=&to[lnglat]=${this.centerInfo[0]}%2C${this.centerInfo[1]}&to[modxy]=&type=car&policy=1`;
			window.open(url, "_blank");
		},
		// 聚焦
		focusClick() {
			this.is_show = true;
		},
		blurClick() {
			if (!this.searchList.length) {
				this.is_show = false;
			}
		},
		// 搜索位置信息
		onSearch() {
			let me = this;
			this.is_show = true;
			if (!this.address.trim()) {
				this.$message.error("请输入关键字!");
				return;
			}
			// 初始化搜索
			var autoComplete = new AMap.Autocomplete({
				datatype: "all",
			});
			autoComplete.search(this.address, function(status, res) {
				if (status == "complete" && res.info == "OK") {
					me.searchList = res.tips;
					me.addressInfo = "";
					me.sel_id = null;
					me.no_date = false;
				} else {
					me.no_date = true;
					me.searchList = [];
					console.log("搜索失败：", status, res);
				}
			});
		},
		// 选择地址
		selArea(index, item) {
			this.sel_id = index;
			this.center = [item.location.lng, item.location.lat];
			this.markers[0] = [item.location.lng, item.location.lat];
			this.zoom = 15;
			this.is_show = false;
			let coordinate = {};
			coordinate.lng = this.center[0];
			coordinate.lat = this.center[1];
			this.getAddressInfo(coordinate).then((res) => {
				let info = res.regeocode.addressComponent;
				let data = {
					pois: coordinate,
					name: item.name,
					address: info.township + info.street + info.streetNumber,
					areaid: info.adcode,
					province: info.province,
					district: info.district ? info.district : info.township,
					city: info.city,
				};
        // console.log(data);
				this.$emit("getAddress", data);
			});
			this.$forceUpdate();
		},
		// 根据坐标返回相应信息
		getAddressInfo(coordinate) {
			return new Promise(function(resolve, reject) {
				var geocoder = new AMap.Geocoder({
					enableHighAccuracy: true,
					zoomToAccuracy: true,
					radius: 1000,
					extensions: "all",
				});
				geocoder.getAddress([coordinate.lng, coordinate.lat], function(
					status,
					result
				) {
					if (status === "complete" && result.info === "OK") {
						if (result && result.regeocode) {
							resolve(result);
						}
					} else {
						reject(status, result);
					}
				});
			});
		},
		//
		clickAll(e) {
			console.log(e);
		},
	},
};
</script>

<style lang="less" scoped>
.map-box-ch {
	position: relative;
	width: 100%;
	height: 100%;
	.search-box {
		z-index: 100;
		position: absolute;
		top: 10px;
		left: 10px;
		width: 50%;
	}
	.amap-box {
		width: 100%;
		height: 60vh;
	}
}
.info {
	flex-shrink: 0;
	width: 20%;
	height: auto;
	padding: 5px;
	font-size: 14px;
	line-height: 19px;
	color: #333;
	box-sizing: border-box;
	border-bottom: 1px solid #dadada;
	& > p {
		font-size: 12px;
		color: #999;
	}
}
#result {
	width: 100%;
	height: calc(45vh - 54px);
	overflow-y: scroll;
	background-color: #fff;
}
.area-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	border-bottom: 1px solid #f1f1f1;
	cursor: pointer;
	&:hover {
		background-color: #f1f1f1;
	}
}
</style>
