<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <div class="header">
            <h3>成员管理</h3>
          </div>
          <!-- 高级管理员 -->
          <el-table
            :data="adminList"
            :header-cell-style="{
              color: '#000',
              background: '#fafafa',
            }"
            style="width: 100%"
          >
            <el-table-column prop="nickName" label="高级管理员" width="180">
              <template slot-scope="scope">
                <div class="member">
                  <img
                    :src="scope.row.photo"
                    width="30px"
                    height="30px"
                    style="border-radius: 50%"
                  />
                  <span>{{ scope.row.nickName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" width="180">
            </el-table-column>
            <el-table-column prop="roleKey" label="权限范围">
              <template slot-scope="scope">
                {{
                  scope.row.roleKey == "rockguys_admin"
                    ? "所有权限"
                    : "所有权限"
                }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 运营管理员 -->
          <el-table
            empty-text="暂时没有运营管理员"
            :data="operationsList"
            :header-cell-style="{
              color: '#000',
              background: '#fafafa',
            }"
            style="width: 100%"
          >
            <el-table-column prop="nickName" label="运营管理员" width="180">
              <template slot-scope="scope">
                <div class="member">
                  <img
                    :src="scope.row.photo"
                    width="30px"
                    height="30px"
                    style="border-radius: 50%"
                  />
                  <span>{{ scope.row.nickName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="" width="180">
            </el-table-column>
            <el-table-column prop="roleKey" label="">
              <template slot-scope="scope">
                {{
                  scope.row.roleKey == "rockguys_verification"
                    ? "活动管理、数据、资料设置、推广营销"
                    : "活动管理、数据、资料设置、推广营销"
                }}
              </template>
            </el-table-column>
            <el-table-column align="right" header-align="center" width="200">
              <template slot="header">
                <el-button
                  icon="el-icon-circle-plus"
                  style="font-weight: bold;font-size: 16px;"
                  type="text"
                  v-if="useradmin == 'rockguys_admin'"
                  @click="addAdmin(1)"
                >
                  添加成员
                </el-button>
              </template>
              <template slot-scope="scope">
                <el-button
                  class="btn-del-style"
                  type="text"
                  v-if="useradmin == 'rockguys_admin'"
                  @click="delAdmin(scope.row, scope.$index)"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 核销管理员 -->
          <el-table
            :data="verificationList"
            empty-text="暂时没有核销管理员"
            :header-cell-style="{
              color: '#000',
              background: '#fafafa',
            }"
            style="width: 100%"
          >
            <el-table-column prop="nickName" label="核销管理员" width="180">
              <template slot-scope="scope">
                <div class="member">
                  <img
                    :src="scope.row.photo"
                    width="30px"
                    height="30px"
                    style="border-radius: 50%"
                  />
                  <span>{{ scope.row.nickName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" width="180"> </el-table-column>
            <el-table-column prop="roleKey">
              <template slot-scope="scope">
                {{
                  scope.row.roleKey == "rockguys_operate"
                    ? "核销、数据、资料设置"
                    : "核销、数据、资料设置"
                }}
              </template>
            </el-table-column>
            <el-table-column align="right" header-align="center" width="200">
              <template slot="header">
                <el-button
                  icon="el-icon-circle-plus"
                  style="font-weight: bold;font-size: 16px;"
                  type="text"
                  v-if="useradmin == 'rockguys_admin'"
                  @click="addAdmin(2)"
                  >添加成员
                </el-button>
              </template>
              <template slot-scope="scope">
                <el-button
                  class="btn-del-style"
                  type="text"
                  v-if="useradmin == 'rockguys_admin'"
                  @click="delAdmin(scope.row, scope.$index)"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="message-tips">
            <i class="el-icon-warning" style="color: #faad14"></i>
            <div>
              <p>
                1.高级管理员拥有全部权限，并且可以添加/删除管理员/资金管理；
              </p>
              <p>
                2.运营管理员拥有活动管理、核销、数据、资料设置、推广营销等添加/删除管理员/资金管理外的所有功能。
              </p>
            </div>
            <i class="el-icon-close" style="color: #999; cursor: pointer"></i>
          </div>
        </div>
      </div>
    </section>
    <el-dialog
      title="手机号搜索并添加"
      :visible.sync="dialogVisible"
      width="48%"
      top="25vh"
      custom-class="dialog-box"
    >
      <div class="dialog-diy">
        <p>手机号</p>
        <el-input
          size="small"
          v-model="mobile"
          oninput="if(value.length>11)value=value.slice(0,11)"
          placeholder="请输入"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="mini"
          class="btn-style"
          @click="searchIng"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "jurisdiction",
  data() {
    return {
      navId: 5,
      mobile: "",
      admin_type: "",
      adminList: [], // 高级管理员
      operationsList: [], // 运营
      verificationList: [], // 核销
      dialogVisible: false,
      useradmin: "",

      other_id: "",
      user_id: "",
    };
  },
  mounted() {
    if (localStorage.getItem("userinfo")) {
      this.user_id = JSON.parse(localStorage.getItem("userinfo")).userId;
    }
    this.useradmin = localStorage.getItem("sadmin");
    // 获取管理员列表
    this.getAdminList();
  },
  methods: {
    // 获取管理员列表
    getAdminList() {
      this.$http({
        url: "/api/auth/v1.0/getAdminList",
        params: {
          authenticationId: localStorage.getItem("sid") || "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          // 高级管理员
          this.adminList = res.data.data.rockguys_admin;
          this.operationsList = res.data.data.rockguys_operate;
          this.verificationList = res.data.data.rockguys_verification;
        } else {
          this.$message.warning({
            message: res.data.msg,
          });
        }
      });
    },
    // 添加管理员
    addAdmin(id) {
      console.log(id);
      if (id == 2) {
        this.admin_type = "rockguys_verification";
      } else {
        this.admin_type = "rockguys_operate";
      }
      this.dialogVisible = true;
    },
    // 搜索
    searchIng() {
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message.error({
          message: "请输入正确的手机号！",
          duration: 2000,
        });
        return;
      }
      this.getMobileAdmin(this.mobile);
    },
    // 搜索用户
    getMobileAdmin(mobile) {
      this.$http({
        url: "/api/auth/v1.0/getMobileAdmin",
        params: {
          mobile: mobile,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.other_id = res.data.data[0].id;
          this.pushAdmin();
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },

    // 上传管理员
    pushAdmin() {
      this.$http({
        url: "/api/auth/v1.0/addAdmin",
        method: "POST",
        data: {
          userId: this.other_id,
          roleKey: this.admin_type,
          currentUserId: this.user_id,
          type: localStorage.getItem("stype") || "",
          authenticationId: localStorage.getItem("sid") || "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success({
            message: "添加成功！",
            duration: 2000,
          });
          this.dialogVisible = false;
          window.location.reload();
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 删除管理员
    delAdmin(info, index) {
      console.log(info, index);
      this.$confirm("是否删除该管理员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: "/api/auth/v1.0/delAdmin",
            method: "POST",
            data: {
              authenticationId: localStorage.getItem("sid") || "",
              roleKey: info.roleKey,
              userId: info.userId,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message.success({
                message: res.data.msg,
                duration: 2000,
              });
              if (info.roleKey == "rockguys_verification") {
                this.verificationList.splice(index, 1);
              } else {
                this.operationsList.splice(index, 1);
              }
            } else {
              console.log(res.data);
            }
          });
        })
        .catch(() => {
          console.log("catch");
        });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      padding: 40px 25px;
      margin: 0 15px;
      box-sizing: border-box;
      background-color: #ffffff;
      .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .add-box {
          z-index: 1000;
          position: absolute;
          top: 35px;
          right: 0;
          width: 230px;
          padding: 10px 0;
          border-radius: 3px;
          box-sizing: border-box;
          background-color: #fff;
          box-shadow: 0px 0px 10px #f1f1f1;
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            box-sizing: border-box;
            &:hover {
              background-color: #fdf2f2;
            }
            & > div {
              width: 100%;
              height: 60px;
              padding: 10px 0;
              box-sizing: border-box;
              cursor: pointer;
              & > p:first-child {
                margin-bottom: 10px;
                font-weight: 600;
              }
              & > p:last-child {
                color: #999;
              }
            }
            i {
              color: #d30404;
            }
          }
        }
      }
      .el-table {
        margin: 15px 0;
        .member {
          display: flex;
          align-items: center;
          & > img {
            margin-right: 10px;
          }
        }
      }
      .message-tips {
        display: flex;
        align-items: baseline;
        justify-content: space-around;
        width: 55%;
        padding: 10px 0;
        border-radius: 10px;
        border: 1px solid #ffe58f;
        background-color: #fffbe6;
        & > div {
          width: 90%;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          & > p {
            margin-top: 2px;
            line-height: 20px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
.btn-del-style {
  width: 100%;
  text-align: center;
}

.dialog-diy {
  & > p {
    color: #000;
  }
  .el-input {
    width: 50%;
    margin: 10px 0;
  }
}
/deep/ .dialog-box {
  border-radius: 15px;
  .el-dialog__footer {
    text-align: left;
  }
}
</style>
