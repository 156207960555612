<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <!-- 财务记录 -->
          <div class="order-box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '../capital' }">
                资金管理
              </el-breadcrumb-item>
              <el-breadcrumb-item>账单详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="info-box">
              <div class="header">
                <img
                    src="../../assets/images/icon/Mask.png"
                    alt=""
                    v-if="orderInfo.businessType == 1"
                  />
                  <img
                    src="../../assets/images/icon/tuik.png"
                    alt=""
                    v-else-if="orderInfo.businessType == 3"
                  />
                  <img
                    src="../../assets/images/icon/mdown.png"
                    alt=""
                    v-else-if="orderInfo.businessType == 5"
                  />
                  <img
                    src="../../assets/images/icon/give.png"
                    alt=""
                    v-else-if="orderInfo.businessType == 6"
                  />
                <p class="font16 m-color-1" v-if="orderInfo.businessType == 1">票房收入</p>
                <p class="font16 m-color-1" v-else-if="orderInfo.businessType == 3">订单退款</p>
                <p class="font16 m-color-1" v-else-if="orderInfo.businessType == 5">结款提现</p>
                <p class="font16 m-color-1" v-else-if="orderInfo.businessType == 6">交易手续费</p>
                <p class="font24 bolden m-color-1">{{ orderInfo.businessType == 1 ? "+" : "-" }}{{ orderInfo.amount }}</p>
                <p class="font16 m-color-1">{{ orderInfo.businessType == 3 ? "已退款" : "交易成功" }}</p>
              </div>
              <div class="info-list">
                <div v-if="orderInfo.businessType == 1 || orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">票品名称</span>
                  <span class="m-color-1 text-two">{{
                    orderInfo.businessTypeDes
                  }}</span>
                </div>
                <div v-if="orderInfo.businessType == 1 || orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">票品类型</span>
                  <span class="m-color-1 text-two">{{
                    orderInfo.ticketType
                  }}</span>
                </div>
                <div v-if="orderInfo.businessType == 1 || orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">票品数量</span>
                  <span class="m-color-1 text-two">{{
                    orderInfo.numberStr
                  }}</span>
                </div>
                <div v-if="orderInfo.businessType == 1">
                  <span class="m-color-2 mr60">到账时间</span>
                  <span class="m-color-1 text-two">{{ orderInfo.createTime }}</span>
                </div>
                <div v-if="orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">退款时间</span>
                  <span class="m-color-1 text-two">{{ orderInfo.createTime }}</span>
                </div>
                <div v-if="orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">退款单号</span>
                  <span class="m-color-1 text-two">{{ orderInfo.tradeCode }}</span>
                </div>
                <div v-if="orderInfo.businessType == 5">
                  <span class="m-color-2 mr60">转出说明</span>
                  <span class="m-color-1 text-two">{{ orderInfo.businessTypeDes }}</span>
                </div>
                <div v-if="orderInfo.businessType == 5">
                  <span class="m-color-2 mr60">转入账户</span>
                  <span class="m-color-1 text-two">{{ orderInfo.toAccountName }}</span>
                </div>
                <div v-if="orderInfo.businessType == 5">
                  <span class="m-color-2 mr60">申请时间</span>
                  <span class="m-color-1 text-two">{{ orderInfo.createTime }}</span>
                </div>
                <div v-if="orderInfo.businessType == 5">
                  <span class="m-color-2 mr60">到账时间</span>
                  <span class="m-color-1 text-two">{{ orderInfo.updateTime }}</span>
                </div>
                <div v-if="orderInfo.businessType == 1 || orderInfo.businessType == 5">
                  <span class="m-color-2 mr60">交易单号</span>
                  <span class="m-color-1 text-two">{{ orderInfo.tradeCode }}</span>
                </div>
                <div v-if="orderInfo.businessType == 6">
                  <span class="m-color-2 mr60">业务说明</span>
                  <span class="m-color-1 text-two">{{ orderInfo.businessTypeDes }}</span>
                </div>
                <div v-if="orderInfo.businessType == 6">
                  <span class="m-color-2 mr60">时间</span>
                  <span class="m-color-1 text-two">{{ orderInfo.createTime }}</span>
                </div>
                <div v-if="orderInfo.businessType == 6">
                  <span class="m-color-2 mr60">业务单号</span>
                  <span class="m-color-1 text-two">{{ orderInfo.tradeCode }}</span>
                </div>
                <div v-if="orderInfo.businessType == 6 || orderInfo.businessType == 3">
                  <span class="m-color-2 mr60">备注</span>
                  <span class="m-color-1 text-two">{{ orderInfo.remark }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "capitalorder",
  data() {
    return {
      navId: 3,
      user_id: "",
      orderInfo: "",
    };
  },
  mounted() {
    // 获取userid
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.userId;
    }

    let id = this.$route.query.date;

    // 获取订单详情信息
    this.getFlowCnyDetail(id);
  },
  methods: {
    // 获取资金信息
    getFlowCnyDetail(id) {
      this.$showLoading("loading...", ".content");
      this.$http({
        url: "/api/wallet/getFlowCnyDetail",
        params: {
          flowId: id,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.orderInfo = res.data.data;
        } else {
          console.log(res);
        }
        this.$hideLoading();
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    min-height: calc(100vh - 48px);
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      min-height: calc(100vh - 48px);
      margin: 0 15px;
      box-sizing: border-box;
      background-color: #f5f5f5;

      .order-box {
        width: 100%;
        height: 100%;
        padding: 20px 40px;
        box-sizing: border-box;
        background-color: #fff;
        .info-box {
          width: 50%;
          .header {
            width: 100%;
            padding: 20px 0;
            line-height: 2;
            text-align: center;
            box-sizing: border-box;
            border-bottom: 1px solid #ebeef0;
            & > img {
              width: 60px;
              height: 60px;
            }
          }
          .info-list {
            width: 100%;
            margin-top: 30px;
            line-height: 30px;
            font-size: 16px;
            & > div {
              display: flex;
              & > span:first-child {
                flex-shrink: 0;
                width: 15%;
              }
            }
          }
        }
      }
    }
  }
}

.m-color-1 {
  color: #061058;
}
.m-color-2 {
  color: #8287ab;
}
.m-color-3 {
  color: #111c60;
}
.mr60 {
  margin-right: 60px;
}
</style>
