<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div class="header">
            <span class="hitem active">
              更多设置
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "more",
  data() {
    return {
      navId: 3, // 侧边导航
      navList: ["演出订单", "商品订单"],
      tid: 0,
      search_val: "",
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 1200px;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
      .header {
        position: fixed;
        width: 1200px;
        height: 60px;
        line-height: 65px;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #dadada;
      }
      .hitem {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
      .infinite-list {
        height: 100%;
        min-height: 50vh;
        overflow-y: scroll;
      }
      .active {
        position: relative;
        color: #000;
        &::after {
          position: absolute;
          bottom: -18px;
          left: 0;
          content: "";
          width: 100%;
          border-bottom: 2px solid #ef4033;
        }
      }
    }
  }
}
</style>
