<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="indexId"></nav-left>
        </div>
        <div class="main-content">
          <img src="../../assets/images/nodata.png" alt="">
          <p class="tit">审核中</p>
          <p>正在进行审核，请您耐心等待</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "verifying",
  data() {
    return {
      indexId: null,
    };
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .main-content {
      width: 1190px;
      min-height: calc(100vh - 48px);
      margin: 0 15px;
      text-align: center;
      box-sizing: border-box;
      background-color: #fff;
      & > img {
        width: 250px;
        height: 240px;
        margin: 150px 0 40px;
      }
      .tit {
        margin-bottom: 10px;
        font-size: 18px;
        color: #333;
      }
      p {
        font-size: 14px;
        color: #666;
      }
    }
  }
}
</style>
