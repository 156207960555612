<template>
	<div class="left-nav">
		<div
			class="item"
			:class="{ active: classId == index }"
			v-for="(item, index) in list"
			:key="index"
			@click="saveChange(index)"
		>
			<router-link :to="item.link">
				<i class="iconfont" :class="item.icon"></i>
				<span class="nav-name">{{ item.name }}</span>
				<el-badge
					:value="count_all"
					class="item-count"
					v-if="item.name == '工作室' && count_all"
				>
				</el-badge>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "indexleft",
	props: {
		classId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			list: [
				// {
				//   name: "Rockguys",
				//   link: "/",
				//   icon: "icon-home",
				// },
				{
					name: "Live Music",
					link: "/",
					icon: "icon-bofang",
				},
				// {
				//   name: "Music Box",
				//   link: "/",
				//   icon: "icon-yinle"
				// },
				// {
				//   name: "我的通知",
				//   link: "/notice",
				//   icon: "icon-laba",
				// },
				{
					name: "个人资料",
					link: "/profile",
					icon: "icon-geren",
				},
				{
					name: "我的订单",
					link: "/order",
					icon: "icon-dingdan",
				},
				// {
				// 	name: "更多设置",
				// 	link: "/more",
				// 	icon: "icon-shezhi",
				// },
				{
					name: "工作室",
					link: "",
					icon: "icon-diannao",
				},
			],
			count_all: 0,
			user_id: "",
		};
	},
	mounted() {
		if (localStorage.getItem("token")) {
			this.getUserStudio();
		}
		let userinfo = JSON.parse(localStorage.getItem("userinfo"));
		if (userinfo) {
			this.user_id = userinfo.userId;
		}
	},
	methods: {
		// 获取工作室列表
		getUserStudio() {
			this.$http({
				url: "/api/auth/v1.0/getUserStudio",
				params: {
					type: 1,
					userId: JSON.parse(localStorage.getItem("userinfo")).userId,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let info = res.data.data;
					for (let i = 0; i < info.length; i++) {
						this.count_all = this.count_all + info[i].messageCount;
					}
					var link =
						document.querySelector("link[rel*='icon']") ||
						document.createElement("link");
					if (this.count_all > 0) {
						// link.type = 'image/x-icon';
						// link.rel = 'shortcut icon';
						link.href = "/favicon1.ico";
						document.getElementsByTagName("head")[0].appendChild(link);
					} else {
						// link.type = 'image/x-icon';
						// link.rel = 'shortcut icon';
						link.href = "/favicon.ico";
						document.getElementsByTagName("head")[0].appendChild(link);
					}
				} else {
					console.log(res.data);
				}
			});
		},
		saveChange(id) {
			if (id == 3) {
				let sid = localStorage.getItem("sid");
				if (sid) {
					let routeUrl = this.$router.resolve({
						path: "/studio/inspect",
					});
					window.open(routeUrl.href, "_blank");
				} else {
					let url = this.$mcallurl + "studio?id=" + encodeURIComponent(encodeURIComponent("洛克人")) + this.user_id;
					window.open(url, "_blank");
				}
			}
		},
	},
};
</script>
<style lang="less" scoped>
.left-nav {
	position: fixed;
	width: 210px;
	height: 100%;
	padding: 36px 0;
	box-sizing: border-box;
	background-color: #fff;
	border-right: 1px solid #e5e5e5;
	.item {
		width: 70%;
		height: 40px;
		padding-left: 10%;
		margin: 0 auto 20px;
		line-height: 40px;
		border-radius: 20px;
		box-sizing: border-box;
		cursor: pointer;
		a {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 600;
			color: #000;
			i {
				margin-right: 10px;
				font-size: 18px;
			}
			.nav-name {
				font-weight: 500;
				cursor: pointer;
			}
		}
	}
	.active {
		background-color: #fdf2f2;
		a {
			color: #d30404;
		}
	}
}
::v-deep.item-count {
	margin-left: 5px;
	.el-badge__content {
		padding: 1px 5px;
		height: 16px;
		line-height: 16px;
		border: none;
	}
}
// @media screen and (max-width: 1400px) {
//   .left-nav {
//     width: 80px;
//     .item {
//       width: 30%;
//       .nav-name {
//         display: none;
//       }
//     }
//     .active {
//       background-color: transparent;
//     }
//   }
// }
</style>
