<template>
	<div class="main">
		<div class="p-hrader">RockGuys</div>
		<div class="con">
			<main>
				<div class="pri-context">
					<p class="title">RockGuys隐私政策</p>
					<p>我们的隐私政策已于2021年1月15日更新。</p>
					<p>请了解并熟悉我们的隐私政策，如果你有任何问题，请联系我们。</p>
					<h1>关于我们</h1>
					<p>
						RockGuys平台隶属于青岛不大一样网络技术有限公司（以下简称本公司），是本公司研发并拥有全部知识产权的SAAS软件系统。
					</p>
					<p>
						本隐私政策在制定时充分考虑到你的需求。你将全面了解我们的个人信息收集和使用惯例，同时确保最终，你能够控制你提供给RockGuys的个人信息，这一点至关重要。
					</p>
					<h1>关于本隐私政策</h1>
					<p>
						除了特定的RockGuys产品或服务提供独立的隐私政策以外，本隐私政策适用于引用或链接至本隐私政策的所有RockGuys网站或应用程序。本隐私政策规定了RockGuys如何收集、使用、披露、处理和保护你使用位于我们网站（<a
							href="#"
							>https://pc.rockguys.cn/privacy</a
						>）以及我们在移动设备上提供的应用程序的产品或服务时，提供给我们或我们收集的信息。若某款RockGuys产品或服务有单独的隐私政策，则该单独隐私政策将被优先适用。该产品或服务的单独隐私政策未涵盖的部分，以本隐私政策内容为准。同时，取决于你所使用的设备机型、服务版本或所在位置等因素，特定产品或服务如何收集、处理你的个人信息可能有所不同。我们建议你参阅单独的隐私政策以获取详细信息。
					</p>

					<p>
						除你所在地区所适用的法律另有特殊规定外，本隐私政策下“个人信息”指通过信息本身或通过关联其他信息后能够识别特定个人的信息。我们将严格遵守隐私政策来使用这些信息。
					</p>

					<p style="text-decoration: underline;">为了在本隐私政策下收集你的信息，或者向你提供服务、优化我们的服务以及保障你的帐号安全，我们将需要向你索取相关的权限；其中敏感权限例如精确地理位置、摄像头、麦克风、相册等均不会默认开启，只有在你明确同意后才会向我们授权。</p>
					<p>需要特别说明的是，获得敏感权限是我们收集特定信息的必要而非充分条件。我们获得具体某一项敏感权限并不代表我们必然会收集你的相关信息；即使我们已经获得敏感权限，也仅在必要时、根据本隐私政策来收集你的相关信息。</p>
					<h1>1 我们收集哪些信息以及如何使用信息？</h1>
					<h2>1.1 我们收集哪些信息</h2>
					<p>
						为了向你提供我们的服务，我们需要你提供使用该服务所必需的信息。我们只会收集为实现具体、特定、明确及合法的目的所必需的信息，并且不会对这些信息进行与上述目的不相符的进一步处理。你有权自行选择是否提供我们请求的信息，但多数情况下，如果你拒绝，我们可能无法向你提供相应的服务，也无法回应你遇到的问题。
					</p>
					<p>根据你选择的服务，我们可能收集以下信息中的一种或多种：</p>
					<h2>1.1.1 你主动提供给我们的个人信息</h2>
					<p>在你使用Rockguys服务的过程中，Rockguys会按照如下方式收集你在使用服务时主动提供或者因为使用服务而产生的信息，用以向你提供服务、优化我们的服务以及保障你的账号和资金安全。</p>
					<p>我们将逐一说明收集的你的个人信息类型及对应的用途，以便你了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。</p>
					<p>
						（1）当你使用一些功能时，我们会在获得你的同意后，收集你的敏感信息，例如你在使用RockGuys购物服务，你可能会提供你的<b>姓名、联系方式</b>、电子邮箱地址、<b>送货地址</b>、订单信息、发票信息，以及<b>银行账户号码</b>、银行账户持有人姓名、<b>信用卡号</b>码等相关信息；若你创建账号，你可能会提供你的生日、性别、账号安全设置等相关信息；若你使用RockGuys社区等社交服务，你可能会提供与你的社交活动相关的信息，如你主动选择填写的职业、教育背景等信息。
					</p>
					<p>（2）当你注册Rockguys服务时我们会收集你的手机号、短信验证码或者获取你的微信号、微信头像、昵称，收集这些信息是为了帮助你完成Rockguys账号注册，保护你Rockguys账号的安全。手机号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。若你不提供这类信息，你可能无法正常使用我们的服务。你还可以根据自身需求选择填写性别、地区等信息。</p>
					<p>（3）当你使用RockGuys中用户资料修改功能，包含但不仅限于上传和修改头像、背景图片上传、工作室头像、私信聊天发送图片或视频和背景图片上传等功能，将会访问你的相机、相册等权限，收集这些信息是为了给你提供更好的产品服务和个人体验，让你在我们的产品中更有辨识度。若你不提供这类信息或不允许该权限，你可能无法使用上述的修改图片、发送图片及视频等服务，但不影响你正常使用RockGuys的其他功能。</p>
					<p>（4）当你使用我的工作室功能时，我们会收集你的头像、艺术家/厂牌/场馆名称、身份证正反照、证件号码、联系电话和电子邮箱信息，收集这些信息是为了能够为你提供我们的服务，这些信息仅用于审核你的身份，同时保障你的资金安全。我们深知身份证正反照、证件号码等信息属于敏感信息，但拒绝提供该信息仅会使你无法使用上述功能，但不影响你正常使用Rockguys的其他功能。</p>
					<p>（5）当你使用工作室提现功能时，我们会收集你的微信钱包/支付宝账号/银行卡信息，其中添加银行卡提现需要收集你的银行卡卡号、开户网点、持卡人姓名、身份证号码、手机号码，收集这些信息是为了能够准确的将资金提现到你的相应账户中，保障你的账户资金安全。若你不提供这类信息，你可能无法正常使用我们的上述服务，但不影响你使用Rockguys的其他功能。</p>
					<p>（6）当你使用Rockguys社区功能时，你上传的社区动态的文字、照片、视频、评论、点赞等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，你也可以随时删除这些信息。除非经你自主选择或遵从相关法律法规要求，我们不会对外提供上述社区动态信息，或者将其用于该功能以外的其他用途。</p>
					<p>（7）当你使用RockGuys社区功能提供的个性化推荐功能时，我们会询问你是否同意APP访问你的通讯录，提供通讯录权限可以给你带来更好的使用体验，访问通讯录的目的是发现和你一样使用我们产品的好友，你们之间可以通过我们的产品产生更多的联系。同时，你也可以拒绝提供这个权限，这并不影响使用我们的产品及其他功能，若是需要开启该权限则重新授权即可使用该功能。我们会对你的通讯录信息进行存储和加密处理，未经过你的同意不会使用到其他场景或用于其他用途。</p>
					<p>（8）我们使用了第三方（上海游昆信息技术有限公司，以下称“MobTech”）MobTech SDK服务为您提供【演出提醒、出票通知等消息推送】功能。为了顺利实现该功能，您需要授权MobTechSDK提供对应的服务;在您授权后，MobTech将收集您相关的个人信息。关于MobTech所收集的信息种类、用途、个人信息保护的规则及退出机制等，详见MobTech官网（www.mob.com）上的隐私政策 （www.mob.com/about/policy）条款。</p>
					<h2>1.1.2 我们在你使用服务过程中收集的信息</h2>
					<ul>
						<li>
							<b>与你的设备或SIM卡相关的信息：</b>
							例如IMEI编号、GAID编号、IMSI编号、MAC地址、安装列表、序列号、ROM版本、Android版本、Android
							ID、SIM卡运营商和归属地、屏幕显示信息、设备输入信息、设备激活时间、设备制造商信息和型号名称、网络运营商、连接类型、硬件基础配置信息及使用相关信息（例如CPU、内存；电量使用情况；设备分辨率、设备温度；相机镜头型号），获取这些信息是为了识别你的设备并且维持我们的服务的正常的网络通信，另外，获取Mac地址、IP地址等，是为了打击刷单、恶意炒票等不正当购买行为并及时向用户推送相关消息通知；后台获取MAC信息，是为了解析用户当前地址，方便给用户显示更精确的当前位置的演出活动信息，给用户更好的服务；查看用户安装列表，是为方便用户提供使用微信等第三方登录渠道的可行性验证，保证用户能够正常使用我们的产品和相关功能，收集IMEI编号、IMSI编号等，是为了识别用户的登录信息，让用户能够正常使用移动终端设备准确登录APP并享受我们产品提供的服务。
						</li>
						<li>
							<b>第三方服务提供商与业务合作伙伴指定的与你相关的信息：</b>
							我们可能收集并使用如第三方服务提供商与业务合作伙伴分配的广告ID。
						</li>
						<li>
							<b>与你的应用使用相关的信息：</b>
							包括应用基础信息，例如应用ID信息、SDK版本、系统更新设置、应用设置（地区、语言、时区、字体），以及应用状态记录（例如下载、安装、更新、删除）。其中使用cn.jpush.android(极光;极光推送) com.xiaomi.mipush(小米;小米推送)com.huawei.hms(华为;华为推送)com.huawei.agconnect(华为;华为联运应用) com.xiaomi.push(小米;小米推送) com.heytap.msp(OPPO;OPPO推送)com.netease(网易;网易IM;网易云信)等SDK是为了给用户提供在线和离线消息通知，使得用户能够及时了解相关演出的变动情况，保障用户的权益；使用com.amap.api(高德地图;高德导航;高德定位;阿里高德地图;高德) 是为了给用户提供演出场次的精准定位，使得用户能够及时准确的找到并到达正确的演出场地，保证用户能够正常的观看演出；使用com.sina.weibo(微博;新浪微博)是给用户提供分享给好友自己喜欢的演出的渠道和方式，能够给用户带来更好的使用体验。
						</li>
						<li>
							<b>你在使用服务时生成的信息：</b>
							例如社区服务中你的用户等级、浏览记录；你在使用社区服务时的站内信（仅发送和接收的双方可见）；你在使用推送服务时的推送文本；你在使用广告服务时的行为（如点击、曝光）。
						</li>
						<li>
							<b>位置信息（仅适用于特定服务/功能）：</b>
							若你使用和位置相关的服务（如查看所在城市区域的演出活动），我们可能采集与你设备的精确或模糊位置相关的各类信息。例如地区、国家代码、城市代码、移动网络代码、移动国家代码、小区标识、经纬度信息、时区设置和语言设置。你可以随时在手机设置（设置-权限）中关闭每个应用的位置服务。
						</li>
						<li>
							<b>日志信息：</b>
							与你使用某些功能、和网站相关的信息。例如Cookie和其他匿名标识符技术、互联网协议（IP）地址、网络请求信息、临时消息历史、标准系统日志、错误崩溃信息、使用服务产生的日志信息（如注册时间、访问时间、活动时间等）。
						</li>
						<li>
							<b>其他信息：</b>
							连接的Wi-Fi产生的信息和地理位置信息。
						</li>
					</ul>
					<h2>1.1.3 来源于第三方的信息</h2>
					<p>
						在一些法律允许的情况下，我们可能从第三方处获得你的个人信息。例如，
					</p>
					<ul>
						<li>
							出于安全和防欺诈的目的，针对特定账号、金融交易等服务，在你授权的前提下，通过合法来源核实你的信息（如电话号码）；
						</li>
						<li>
							通过指定的唯一标识符（如从广告主处获得的IMEI编号）为你对应提供广告服务；
						</li>
						<li>
							我们还可能从第三方社交网络服务中获取某些信息，例如账户、昵称（例如，当你使用社交网络账户登录RockGuys服务时）；
						</li>
						<li>
							他人提供的与你有关的信息，例如其他用户在使用RockGuys商城时填写的你的收货地址。
						</li>
					</ul>
					<h2>1.1.4 非个人信息</h2>
					<p>
						我们还可能收集其他无法识别到特定个人、根据当地适用法律不属于个人信息的信息，例如你使用特定服务时产生的统计类数据，如匿名设备相关信息、日活事件、页面访问事件、页面访问时长事件、会话事件；网络监控数据如请求时长、请求与错误请求数等；以及应用崩溃事件。收集此类信息的目的在于改善我们向你提供的服务。所收集信息的类别和数量取决于你如何使用我们产品或服务。
					</p>
					<p>
						我们会收集诸如唯一设备标识符、设备型号、系统版本号等与你在我们的网站或移动应用中的活动有关的信息。我们会将此类信息汇总，用于帮助我们向客户提供更有用的信息，了解客户对我们网站、产品或服务中的哪些部分最感兴趣。就本隐私政策而言，汇总数据被视为非个人信息。如果我们将非个人信息与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
					</p>
					<h2>1.2 这些个人信息将会被如何使用</h2>
					<p>
						收集个人信息的目的在于向你提供产品或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。这包括：
					</p>
					<ul>
						<li>
							<b>提供、处理、维护、改善、开发我们的产品或提供给你的服务，</b
							>例如购票、电子票展示、客户服务、当地天气。
						</li>
						<li>
							<b
								>用于防丢失和防欺诈等目的的安全保障，协助识别用户、验证身份等。</b
							>我们仅在满足以下两个条件时将你的信息用于反欺诈的目的：绝对有必要；将该信息用于评估符合RockGuys保护用户及服务的合法利益。
						</li>
						<li>
							<b>处理你关于设备与服务的提问或请求，</b
							>例如解答客户询问、发送系统及应用程序的消息通知、管理你参加的抽奖等活动。
						</li>
						<li>
							<b>进行相关的推广活动，</b
							>例如提供推广与促销的资料和更新。除非适用的法律另有规定，如果你不再希望接收某些类别的推广信息，你可以通过该信息中提供的退出方式（如邮件底部的取消订阅链接）来拒绝此类服务。
						</li>
						<li>
							<b>提供个性化用户服务和内容，</b
							>我们可能会根据你在使用我们产品或服务时生成的信息，例如购买历史、网站浏览历史和地理位置信息，向你推荐个性化的产品、服务和活动，包括广告。
						</li>
						<li><b>允许你在公共论坛上发表评论。</b></li>
						<li>
							<b>内部目的</b
							>如数据分析、研究和开发与我们产品或服务的使用相关的统计信息，以更好地改进我们的产品或服务。例如在去标识化处理后进行机器学习或模型算法训练。
						</li>
						<li>
							<b>优化设备的性能，</b
							>例如分析应用程序的内存使用情况或应用的CPU利用率。
						</li>
						<li>
							<b>储存并维护与你相关的信息，用于我们的业务运营</b>
							（例如业务统计）<b>或履行法律义务。</b>
						</li>
						<li><b>提供不需要与我们的服务器通信的本地服务。</b></li>
						<li><b>其他征得你同意的目的。</b></li>
						<p>
							关于我们如何使用你的信息（其中可能包含个人信息），下面提供了更多详细示例：
						</p>
						<li><b>注册登录RockGuys产品或服务。</b></li>
						<li>
							<b>创建及维护你的RockGuys账号。</b
							>你通过网站或移动设备创建RockGuys账号时提供的个人信息可用于建立你个人RockGuys账号和资料页。
						</li>
						<li>
							<b>处理你的订购单。</b
							>与电子票务、电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。此外，订单号将用于校验物流合作伙伴的订单和包裹的实际交付。收件人信息，包括姓名、地址、电话号码和邮政编码，将用于送货。电子邮件地址用于向你发送包裹跟踪信息。购买物品的清单用于打印发票，并使你能够确定包裹中的物品。
						</li>
						<li>
							<b>参与RockGuys社区。</b
							>与RockGuys社区或RockGuys其他类似社交平台有关的个人信息可能被用于个人资料页面展示、与其他用户进行互动、参与论坛。
						</li>
						<li>
							<b>提供基于位置的服务。</b
							>在使用RockGuys服务时，我们或第三方服务提供商与业务合作伙伴（更多详细信息请参阅下方“<b>我们如何共享，转移和公开披露你的个人信息</b>”）可能会使用位置信息以便为你提供服务，并且基于准确的位置提供更好的用户体验，例如天气信息、位置接入。你可以随时在设置中关闭位置服务。
						</li>
						<li>
							<b>改善用户体验。</b
							>某些选择性加入功能，例如用户体验计划，允许RockGuys分析用户如何使用RockGuys，以改善用户体验，例如发送崩溃报告。
						</li>
						<li>
							<b>提供推送服务。</b
							>RockGuys账号和IMEI/GAID编号可能用于提供RockGuys推送服务，以改善用户体验向你发送软件更新或新产品发布的通知，包括销售和促销信息。同时，在第三方（同样为你个人信息的数据控制者
							- 更多详细信息请参阅下方“<b>
								我们如何共享，转移和公开披露你的个人信息</b
							>”）的授权下，RockGuys的推送通知也将使用你的RockGuys账号和IMEI/GAID编号，以评估相关广告表现或从苹果或手机厂商向你发送相关通知。我们可能基于推送服务（无论是通过服务中集成的信息推送，还是电子邮件或其他方式）而使用你个人信息，并为你提供或推广我们及相关第三方的产品或服务。<b>你可以随时通过更改手机设置项下的偏好设置来拒绝接收来自我们及第三方的营销信息，或通过使用RockGuys推送的应用/网页来管理你的偏好设置。请参阅下方“你的权利”</b>。
						</li>
						<li>
							<b>收集用户反馈。</b
							>你选择提供的反馈对帮助RockGuys改进服务非常重要。为了处理你提供的反馈，RockGuys可能会使用你所提供的个人信息与你联系，并保留记录用于解决问题及改进服务。
						</li>
						<li>
							<b>发送通知。</b
							>我们可能会将你的个人信息用于发送重要通知，例如有关订单的通知和我们的条款、条件和政策的变更。由于此类信息对你和RockGuys之间的沟通至关重要，不建议你拒绝接收此类信息。
						</li>
						<li>
							<b>进行促销活动。</b
							>如果你通过RockGuys的社交媒体平台参与了抽奖、比赛或类似的促销，我们可能会使用你提供的个人信息以为你发送此类奖励。
						</li>
						<li>
							<b>进行设备的分析以提供更好的用户体验。</b
							>RockGuys将会进行硬件及软件的分析，以进一步提升设备的使用体验。
						</li>
					</ul>
					<p>
						我们可能将这些信息与其他信息结合起来（包括跨不同服务或设备如电脑、手机、智能电视和其他联网设备中的信息），用于提供和改进我们的产品、服务、内容和广告宣传。例如，我们可能会在所有需要RockGuys账号的服务中使用你的RockGuys账号的详细信息。同时，为了让你有更好的体验以及改善我们的服务，在符合相关法律法规规定或经你同意时，我们可能会将来自你或与你相关的不同产品、服务或设备的信息整理成标签，来提供建议、定制内容和个性化功能。例如使用你在浏览器中的搜索历史在RockGuys商城中向你推荐你感兴趣的商品或服务。<b
							>根据我们合并信息的原因以及适用法律的要求，我们将为你提供此类整合的具体控制机制。你有权拒绝以直接营销目的进行的个人信息处理以及自动化决策等。为了行使上述权利，你可以发送邮件至
							<a href="#">privacy@rockguys.cn</a>
							或者参考各产品或服务的单独隐私政策中说明的控制机制。请参阅下方“你的权利”。</b
						>
					</p>
					<h1>2 我们如何使用 Cookie 和同类技术</h1>
					<p>
						RockGuys和第三方服务提供商及业务合作伙伴（更多详细信息请参阅下方“<b>我们如何共享，转移和公开披露你的个人信息</b>”）使用Cookie、标签和脚本等技术。这些技术用于分析趋势、管理网站、追踪你的网站活动并收集关于整个用户群体的统计信息。我们会收到通过以上技术收集的个体或汇总的分析报告。此等技术帮助我们更好地了解你的行为，使我们了解你浏览我们网站的哪些部分，衡量广告和网络搜索的效果并加以改善。我们将通过Cookie和其他技术收集的信息视为非个人信息。但是，如果当地法律将互联网协议(IP)地址或类似识别标记视为个人信息，我们亦将此等识别标记视为个人信息。
					</p>
					<ul>
						<li>
							<b>日志文件：</b>
							和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议(IP)
							地址、浏览器类型、互联网服务供应商(ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。
						</li>
						<li>
							<b>广告：</b
							>我们与第三方服务提供商及业务合作伙伴（更多详细信息请参阅下方“<b
								>我们如何共享，转移和公开披露你的个人信息 </b
							>”）合作，在我们的网站上展示广告或管理我们在其他网站上的广告。我们的第三方服务提供商及业务合作伙伴可能会使用诸如Cookie等技术来收集你在其网站或其他网站上的活动信息，基于你的浏览活动和兴趣为你提供广告。我们会在向你提供广告服务之前，获取你明确的事前同意。
						</li>
						<li>
							<b>移动分析：</b
							>在某些移动应用中，我们使用移动分析软件，以更好地了解我们的移动软件在你的手机中的功能。此款软件可能记录以下信息，如你使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息与你在移动应用程序中提交的任何个人信息相关联。
						</li>
						<li>
							<b>本地存储-HTML5/Flash：</b>我们使用本地存储对象
							(LSO)，例如HTML5或Flash，来存储内容和偏好。与我们合作在我们的网站上提供某些功能，或根据你的网页浏览行为投放广告的第三方也会通过HTML5或Flash
							Cookie来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除HTML5本地存储对象。
						</li>
					</ul>
					<h1>我们如何共享、转让、公开披露你的个人信息</h1>
					<h2>3.1 共享：</h2>
					<p>我们不会将任何个人信息出售给第三方。</p>
					<p>
						我们有时可能会向第三方（定义见下文）共享你的个人信息，以便提供或改进我们的产品或服务，包括根据你的要求提供产品或服务。
					</p>
					<h2>3.1.1 你主动选择的共享</h2>
					<p>
						在获得你的明确同意或主动选择的情况下，与你指定的第三方共享你授权/请求范围内的信息。例如，你使用RockGuys账号快捷登录第三方网站或者APP。
					</p>
					<h2>3.1.2 与我们共享信息</h2>
					<p>
						为了顺利地从事商业经营，以向你提供产品或服务的全部功能，我们可能不时与其他的RockGuys关联公司共享你的个人信息。
					</p>
					<h2>3.1.3 与我们的生态系统公司共享信息</h2>
					<p>
						RockGuys与旗下的关联公司进行合作，共同构成了RockGuys生态系统。RockGuys生态系统公司是由RockGuys投资和运营的独立实体，并且是这些领域内的专家。RockGuys可能会向RockGuys生态系统公司披露你的个人信息，以便为你提供和改善来自于RockGuys生态系统公司的令人兴奋的产品或服务（包括软件、硬件）。某些产品或服务仍使用RockGuys品牌，但其他产品或服务可能使用自己的品牌。RockGuys生态系统公司可能不时与RockGuys共享与RockGuys品牌的产品或服务相关的数据，以提供软件、硬件服务，以提供更好的功能和用户体验。RockGuys将会采用适当的管理及技术方法，以保证你的个人信息处理过程中的安全，其中包括但不限于对个人信息的加密保护。
					</p>
					<h2>3.1.4 与第三方服务提供商与业务合作伙伴共享</h2>
					<p>
						<b
							>为保证向你提供本隐私政策目的所述的服务，我们可能会向第三方服务提供商与业务合作伙伴等第三方共享必要的个人信息。</b
						>
					</p>
					<p>
						这包括我们的物流供应商、数据中心、数据存储设施、客户服务供应商、广告和营销服务供应商以及其他业务合作伙伴。这些第三方可能代表RockGuys或出于本隐私政策的一项或多项目的处理你的个人信息。<b
							>我们保证仅出于正当、合法、必要、特定、明确的目的共享为你提供服务所必要的个人信息。</b
						>
						RockGuys将进行尽职调查并签订合同，以确保第三方服务提供商遵守你所属司法管辖区中适用的隐私法律。第三方服务提供商也可能拥有其子处理者。
					</p>
					<p>
						为提供成效衡量、分析和其他商业服务，我们还可能以汇总的形式与第三方（例如我们网站上的广告商）共享信息（非个人信息）。我们使用所掌握的信息来帮助广告主和其他合作伙伴评估他们广告和服务的成效及覆盖情况，并了解使用他们服务的人群类型以及人们如何与其网站、应用和服务互动。我们也可能与其共享我们服务的一般使用趋势，例如购买某些产品或从事某些交易的特定人群中的客户数量。
					</p>
					<h2>3.1.5 其他</h2>
					<p>
						根据法律法规规定要求、法律程序、诉讼和/或公共机构和政府部门依法提出的要求，RockGuys可能有必要披露你的个人信息。如果我们确定就国家安全、执法或具有公众重要性的其他事宜而言，披露是必须的或适当的，我们也可能会披露关于你的信息。
					</p>
					<p>
						如果我们确定为了执行我们的条款或保护我们的经营、权利、资产或产品，或保护用户，或对于达成以下目的（检测、预防并解决欺诈、对产品的未授权使用、对我们条款或政策的违反或其他有害或非法活动）披露是合理必要的，我们也可能会披露关于你的信息。（为避免疑问，在当地数据保护法律明确许可的范围内且仅限在此情况下，RockGuys公司才会在未经你同意的情况下收集、使用或披露你的个人信息。）这可能包括向公共或政府机构提供信息；与第三方合作伙伴交流有关你账户可靠性方面的信息，以防止在我们产品内外所进行的欺诈、违规等其他有害行为。
					</p>
					<p>此外，我们可能与下列人员共享你的个人信息：</p>
					<ul>
						<li>
							我们的会计师、审计师、律师或类似的顾问，当我们要求他们提供专业建议时；
						</li>
						<li>
							投资者以及其他相关第三方，如果发生实际或潜在的出售或其他公司交易，且与RockGuys集团内实体相关时；
						</li>
						<li>其他任何第三方，如经你授权就特定信息进行披露共享。</li>
					</ul>
					<h2>3.2 转让</h2>
					<p>除以下情形外，RockGuys不会将你的信息转让给任何主体：</p>
					<ul>
						<li>获得你的明确同意；</li>
						<li>
							如果RockGuys参与兼并、收购或出售其资产的全部或部分，我们将通过电子邮件和/或在我们网站上发布醒目通知，告知对你个人信息所有权、使用方面的任何变化，以及你可能拥有的关于你个人信息的任何选择。
						</li>
					</ul>
					<h2>3.3 公开披露</h2>
					<p>
						除在公布中奖活动名单时会脱敏展示中奖者的手机号或者RockGuys账号、用户名外，RockGuys仅会在以下情况下，公开披露你的个人信息：
					</p>
					<ul>
						<li>获得你的明确同意；</li>
						<li>
							基于法律或合理依据的公开披露：包括法律法规规范、法律程序、诉讼或应政府主管部门要求。
						</li>
					</ul>
					<h1>4 我们如何保存和保护你的个人信息</h1>
					<h2>4.1 RockGuys的安全措施</h2>
					<p>
						我们承诺保证你的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了所有法律规定的物理、电子和管理措施流程，保护我们从你的移动设备和RockGuys网站上收集的信息。我们将确保依据适用的法律保护你的个人信息。
					</p>
					<p>
						例如，当你访问RockGuys账号时，你可以选择我们的两步验证程序来更好地保证安全性。当你从RockGuys设备向我们的服务器发送或收取信息时，我们确保使用安全套接层
						(SSL) 和其他算法对其进行加密。
					</p>
					<p>
						你的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对你的信息进行分类，并且保证你的个人信息具有相应的安全等级。同样，我们对以云为基础的数据存储设有专门的访问控制措施，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。
					</p>
					<p>
						我们将对第三方服务提供商与业务合作伙伴进行尽职调查以确保他们有能力保护你的个人信息。我们还将通过实施适当的合同限制，并在必要时进行审计及评估，来检查第三方是否采用了适当的安全标准。此外，我们保证通过访问这些信息来帮助向你提供产品或服务的员工和第三方服务提供商与业务合作伙伴遵守严格的合同保密义务。
					</p>
					<p>
						我们会举办安全和隐私保护培训课程、测试，加强我们员工对于保护个人信息重要性的认识。我们将采取所有可行且法律要求的措施保护你的个人信息。但是，你应当意识到互联网的使用并不总是安全的，因此，我们不能保证在通过互联网双向传输时任何个人信息的安全性或完整性。
					</p>
					<p>
						我们将根据适用的数据保护法规的要求处理个人信息的泄露，在必要时，及时通知相关的监管机构及数据主体相关的数据泄露事件。
					</p>
					<h2>4.2 你能做什么?</h2>
					<p>
						你可以通过不向任何人（除非此人经你正式授权）披露你的登录密码或账号信息，你可以为RockGuys设置唯一的密码，以防止其他网站密码泄露危害你在RockGuys的账号安全。无论何时，请不要向任何人（包括自称是RockGuys客服的人士）透露你收到的验证码。无论你何时作为RockGuys账号用户登录RockGuys，尤其是在他人的计算机或公共互联网终端上登录时，在会话结束时你总应注销登出。
					</p>
					<p>
						RockGuys不对因你未能保持个人信息的私密性而导致第三方访问你的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生其他任何互联网用户未经授权使用你账号的情况或其他任何安全漏洞，你应当立即通知我们。
					</p>
					<p>你的协助将有助于我们保护你个人信息的私密性。</p>
					<h2>4.3 访问你的设备上的其他功能</h2>
					<p>
						我们的应用程序可能会访问你设备上的某些功能，例如使电子邮件启动联系人、短信存储和Wi-Fi网络状态等功能。这些信息用于允许这些应用程序在你的设备上运行，并且允许你与其互动。在任何时候，你可以通过在设备上关闭应用程序。
					</p>
					<h2>4.4 保留政策</h2>
					<p>
						我们基于本隐私政策以及特定产品或服务的单独隐私政策中所述的信息收集的目的所必需的期间，或者遵守适用的相关法律要求保留个人信息，具体保留时长可参见特定服务或相关产品页面。个人信息在完成收集目的，或在我们确认过你的删除或注销申请后，或我们终止运营相应产品或服务后，我们将停止保留，并做删除或匿名化处理。例外情况是，如果出于公众利益、科学、历史研究或统计的目的处理个人信息，RockGuys将基于适用的法律或你的请求继续保留相关信息，即使进一步的数据处理与原有的收集目的无关。
					</p>
					<h1>5 你的权利</h1>
					<p><b>你可以控制你的个人信息！</b></p>
					<h2>5.1 控制设置</h2>
					<p>
						RockGuys承认每个人对隐私权的关注各不相同。因此，我们提供了一些示例，说明RockGuys提供的各种方式，供你选择，以限制收集、使用、披露或处理你的个人信息，并控制你的隐私权设置：
					</p>
					<ul>
						<li>登入或登出RockGuys账户；</li>
						<li>
							打开或关闭其他处理敏感信息或个人信息的服务和功能。你可以在系统设置中控制更多与个人信息相关的详细信息。
						</li>
					</ul>
					<h2>5.2 你对你的个人信息享有的权利</h2>
					<p>
						根据你所适用的国家或地区法律法规，你有权要求访问、更正、删除我们持有的与你相关的任何个人信息（以下简称请求）。
					</p>
					<p>本隐私政策要求你的请求应当符合适用的法律法规以及以下情形：</p>
					<p>
						(1)
						通过RockGuys专门的请求渠道，并且出于保护你的信息安全的考虑，你的请求应当是书面的（除非当地法律明确承认口头申请）；
					</p>
					<p>
						(2)
						提供足够的信息使RockGuys可以验证你的身份，确保请求人是所请求信息主体本人或合法授权人；
					</p>
					<p>
						一旦我们获得充分信息确认可处理你的请求时，我们将在适用数据保护法律规定的时间内对你的请求做出回应。
					</p>
					<p>具体而言：</p>
					<ul>
						<li>
							就我们如何使用你的个人信息以及你拥有的权利，你有权获得清晰、透明且易于理解的信息。这即是本隐私政策的宗旨所在。
						</li>
						<li>
							基于你的要求及适用法律规定，我们可免费提供一份我们已收集并处理的你个人信息的相关记录，如你提出对于相关信息的其他请求，我们可能会基于相关适用法律，并结合实际的管理成本向你收取一笔合理的费用。
						</li>
						<li>
							如果你认为我们持有的任何关于你的信息是不正确或不完整的，可要求我们基于使用目的进行更正或完善。
						</li>
						<li>
							根据你适用的法律法规，当我们没有理由继续使用你的个人信息时，你有权要求我们删除你的个人信息。我们将会根据你的删除请求进行评估，若满足相应规定，我们将会采取包括技术手段在内的相应步骤进行处理。<b
								>当你或我们协助你删除相关信息后，因为适用的法律和安全技术的限制，我们可能无法从备份系统中立即删除相应的信息。在这种情况下，我们将安全地存储你的个人信息并将其与任何进一步的处理相隔离，直到备份可以清除或实现匿名。</b
							>
						</li>
						<li>
							你有权拒绝某些类型的处理，包括为直接营销而进行的处理（含使用用户画像的情况），以及在某些情况下，依据我们的合法利益进行个人信息的处理（包括用户画像）。
						</li>
					</ul>
					<p>特别地，依据某些司法管辖区的法律规定：</p>
					<ul>
						<li>
							你有权要求我们限制处理你的个人信息。我们将会根据你的限制请求进行评估。若满足《一般数据保护条例》（GDPR）规定，我们将会根据条例中适用的具体情况处理你的信息，并在取消限制处理前通知你。
						</li>
						<li>
							你有权拒绝基于自动化决策的处理，包括用户画像等可对你产生法律影响或类似影响的自动处理。
						</li>
						<li>
							你有权以结构化、通用的形式申请你的个人信息并将其转移至其他数据控制者。
						</li>
					</ul>
					<p>
						我们有权拒绝处理无实质意义/纠缠式重复的请求、损害他人隐私权的请求、极端不现实的请求，要求不相称的技术工作，以及根据当地法律无需给予的请求，已经被公之于众的信息，保密条件下给出的信息。如果我们认为删除信息或访问信息的请求的某些方面可能会导致我们无法出于前述反欺诈和安全目的合法使用数据，可能也会予以拒绝。
					</p>
					<h2>5.3 撤销同意</h2>
					<p>
						你可以通过提交请求撤销之前出于特定目的授予我们的同意，包括收集、使用和/或披露我们掌握或控制的你的个人信息。根据你所使用的具体服务，你可以通过联系RockGuys相关部门进行相关操作。我们将会在你做出请求后的合理时间内处理你的请求，并且会根据你的请求，此后不再收集、使用和/或披露你的个人信息。
					</p>
					<p>
						取决于你撤销同意的范围，其可能导致你不能继续享受RockGuys的产品或服务。但你撤回同意或授权的决定，不会影响我们此前基于你的同意而开展的个人信息处理活动的有效性。
					</p>
					<h2>5.4 注销服务或账号</h2>
					<p>1.若你是未开通工作室的用户，可以通过登录RockGuysAPP，点击“我的”-“系统设置”-“账号”-“注销账号”在线注销账号。</p>
          <p>2.若你已开通工作室并成为该工作室的超级管理员，你需要首先注销工作室账号，我们将会删除或匿名化处理你在该工作室的相关个人信息，但你作为RockGuys个人用户的个人信息将会保留。在你主动注销工作室账号后，我们将停止为你提供服务并根据适用法律的要求删除你的个人信息或做匿名化处理。</p>
          <p>3.在你的帐号被注销前，我们将验证你的账号和个人信息、安全状态、设备信息等，保证工作室中的资金管理中的余额为0后，并且没有审核通过且未举办的演出活动，也没有正在参与的拼团运营活动以及未使用的演出门票后，将帮助你完成注销。</p>
          <p>4. 若你希望注销RockGuys账号，由于注销RockGuys账号的操作将使你无法使用RockGuys全线产品或服务，请你谨慎操作。在某些特定情况下，取消可能会被阻止或延迟。例如，你在RockGuys相关服务存在尚未偿还的贷款，则我们不能立即支持你的请求。</p>
          <p>5.当你通过第三方账号授权登录RockGuys时，需要向第三方申请注销账号。</p>
          <p>你知悉并理解，你注销账号的行为是不可逆的，建议你在注销前自行备份RockGuys帐号相关的所有信息，并请确认与本RockGuys帐号相关的所有服务均已进行妥善处理。你的账号被注销后，RockGuys运营方将不会在平台界面内展示你的个人信息及已删除的信息，同时，你也无法通过已注销的账户获得RockGuys的相关服务。对于你已注销的账号及账号相关联信息，我们会依据适用法律法规的要求将信息保存至法定期限届满后尽快删除或进行匿名化处理。</p>
					<h1>6 未成年人信息的保护</h1>
					<p>
						我们认为监督孩子使用我们的产品或服务是家长或监护人的责任。但是，我们不直接向儿童提供服务，也不将儿童的个人信息用于营销目的。
					</p>
					<p>
						如果你是家长或监护人，并且你认为未成年人向RockGuys提交了个人信息，请发送邮件至
						<a href="#">privacy@rockguys.cn</a>
						联系我们以确保此类个人信息被立即删除，并保证未成年人取消订阅任何适用的RockGuys服务。
					</p>
					<h1>7 第三方网站和服务</h1>
					<p>
						我们的隐私政策不适用于第三方提供的产品或服务。取决于你所使用的RockGuys产品或服务，其中可能包括第三方的产品或服务，涉及语音支持、相机处理、视频播放、系统清理及安全相关服务、游戏、统计、社交、支付、地图导航、分享、推送、信息过滤、输入法等类型，其中一些会以第三方网站的链接形式提供，还有一些会以SDK、API等形式接入。当你使用这些产品或服务时，也可能收集你的信息。因此，我们强烈建议你花时间阅读该第三方的隐私政策，就像阅读我们的隐私政策一样。我们不对第三方如何使用他们向你收集的个人信息负责，也不能控制其使用。我们的隐私政策不适用于通过我们的服务链接的其他网站。
					</p>
					<p>
						以下提供了当你使用上述特定产品或服务时，所适用的第三方条款和隐私政策的示例：
					</p>
					<ul>
						<li>
							使用第三方支付服务完成和支付订单时，你在支付时提供的个人信息将依据第三方支付服务提供商的隐私政策进行处理。
						</li>
						<li>
							腾讯的隐私政策：<a href="https://privacy.qq.com" target="_black">
								https://privacy.qq.com</a
							>
						</li>
						<li>
							苹果的隐私政策：<a
								href="https://www.apple.com.cn/legal/privacy/szh/"
								target="_black"
								>https://www.apple.com.cn/legal/privacy/szh/</a
							>
						</li>
					</ul>
					<h1>8 本政策如何更新</h1>
					<p>
						我们会基于业务和技术的变更对隐私政策进行定期审核，我们可能会更新本隐私政策。如果我们对本隐私政策进行重大变更，我们将通过你登记的联系方式如电子邮件（向你账号指定的邮箱地址发送）或在RockGuys网站公布或通过移动设备通知你，这样你可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效日期开始适用。我们建议你定期查阅本网页获取我们隐私权实践的最新信息。你继续使用产品和网站、手机和/或其他任何设备上的服务，将被视为接受更新的隐私政策。在我们向你收集更多的个人信息或我们因为新的目的使用或披露你的个人信息时，我们会再次征得你的同意。
					</p>
					<h1>9 其他</h1>
					<h2>9.1 单点登录</h2>
					<p>
						取决于你所属的司法管辖区，你可以使用各类OAuth提供的登录服务，来登录到我们的网站。这些服务将验证你的身份，并让你选择是否与我们分享某些个人信息（例如你的姓名和电子邮件地址），以预先填写我们的注册表。OAuth服务允许你选择是否将你在此网站中有关个人活动的信息公布在你的资料页中，以便与网络中的其他人共享。
					</p>
					<h2>10 联系我们</h2>
					<p>
						如果你对本隐私政策有任何意见或问题，或者你对RockGuys收集、使用或披露你的个人信息有任何问题，请通过下方地址联系我们，并提及“隐私政策”。当我们收到有关你个人信息相关的权利请求、问题咨询等时，我们有专业的团队解决你的问题。如果你的问题本身涉及比较重大的事项，我们可能会要求你提供更多信息。如果你对收到的答复不满意，你可以将投诉移交给所在司法辖区的相关监管机构。如果你咨询我们，我们会根据你的实际情况，提供可能适用的相关投诉途径的信息。
					</p>
					<div class="footer">
						<p><b>RockGuys Team（青岛不大一样网络技术有限公司）</b></p>
						<p>山东省青岛市崂山区株洲路78号（12A）</p>
						<p>邮政编码 466000</p>
						<p>电子邮箱：<a href="#">privacy@rockguys.cn</a></p>
						<p>感谢你花时间了解我们的隐私政策！</p>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
export default {
	name: "privacy",
};
</script>

<style scoped>
/* body::-webkit-scrollbar {
  display: inline-block !important;
  width: 5px;
  height: 5px;
  opacity: 0;
} */
.main {
	width: 100%;
	height: 100%;
}
.p-hrader {
	width: 100%;
	padding: 1rem 10% !important;
	box-sizing: border-box;
	text-align: right;
	border-bottom: 1px solid #dadada !important;
	cursor: pointer;
}
.con {
	max-width: 960px;
	height: 960px;
	margin: 0 auto;
	padding: 0px 1.0875rem 1.45rem;
}
.title {
	margin: 50px 0;
	font-size: 30px;
	font-weight: bold;
}
.pri-context {
	max-width: 740px;
	margin: 1.5rem auto;
}
.pri-context > h1 {
	margin: 20px 0 !important;
	font-size: 22px !important;
	font-weight: bold !important;
}

.pri-context > h2 {
	margin: 15px 0 !important;
	font-size: 20px !important;
	font-weight: bold !important;
}

.pri-context a {
	color: #3370ff !important;
	pointer-events: none;
}

.pri-context > p {
	color: #000;
	line-height: 28px;
}
.pri-context > ul {
	padding-left: 1.5em !important;
}
.pri-context > ul li {
	font-size: 16px;
	line-height: 28px;
	list-style: disc;
	color: #000;
}
.footer {
	padding-bottom: 50px;
}
.footer > p {
	line-height: 2.5;
}
</style>
