<template>
  <el-dialog
    :visible.sync="is_cropper"
    width="60%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div class="cropper-box">
      <vue-cropper
        ref="cropper"
        :img="option.img"
        :outputSize="option.outputSize"
        :outputType="option.outputType"
        :info="option.info"
        :canScale="option.canScale"
        :autoCrop="option.autoCrop"
        :autoCropWidth="option.autoCropWidth"
        :autoCropHeight="option.autoCropHeight"
        :fixed="option.fixed"
        :fixedBox="option.fixedBox"
        :centerBox="option.centerBox"
        :fixedNumber="option.fixedNumber"
      ></vue-cropper>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeCropper">
        取消
      </el-button>
      <el-button size="small" type="primary" @click="cropperImg">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
// 裁切
import { VueCropper } from "vue-cropper";
export default {
  name: "uploadcropper",
  props: {
    cropper: {
      type: Object,
    },
  },
  components: {
    "vue-cropper": VueCropper,
  },
  data() {
    return {
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: false,
      },
      is_cropper: false,
    };
  },
  methods: {
    getInfo(val) {
      this.is_cropper = val.is_cropper;
      this.option.img = val.temp_img;
      this.temp_name = val.temp_name;
      // 尺寸
      this.option.fixedNumber = val.fixedNumber;
      this.option.autoCropWidth = val.autoCropWidth || 200;
      this.option.autoCropHeight = val.autoCropHeight || 200;
    },
    // 裁切图片
    cropperImg() {
      this.is_cropper = false;
      this.$refs.cropper.getCropBlob((data) => {
        let file = new window.File([data], this.temp_name, {
          type: data.type,
        });
        var formData = new FormData();
        formData.append("multipartFile", file);
        this.uploadimg(formData);
      });
    },
    // 上传
    uploadimg(formData) {
      this.$http({
        url: "/api/public/v1.0/upload",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: formData,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$emit("getAvatar", res.data.msg);
        } else if (res.data.code == 500) {
          this.$message.warning(res.data.data.fail);
          this.$emit("getAvatar", "违规");
        } else {
          this.$emit("getAvatar", "cancel");
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
        this.$hideLoading();
      });
    },
    // 关闭弹框
    closeCropper() {
      this.is_cropper = false;
      this.$emit("getAvatar", "cancel");
      this.$hideLoading();
    },
  },
};
</script>

<style lang="less" scoped>
.cropper-box {
  width: 100%;
  height: 500px;
}
</style>
