<template>
	<div>
		<div class="left-menu">
			<div class="title"><i class="el-icon-tickets"></i>票务管理</div>
			<div class="box">
				<div
					class="item"
					:class="{ active: classId == index }"
					v-for="(item, index) in list"
					:key="index"
					@click="gotoInfo(item.link)"
				>
					<a>
						<i class="iconfont" :class="item.icon"></i>
						{{ item.name }}
					</a>
				</div>
				<!-- <div class="btn-init" @click="gotoInfo('/studio/open')">开设工作室</div> -->
				<div class="message">
					<img class="avatar" src="../assets/images/kefu.png" alt="" />
					<div>
						<p>联系RockGuys运营经理</p>
						<p>获取服务人员联系方式</p>
					</div>
          <div class="kefu-img">
            <img src="../assets/images/kefucode.png" alt="">
            <p>手机扫码进入对话</p>
          </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "navleft",
	props: {
		classId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			list: [
				{
					name: "扫码验票",
					link: "/studio/inspect",
					icon: "icon-ico",
				},
				{
					name: "资料设置",
					link: "/studio/profile",
					icon: "icon-wenjian",
				},
				{
					name: "活动管理",
					link: "/studio/activity",
					icon: "icon-laba",
				},
				{
					name: "资金管理",
					link: "/studio/capital",
					icon: "icon-dingdangdaizijinliushui",
				},
				{
					name: "数据报表",
					link: "/studio/trend",
					icon: "icon-ziliao",
				},
				{
					name: "权限管理",
					link: "/studio/jurisdiction",
					icon: "el-icon-lock",
				},
				// {
				//   name: "通知设置",
				//   link: "/studio/setting",
				//   icon: "icon-shezhi"
				// }
			],
			is_qrcode: false,
		};
	},
	methods: {
		// 跳转详情
		gotoInfo(link) {
			if (link == this.$route.path) {
				this.$router.go(0);
			} else {
				this.$router.push(link);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.left-menu {
	position: fixed;
	width: 210px;
	height: calc(100vh - 48px);
	padding: 50px 0;
	box-sizing: border-box;
	background-color: #fff;
	border-right: 1px solid #e5e5e5;
	.title {
		margin: 0 0 30px 40px;
		font-size: 18px;
		color: #000;
		& > i {
			margin-right: 10px;
		}
	}
	.box {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		.item {
			width: 55%;
			height: 40px;
			margin: 0 auto 20px;
			line-height: 40px;
			text-align: center;
			border-radius: 20px;
			cursor: pointer;
			a {
				padding: 0 10px;
				font-size: 16px;
				font-weight: 500;
				color: #666;
			}
			&:hover {
				background-color: #fdf2f2;
			}
			.iconfont {
				margin-right: 5px;
			}
		}
		.active {
			color: #d30404;
			background-color: #fdf2f2 !important;
			a {
				color: #d30404;
			}
		}
		.btn-init {
			width: 85%;
			height: 45px;
			margin: 0 auto;
			line-height: 45px;
			text-align: center;
			font-size: 18px;
			font-weight: 600;
			color: #fff;
			border-radius: 24px;
			background: linear-gradient(223deg, #ef4033 0%, #e70030 100%);
			cursor: pointer;
		}
		.message {
      position: relative;
			display: flex;
			align-items: center;
			align-items: flex-end;
			justify-content: center;
			width: 90%;
			margin: auto auto 10px;
			padding: 10px 0;
			border-radius: 10px;
			border: 1px solid #d8d6d6;
			.avatar {
				width: 35px;
				height: 35px;
				margin-right: 5px;
				border-radius: 50%;
				cursor: pointer;
			}
			& > div {
				cursor: pointer;
				font-size: 12px;
				& > p:first-child {
					padding-bottom: 5px;
					color: #333;
				}
				& > p:last-child {
					color: #999;
				}
			}
      .kefu-img {
        display: none;
        position: absolute;
        top: -190px;
        left: 15px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        background-color: #fff;
        & > img {
          width: 140px;
          height: 140px;
        }
        & > p {
          text-align: center;
        }
      }
      &:hover {
        .kefu-img {
          display: block;
        }
      }
		}
	}
}
.el-icon-lock {
	font-family: element-icons !important;
}
</style>
