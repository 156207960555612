<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <h4 class="header">
            <i class="el-icon-back" @click="$router.back(-1)"></i>通知详情
          </h4>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '../notice' }">
              全部通知
            </el-breadcrumb-item>
            <el-breadcrumb-item>通知详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="info">
            <i
              class="icon-ui"
              v-if="type"
              :class="[type == 1 ? 'icon-card' : 'icon-notice']"
            ></i>
            <img v-else :src="order_img" alt="" />
            <div>
              <p class="title">{{ title }}</p>
              <p class="date">{{ date }}</p>
              <p class="text">{{ content }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "noticeinfo",
  data() {
    return {
      navId: null,
      user_id: "",
      order_img: "",
      type: null,
      title: "",
      date: "",
      content: "",
    };
  },
  mounted() {
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.userId;
    }

    let mid = this.$route.query.mid;
    this.getNoticeInfo(mid);
  },
  methods: {
    // 获取通知
    getNoticeInfo(id) {
      this.$http({
        url: "/api/wallet/getMessageInfo",
        params: {
          messageId: id,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          // console.log(res);
          this.order_img = res.data.data.image;
          this.title = res.data.data.title;
          this.date = res.data.data.createTime;
          this.content = res.data.data.content;
          this.type = res.data.data.type;
        } else {
          console.log(res.data);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      margin: 0 15px;
      padding: 0 25px;
      box-sizing: border-box;
      background-color: #ffffff;
      .header {
        padding: 25px 0;
        font-weight: bold;
        color: #333;
        border-bottom: 1px solid #dadada;
        & > i {
          margin-right: 30px;
          font-weight: bold;
          font-size: 18px;
          color: #ef4033;
        }
      }
      .el-breadcrumb {
        padding: 20px 0 0 70px;
        box-sizing: border-box;
      }
      .info {
        display: flex;
        margin-top: 30px;
        .icon-ui,
        & > img {
          flex-shrink: 0;
          width: 50px !important;
          height: 50px !important;
          margin-right: 20px;
          border-radius: 50%;
        }
        .title {
          margin: 5px 0 10px;
          font-size: 16px;
          font-weight: bold;
          color: #000;
        }
        .date {
          font-size: 12px;
          color: #999;
        }
        .text {
          margin-top: 20px;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 1px;
          color: #666;
        }
      }
    }
  }
}
</style>
