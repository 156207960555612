<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <div class="info-top">
            <!-- 资金 -->
            <div class="overview">
              <h3 class="m-color-1">资金概览</h3>
              <div class="bd-money">
                <img
                  class="icon-moneyde"
                  src="../../assets/images/icon/allm.png"
                  alt=""
                />
                <div>
                  <p class="font16 m-color-2">总资产</p>
                  <p class="font24 bolden m-color-1">￥{{ balance }}</p>
                </div>
              </div>
              <div class="bd-money">
                <img
                  class="icon-moneyde"
                  src="../../assets/images/icon/witem.png"
                  alt=""
                />
                <div>
                  <p class="font16 m-color-2">等待结款</p>
                  <p class="font24 bolden m-color-1">￥{{ waitPay }}</p>
                </div>
              </div>
              <div>
                <img
                  class="icon-moneyde"
                  src="../../assets/images/icon/Rectangle.png"
                  alt=""
                />
                <div>
                  <p class="font16 m-color-2">红包余额</p>
                  <p class="font24 bolden m-color-1">￥{{ balanceFee }}</p>
                </div>
              </div>
            </div>
            <!-- 结款 -->
            <div class="settlement">
              <!-- 头部 -->
              <div class="flexd">
                <h3 class="m-color-1">申请结款</h3>
                <div class="ml-auto">
                  <el-button
                    type="text"
                    size="small"
                    @click="bindAlipay"
                    class="font14"
                    :disabled="Boolean(isAliBind)"
                  >
                    绑定支付宝
                  </el-button>
                  <!-- <el-button
                    type="text"
                    size="small"
                    @click="bindWechat"
                    class="font14"
                    :disabled="Boolean(isWxBind)"
                  >
                    绑定微信
                  </el-button> -->
                  <span
                    type="danger"
                    class="btn-bank font12"
                    size="mini"
                    @click="$router.push('/bank')"
                  >
                    添加银行卡
                  </span>
                </div>
              </div>

              <h3 class="m-color-2 mt30">选择结款账户</h3>

              <!-- 选择结款账户 -->
              <div class="account-box mt20">
                <i v-if="icon_id == 1" class="iconfont icon-zhifubao"></i>
                <i v-else-if="icon_id == 2" class="iconfont icon-weixin"></i>
                <template v-else>
                  <span
                    class="bg-code"
                    v-if="accountType == 1"
                    :style="{
                    background: `url(${temp_url}${bank_temp_logo})`,
                  }"
                ></span>
                  <img
                    class="bg-code"
                    v-if="accountType == 2"
                    src="../../assets/images/icon/duigo.png"
                  />
                </template>
                <span>{{ bank_temp_name }}</span>
                <el-select
                  class="account-sel"
                  size="small"
                  v-model="bankId"
                  placeholder="请选择"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="item in bankList"
                    :key="item.id"
                    :label="item.bankNumber"
                    :value="item.id"
                    @click.native="selectBank(item)"
                  >
                    <div class="band-option">
                      <i
                        v-if="item.ui_id == 1"
                        class="iconfont icon-zhifubao"
                      ></i>
                      <i
                        v-else-if="item.ui_id == 2"
                        class="iconfont icon-weixin"
                      ></i>
                      <template v-else>
                        <span
                          class="bg-code"
                          v-if="item.accountType == 1"
                          :style="{
                            background: `url(${temp_url}${item.identificationCode})`,
                          }"
                        ></span>
                        <img
                          class="bg-code"
                          src="../../assets/images/icon/duigo.png"
                          v-else
                        />
                      </template>
                      <span>{{ item.bankName }}</span>
                      <span class="num-box">
                        {{ item.bankNumber }}
                      </span>
                    </div>
                  </el-option>
                </el-select>
              </div>

              <!-- 结款金额 -->
              <h3 class="mt80 m-color-2">结款金额</h3>
              <div class="withdraw-box">
                <div class="withdraw">
                  <span>￥</span>
                  <input v-model="drawPrice" class="price" type="number" />
                  <el-button
                    type="danger"
                    class="btn-upmoney btn-style"
                    :loading="withing"
                    @click="withdrawCny"
                  >
                    提 现
                  </el-button>
                </div>
                <p class="tips">
                  <!-- 扣除1%手续费后可提现金额￥
                  {{ canPay }}, -->
                  <span @click="drawAllMoney">全部提现</span>
                </p>
              </div>
            </div>
          </div>
          <!-- 财务记录 -->
          <div
            class="financial-records mt20"
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="30"
          >
            <h3 class="m-color-1">财务记录</h3>
            <!-- 选择条件 -->
            <div class="condition-box">
              <div>
                <span class="m-color-3 mr20">时间：</span>
                <span class="font12 btn-all" @click="searchAll">全部</span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker
                  v-model="sel_date"
                  type="daterange"
                  size="mini"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeDate"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
              <el-dropdown @command="handleCommand" trigger="click">
                <span class="el-dropdown-link">
                  {{ text_tip ? text_tip : "全部交易类型" }}
                  <i class="el-icon-arrow-down el-icon--right bolden"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in typeList"
                    :key="index"
                    :command="index"
                  >
                    {{ item.dictLabel }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <!-- 筛选列表 -->
            <div v-if="text_tip || up_date">
              <div
                class="fin-item"
                v-for="(info, index) in financialList"
                :key="index"
              >
                <div
                  class="fin-info"
                  v-if="info.flowType == 1 || info.flowType == 2"
                  @click="gotoInfo('/studio/capital/info', info.flowId)"
                >
                  <img
                    class="mr15"
                    src="../../assets/images/icon/Mask.png"
                    alt=""
                    v-if="info.businessType == 1"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/tuik.png"
                    alt=""
                    v-else-if="info.businessType == 3"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/mdown.png"
                    alt=""
                    v-else-if="info.businessType == 5"
                  />
                  <img
                    class="mr15"
                    src="../../assets/images/icon/give.png"
                    alt=""
                    v-else-if="info.businessType == 6"
                  />
                  <div class="info-tit">
                    <p class="m-color-1 font16 text-two">
                      {{ info.businessTypeDes }}
                    </p>
                    <p class="font12 lightGray">{{ info.createTime }}</p>
                  </div>
                </div>
                <div class="fin-info" v-else>
                  <img
                    class="mr15"
                    src="../../assets/images/icon/qit.png"
                    alt=""
                  />
                  <div class="lineh20">
                    <p class="m-color-1 font16 text-two">
                      {{ info.businessTypeDes }}
                    </p>
                    <p class="font12 lightGray">{{ info.createTime }}</p>
                  </div>
                </div>
                <span class="font18 bolden m-color-3">
                  {{ info.flowType == 1 ? "+" : info.flowType == 2 ? "-" : "" }}
                  {{ info.amount }}
                </span>
              </div>
              <p
                class="bottom-style m-color-1"
                v-show="noMore && financialList.length"
              >
                <i class="el-icon-hot-water"></i>没有更多了
              </p>
              <div class="no-data font14" v-if="!financialList.length">
                暂无财务记录
              </div>
            </div>
            <!-- 全部列表 -->
            <template v-else>
              <div
                class="financial-list mb20"
                v-for="(item, index) in allFlow"
                :key="index"
              >
                <div class="flexd">
                  <div>
                    <p class="font12 m-color-3">
                      <span class="font26 mr5">{{ item.month }}</span>月
                      <span>{{ item.date.slice(0,4) == now_year ? "" : " / " + item.date.slice(0,4) }}</span>
                    </p>
                    <p class="mt15 mb20 font14 m-color-3">
                      <span class="mr30">
                        支出￥{{ item.outlay ? item.outlay : "0.00" }}
                      </span>
                      <span>收入￥{{ item.income ? item.income : "0.00" }}</span>
                    </p>
                  </div>
                  <span
                    class="ml-auto font12 red cursor-p"
                    @click="gotoInfo('/studio/capital/month', item.date)"
                    >查看全部</span
                  >
                </div>
                <div
                  class="fin-item"
                  v-for="(info, id) in item.bsiFlowCnyList"
                  :key="id"
                >
                  <div
                    class="fin-info"
                    v-if="info.flowType == 1 || info.flowType == 2"
                    @click="gotoInfo('/studio/capital/info', info.flowId)"
                  >
                    <img
                      class="mr15"
                      src="../../assets/images/icon/Mask.png"
                      alt=""
                      v-if="info.businessType == 1"
                    />
                    <img
                      class="mr15"
                      src="../../assets/images/icon/tuik.png"
                      alt=""
                      v-else-if="info.businessType == 3"
                    />
                    <img
                      class="mr15"
                      src="../../assets/images/icon/mdown.png"
                      alt=""
                      v-else-if="info.businessType == 5"
                    />
                    <img
                      class="mr15"
                      src="../../assets/images/icon/give.png"
                      alt=""
                      v-else-if="info.businessType == 6"
                    />
                    <div class="info-tit">
                      <p class="m-color-1 font16 text-two">
                        {{ info.businessTypeDes }}
                      </p>
                      <p class="font12 lightGray">{{ info.createTime }}</p>
                    </div>
                  </div>
                  <div class="fin-info" v-else>
                    <img
                      class="mr15"
                      src="../../assets/images/icon/qit.png"
                      alt=""
                    />
                    <div class="lineh20">
                      <p class="m-color-1 font16 text-two">
                        {{ info.businessTypeDes }}
                      </p>
                      <p class="font12 lightGray">{{ info.createTime }}</p>
                    </div>
                  </div>
                  <span class="font18 bolden m-color-3">
                    {{ info.flowType == 1 ? "+" : info.flowType == 2 ? "-" : ""
                    }}{{ info.amount }}
                  </span>
                </div>
                <div class="no-da font12" v-if="!item.bsiFlowCnyList.length">
                  本月无财务记录
                </div>
              </div>
              <p
                class="bottom-style m-color-1"
                v-show="noMore && allFlow.length"
              >
                <i class="el-icon-hot-water"></i>没有更多了
              </p>
              <div class="no-data font14" v-if="!allFlow.length">
                暂无财务记录
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "capital",
  data() {
    return {
      navId: 3,
      bank_temp_name: "结款账户",
      bank_temp_logo: "",
      temp_url: "https://apimg.alipay.com/combo.png?d=cashier&t=",
      accountType: null,
      balance: 0, //账户余额
      balance_pull: 0, //取账户余额
      waitPay: 0, // 等待结款
      drawPrice: 0, // 提现金额
      balanceFee: 0,
      bankId: "", // 银行卡id
      withing: false, // 正在提现

      isAliBind: false,
      isWxBind: false,
      icon_id: null,
      bank_type: null,

      bankList: [],
      financialList: [],
      page: 1,
      busy: true,
      noMore: false,
      page_count: 10,
      now_year: "",
      user_id: "",
      typeList: [],

      sel_date: [],
      sel_tip: "",
      text_tip: "",
      up_date: "",
      allFlow: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    // 获取userid
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.userId;
    }

    // 绑定支付宝
    let Alipay_code = this.$route.query.auth_code || "";
    if (Alipay_code) {
      this.bindAlipayUp(Alipay_code, userinfo.userId);
    }

    // 绑定微信
    let Wechat_code = this.$route.query.code || "";
    if (Wechat_code) {
      this.bindWechatUp(Wechat_code, userinfo.userId);
    }

    this.now_year = new Date().getFullYear();

    // 获取银行卡
    this.getMyBank();
    // 获取资金信息
    this.getFlowCny();
    // 获取全部资金信息
    this.getFlowCnyAll();
    // 获取交易类型
    this.getDicts();
  },
  methods: {
    // 下拉加载
    loadMore() {
      this.busy = true;
      this.page++;
      if (this.text_tip || this.up_date) {
        this.getFlowCny();
      } else {
        this.getFlowCnyAll();
      }
    },
    // 获取全部
    getFlowCnyAll() {
      this.$http({
        url: "/api/wallet/getFlowCnyAll",
        params: {
          studioId: localStorage.getItem("sid"),
          pageSize: this.page_count,
          pageNo: this.page,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          if (this.allFlow.length) {
            this.allFlow = this.allFlow.concat(res.data.data);
          } else {
            this.allFlow = res.data.data;
          }
          if (res.data.data.length) {
            this.busy = false;
          } else {
            this.busy = true;
            this.noMore = true;
          }
        } else {
          console.log(res);
        }
      });
    },
    // 获取资金信息
    getFlowCny() {
      this.$showLoading("loading...", ".content");
      let data = {
        pageNo: this.page,
        pageSize: this.page_count,
        businessType: this.sel_tip,
        studioId: localStorage.getItem("sid"),
      };
      if (this.up_date.length == 7) {
        data.dateQuery = this.up_date;
      } else {
        data.dateBetweenQuery = this.up_date;
      }
      this.$http({
        url: "/api/wallet/getFlowCny",
        params: data,
      }).then((res) => {
        if (res.data.code == 200) {
          this.balance = res.data.data.balance;
          this.waitPay = res.data.data.balanceAvl;
          this.balanceFee = res.data.data.balanceFee;
          if (this.financialList.length) {
            this.financialList = this.financialList.concat(
              res.data.data.merchantFlows
            );
          } else {
            this.financialList = res.data.data.merchantFlows;
          }
          if (res.data.data.merchantFlows.length) {
            this.busy = false;
          } else {
            this.busy = true;
            this.noMore = true;
          }
        } else {
          console.log(res);
        }
        this.$hideLoading();
      });
    },
    // 获取交易类型
    getDicts() {
      this.$http({
        url: "/api/public/v1.0/getDicts",
        params: {
          type: 1,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = {
            dictLabel: "全部",
            dictValue: "",
          };
          this.typeList = res.data.data;
          setTimeout(() => {
            this.typeList.push(data);
          }, 500);
        } else {
          console.log(res);
        }
      });
    },
    // 获取银行卡
    getMyBank() {
      this.$http({
        url: "/api/wallet/getMerchantBindInfo",
        params: {
          studioId: localStorage.getItem("sid"),
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.bankList = res.data.data.cardList;
          // 支付宝绑定状态
          this.isAliBind = res.data.data.isAliBind;
          // 微信绑定状态
          this.isWxBind = res.data.data.isWxBind;
          let alipay = res.data.data.alipayBind || "";
          let weixin = res.data.data.weixinBind || "";
          if (alipay.tnickName) {
            let data = alipay;
            data.bankNumber = alipay.tnickName;
            data.bankName = "支付宝账户";
            data.ui_id = 1;
            this.bankList.push(data);
          }
          if (weixin.tnickName || weixin.id) {
            let data = weixin;
            data.bankNumber = weixin.tnickName;
            data.bankName = "微信账户";
            data.ui_id = 2;
            this.bankList.push(data);
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 选择银行卡
    selectBank(item) {
      this.icon_id = item.ui_id;
      this.bank_type = item.type;
      this.accountType = item.accountType;
      this.bank_temp_name = item.bankName;
      this.bank_temp_logo = item.identificationCode;
    },
    // 绑定支付宝
    bindAlipay() {
      // this.$http("/api/wallet/getAliAuthUrl").then((res) => {
      //   if (res.data.code == 200) {
      //     window.location.href = res.data.msg;
      //   }
      // });
      let url = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002103650831&scope=auth_user&redirect_uri=${this.$callurl}/studio/capital`;
      window.location.href = url;
    },
    bindAlipayUp(code, id) {
      this.$http({
        url: "/api/wallet/alipayBind",
        method: "POST",
        params: {
          code: code,
          userId: id,
          requestFrom: 2,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("绑定成功！");
          if (this.$route.query.auth_code) {
            history.pushState("", "", `${this.$callurl}/studio/capital`);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 绑定微信
    bindWechat() {
      this.$http("/api/user/v1.0/getQRCodeUrl1").then((res) => {
        if (res.data.code == 200) {
          window.location.href = res.data.msg;
        }
      });
    },
    bindWechatUp(code, id) {
      this.$http({
        url: "/api/wallet/wxBind",
        method: "POST",
        params: {
          code: code,
          userId: id,
          requestFrom: 2,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("绑定成功！");
          if (this.$route.query.code) {
            history.pushState("", "", `${this.$callurl}/studio/capital`);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 全部提现
    drawAllMoney() {
      this.drawPrice = this.waitPay;
    },
    // 提现
    withdrawCny() {
      // console.log(this.canPay);
      // if (this.drawPrice < 1) {
      //   this.$message.warning("提现金额不得少于1元！");
      //   return;
      // }
      if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(this.drawPrice)) {
        this.$message.warning("请输入正确的金额!");
        return;
      }
      if (!this.bankId) {
        this.$message.warning("请选择结款账户！");
        return;
      }
      this.withing = true;
      this.$http({
        url: "/api/wallet/withdrawCny",
        method: "POST",
        params: {
          amount: this.canPay,
          bankId: this.bankId,
          type: this.bank_type,
          userId: this.user_id,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
          window.location.reload();
        } else {
          this.$message.warning(res.data.msg);
        }
        this.withing = false;
      });
    },
    // 全部
    searchAll() {
      this.sel_tip = "";
      this.text_tip = "";
      this.up_date = "";
      this.sel_date = [];
      this.busy = true;
      this.noMore = false;
      this.page = 1;
      this.allFlow = "";
      this.financialList = "";
      this.getFlowCnyAll();
    },
    // 选择时间
    changeDate(e) {
      let start = this.$formatDate(e[0], "date");
      let end = this.$formatDate(e[1], "date");
      this.up_date = start + "&" + end;
      this.busy = true;
      this.noMore = false;
      this.page = 1;
      this.allFlow = "";
      this.financialList = "";
      this.getFlowCny();
    },
    // 选择条件
    handleCommand(e) {
      // console.log(e);
      this.text_tip = this.typeList[e].dictLabel;
      this.sel_tip = this.typeList[e].dictValue;
      this.busy = true;
      this.noMore = false;
      this.page = 1;
      this.allFlow = "";
      this.financialList = "";
      this.getFlowCny();
    },
    //
    gotoInfo(url, date) {
      this.$router.push({
        path: url,
        query: { date: date },
      });
    },
  },
  computed: {
    canPay: function() {
      return this.drawPrice >= 100
        ? (this.drawPrice - Number(this.drawPrice) / 100).toFixed(2)
        : Number(this.drawPrice).toFixed(2);
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    min-height: calc(100vh - 48px);
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      height: 100%;
      margin: 15px 15px 0;
      box-sizing: border-box;
      background-color: #f5f5f5;
      .info-top {
        display: flex;
        align-items: center;
        width: 100%;
        & > div {
          height: 460px;
          border-radius: 20px;
          box-sizing: border-box;
          background-color: #fff;
        }
      }
      // 资金概括
      .overview {
        width: 410px;
        padding: 30px;
        margin-right: 15px;
        & > div {
          display: flex;
          padding: 20px 0;
          margin: 20px 0;
          & > div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
        .bd-money {
          border-bottom: 1px solid #e1e2eb;
        }
      }
      // 申请借款
      .settlement {
        flex: 1;
        padding: 20px 30px;
        .ml-auto {
          & > .el-button {
            padding: 0;
          }
        }
      }
      .icon-moneyde {
        width: 55px;
        height: 55px;
        margin-right: 25px;
      }

      // 选择账户
      .account-box {
        position: relative;
        display: flex;
        align-items: center;
        width: 440px;
        padding: 0 20px;
        font-size: 12px;
        color: #8287ab;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        .bg-code {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
          background-position: 4% 30% !important;
          background-repeat: no-repeat !important;
        }
        .icon-zhifubao {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          color: #1296db;
          line-height: 20px;
        }
        .icon-weixin {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          color: #04be02;
          line-height: 20px;
        }
        .account-sel {
          margin-left: auto;
          .band-option {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #8287ab;
            .num-box {
              width: 46%;
              margin-left: auto;
            }
          }
        }
        ::v-deep.el-input__inner {
          border: none !important;
          color: #8287ab;
        }
      }
      .withdraw-box {
        margin-top: 30px;
        .withdraw {
          display: flex;
          margin: 35px 0;
          & > span {
            color: #8287ab;
            font-size: 30px;
            font-weight: bold;
          }
          & > input {
            font-size: 18px;
            letter-spacing: 1px;
          }
          .price {
            padding: 5px 10px;
            margin: 0 10px;
            box-sizing: border-box;
            border-bottom: 2px solid #f5f5f5;
          }
          .btn-upmoney {
            height: 30px;
            padding: 0 20px;
            font-size: 12px;
            border-radius: 8px;
          }
        }
        .tips {
          margin-top: 20px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #8287ab;
          span {
            color: #ef4033;
            cursor: pointer;
          }
        }
      }

      .financial-records {
        width: 100%;
        height: 100%;
        max-height: 100vh;
        padding: 30px;
        overflow-y: scroll;
        border-radius: 20px;
        box-sizing: border-box;
        background-color: #fff;
        .condition-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          .btn-all {
            padding: 5px 8px;
            color: #fff;
            border-radius: 4px;
            background-color: #ef4033;
            cursor: pointer;
          }
          .el-dropdown-link {
            font-size: 16px;
            font-weight: 600;
            color: #111c60;
            cursor: pointer;
          }
        }
        .financial-list {
          padding: 10px 0;
          border-bottom: 1px solid #e1e2eb;
        }
      }
      .fin-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 30px;
        .fin-info {
          display: flex;
          & > img {
            width: 50px;
            height: 50px;
          }
          & > div {
            display: flex;
            flex-direction: column;
          }
        }
        .info-tit {
          width: 90%;
          line-height: 1.6;
          cursor: pointer;
        }
      }
    }
  }
}
.no-da {
  line-height: 4;
  text-align: center;
  color: #8287ab;
}
.no-data {
  line-height: 20vh;
  text-align: center;
  color: #999;
}
.el-select {
  ::v-deep.el-select-dropdown {
    width: 440px !important;
    left: -218px !important;
  }
}
::v-deep.el-dropdown-menu__item {
  padding: 0 35px;
  text-align: center;
}
::v-deep.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fdf2f2;
}

.btn-bank {
  display: inline-block;
  height: 30px;
  padding: 0 10px;
  margin-left: 15px;
  line-height: 30px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(223deg, #ef4033 0%, #e70030 100%);
}

.m-color-1 {
  color: #061058;
}
.m-color-2 {
  color: #8287ab;
}
.m-color-3 {
  color: #111c60;
}
.mt80 {
  margin-top: 80px;
}
</style>
