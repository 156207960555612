<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '../trend' }">
              数据报表
            </el-breadcrumb-item>
            <el-breadcrumb-item>单场演出数据</el-breadcrumb-item>
          </el-breadcrumb>
          <h3>{{ session_title }}</h3>
          <div class="header">
            <div class="date-box">
              <div
                v-for="(item, index) in dateList"
                :key="index"
                :class="{ 'btn-style': index == n_id }"
                @click="saveIndex(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="box">
              <div>
                <p>票房（元）</p>
                <p>{{ totalAmount }}</p>
              </div>
              <div class="ui-cut"></div>
              <div>
                <p>票房转化率（整体）</p>
                <p>{{ conversionRatio }}%</p>
              </div>
            </div>
            <div class="op-list">
              <div v-for="(item, index) in opList" :key="index">
                <div class="cube">
                  <div>
                    <p>{{ item.num }}</p>
                    <p>{{ item.name }}</p>
                  </div>
                  <span class="icon-ui" :class="item.icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="trand-view">
            <h3>趋势图</h3>
            <ve-line :data="chartData" :settings="chartSettings"></ve-line>
            <h3>售票详情Excel表格</h3>
            <div class="btn-op">
              <el-button type="text" @click="downloadEx">下载查看</el-button>
              <el-button type="text" @click="copyText">复制网址</el-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// 折线图
import VeLine from 'v-charts/lib/line.common'
export default {
  name: "info",
  components: {
    "ve-line": VeLine
  },
  data() {
    this.chartSettings = {
      labelMap: {
        totalAmount: "票房",
        conversionRatio: "转化率%",
      },
      axisSite: { right: ["conversionRatio"] },
      yAxisName: ["票房", "转化率"],
    };
    return {
      navId: 4,
      dateList: ["7天", "30天"],
      n_id: 0,

      totalAmount: 0,
      conversionRatio: 0,
      session_id: "", // 场次id
      session_title: "",

      opList: [
        {
          name: "支付笔数",
          num: 0,
          icon: "icon-data",
        },
        {
          name: "核销次数",
          num: 0,
          icon: "icon-seal",
        },
        {
          name: "访问人数",
          num: 0,
          icon: "icon-group",
        },
        {
          name: "访问次数",
          num: 0,
          icon: "icon-foot",
        },
      ],
      chartData: {
        columns: ["dayStr", "totalAmount", "conversionRatio"],
        rows: [],
      }
    };
  },
  mounted() {
    this.session_id = this.$route.query.id;
    this.session_title = this.$route.query.title;
    this.getDataReport(2);
    this.getTrend(1);
  },
  methods: {
    // 查询商户数据报表
    getDataReport(type) {
      this.$http({
        url: "/api/wallet/getDataReport",
        params: {
          studioId: localStorage.getItem("sid"),
          type: type,
          siteId: this.session_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.opList[0].num = data.orderCount;
          this.opList[1].num = data.destroyedCount;
          this.opList[2].num = data.visitingUserVolume;
          this.opList[3].num = data.visitingVolume;
          this.totalAmount = data.totalAmount;
          this.conversionRatio = data.conversionRatio;
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 查询场次下票房趋势图
    getTrend(type) {
      this.$http({
        url: "/api/wallet/getTrend",
        params: {
          siteId: this.session_id,
          studioId: localStorage.getItem("sid"),
          trendType: type,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.chartData.rows = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 下载文档
    downloadEx() {
      let id = localStorage.getItem("sid");
      let url = `${this.$callurl}/api/wallet/export?siteId=${this.session_id}&studioId=${id}`;
      window.location.href = url;
    },
    // 复制
    copyText() {
      let id = localStorage.getItem("sid");
      let url = `${this.$callurl}/api/wallet/export?siteId=${this.session_id}&studioId=${id}`;
      this.$copyText(url).then((e) => {
          console.log(e);
          this.$message.success("复制成功!");
        },
        function(err) {
          console.log(err);
        }
      );
    },

    //
    saveIndex(id) {
      this.n_id = id;
      this.getDataReport(parseInt(id + 2));
      this.getTrend(parseInt(id + 1));
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1200px;
      margin: 0 15px;
      box-sizing: border-box;
      .el-breadcrumb {
        padding: 15px 25px;
        box-sizing: border-box;
      }
      & > h3 {
        padding: 15px 25px;
        font-weight: 600;
        background-color: #fff;
      }
      & > div {
        border-radius: 5px;
        background-color: #ffffff;
      }
      .header {
        .date-box {
          display: flex;
          align-items: center;
          padding: 15px 25px;
          box-sizing: border-box;
          border-bottom: 2px solid #f2f2f2;
          & > div {
            width: 95px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 2px;
            color: #333;
            background-color: #fdf2f2;
            cursor: pointer;
          }
        }
        .box {
          display: flex;
          padding: 25px 30px;
          box-sizing: border-box;
          & > div {
            p:first-child {
              margin-bottom: 15px;
              font-size: 14px;
              color: #666;
            }
            p:last-child {
              font-size: 22px;
              font-weight: 600;
            }
          }
          .ui-cut {
            width: 2px;
            height: 50px;
            margin: 0 40px;
            background-color: #f5f5f5;
          }
        }
        .op-list {
          display: flex;
          padding: 0 0 25px 25px;
          .cube {
            display: flex;
            justify-content: space-between;
            width: 165px;
            height: 100px;
            padding: 25px;
            margin-right: 15px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: rgba(253, 242, 242, 0.65);
            & > div {
              p:first-child {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 600;
              }
              p:last-child {
                font-size: 14px;
                color: #666;
              }
            }
          }
        }
      }
      .trand-view {
        padding: 50px;
        margin-top: 15px;
        box-sizing: border-box;
        & > h3 {
          font-weight: 600;
        }
        .btn-op {
          margin-top: 10px;
          .el-button {
            color: #d30404;
            font-weight: bold !important;
          }
        }
      }
    }
  }
}
</style>
