<template>
	<div>
		<template v-if="card_type == 1">
			<p class="id-title">
				上传身份证<span>（上传身份证件照可直接填充信息）</span>
			</p>
			<div class="ocr-box">
				<el-upload
					ref="front"
					class="ocr-up"
					action="/api/public/v1.0/upload"
					:show-file-list="false"
					:headers="headers"
					name="multipartFile"
					:on-success="(res, file) => uploadSuccess2(res, file, 1)"
					:before-upload="uploadPreviewId"
					:multiple="false"
					:limit="1"
					accept="image/png,image/jpg,image/jpeg"
				>
					<img class="id-img" :src="img_front" v-if="img_front" alt="" />
					<img
						class="id-img"
						src="../assets/images/positive.png"
						v-else
						alt=""
					/>
					<el-button icon="el-icon-upload2" size="mini">
						上传人像面
					</el-button>
				</el-upload>
				<el-upload
					ref="reverse"
					class="ocr-up"
					action="/api/public/v1.0/upload"
					:show-file-list="false"
					:headers="headers"
					name="multipartFile"
					:on-success="(res, file) => uploadSuccess2(res, file, 0)"
					:before-upload="uploadPreviewId"
					:multiple="false"
					:limit="1"
					accept="image/png,image/jpg,image/jpeg"
				>
					<img class="id-img" :src="img_reverse" alt="" v-if="img_reverse" />
					<img
						class="id-img"
						src="../assets/images/reverse.png"
						alt=""
						v-else
					/>
					<el-button icon="el-icon-upload2" size="mini">
						上传国徽面
					</el-button>
				</el-upload>
			</div>
		</template>
		<template v-if="card_type == 2">
			<p class="id-title">
				上传银行卡<span>（上传银行卡照片可直接填充信息）</span>
			</p>
			<div class="ocr-box">
				<el-upload
					ref="front"
					class="ocr-up"
					action="/api/public/v1.0/upload"
					:show-file-list="false"
					:headers="headers"
					name="multipartFile"
					:on-success="(res, file) => uploadSuccess2(res, file, 1)"
					:before-upload="uploadPreviewId"
					:multiple="false"
					:limit="1"
					accept="image/png,image/jpg,image/jpeg"
				>
					<img :src="img_front" v-if="img_front" alt="" />
					<img src="../assets/images/positive.png" v-else alt="" />
					<el-button icon="el-icon-upload2" size="mini">
						上传人像面
					</el-button>
				</el-upload>
				<el-upload
					ref="reverse"
					class="ocr-up"
					action="/api/public/v1.0/upload"
					:show-file-list="false"
					:headers="headers"
					name="multipartFile"
					:on-success="(res, file) => uploadSuccess2(res, file, 0)"
					:before-upload="uploadPreviewId"
					:multiple="false"
					:limit="1"
					accept="image/png,image/jpg,image/jpeg"
				>
					<img :src="img_reverse" alt="" v-if="img_reverse" />
					<img src="../assets/images/reverse.png" alt="" v-else />
					<el-button icon="el-icon-upload2" size="mini">
						上传国徽面
					</el-button>
				</el-upload>
			</div>
		</template>
		<template v-if="card_type == 3">
			<p class="id-title">
				上传营业执照<span>（上传营业执照可直接填充信息）</span>
			</p>
			<div class="ocr-box">
				<el-upload
					ref="front"
					class="ocr-up"
					action="/api/public/v1.0/upload"
					:show-file-list="false"
					:headers="headers"
					name="multipartFile"
					:on-success="(res, file) => uploadSuccess2(res, file, 1)"
					:before-upload="uploadPreviewId"
					:multiple="false"
					:limit="1"
					accept="image/png,image/jpg,image/jpeg"
				>
					<img class="main-img" :src="licenseimg" v-if="licenseimg" alt="" />
					<img
						class="main-img"
						src="../assets/images/upload.png"
						v-else
						alt=""
					/>
				</el-upload>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "uploadCard",
	props: ["card_type", "urlInfo"],
	data() {
		return {
			headers: {
				Authorization: localStorage.getItem("token"),
			},
			img_front: "",
			img_reverse: "",
			licenseimg: "",
		};
	},
	mounted() {
		if (this.card_type == 1) {
			if (this.urlInfo.length) {
				this.img_front = this.urlInfo[0];
				this.img_reverse = this.urlInfo[1];
			}
		} else if (this.card_type == 3) {
			this.licenseimg = this.urlInfo;
		}
	},
	methods: {
		// 选择文件 进行判断
		uploadPreviewId(file) {
			this.$showLoading("loading...", ".ocr-box");
			const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
			const SIZE = file.size / 1024 / 1024 < 10;
			if (!TYPE) {
				this.$message.error({
					message: "请选择正确的图片！",
					center: true,
					duration: 2000,
				});
				return TYPE;
			}
			if (!SIZE) {
				this.$message.warning({
					message: "上传头像图片大小不能超过 10MB!",
					center: true,
					duration: 2000,
				});
				return SIZE;
			}
			return TYPE && SIZE;
		},
		// element上传
		uploadSuccess2(res, file, type) {
			// console.log(res, file, type);
			// base64
			// var reader = new FileReader();
			// reader.onloadend = function() {
			//   console.log(reader.result);
			// };
			// reader.readAsDataURL(file.raw);
			//
			if (res.code == 200) {
				if (this.card_type == 1) {
					// ocr
					this.cardOcr(res.msg, type);
				} else if (this.card_type == 2) {
					console.log(2);
				} else if (this.card_type == 3) {
					this.getLicenseInfo(res.msg);
				}
			} else {
				console.log(file);
			}
		},
		// 身份证ocr
		cardOcr(url, type) {
			let formdata = new FormData();
			formdata.append("imgUrl", url);
			formdata.append("type", type);
			this.$http({
				method: "POST",
				url: "/api/public/v1.0/idCardOcr",
				params: {
					imgUrl: url,
					type: type,
				},
			}).then((res) => {
				this.$refs.front.clearFiles();
				this.$refs.reverse.clearFiles();
				this.$hideLoading();
				if (res.data.code == 200) {
					if (type == 1) {
						this.img_front = url;
						var data = {
							username: res.data.data["姓名"].words,
							idcard: res.data.data["公民身份号码"].words,
						};
					} else {
						this.img_reverse = url;
					}
					// console.log(res.data.msg);
					this.$emit("getIDcardInfo", data, url, type);
				} else {
					this.$message.error("识别失败,请重新上传!");
				}
			});
		},
		// 银行卡ocr
		bankOcr(url, type) {
			this.$http({
				url: "/api/public/v1.0/cardOcr",
				params: {
					imgFile: url,
					type: type,
				},
			}).then((res) => {
				this.$refs.front.clearFiles();
				this.$refs.reverse.clearFiles();
				this.$hideLoading();
				if (res.data.code == 200) {
					if (type == 1) {
						this.img_front = url;
					} else {
						this.img_reverse = url;
					}
					// console.log(res.data.msg);
					this.$emit("getIDcardInfo", JSON.parse(res.data.msg), url, type);
				} else {
					this.$message.error("识别失败,请重新上传!");
				}
			});
		},
		// 获取营业执照信息
		getLicenseInfo(url) {
			this.$http({
				method: "POST",
				url: "/api/public/v1.0/businessLicense",
				params: {
					imgUrl: url,
				},
			}).then((res) => {
				this.$hideLoading();
				if (res.data.code == 200) {
					let info = res.data.data;
					let data = {
						name: info["单位名称"].words,
						code: info["社会信用代码"].words,
					};
					if (!data.name || !data.code) {
						this.$message.warning("请上传失败，请重新上传!");
						return;
					}
					this.licenseimg = url;
					this.$emit("getMainInfo", data, url);
				} else {
					this.$message.warning(res.data.msg);
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.id-title {
	margin-top: 20px;
	font-size: 14px;
	& > span {
		font-size: 12px;
		color: #999;
	}
}
.ocr-box {
	display: flex;
	padding: 20px 0;
	.ocr-up {
		margin-right: 60px;
		text-align: center;
	}
}
.id-img {
	display: block;
	width: 190px;
	height: 120px;
	margin-bottom: 15px;
}
.bank-img {
	display: block;
	width: 190px;
	height: 120px;
	margin-bottom: 15px;
}
.main-img {
	display: block;
	width: 100px;
	margin-bottom: 15px;
}
</style>
