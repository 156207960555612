<template>
  <div class="main">
    <div v-title data-title="关于普通用户以及艺人等商业账号使用协议"></div>
    <div class="p-hrader">RockGuys</div>
    <div class="con">
      <main>
        <div class="pri-context">
          <p class="title">关于普通用户以及艺人等商业账号使用协议</p>
          <p>导言</p>
          <p>欢迎你使用 RockGuys 软件平台服务！</p>
          <p>为使用RockGuys（以下简称“本软件”）及服务，你应当阅读并遵守《关于艺人等商业账号使用协议》（以下简称“本协议”）。请你务必仔细阅读、充分理解各条款内容。</p>
          <p>RockGuys软件平台是指青岛不大一样网络技术有限公司开发的RockGuys APP（iOS系统和Android系统）、RockGuys微信小程序、RockGuys网站，除非你已阅读并遵守本协议所有条款，否则你无权使用本软件及相关服务。你的使用、开通工作室帐号、登录等行为即视为你已阅读并同意上述协议的约束。</p>
          <p>如果你未满18周岁，请在法定监护人的陪同些阅读本协议及其他上述协议，并特别注意未成年人使用条款。</p>
          <h2>一、协议适用主体范围</h2>
          <p>
            本协议是RockGuys软件平台中开设工作室账号的用户与青岛不大一样网络技术有限公司关于安装、使用本软件平台，以及相关服务所订立的协议。在你开设工作室账号时以及之后使用期间或者作为普通用户使用RockGuys提供的服务时，您需要遵守。
          </p>
          <h2>二、关于本服务</h2>
          <p>2.1本服务的内容</p>
          <p>2.1本服务内容是指青岛不大一样网络技术有限公司通过RockGuys软件平台向用户提供发布活动、票务销售、核销统计、对账查询等功能的软件平台系统软件。</p>
          <p>2.2工作室帐号</p>
          <p>工作室帐号是指用户在RockGuys开设的可选择为艺人、场馆、厂牌等形式的商业帐号，工作室账号开通后会自动开通创建管理活动、核销、数据统计、财务管理、工作室账号主页、权限管理等功能。在开设工作室账号时，用户应保证提交的身份认证审核信息的真实性，如因用户提交虚假信息RockGuys团队有权在不通知用户的情况下删除该工作室账号以及相关的所有数据，RockGuys团队无需对此承担责任。如用户提交的虚假信息、冒用他人信息造成对第三方的肖像、版权、个人隐私等造成侵害，用户需为此承担一切法律责任。</p>
          <p>2.3票务系统</p>
          <p>2.3.1本协议声明工作室账号用户可以通过RockGuys软件平台发布演出活动并制定各票种信息灯功能，包括但不限于发布活动、设定票价、设定票种、开卖周期、核销票务、票务统计、权限管理等，工作室账号用户作为活动主办或者相关方可自主选择是否使用RockGuys软件平台为单一售票平台。</p>
          <p>2.3.2工作室账号用户发布或创建的演出活动信息可以自主推广，包括但不限于微信朋友圈、微信群、微信公众号等渠道，但RockGuys团队有权决定其在RockGuys内活动主页的展示形式。</p>
          <p>2.3.3RockGuys票务系统出票形式为电子票（附含二维码），购票用户可凭二维码在到演出现场作为购票凭证出示，工作室账号用户需为购票用户核销电子票，因工作室账号用户未及时核销或者未核销导致的演出方财务损失应由工作室账号用户承担，RockGuys团队不承担责任。RockGuys只提供核销数据和对账明细。</p>
          <p>2.3.4工作室账号用户发布或者创建的活动，所设定的票务库存、开售停售日期、票种信息需要仔细核对，确认无误后方可提交审核，如RockGuys团队审核失败则需修改后再次提交审核，直至审核成功后发布活动才可正式售票以及在活动列表页显示。因工作室账号用户设定原因导致的票务超卖、票房不利等损失，RockGuys团队不承担责任。</p>
          <p>2.3.5购票用户使用RockGuys软件平台购票时，支持微信支付、支付宝等形式，用户可以通过RockGuys APP、微信小程序、移动网站渠道支付。工作室账号用户发布或者创建活动带来票房收入后，正常情况下可在活动结束一个工作日内发起财务结款申请，结款资金通过审核会在三个工作日内打到工作室账号用户指定的银行账户内或微信钱包或支付宝账户内。对于一般性演出（特指livehouse级别演出），RockGuys不收取服务费用，但RockGuys不承担在资金流转过程中所消耗的支付手续费用，包括但不限于微信支付0.6%、银行手续费1%，RockGuys将对所有演出票房扣除综合1%的支付通道手续费用；针对大型演出活动，例如音乐节、演唱会等，RockGuys收取相关服务费或支付通道手续费将单独协商。因不可抗力或者工作室账号用户原因导致演出活动未举办，请务必及时告知RockGuys团队，以便快速给购票用户退款解决后续事宜，避免与购票用户产生冲突造成不利影响，退票以及后续事宜视具体情况协商解决。因工作室账号主体通知不及时、沟通不到位所造成的损失，RockGuys团队不承担任何责任。</p>
          <p>2.3.6工作室账号主体用户需使用RockGuys软件平台扫码核销票务，并且至少核销全部售票的80%以上，不然不可主动结算票务，核销数据会在完整数据报告中体现，核销数据可体现观众到场率。工作室账号创建者管理员可添加运营管理员和核销管理员来核销票务，并且可以随时删除增加运营管理员和核销员，所添加的核销员和运营管理员只可以核销该工作室账号的场次票务。当用户拥有多个工作室账号的核销权限时，只能进入需核销的工作室账号拥有核销该工作室账号创建的活动的核销权限。</p>
          <p>2.3.7工作室账号创建演出活动之后可通过多种方式宣传推广该演出活动，包括不限于RockGuys生成的含软件平台码的海报，分享朋友圈、插入到微信图文、网站传播、APP内传播以及其他可以显示的渠道；分享活动页面到微信群/微信好友/其他APP平台等。</p>
          <h2>三、软件的更新</h2>
          <p>
            RockGuys团队有权在不通知用户的前提下更新RockGuys，在不告知用户的情况下，提供更多更丰富的功能升级/改进，RockGuys默认工作室账号主体阅读并同意遵守RockGuys升级所带来的改变，工作室账号主体可及时沟通RockGuys团队来咨询软件更新带来的改变，如工作室账号主体不同意软件更新带来的改变，则可自动放弃使用RockGuys软件平台，RockGuys团队不对软件更新承担任何责任。
          </p>
          <h2>四、用户个人信息的保护</h2>
          <p>4.1保护用户个人信息是RockGuys团队的一项基本原则，RockGuys将会才去合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可RockGuys不会向第三方公开、透露用户个人信息。</p>
          <p>4.2你在注册使用RockGuys服务过程中，需要提供一些必要的信息。例如：手机号、身份信息等。</p>
          <p>4.3一般情况下，你可随时浏览、修改自己提交的信息，但处于安全性和身份识别的考虑，你可能无法修改注册时提供的初始注册信息以及其它验证信息。</p>
          <p>4.4未经你的同意，RockGuys团队不会向第三方公司、组织、个人披露你的个人信息，但法律法规另有规定的除外。</p>
          <p>4.5RockGuys团队非常重视对未成年人个人信息的保护。如果你是未满18周岁的未成年人，在使用RockGuys的服务前，你应事先取得家长或者法定监护人的书面同意。</p>
          <h2>五、主权义务条款</h2>
          <p>5.1RockGuys软件平台支持APP、微信小程序、网页等载体服务，普通用户可以直接进入RockGuys软件平台使用RockGuys提供的服务，但需要用户在访问个别服务时绑定手机号和邮箱。</p>
          <p>5.2RockGuys帐号所有权归属青岛不大一样网络技术有限公司所有，普通用户和工作室账号注册后，获得RockGuys帐号的使用权，并且该使用权仅限本人使用，不得转让。</p>
          <h2>六、用户行为规范</h2>
          <p>6.1禁止用户发布违规内容、违法内容、违反国家法律法规禁止的内容：</p>
          <p>（1）违反写法规定的基本原则的；</p>
          <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>（3）损害国家荣誉和利益的；</p>
          <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
          <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
          <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
          <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
          <p>（10）以非法民间组织名义活动的；</p>
          <p>（11）含有法律、行政法规禁止的其他内容的。</p>
          <p>6.2禁止发布、传送、传播、存储侵害他人名誉权、肖像权、知识产权、商业秘密、设计他人隐私、个人信息资料等合法权利的内容；</p>
          <h2>七、知识产权声明</h2>
          <p>工作室账号主体需对RockGuys提供的一切资料信息和合作细节履行保密义务，未经RockGuys团队同意，不得向第三方泄露。</p>
          <h2>八、第三方软件或技术</h2>
          <p>本软件可能会使用第三方软件或者技术（包括本软件可能使用的开源代码和公共领域代码等），这种使用已经获得合法授权。</p>
          <h2>九、其他</h2>
          <p>9.1你使用本软件即视为你已阅读并同意遵守本协议的约束。RockGuys团队有权在必要时修改本协议条款。你可以在本软件的最新版本中查阅相关协议条款。本协议条款变更后，如果你继续试用本软件，即视为你接受修改后的协议。如果你不接受修改后的协议，你应当停止使用本软件。</p>
          <p>9.2本协议签订地为中华人民共和国山东省青岛市市北区。</p>
          <p>9.3本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
          <p>9.4若你和RockGuys团队之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，你同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
          <p>9.5本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
          <div class="footer">
            <p>青岛不大一样网络技术有限公司</p>
            <p>2021年1月15日</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>

<style scoped>
/* body::-webkit-scrollbar {
  display: inline-block !important;
  width: 5px;
  height: 5px;
  opacity: 0;
} */
.main {
  width: 100%;
  height: 100%;
}
.p-hrader {
  width: 100%;
  padding: 1rem 10% !important;
  box-sizing: border-box;
  text-align: right;
  border-bottom: 1px solid #dadada !important;
  cursor: pointer;
}
.con {
  max-width: 960px;
  height: 960px;
  margin: 0 auto;
  padding: 0px 1.0875rem 1.45rem;
}
.title {
  margin: 50px 0 !important;
  font-size: 30px;
  font-weight: bold;
}
.pri-context {
  max-width: 740px;
  margin: 1.5rem auto;
}
.pri-context > h1 {
  margin: 20px 0 !important;
  font-size: 22px !important;
  font-weight: bold !important;
}

.pri-context > h2 {
  margin: 40px 0 20px !important;
  font-size: 20px !important;
  /* font-weight: bold !important; */
}

.pri-context a {
  color: #3370ff !important;
}

.pri-context > p {
  margin: 5px 0;
  color: #000;
  line-height: 28px;
}
.pri-context > ul {
  padding-left: 1.5em !important;
}
.pri-context > ul li {
  font-size: 16px;
  line-height: 28px;
  list-style: disc;
  color: #000;
}
.footer {
  padding: 50px 0;
}
.footer > p {
  line-height: 2;
}
</style>
