<template>
  <section>
    <navs-top></navs-top>
    <div class="main">
      <div class="header-tip">
        <div>
          <h3>开设工作室</h3>
          <p>票务、电商、艺术家价值提升</p>
        </div>
        <img src="../../assets/images/tips.png" alt="" />
      </div>
      <h3 class="title">认领或创建工作室账号</h3>
      <!-- 信息提示板 -->
      <div class="message-tips">
        <i class="el-icon-warning" style="color: #faad14"></i>
        <div>
          <p>
            工作室是RockGuys为艺术家、演出主办方、厂牌、livehouse等提供的一站式saas系统，它包含了票务、电商、艺术家价值提升等诸多业务功能，快去了解开设你自己的工作室，发现其中的价值吧~
          </p>
        </div>
        <i class="el-icon-close" style="color: #999; cursor: pointer"></i>
      </div>
      <!-- 搜索 -->
      <div class="search-box">
        <el-input
          size="small"
          v-model="studio_name"
          placeholder="搜索并认领你的工作室账号"
          prefix-icon="el-icon-search"
          @keyup.enter.native="getClaim"
        ></el-input>
        <el-button size="small" @click="getClaim">搜索</el-button>
      </div>
      <div class="sinfo" v-if="searchList.length">
        <div
          v-for="(item, index) in searchList"
          :class="{ opdis: item.claim }"
          :key="index"
        >
          <img :src="item.photo" alt="" />
          <span>{{ item.name }}</span>
          <el-button
            size="mini"
            :disabled="Boolean(item.claim)"
            @click="initStudio(item)"
          >
            {{ item.claim ? "已被认领" : "认领并完善" }}
          </el-button>
        </div>
      </div>
      <div class="no-data" v-else-if="!searchList.length && no_data">
        <el-tag type="info" closable @close="no_data = false">
          哎呀，暂时没找到此工作室呢！快去创建吧！keep going!
        </el-tag>
      </div>
      <!-- 信息提示板 -->
      <div class="message-tips">
        <i class="el-icon-warning" style="color: #faad14"></i>
        <div>
          <p>
            我的工作室账号已被认领？联系下方的RockGuys运营经理，让他协助你找回，其他问题你也可以咨询他。
            恶意认领他人艺术家账号将受到最高封号的相应惩罚。
          </p>
        </div>
        <i class="el-icon-close" style="color: #999; cursor: pointer"></i>
      </div>
      <div class="contact">
        <div>
          <img src="../../assets/images/default.png" alt="" />
          <div>
            <p>联系RockGuys运营经理</p>
            <p style="color: #999;">获取服务人员联系方式</p>
          </div>
        </div>
        <p class="tips" v-if="no_data && !searchList.length">
          没检索到“{{ studio_name }}”？点击下方按钮直接创建新账号
        </p>
      </div>
      <el-button
        size="small"
        type="danger"
        class="btn-style"
        @click="openStudio"
      >
        开设新账号
      </el-button>
    </div>
  </section>
</template>

<script>
export default {
  name: "studio",
  data() {
    return {
      studio_name: "",
      searchList: [],
      no_data: false,
    };
  },
  mounted() {
    let sid = localStorage.getItem("sid") || "";
    if (sid) {
      this.$router.push("/studio/inspect");
    }
  },
  methods: {
    // 搜索认领
    getClaim() {
      if (!localStorage.getItem("token")) {
        this.$message.warning("请先登录！");
        return;
      }
      if (!this.studio_name.trim()) {
        this.$message.warning("请输入工作室账号！");
        return;
      }
      this.$showLoading("loading", ".search-box");
      this.$http({
        url: "/api/authentication/v1.0/getClaim",
        params: {
          name: this.studio_name,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.searchList = res.data.data;
          this.no_data = true;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.$hideLoading();
      });
    },
    // 认领工作室
    initStudio(info) {
      let item = {};
      item.id = info.id;
      item.type = info.type;
      item.name = info.name;
      item.photo = info.photo;
      this.$router.push({
        name: "openstudio",
        params: { item },
      });
    },
    // 跳转开设工作室
    openStudio() {
      if (!localStorage.getItem("token")) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$router.push("/studio/open");
    },
  },
};
</script>

<style lang="less" scoped>
section {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 108px);
  padding: 30px 0;
  margin-top: 48px;
  background-color: #f5f5f5;
}
.main {
  width: 810px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #e1e1e1;
  .header-tip {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 120px;
    background: linear-gradient(90deg, #fff6f6 0%, #fcf5f5 100%);
    h3 {
      margin-bottom: 10px;
      color: #3d2a2a;
      font-weight: bold;
    }
    p {
      color: #716464;
    }
    & > img {
      height: 120px;
    }
  }
  .title {
    margin-top: 40px;
    text-align: center;
    font-weight: bold;
  }
  .message-tips {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    width: 460px;
    padding: 15px 0;
    margin: 40px auto;
    border-radius: 8px;
    border: 1px solid #ffe58f;
    background-color: #fffbe6;
    & > div {
      width: 80%;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      & > p {
        margin-top: 2px;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 460px;
    margin: 0 auto;
    .el-input {
      width: 85%;
    }
  }
  .sinfo {
    width: 460px;
    margin: 10px auto;
    .opdis {
      opacity: 0.6;
    }
    & > div {
      display: flex;
      align-items: center;
      margin: 20px 0;
      & > img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
      }
      & > span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.55);
      }
      .el-button {
        margin-left: auto;
      }
    }
  }
  .no-data {
    width: 460px;
    margin: 0 auto;
    height: 100px;
    line-height: 120px;
    text-align: center;
  }
  .contact {
    width: 460px;
    margin: 0 auto;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 200px;
      height: 60px;
      margin: 25px 0 40px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      p {
        padding-bottom: 2px;
        font-size: 12px;
        color: #333333;
      }
      & > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
    .tips {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .btn-style {
    display: block;
    margin: 60px auto 0;
  }
}
</style>
