<template>
	<div v-if="is_update">
		<nav-top></nav-top>
		<section>
			<div class="main">
				<div class="left">
					<index-left :classId="navId"></index-left>
				</div>
				<div class="main-box">
					<div class="header-nav">
						<div
							:class="{ active: c_id == index }"
							v-for="(nav, index) in navList"
							:key="index"
							@click="saveChange(index)"
						>
							{{ nav }}
						</div>
						<div class="location" v-show="!c_id">
							<i class="el-icon-location-outline"></i>
							<el-dropdown @command="handleCommand" trigger="click">
								<el-button type="text" style="color: #333;">
									{{ address }}
									<i class="el-icon-arrow-down"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="addressNow">
										{{ addressNow }}
									</el-dropdown-item>
									<el-dropdown-item
										divided
										:command="addressNow"
										v-if="addressNow"
									>
									</el-dropdown-item>
									<el-dropdown-item
										v-for="(item, index) in cityList"
										:key="index"
										:command="item.city"
									>
										{{ item.city }}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<!-- 附近的演出 -->
					<div
						v-infinite-scroll="loadMore"
						infinite-scroll-disabled="busy"
						infinite-scroll-distance="30"
						style="overflow: auto;"
						@scroll="scrollEvent"
						ref="smain"
					>
						<div class="content" v-if="c_id == 0">
							<a-item :activityList="nearbyList"></a-item>
							<div class="no-data" v-if="!nearbyList.length">
								<i class="el-icon-receiving"></i>
								<p v-if="noMore">附近暂时没有演出哦！</p>
							</div>
							<p class="bottom-style" v-show="busy && !noMore">
								<i class="el-icon-loading"></i>loading...
							</p>
							<p class="bottom-style" v-show="noMore && nearbyList.length">
								<i class="el-icon-hot-water"></i>没有更多了
							</p>
						</div>
						<!-- 关注的演出 -->
						<div class="content" v-else>
							<a-item :activityList="followList"></a-item>
							<div class="no-data" v-if="!followList.length">
								<i class="el-icon-receiving"></i>
								<p>暂时没有关注的演出哦！</p>
							</div>
							<p class="bottom-style" v-show="busy && !noMore">
								<i class="el-icon-loading"></i>loading...
							</p>
							<p class="bottom-style" v-show="noMore && followList.length">
								<i class="el-icon-hot-water"></i>没有更多了
							</p>
						</div>
					</div>
				</div>
				<!-- 右侧 -->
				<div class="right">
					<hot-right show="1"></hot-right>
				</div>
			</div>
			<div class="backtop" v-if="show_top">
				<img src="../assets/images/icon/up.png" @click="gotoTop" />
			</div>
		</section>
	</div>
	<div v-else>
		<div class="update-box">
			<div class="in-update">
				<img src="../assets/images/upStop.png" alt="" />
				<p class="font14 mt10 lightGray">ROCKGUYS系统服务已经升级，PC站目前暂不可访问。</p>
				<p class="font14 bolden mt30">温馨提示：</p>
				<p class="mt10">一、你可以通过访问以下方式进行购票：</p>
				<div class="font14">
					<p class="mt10">1·ROCKGUYS APP（<el-link type="primary" @click="showDownApp">前往下载</el-link>）</p>
					<p class="mt10">2·ROCKGUYS 微信小程序</p>
					<p class="mt10">3·移动端网站（<el-link type="primary" href="https://m.rockguys.cn/#/">点击前往</el-link>）</p>
				</div>
				<p class="mt10 font14">
					二、你可以通过下载APP进行票务的上架/管理/验票/结算等功能，前往下载。
				</p>
			</div>
		</div>
    <!-- 二维码 -->
		<div class="mask-box" v-show="is_qrcode">
			<div class="qrcode-box">
				<i class="el-icon-close" @click="showDownApp"></i>
				<img class="qrcode" src="../assets/images/appcode.png" />
        <p>扫码下载RockGuys App</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			is_update: false,
			navId: 0,
			c_id: 0, // 附近 | 关注
			navList: ["附近", "关注"],

			address: "全国",
			addressNow: "",

			visible: false,

			// amapManager,

			page: 1,
			busy: true,
			noMore: false,
			nearbyList: [], // 附近的活动
			followList: [], // 关注的活动

			cityList: [],
			show_top: 0,
      is_qrcode: false,
			user_id: "",
		};
	},
	mounted() {
		// 获取当前选择城市
		this.address = sessionStorage.addressSel || "全国";
		// console.log(this.historyList);
		// 获取本地储存定位
		if (sessionStorage.addressComponent) {
			let city = JSON.parse(sessionStorage.addressComponent).city;
			if (city.indexOf("市") != -1 || city.indexOf("州") != -1) {
				city = city.substring(0, city.length - 1);
			}
			this.addressNow = city;
		} else {
			setTimeout(() => {
				this.getLocation();
			}, 3000);
		}

		// 获取用户信息
		let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
		if (userinfo) {
			this.user_id = userinfo.userId;
		}

		// 获取热门城市
		this.getHotCity();
		// 获取活动信息
		this.getTicket(0);
	},
	methods: {
		// 获取位置 定位
		getLocation() {
			let me = this;
			AMap.plugin("AMap.Geolocation", function() {
				var geolocation = new AMap.Geolocation({
					enableHighAccuracy: false, //是否使用高精度定位，默认:true
					timeout: 5000, //超过5秒后停止定位，默认：无穷大
				});
				geolocation.getCurrentPosition((status, res) => {
					if (res.info == "SUCCESS" && status === "complete") {
						console.log(res);
						let city = res.addressComponent.city;
						if (city.indexOf("市") != -1 || city.indexOf("州") != -1) {
							city = city.substring(0, city.length - 1);
						}
						me.addressNow = city;
						sessionStorage.addressComponent = JSON.stringify(
							res.addressComponent
						);
					} else {
						me.addressNow = "全国";
					}
				});
			});
		},
		// 获取城市
		getHotCity() {
			this.$http.get("/api/ticket/v1.0/getCity").then((res) => {
				if (res.data.code == 200) {
					this.cityList = res.data.data.cityDTOS || []; // 活动城市
				} else {
					this.$message.warning({
						message: res.data.msg,
						duration: 2000,
					});
				}
			});
		},

		// 选择城市
		handleCommand(e) {
			this.address = e;
			sessionStorage.addressSel = e;
			this.getTicket(this.c_id);
			window.location.reload();
		},

		// 切换
		saveChange(id) {
			this.page = 1;
			this.c_id = id;
			this.busy = true;
			this.noMore = false;
			this.nearbyList = [];
			this.followList = [];
			this.getTicket(id);
		},

		// 获取首页列表
		getTicket(type) {
			// this.$showLoading("SHAKING · · ·", ".main-box");
			let address = "";
			if (type == 1) {
				address = "全国";
			} else address = this.address;
			this.$http({
				url: "/api/ticket/v1.0/getTicket",
				params: {
					CityName: address,
					cityStatus: address == "全国" ? "1" : "0",
					status: type,
					userId: this.user_id,
					pageSize: 16,
					pageNumber: this.page,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					if (type == 1) {
						if (this.followList.length) {
							this.followList = this.followList.concat(res.data.rows);
						} else {
							this.followList = res.data.rows;
						}
					} else {
						if (this.nearbyList.length) {
							this.nearbyList = this.nearbyList.concat(res.data.rows);
						} else {
							this.nearbyList = res.data.rows;
						}
					}
					if (res.data.rows.length) {
						this.busy = false;
					} else {
						this.busy = true;
						this.noMore = true;
					}
				} else {
					this.$message.warning({
						message: res.data.msg,
						duration: 2000,
					});
				}
				// this.$hideLoading();
			});
		},
		// 加载更多
		loadMore() {
			this.busy = true;
			this.page++;
			this.getTicket(this.c_id);
		},
		scrollEvent() {
			this.show_top =
				this.$refs.smain.scrollTop - window.innerHeight > 0 ? 1 : 0;
		},
    // 显示二维码
		showDownApp() {
			this.is_qrcode = !this.is_qrcode;
		},
		// 回到顶部
		gotoTop() {
			this.$nextTick(() => {
				this.$refs.smain.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			});
		},
	},
};
</script>
<style lang="less" scoped>
section {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	margin-top: 48px;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		height: 100%;
		min-height: calc(100vh - 48px);
		margin: 0 auto;
		box-sizing: border-box;
		.left {
			width: 210px;
		}
		.main-box {
			width: 855px;
			height: 100%;
			margin-right: 15px;
			box-sizing: border-box;
			background-color: #fff;
			border-right: 1px solid #e5e5e5;
			.header-nav {
				display: flex;
				align-items: center;
				padding: 15px 25px;
				& > div {
					width: 60px;
					height: 30px;
					margin-right: 30px;
					text-align: center;
					line-height: 30px;
					font-size: 14px;
					color: #7b7b7b;
					cursor: pointer;
				}
				.active {
					position: relative;
					font-size: 16px;
					font-weight: 600;
					color: #282828;
					&::after {
						position: absolute;
						bottom: -2px;
						left: 10px;
						display: inline-block;
						width: 65%;
						border-bottom: 2px solid #d30404;
						content: "";
					}
				}
				.location {
					flex: 1;
					text-align: right;
					& > i:first-child {
						margin-right: 5px;
						font-weight: bold;
						font-size: 16px;
					}
					& > i:last-child {
						margin-left: 5px;
					}
				}
			}
			.content {
				width: 100%;
				height: calc(100vh - 110px);
				padding: 30px 0;
				box-sizing: border-box;
			}
			.no-data {
				width: 100%;
				margin-top: 150px;
				text-align: center;
				color: #999;
				i {
					font-size: 50px;
					color: #999;
					opacity: 0.5;
				}
				& > p {
					margin-top: 10px;
					font-size: 15px;
				}
			}
		}
		.right {
			margin-top: 50px;
		}
	}
}
.el-dropdown-menu {
	height: 50vh;
	overflow-y: scroll;
}
.backtop {
	position: fixed;
	bottom: 50px;
	right: 70px;
	& > img {
		width: 40px;
		height: 40px;
		cursor: pointer;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}

.update-box {
	max-width: 960px;
	// height: 960px;
	padding: 0px 1.0875rem 1.45rem;
	margin: 0 auto;
	// text-align: center;
	box-sizing: border-box;
	.in-update {
    width: 50%;
    padding-top: 5vh;
    margin: 0 auto;
		& > img {
			width: 100%;
			height: auto;
			margin-top: 30px;
		}
	}
}

.mask-box {
	z-index: 1001;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	.qrcode-box {
		position: relative;
		width: 300px;
		height: 300px;
		padding: 30px 0;
		margin: 30vh auto 0;
		text-align: center;
		border-radius: 10px;
		box-sizing: border-box;
		background-color: #fff;
		& > p {
      margin-top: 10px;
			// margin: 60px 0 40px;
			font-size: 16px;
			font-weight: 600;
		}
		.qrcode {
			width: 200px;
			height: 200px;
			padding: 10px;
			margin: 0 auto;
			box-sizing: border-box;
		}
		.el-icon-close {
			position: absolute;
			top: -35px;
			right: -35px;
			padding: 5px;
			font-size: 20px;
			font-weight: 600;
			color: #fff;
			border-radius: 50%;
			border: 2px solid #fff;
			cursor: pointer;
		}
	}
}
</style>