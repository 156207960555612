<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div class="fixed-top">
            <i class="el-icon-back"></i>
            <span>{{ nickname }}</span>
            <el-button type="text">关注</el-button>
          </div>
          <div
            class="header-box"
            :style="{ background: 'url( ' + backgroundImage + ')' }"
          >
            <!-- <img class="bgimg" src="../assets/images/bbb.jpg" alt="" /> -->
            <div class="info-box">
              <img
                class="avatar"
                :src="avatar ? avatar : '../../assets/images/default.png'"
                alt="avatar"
              />
              <div class="box">
                <!-- <div class="header">
                  <p>{{ nickname }}</p>
                  <p>{{ remark ? remark : "这个家伙很懒，啥都没有" }}</p>
                </div> -->
                <div class="info">
                  <div>
                    <p>
                      <i
                        class="el-icon-location-outline"
                        style="margin-right: 5px;"
                      ></i>
                      {{ province }} · {{ city }}
                    </p>
                    <!-- <p>
                      <span>关注了 6512</span>
                      <span>关注你 6512</span>
                    </p> -->
                    <!-- <p class="flex-right">
                      <span><i class="el-icon-document"></i>详细介绍</span>
                      <i class="el-icon-arrow-right"></i>
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="content-box">
              <div class="nav-box">
                <div
                  :class="{ active: n_id == index }"
                  v-for="(item, index) in navList"
                  :key="index"
                  @click="saveIndex(index)"
                >
                  {{ item }}
                </div>
              </div>
              <div v-if="n_id == 0">演出</div>
              <div v-else>讨论</div>
            </div>
          </div>
        </div>
        <hot-right></hot-right>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "people",
  data() {
    return {
      navId: null, // 侧边导航
      typeId: 0, // 0 艺术家 || 1 场馆 || 2 厂牌
      n_id: 0, // 顶部
      navList: ["动态", "喜欢", "观演经历"],

      // 资料
      avatar: "",
      nickname: "",
      backgroundImage: "",
      remark: "",

      city: "青岛",
      province: "山东",

      user_id: "",

      other_id: 0,

      cropper: {},
    };
  },
  mounted() {
    // 获取用户信息
    let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
    if (userinfo) {
      this.user_id = userinfo.userId;
    }

    let id = this.$route.query.uid;
    // 获取资料
    this.getInformation(id);
  },
  methods: {
    saveIndex(id) {
      this.n_id = id;
    },
    // 获取资料
    getInformation(id) {
      this.$http({
        url: "/api/information/v1.0/getInformation",
        params: {
          userId: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.avatar = data.photo;
          this.nickname = data.nickName;
          this.backgroundImage = data.backgroundImage;

          this.city = data.city;
          this.remark = data.remark;
          this.province = data.province;
        } else {
          console.log("资料获取", res.data.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 855px;
      margin-right: 15px;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
      .fixed-top {
        z-index: 999;
        position: fixed;
        display: flex;
        align-items: center;
        width: 855px;
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: #fff;
        border-right: 1px solid #e5e5e5;
        .el-icon-back {
          color: #ef4033;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
        }
        & > span {
          margin-left: 20px;
          font-size: 16px;
          font-weight: bold;
        }
        .el-button {
          padding: 5px 15px;
          margin-left: auto;
          color: #ef4033;
          border-radius: 20px;
          border: 1px solid #ef4033;
        }
      }
      .header-box {
        position: relative;
        width: 100%;
        height: 270px;
        // padding-top: 195px;
        margin-top: 50px;
        box-sizing: border-box;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        background-color: #d5d5d5;
        .bgimg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .info-box {
          position: relative;
          display: flex;
          width: 100%;
          box-sizing: border-box;
          .avatar {
            position: absolute;
            width: 125px;
            height: 125px;
            margin-left: 40px;
            border-radius: 50%;
            border: 5px solid #fff;
          }
          .box {
            width: 100%;
            box-sizing: border-box;
            .header {
              width: 100%;
              padding: 20px 20px 10px;
              box-sizing: border-box;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.25) 100%
              );
              & > p {
                margin-left: 180px;
                color: #fff;

                &:first-child {
                  font-size: 18px;
                  font-weight: 600;
                }
                &:last-child {
                  margin-top: 10px;
                  font-size: 16px;
                }
              }
            }
            .info {
              padding: 15px 20px;
              & > div {
                margin-left: 180px;
                & > p {
                  margin-bottom: 15px;
                  font-size: 14px;
                  color: #666;

                  span {
                    margin-right: 30px;
                  }
                }
                .flex-right {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 500px;
                }
              }
            }
          }
        }
        .content-box {
          width: 100%;
          text-align: center;
          .nav-box {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 15px 120px;
            margin-top: 60px;
            box-sizing: border-box;
            border-bottom: 2px solid #f1f1f1;
            & > div {
              font-size: 18px;
              font-weight: 500;
              color: #818181;
              cursor: pointer;
            }
            .active {
              position: relative;
              color: #000;
              &::after {
                content: "";
                position: absolute;
                bottom: -17px;
                left: -50%;
                width: calc(100% + 40px);
                border-bottom: 2px solid #ef4033;
              }
            }
          }
        }
      }
    }
  }
}
</style>
