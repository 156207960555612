<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: 'studio/capital' }">
              资金管理
            </el-breadcrumb-item>
            <el-breadcrumb-item>添加银行卡</el-breadcrumb-item>
          </el-breadcrumb>
          <h3>添加银行卡</h3>
          <h4>填写银行卡信息</h4>
          <div class="item">
            <div>
              <p>账户类型</p>
              <el-select
                v-model="account_val"
                size="small"
                placeholder="请选择"
                @change="selectType"
              >
                <el-option
                  v-for="item in accountType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <p>银行卡号</p>
              <el-input
                v-model="bank_number"
                size="small"
                placeholder="请输入"
                @input="getCardInfo"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div>
              <p>开户银行</p>
              <el-input
                size="small"
                v-model="bank_type"
                placeholder="****银行"
              ></el-input>
            </div>
            <div>
              <p>开户网点</p>
              <el-input
                v-model="outlets"
                size="small"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="item" v-if="type == 2">
            <div>
              <p>开户名称</p>
              <el-input
                v-model="company_name"
                size="small"
                placeholder="请输入公司名称"
              ></el-input>
            </div>
            <div>
              <p>税号</p>
              <el-input
                size="small"
                v-model="tax_number"
                placeholder="税号或社会统一代码"
              ></el-input>
            </div>
          </div>
          <h4 v-if="type == 1">填写个人信息</h4>
          <div class="item" v-if="type == 1">
            <div>
              <p>持卡人姓名</p>
              <el-input
                v-model="name"
                size="small"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <p>身份证号码</p>
              <el-input
                v-model="IDcard"
                size="small"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="item" v-if="type == 1">
            <div>
              <p>手机号码</p>
              <el-input
                v-model="mobile"
                size="small"
                oninput="if(value.length>11)value=value.slice(0,11)"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>

          <el-button
            type="danger"
            class="btn-style"
            size="small"
            @click="addBank"
          >
            提交
          </el-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "bankcard",
  data() {
    return {
      navId: 3,
      type: 1, // 1 个人 || 2 公司
      accountType: [
        {
          label: "个人账户",
          value: 1,
        },
        {
          label: "对公账户",
          value: 2,
        },
      ],
      account_val: null,
      bank_number: null,
      bank_type: "",
      outlets: "",
      code: "",

      company_name: "",
      tax_number: "",

      name: "",
      IDcard: "",
      mobile: "",
    };
  },
  methods: {
    // 账户类型
    selectType() {
      this.type = this.account_val;
      this.bankAccountName = "";
      this.bankAddress = "";
      this.bankName = "";
      this.detyCode = "";
      this.bankNumber = "";
      this.idNumber = "";
      this.phone = "";
    },
    // 根据银行卡号获取银行卡名称信息
    getCardInfo() {
      if (this.bank_number.length > 15) {
        this.$http({
          url: "/api/bank/getCardInfo",
          params: {
            cardNumber: this.bank_number,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.bank_type = res.data.data.bankName || "";
            this.code = res.data.data.identificationCode || "";
          }
        });
      }
    },
    // 添加银行卡
    addBank() {
      if (!this.account_val) {
        this.$message.warning("请选择账户类型");
        return;
      }
      if (!/^[1-9]\d{9,29}$/.test(this.bank_number)) {
        this.$message.warning("请填写正确的银行卡号");
        return;
      }
      if (!/^[1-9]\d{9,29}$/.test(this.bank_number)) {
        this.$message.warning("请填写正确的银行卡号");
        return;
      }
      if (!this.outlets.trim()) {
        this.$message.warning("请填写开户网点信息");
        return;
      }
      if (this.account_val == 2) {
        if (!this.company_name.trim()) {
          this.$message.warning("请填写开户名称");
          return;
        }
        if (
          !/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/.test(
            this.tax_number
          )
        ) {
          this.$message.warning("请填写正确的税号");
          return;
        }
      }
      if (this.account_val == 1) {
        if (!this.name.trim()) {
          this.$message.warning("请填写持卡人姓名");
          return;
        }
        if (
          !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.IDcard
          )
        ) {
          this.$message.warning("请输入正确的证件号！");
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请填写正确手机号！");
          return;
        }
      }
      this.$http({
        url: "/api/bank/addNewBank",
        method: "POST",
        data: {
          accountType: this.account_val,
          bankAccountName: this.account_val == 1 ? this.name : this.company_name,
          bankAddress: this.outlets,
          bankName: this.bank_type,
          identificationCode: this.code,

          detyCode: this.tax_number,

          bankNumber: this.bank_number,
          idNumber: this.IDcard,
          phone: this.mobile,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("添加成功！");
          this.$router.back(-1);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      padding: 15px 40px;
      margin: 0 15px;
      box-sizing: border-box;
      background-color: #fff;
      h3 {
        margin-top: 40px;
        font-weight: 600;
        color: #333;
      }
      h4 {
        margin: 40px 0 20px;
        font-weight: 600;
        color: #333;
      }
      .item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 20px 0;
        & > div {
          width: 50%;
          p {
            margin-bottom: 10px;
            font-size: 14px;
          }
          .el-input {
            width: 80%;
          }
          .el-select {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>
