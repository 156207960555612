<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <h4 class="header">
            <i class="el-icon-back btn-back" @click="$router.back(-1)"></i>全部通知
          </h4>
          <div
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="30"
            class="infinite-scroll"
          >
            <div
              class="notice-list"
              v-for="(item, index) in noticeList"
              :key="index"
            >
              <el-badge is-dot :hidden="Boolean(item.isRead)">
                <i
                  class="icon-ui"
                  v-if="item.type"
                  :class="[item.type == 1 ? 'icon-card' : 'icon-notice']"
                ></i>
                <img
                  v-else
                  :src="item.image"
                  alt=""
                  @click="gotoInfo(item.id)"
                />
              </el-badge>
              <div>
                <p class="title">
                  <span @click="gotoInfo(item.id)">{{ item.title }}</span>
                  <span>{{ item.createTime }}</span>
                </p>
                <p class="info text-one">{{ item.content }}</p>
              </div>
            </div>
            <div class="no-data" v-if="!noticeList.length">
              暂无通知
            </div>
            <p class="bottom-style" v-show="busy && !noMore">
              <i class="el-icon-loading"></i>loading...
            </p>
            <p class="bottom-style" v-show="noMore && noticeList.length">
              <i class="el-icon-hot-water"></i>没有更多了
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "notice",
  data() {
    return {
      navId: null,
      page: 1,
      busy: true,
      noMore: false,
      noticeList: [],
    };
  },
  mounted() {
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.userId;
    }
    this.getNotice();
  },
  methods: {
    // 加载更多
    loadMore() {
      this.busy = true;
      this.page++;
      this.getNotice();
    },
    // 获取通知
    getNotice() {
      this.$http({
        url: "/api/wallet/getStudioMessage",
        params: {
          pageNo: this.page,
          pageSize: 10,
          userId: this.user_id,
          studioId: localStorage.getItem("sid"),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          if (this.noticeList.length) {
            this.noticeList = this.noticeList.concat(res.data.data.messages);
          } else {
            this.noticeList = res.data.data.messages;
          }
          if (res.data.data.messages.length) {
            this.busy = false;
          } else {
            this.busy = true;
            this.noMore = true;
          }
        } else {
          console.log(res.data);
        }
      });
    },
    // 跳转详情
    gotoInfo(id) {
      this.$router.push({
        path: "/studio/notice/info",
        query: { mid: id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1190px;
      margin: 0 15px;
      padding: 0 25px;
      box-sizing: border-box;
      background-color: #ffffff;
      .header {
        padding: 25px 0;
        font-weight: bold;
        color: #333;
        border-bottom: 1px solid #dadada;
      }
      .notice-list {
        display: flex;
        align-items: center;
        padding: 25px 0;
        & > img,.icon-ui {
          position: relative;
          width: 50px !important;
          height: 50px !important;
          margin-right: 20px;
          border-radius: 50%;
          cursor: pointer;
        }
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 50px;
        }
        .title {
          & > span:first-child {
            margin-right: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #000;
            cursor: pointer;
          }
          & > span:last-child {
            font-size: 14px;
            color: #999;
          }
        }
        .info {
          width: 100%;
          font-size: 14px;
          color: #666;
        }
      }
      .no-data {
        width: 100%;
        height: 200px;
        text-align: center;
        line-height: 200px;
        font-size: 16px;
        color: #333;
      }
    }
  }
}
/deep/.el-badge__content.is-fixed {
  top: 5px !important;
  right: 30px !important;
}
.infinite-scroll {
  max-height: calc(100vh - 115px);
  overflow-y: scroll;
}
</style>
