import Vue from "vue";
import axios from "axios";
import router from "./router";
import ElementUI from "element-ui";


// import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

// import qs from "qs";
import "element-ui/lib/theme-chalk/index.css";
import "../src/assets/font/iconfont.css"; // 图标
import "@/assets/style/resrt.css"; // 清除默认样式
// 引入导航栏组件
import Navtop from "./components/navTop";
import Navstop from "./components/navsTop";
// 引入主页侧边导航
import Indexleft from "./components/indexLeft";
// 引入侧边栏组件
import Navleft from "./components/navLeft";
// 右侧的工具栏
import HotRight from "./components/hotRight.vue";
// 活动列表组件
import activityItem from "./components/activityItem.vue";
// 高德地图
import VueAMap from "vue-amap";
// 复制
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";

Vue.use(VueAMap);
Vue.use(VueClipboard);
Vue.use(ElementUI, axios);

VueAMap.initAMapApiLoader({
	key: 'af9840998489b1ee207fd65513901cb6',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder'],
	// 默认高德 sdk 版本为 1.4.4
	v: '1.4.15',
	uiVersion: '1.0.11'
});


//axios请求配置

var root = process.env.VUE_APP_BASE_API;
// console.log(root);

//请求拦截器
axios.interceptors.request.use(config => {
	//--默认请求参数--
	//--请求之前重新拼装url--
	config.url = root + config.url;
	// console.log(config);
	// 请求头添加token
	let token = localStorage.getItem('token');
	if (token) {
		config.headers.Authorization = token;
	}
	// config.data = qs.stringify(config.data);
	return config;
});


// 监听axios
axios.interceptors.response.use(data => {
	let code = data.data.code;
	if (code == 550) {
		localStorage.removeItem("sid");
		localStorage.removeItem("aid");
		localStorage.removeItem("token");
		localStorage.removeItem("stype");
		localStorage.removeItem("sinfo");
		localStorage.removeItem("user_id");
		localStorage.removeItem("userinfo");
		localStorage.removeItem("only_play");
		// window.location.reload();
		Vue.prototype.$notify.error({
			title: "提示",
			message: "登录信息异常，请重新登录！",
		})
		setTimeout(() => {
			window.location.href = "https://pc.rockguys.cn";
		}, 1000)
	}
	return data;
})

// 登录检测
Vue.prototype.$auth = {
	auth: false,
	changeStudio: false,
};
// loading
let loading;
Vue.prototype.$showLoading = function (text, target) {
		loading = this.$loading({
			lock: true,
			text: text,
			target: document.querySelector(target)
		})
		// 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
		setTimeout(function () {
			loading.close();
		}, 10000)
	},
	Vue.prototype.$hideLoading = function () {
		loading.close();
	},
	// 格式时间
	Vue.prototype.$formatDate = function (date, type) {
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		m = m < 10 ? "0" + m : m;
		var d = date.getDate();
		d = d < 10 ? "0" + d : d;
		var h = date.getHours();
		h = h < 10 ? "0" + h : h;
		var minute = date.getMinutes();
		minute = minute < 10 ? "0" + minute : minute;
		var second = date.getSeconds();
		second = second < 10 ? "0" + second : second;
		if (type == "ym") {
			return y + "-" + m;
		} else if (type == "date") {
			return y + "-" + m + "-" + d;
		} else if (type == "dateTime") {
			return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
		} else {
			return new Date(date).getTime();
		}
	},
	//1.在main.js 页面里添加自定义指令
	// 动态修改标题
	Vue.directive("title", {
		//单个修改标题
		inserted: function (el, binding) {
			const {
				value
			} = binding;
			if (el.dataset.title) {
				// 方式1，可以给标签的data-title的属性赋值
				document.title = el.dataset.title;
			} else if (value && value.title) {
				// 方式2，指令传参
				document.title = value.title;
			} else {
				document.title = "RockGuys";
			}
		},
		update(el, binding) {
			// console.log(vnode, oldVnode);
			const {
				value
			} = binding;
			if (el.dataset.title) {
				document.title = el.dataset.title;
			} else if (value && value.title) {
				document.title = value.title;
			}
		}
	});
// meta title
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

Vue.prototype.$http = axios;
Vue.prototype.$api = "";
Vue.prototype.$mcallurl = "https://m.rockguys.cn/#/";
// Vue.prototype.$mcallurl = "https://testm.rockguys.cn/#/";
Vue.prototype.$callurl = "https://pc.rockguys.cn";
// Vue.prototype.$callurl = "http://testf.rockguys.cn:88";
Vue.component("nav-top", Navtop);
Vue.component("navs-top", Navstop);
Vue.component("nav-left", Navleft);
Vue.component("index-left", Indexleft);
Vue.component("hot-right", HotRight);
Vue.component("a-item", activityItem);

Vue.config.productionTip = 0;
new Vue({
	router,
	render: h => h(App),
	mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");