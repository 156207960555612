<template>
	<div>
		<navs-top></navs-top>
		<section>
			<div class="main">
				<div class="left">
					<nav-left :classId="navId"></nav-left>
				</div>
				<div class="main-content">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '../profile' }">
							资料设置
						</el-breadcrumb-item>
						<el-breadcrumb-item>编辑资料</el-breadcrumb-item>
					</el-breadcrumb>
					<p class="title title-top">
						{{
							studioId == 1 ? "艺术家" : studioId == 2 ? "厂牌" : "场馆"
						}}工作室资料
					</p>
					<!-- 头像上传 -->
					<div class="edit-box">
						<div class="avatar">
							<p>头像</p>
							<div class="box">
								<img
									class="avatar-img"
									:src="[
										img_url
											? img_url
											: 'https://i.loli.net/2020/11/27/mTf6NF9p2ChEMxV.png',
									]"
									alt="头像"
								/>
								<el-upload
									action="/api/public/v1.0/upload"
									name="multipartFile"
									ref="upload"
									:limit="1"
									:http-request="uploadSuccess"
									:before-upload="uploadPreview"
									:show-file-list="false"
								>
									<div class="btn-box">
										<div class="btn-upload">
											<i class="el-icon-upload2"></i>
											上传头像
										</div>
										<div slot="tip" class="tip-class">
											支持图片类型：png, jpg, jpeg
										</div>
									</div>
								</el-upload>
								<up-cropper
									ref="cropper"
									:cropper="cropper"
									@getAvatar="getChildInfo"
								></up-cropper>
							</div>
						</div>
					</div>
					<!-- 资料选择 -->
					<div class="studio-box">
						<!-- 1 -->
						<div class="info-item">
							<div v-if="studioId == 1">
								<p>艺术家名称</p>
								<el-input
									v-model="artist_name"
									size="small"
									placeholder="请输入"
								></el-input>
							</div>
							<div v-if="studioId == 2">
								<p>厂牌名称</p>
								<el-input
									v-model="brand_name"
									size="small"
									placeholder="请输入"
								></el-input>
							</div>
							<div style="width: 100%;" v-if="studioId == 3">
								<p>场馆名称</p>
								<el-input
									v-model="arena_name"
									size="small"
									style="width: 95%;"
									placeholder="请输入"
								></el-input>
							</div>
							<div v-if="studioId != 3">
								<p>地区</p>
								<el-cascader
									placeholder="请选择"
									size="small"
									v-model="address"
									:options="addressList"
									:props="areaProps"
									clearable
									ref="myAddress1"
									@change="selectAddress"
								></el-cascader>
							</div>
						</div>
						<!-- 2 -->
						<div class="info-item">
							<div v-if="studioId == 1">
								<p>音乐风格</p>
								<el-cascader
									placeholder="可搜索"
									size="small"
									v-model="music_type"
									:options="musicType"
									:props="musicProps"
									clearable
									filterable
									@change="selectMusicType"
								></el-cascader>
							</div>
							<div>
								<p>成立于</p>
								<el-date-picker
									:picker-options="pickerOptions"
									v-model="createTime"
									value-format="yyyy-MM-dd"
									size="small"
									type="date"
								>
								</el-date-picker>
							</div>
							<div v-if="studioId != 1">
								<p>一句话认识你</p>
								<el-input
									v-model="introduction"
									size="small"
									placeholder="我来自大海的故乡"
									:class="{ 'input-ban': studioId == 1 }"
								></el-input>
							</div>
						</div>
						<div class="info-item" v-if="studioId == 1">
							<div>
								<p>一句话认识你</p>
								<el-input
									v-model="introduction"
									size="small"
									placeholder="我来自大海的故乡"
									:class="{ 'input-ban': studioId == 1 }"
								></el-input>
							</div>
						</div>
					</div>
					<!-- 场馆地址 -->
					<div class="venue-box" v-if="studioId == 3">
						<p class="title title-mid">场馆地址</p>
						<div class="info-item">
							<span class="font14 black mr20">
								{{ addressBox.province }}
								{{ addressBox.city }}
								{{ addressBox.district }}
								{{ addressBox.address }}
							</span>
							<el-link type="primary" icon="el-icon-edit" @click="is_map = true"
								>选择地址</el-link
							>
						</div>
					</div>
					<!-- 主体资质 -->
					<div class="main-quali" v-if="studioId != 1">
						<p class="title title-mid">
							主体资质认证信息
							<el-button
								type="text"
								@click="is_main = false"
								style="float: right; padding-right: 70px;"
							>
								重新认证
							</el-button>
						</p>
						<div class="info-item">
							<div :class="{ w50: main_type == 2 }">
								<p>主体类型</p>
								<el-select
									v-model="main_type"
									size="small"
									placeholder="请选择"
									:disabled="is_main"
									@change="selectEnd"
								>
									<el-option
										v-for="item in mainType"
										:key="item.dictValue"
										:label="item.dictLabel"
										:value="item.dictValue"
									>
									</el-option>
								</el-select>
							</div>
							<div v-if="main_type != 2">
								<p>主体名称</p>
								<el-input
									v-model="main_name"
									size="small"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
						</div>
						<div class="info-item" v-if="main_type != 2">
							<div>
								<p>证件号码</p>
								<el-input
									v-model="main_ID"
									size="small"
									style="width: 45%"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
						</div>
						<up-IDcard
							:card_type="3"
							:urlInfo="licenseimg"
							@getMainInfo="getMainInfo(arguments)"
							v-if="!is_main && main_type != 2"
						>
						</up-IDcard>
					</div>
					<!-- 超级管理员信息 -->
					<div class="super-box">
						<p class="title title-mid">
							认证信息
							<el-button
								type="text"
								@click="is_main = false"
								style="float: right; padding-right: 70px;"
								v-if="studioId == 1"
							>
								重新认证
							</el-button>
						</p>
						<div class="info-item">
							<div>
								<p>姓名</p>
								<el-input
									v-model="name"
									size="small"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
							<div>
								<p>证件类型</p>
								<el-select
									v-model="ID_type"
									size="small"
									placeholder="请选择"
									:disabled="is_main"
								>
									<el-option
										v-for="item in certificateList"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="info-item">
							<div>
								<p>证件号码</p>
								<el-input
									v-model="ID_number"
									size="small"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
							<div>
								<p>联系电话</p>
								<el-input
									v-model="mobile"
									size="small"
									oninput="if(value.length>11)value=value.slice(0,11)"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
						</div>
						<div class="info-item">
							<div>
								<p>电子邮箱</p>
								<el-input
									v-model="email"
									size="small"
									style="width: 45%"
									placeholder="请输入"
									:disabled="is_main"
								></el-input>
							</div>
						</div>
						<up-IDcard
							:card_type="1"
							:urlInfo="[ID_url_f, ID_url_r]"
							@getIDcardInfo="getIDcardInfo(arguments, IDInfo)"
							v-if="!is_main"
						>
						</up-IDcard>
					</div>
					<template v-if="is_main">
						<!-- 艺术家详细介绍 -->
						<div v-if="studioId == 1">
							<p class="title">详细介绍</p>
							<p style="margin-bottom:10px;">文字介绍</p>
							<div id="editor-container">
								<quillEditor
									id="div1"
									ref="myQuillEditor"
									:content="introduce"
									:options="editorOption"
									@change="onEditorChange($event)"
								></quillEditor>
							</div>
						</div>
						<!-- 厂牌详细介绍 -->
						<div class="band-more" v-if="studioId == 2">
							<h4>详细介绍</h4>
							<div id="editor-container">
								<quillEditor
									id="div1"
									ref="myQuillEditor"
									:content="introduce"
									:options="editorOption"
									@change="onEditorChange($event)"
								></quillEditor>
							</div>
							<div v-if="0">
								<h4>旗下艺术家</h4>
								<p>搜索并添加艺术家</p>
								<div class="artist-list">
									<div
										class="item"
										v-for="(item, index) in artistList"
										:key="index"
									>
										<img :src="item.photo" alt="" />
										<p class="text-one">{{ item.name }}</p>
										<i @click="delectArtis(index)" class="el-icon-close"></i>
									</div>
								</div>
								<el-popover
									placement="bottom"
									popper-class="search-popover"
									width="310"
									trigger="manual"
									:visible-arrow="false"
								>
									<el-input
										prefix-icon="el-icon-search"
										v-model="searchval"
										placeholder="搜索并添加艺术家账号"
										@keyup.native.enter="searchArtis"
										slot="reference"
										size="small"
									></el-input>
									<el-button size="small" @click="searchArtis" slot="reference"
										>检索</el-button
									>
								</el-popover>
							</div>
						</div>
						<!-- 场馆详细信息 -->
						<div class="area-more" v-if="studioId == 3">
							<h4 style="font-weight: 600; margin-top: 40px;">详细介绍</h4>
							<p style="margin: 25px 0 10px;">文字介绍</p>
							<div id="editor-container">
								<quillEditor
									id="div1"
									ref="myQuillEditor"
									:content="introduce"
									:options="editorOption"
									@change="onEditorChange($event)"
								></quillEditor>
							</div>
							<div>
								<h4>设备清单</h4>
								<div id="editor-container">
									<quillEditor
										id="div2"
										ref="myQuillEditor1"
										:content="detailed"
										:options="editorOption1"
										@paste.native.stop="imagePaste"
										@change="onEditorChange1($event)"
									></quillEditor>
								</div>
							</div>
							<div v-if="0">
								<h4>更多信息</h4>
								<div class="">
									<el-checkbox-group v-model="checkList" @change="selectMore">
										<el-checkbox
											v-for="(item, index) in aresMoreBox"
											:label="item.name"
											:key="index"
										>
											{{ item.name }}
										</el-checkbox>
									</el-checkbox-group>
									{{ checkList }}
								</div>
							</div>
						</div>
					</template>
					<el-button
						class="btn-submit"
						type="danger"
						size="small"
						@click="submitPass"
					>
						{{ is_main ? "保存信息" : "提交审核" }}
					</el-button>
				</div>
			</div>
		</section>
		<!-- 地图 -->
		<el-dialog class="map-box" width="60%" :visible.sync="is_map" center>
			<p class="area-view">地址：{{ addressInfo }}</p>
			<mapView @getAddress="getAddressInfo"></mapView>
			<div slot="footer">
				<el-button size="small" @click="is_map = false">取消</el-button>
				<el-button type="primary" size="small" @click="changeMask"
					>确定</el-button
				>
			</div>
		</el-dialog>
		<!-- 富文本上传图片隐藏 -->
		<el-upload
			class="disnone"
			action="/api/public/v1.0/upload"
			name="multipartFile"
			ref="rich"
			:headers="headers"
			:before-upload="uploadPreview"
			:on-success="richUpload"
			:limit="1"
			:show-file-list="false"
		>
		</el-upload>
	</div>
</template>

<script>
// 富文本
import { quillEditor } from "vue-quill-editor";
// 地图
import mapView from "../../../components/mapView";
import area_format from "../../../assets/area.json";
// 裁切
import uploadCropper from "../../../components/uploadCropper";
// 身份认证扫描
import uploadCard from "../../../components/uploadCard";
export default {
	name: "profileEdit",
	components: {
		mapView,
		"up-cropper": uploadCropper,
		"up-IDcard": uploadCard,
		quillEditor,
	},
	data() {
		return {
			navId: 1,
			user_id: "",
			studioId: null, // 1 艺术家 || 2 厂牌 || 3 场馆
			img_url: "",

			address: "", // 地点
			addressList: [],
			areaProps: {
				value: "code",
				label: "name",
				children: "areaList",
			},

			headers: {
				Authorization: localStorage.getItem("token"),
			},

			artist_name: "", // 艺术家名称
			createTime: "",
			// 设置日期限制为当天
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},

			music_type: [], // 音乐风格
			musicType: [], //

			musicProps: {
				value: "id",
				label: "name",
				children: "style",
			},

			introduction: "", // 简介
			name: "", // 姓名
			brand_name: "", // 厂牌名称
			arena_name: "", // 场馆名称
			arean_area: "", // 场馆地址
			address_info: "", // 场馆详细地址

			main_type: "", // 主体类型
			mainType: [], // 主体
			main_name: "", // 主体名称
			main_ID: "", // 证件号码
			licenseimg: "", // 营业执照

			// 身份
			ID_type: "", // 证件类型
			ID_number: "", // 证件号码
			certificateList: [
				{
					id: 1,
					label: "身份证",
				},
			],

			mobile: "", // 手机号
			email: "", // 电子邮箱

			isSuccess: 0, // 状态
			is_main: true, // 是否重新认证 资质信息

			// 地址搜索问题待解决  地址详情文字
			myArea: [], // 地区
			sessionArea: [], // 场馆

			textarea: "",
			// 艺术家文字介绍
			introduce: "",
			// 设备清单
			detailed: "",
			editorOption: {
				modules: {
					toolbar: ["bold", "italic", { header: [1, 2, 3, false] }],
				},
				placeholder: "填写详细介绍",
				theme: "snow",
			},
			editorOption1: {
				modules: {
					toolbar: {
						container: ["bold", "italic", "image", { header: [1, 2, 3, false] }],
						handlers: {
							'image': function(value) {
								console.log(value);
								if (value) {
									document.querySelector('.disnone .el-upload__input').click();
								} else {
									this.quill.format('image', false);
								}
							}
						}
					},
				},
				placeholder: "填写详细介绍",
				theme: "snow",
			},

			aresMoreBox: [
				{
					name: "全场禁烟",
					checked: false,
				},
				{
					name: "有吸烟室",
					checked: false,
				},
				{
					name: "可停车",
					checked: false,
				},
				{
					name: "酒水售卖",
					checked: false,
				},
				{
					name: "免费wifi",
					checked: false,
				},
				{
					name: "可存包",
					checked: false,
				},
				{
					name: "身障专区",
					checked: false,
				},
				{
					name: "儿童专区",
					checked: false,
				},
			],
			checkList: [],

			// 身份认证
			IDInfo: null,
			ID_info_f: "", // 信息
			ID_info_r: "",
			ID_url_f: "", // 身份证照地址
			ID_url_r: "",

			artistList: [], // 艺术家列表
			searchval: "",

			cropper: {
				temp_name: "",
				is_cropper: "",
				temp_img: "",
			},
			is_map: false,
			addressInfo: "",
			addressBox: {},

			studio_id: "",
		};
	},
	mounted() {
		let id = localStorage.getItem("sid");
		let type = localStorage.getItem("stype");
		this.studio_id = id;
		// 获取资料
		this.getInformation(id, type);
		if (type == 1) {
			this.getCpYsj(id);
		}

		// 获取本地地址数据
		this.addressList = area_format || [];
		// 获取userid
		if (localStorage.getItem("userinfo")) {
			this.user_id = JSON.parse(localStorage.getItem("userinfo")).userId;
		}
		// 音乐风格
		this.getMusicType();
		this.getStudioType("subject_type");
	},
	methods: {
		// 选择文件 进行判断
		uploadPreview(file) {
			const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
			const SIZE = file.size / 1024 / 1024 < 5;
			if (!TYPE) {
				this.$message.error({
					message: "请选择正确的图片！",
					center: true,
					duration: 2000,
				});
				return TYPE;
			}
			if (!SIZE) {
				this.$message.warning({
					message: "上传头像图片大小不能超过 5MB!",
					center: true,
					duration: 2000,
				});
				return SIZE;
			}
			return TYPE && SIZE;
		},
		// 富文本复制图片上传文件
		imagePaste(event) {
			let items;
			items = event.clipboardData && event.clipboardData.items;
			var file = null;
			if (items && items.length) {
				// 检索剪切板items
				for (var i = 0; i < items.length; i++) {
					if (items[i].type.indexOf("image") !== -1) {
						if (items[i].kind === "file" && items[i].type.match(/^image\//i)) {
							event.preventDefault();
							file = items[i].getAsFile();
						}
						break;
					}
				}
			}
			if (file) {
				this.$showLoading();
				var formData = new FormData();
				formData.append("multipartFile", file);
				this.uploadimg(formData);
			}
		},
		// 上传图片
		uploadimg(formData) {
			this.$http({
				url: "/api/public/v1.0/upload",
				method: "POST",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				data: formData,
			})
				.then((res) => {
					if (res.data.code == 200) {
						console.log(res);
						this.richUpload(res.data);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		// 上传成功
		uploadSuccess(data) {
			this.$showLoading("loading", ".edit-box");
			this.cropper.is_cropper = true;
			this.cropper.temp_name = data.file.name;
			this.cropper.temp_img = URL.createObjectURL(data.file);
			this.$refs.cropper.getInfo(this.cropper);
		},
		// 获取头像链接
		getChildInfo(val) {
			this.$refs.upload.clearFiles();
			if (val == "cancel") {
				console.log("cancel");
			} else {
				this.img_url = val;
			}
		},

		// 获取工作室信息
		getInformation(id, type) {
			this.$http({
				url: "/api/authentication/v1.0/getInformation",
				params: {
					authenticationId: id,
					type: type,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let data = res.data.data;
					this.studioId = parseInt(data.type + 1);
					if (this.studioId == 1) {
						this.artist_name = data.name;
						this.myArea[0] = data.province;
						this.myArea[1] = data.city;
						this.myArea[2] = data.area;
						let musicTp = data.musicStyleId.split(",").map((val) => {
							return +val;
						});
						this.music_type = musicTp;
					} else if (this.studioId == 2) {
						this.myArea[0] = data.province;
						this.myArea[1] = data.city;
						this.myArea[2] = data.area;
						this.brand_name = data.name;
					} else if (this.studioId == 3) {
						this.arena_name = data.name;
						let info = {
							address: data.address,
							areaid: data.areaId,
							city: data.city,
							district: data.area,
							province: data.province,
						};
						this.addressBox = info;
					}
					if (data.subjectType == "企业") {
						this.main_type = "0";
					} else if (data.subjectType == "个体工商户") {
						this.main_type = "1";
					} else {
						this.main_type = "2";
					}
					this.main_name = data.subjectName;
					this.main_ID = data.subjectCard;
					this.licenseimg = data.qualificationsImage;
					this.address = data.areaId;
					this.createTime = this.$formatDate(
						new Date(data.establishTime),
						"date"
					);

					this.ID_number = data.adminCard;
					this.email = data.adminEmail;
					this.mobile = data.adminMobile;
					this.name = data.adminName;
					this.ID_type = 1;
					if (data.cardImage) {
						this.ID_url_f = data.cardImage.split(",")[0];
						this.ID_url_r = data.cardImage.split(",")[1];
					}

					this.introduce = data.introduce;
					this.detailed = data.detailed;
					this.introduction = data.remark;
					this.isSuccess = data.isSuccess;

					this.img_url = data.photo;
				} else {
					this.$message.warning({
						message: res.data.msg,
						duration: 2000,
					});
				}
			});
		},
		// 获取艺术家
		getCpYsj(id) {
			this.$http({
				url: "/api/authentication/v1.0/getCpYsj",
				params: {
					brandId: id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.artistList = res.data.data;
				} else {
					console.log("获取艺术家", res.data);
				}
			});
		},
		// 获取  艺术家||厂牌  地址
		selectAddress() {
			let address = this.$refs.myAddress1.getCheckedNodes()[0].pathLabels;
			if (
				address[1] == "市辖区" ||
				address[1] == "省辖区" ||
				address[1] == "辖区"
			) {
				this.myArea[0] = address[0];
				this.myArea[1] = "";
				this.myArea[2] = address[2];
			} else {
				this.myArea = address;
			}
			console.log(this.myArea);
		},
		// 获取音乐风格
		getMusicType() {
			this.$http({
				url: "/api/authentication/v1.0/getMusicStyle",
			}).then((res) => {
				if (res.data.code == 200) {
					this.musicType = res.data.data;
				}
			});
		},
		// 选择音乐风格
		selectMusicType() {
			console.log(this.music_type);
		},
		// 获取工作室类别
		getStudioType(type) {
			this.$http({
				url: "/api/public/v1.0/getStudioType",
				params: {
					type: type,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					if (type == "studio") {
						this.studioType = res.data.data;
					} else {
						this.mainType = res.data.data;
					}
				}
			});
		},
		selectEnd(e) {
			if (e == 2) {
				this.main_name = "";
				this.main_ID = "";
			}
		},
		// 场地选择
		selectMore(e) {
			console.log(e);
		},
		// 场馆地址
		getAddressInfo(info) {
			this.addressInfo =
				info.province + info.city + info.district + info.address;
			this.addressBox = info;
		},
		// 地图弹框
		changeMask() {
			if (this.addressInfo == "") {
				this.$alert("请选择场馆地址！", "提示", {
					confirmButtonText: "确定",
				});
				return;
			}
			this.is_map = false;
		},

		// 详细资料
		onEditorChange(e) {
			let code = JSON.stringify(e.quill.getContents().ops);
			this.introduceFormat = code
				.replace(
					/{"insert":{"image":/g,
					'{"insert":"​","attributes":{"embed":{"type":"image","source":'
				)
				.replace(/"}}/g, '"}}}')
				.replace(/bold/g, "b")
				.replace(/italic/g, "i")
				.replace(/header/g, "heading");
			this.introduce = e.html.replace(/<a[^>]+>/g, "");
		},

		// 图文
		onEditorChange1(e) {
			console.log(e);
			let code = JSON.stringify(e.quill.getContents().ops);
			this.detailedFormat = code
				.replace(
					/{"insert":{"image":/g,
					'{"insert":"​","attributes":{"embed":{"type":"image","source":'
				)
				.replace(/"}}/g, '"}}}')
				.replace(/bold/g, "b")
				.replace(/italic/g, "i")
				.replace(/header/g, "heading");
			this.detailed = e.html;
		},

		richUpload(res, file) {
			this.$showLoading();
			if (res.code == 200) {
				this.$hideLoading();
				let index = this.$refs.myQuillEditor1.quill.getSelection().index;
				this.$refs.myQuillEditor1.quill.insertEmbed(index, "image", res.msg);
				this.$refs.rich.clearFiles();
			} else {
				this.$hideLoading();
				this.$message.warning("图片上传失败请重试");
				console.log(res, file);
			}
		},

		// 获取ocr信息
		getIDcardInfo(IDInfo) {
			if (IDInfo[2] == 1) {
				this.name = IDInfo[0].username;
				this.ID_number = IDInfo[0].idcard;
				this.ID_url_f = IDInfo[1];
			} else {
				this.ID_info_r = IDInfo[0];
				this.ID_url_r = IDInfo[1];
			}
		},
		// 营业执照信息
		getMainInfo(info) {
			this.main_name = info[0].name;
			this.main_ID = info[0].code;
			this.licenseimg = info[1];
		},
		// 搜索艺术家
		searchArtis() {
			if (!this.searchval.trim()) {
				this.$message.warning("请输入艺术家名称！");
				return;
			}
			this.getActivityMessage(this.searchval, 0);
		},
		// 获取  艺术家 || 场馆
		getActivityMessage(name, type) {
			this.$http({
				url: "/api/activity/v1.0/getActivityMessage",
				params: {
					name: name,
					type: type,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					if (!res.data.data.length) {
						this.no_search = true;
						this.$notify({
							title: "提示",
							message: "暂未搜索到相关信息！",
							type: "warning",
						});
					}
					if (type == 0) {
						this.searchList = res.data.data;
					} else {
						console.log(res);
					}
				} else {
					this.$notify({
						title: "提示",
						message: "暂未搜索到相关信息！",
						type: "warning",
					});
				}
			});
		},

		// 验证资料
		submitPass() {
			if (!this.img_url.length) {
				this.$message.error("请先上传头像！");
				return;
			}
			// 艺术家
			if (this.studioId == 1) {
				// 基本信息
				if (!this.artist_name.trim()) {
					this.$message.error("请输入艺术家名称！");
					return;
				}
				if (!this.address.length) {
					this.$message.error("请选择地区！");
					return;
				}
				if (!this.music_type) {
					this.$message.error("请选择音乐风格！");
					return;
				}

				// 厂牌信息
			} else if (this.studioId == 2) {
				if (!this.brand_name.trim()) {
					this.$message.error("请填写厂牌名称！");
					return;
				}
				if (!this.address.length) {
					this.$message.error("请选择地区！");
					return;
				}
			} else if (this.studioId == 3) {
				if (!this.arena_name.trim()) {
					this.$message.error("请填写场馆名称！");
					return;
				}
			}
			// 共有信息
			if (!this.createTime) {
				this.$message.error("请选择成立时间！");
				return;
			}
			if (!this.introduction) {
				this.$message.error("请填写简介！");
				return;
			}

			if (this.studioId == 3) {
				if (!this.addressBox.areaid) {
					this.$message.error("请选择场馆地址！");
					return;
				}
			}

			// 判断类型是否认证
			if (!this.is_main) {
				// 场馆地址
				if (this.studioId == 3) {
					if (!this.addressBox.pois) {
						this.$message.error("请选择场馆地址！");
						return;
					}
				}

				// 资质信息 厂牌 || 场馆
				if (this.studioId != 1) {
					if (!(parseInt(this.main_type) + 1)) {
						this.$message.error("请先选择主体类型！");
						return;
					}
					if (this.main_type != 2) {
						if (!this.main_name.trim()) {
							this.$message.error("请输入主体名称！");
							return;
						}
						if (!this.main_ID) {
							this.$message.error("请输入统一社会信用代码！");
							return;
						}
						if (!this.licenseimg) {
							this.$message.error("请上传营业执照！");
							return;
						}
					}
				}

				// 认证资料
				if (!this.name.trim()) {
					this.$message.error("请填写正确姓名！");
					return;
				}
				if (!this.ID_type) {
					this.$message.error("请选择证件类型！");
					return;
				}
				// 选择证件类型判断
				if (this.ID_type == 1) {
					if (
						!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
							this.ID_number
						)
					) {
						this.$message.error("请输入正确的证件号！");
						return;
					}
				}
				if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
					this.$message.error("请输入正确的手机号！");
					return;
				}
				if (
					!/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(
						this.email
					)
				) {
					this.$message.error("请填写正确的邮箱！");
					return;
				}
				if (!this.ID_url_f.length) {
					this.$message.error("请上传身份证正面！");
					return;
				}
				if (!this.ID_url_r.length) {
					this.$message.error("请上传身份证背面！");
					return;
				}
			}
			if (!this.introduce) {
				// 保存资料
				this.$message.warning("请填写详细介绍!");
				return;
			}

			if (this.studioId == 1) {
				// 认证
				if (!this.is_main) {
					let data = {
						type: this.studioId - 1,
						photo: this.img_url,
						name: this.artist_name,
						areaId:
							this.address.constructor === Array
								? this.address[2]
								: this.address,
						province: this.myArea[0],
						city: this.myArea[1],
						area: this.myArea[2],
						musicStyle: this.music_type[1]
							? this.music_type[0] + "," + this.music_type[1]
							: this.music_type[0],
						establishTime: this.createTime,
						remark: this.introduction,

						adminName: this.name,
						adminCard: this.ID_number,
						adminMobile: this.mobile,
						adminEmail: this.email,
						cardImage: this.ID_url_f + "," + this.ID_url_r,

						authType: 3,
						studioId: this.studio_id,
						userId: this.user_id,
					};
					this.pushData(data);
				} else {
					let data = {
						photo: this.img_url,
						name: this.artist_name,
						areaId:
							this.address.constructor === Array
								? this.address[2]
								: this.address,
						province: this.myArea[0],
						city: this.myArea[1],
						area: this.myArea[2],
						musicStyle: this.music_type[1]
							? this.music_type[0] + "," + this.music_type[1]
							: this.music_type[0],
						establishTime: this.createTime,
						remark: this.introduction,

						introduce: this.introduce,
						introduceFormat: this.introduceFormat,
						id: this.studio_id,
					};
					this.updateMeans(data);
				}
			} else if (this.studioId == 2) {
				if (!this.is_main) {
					let data = {
						type: this.studioId - 1,
						photo: this.img_url,
						name: this.brand_name,
						areaId:
							this.address.constructor === Array
								? this.address[2]
								: this.address,
						province: this.myArea[0],
						city: this.myArea[1],
						area: this.myArea[2],
						establishTime: this.createTime,
						remark: this.introduction,

						subjectType: this.main_type,
						subjectName: this.main_name,
						subjectCard: this.main_ID,
						qualificationsImage: this.licenseimg,

						adminName: this.name,
						adminCard: this.ID_number,
						adminMobile: this.mobile,
						adminEmail: this.email,
						cardImage: this.ID_url_f + "," + this.ID_url_r,

						authType: 3,
						studioId: this.studio_id,
						userId: this.user_id,
					};
					this.pushData(data);
				} else {
					let data = {
						photo: this.img_url,
						name: this.brand_name,
						areaId:
							this.address.constructor === Array
								? this.address[2]
								: this.address,
						province: this.myArea[0],
						city: this.myArea[1],
						area: this.myArea[2],
						establishTime: this.createTime,
						remark: this.introduction,

						introduce: this.introduce,
						introduceFormat: this.introduceFormat,
						id: this.studio_id,
					};
					this.updateMeans(data);
				}
			} else if (this.studioId == 3) {
				if (!this.is_main) {
					let data = {
						type: this.studioId - 1,
						photo: this.img_url,
						name: this.arena_name,

						areaId: this.addressBox.areaid
							? this.addressBox.areaid
							: this.address,
						province: this.addressBox.province,
						city: this.addressBox.city,
						area: this.addressBox.district,
						address: this.addressBox.address,
						latitude: this.addressBox.pois.lat,
						longitude: this.addressBox.pois.lng,

						establishTime: this.createTime,
						remark: this.introduction,

						subjectType: this.main_type,
						subjectName: this.main_name,
						subjectCard: this.main_ID,
						qualificationsImage: this.licenseimg,

						adminName: this.name,
						adminCard: this.ID_number,
						adminMobile: this.mobile,
						adminEmail: this.email,
						cardImage: this.ID_url_f + "," + this.ID_url_r,

						authType: 3,
						studioId: this.studio_id,
						userId: this.user_id,
					};
					this.pushData(data);
				} else {
					let data = {
						photo: this.img_url,
						name: this.arena_name,

						areaId: this.addressBox.areaid
							? this.addressBox.areaid
							: this.address,
						province: this.addressBox.province,
						city: this.addressBox.city,
						area: this.addressBox.district,
						address: this.addressBox.address,

						establishTime: this.createTime,
						remark: this.introduction,

						introduce: this.introduce,
						introduceFormat: this.introduceFormat,
						detailed: this.detailed,
						detailedFormat: this.detailedFormat,
						id: this.studio_id,
					};
					this.updateMeans(data);
				}
			}
		},
		// 修改资料
		updateMeans(data) {
			this.$http({
				url: "/api/authentication/v1.0/updateMeans",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					this.$message.success("保存成功！");
					let data = {};
					data.photo = this.img_url;
					if (this.studioId == 1) {
						data.name = this.artist_name;
					} else if (this.studioId == 2) {
						data.name = this.brand_name;
					} else {
						data.name = this.arena_name;
					}
					data = JSON.stringify(data);
					localStorage.setItem("sinfo", data);
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 提交审核
		pushData(data) {
			this.$http({
				url: "/api/studio/studioAuth",
				method: "POST",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					this.$message.success(res.data.msg);
					let data = {};
					data.photo = this.img_url;
					if (this.studioId == 1) {
						data.name = this.artist_name;
					} else if (this.studioId == 2) {
						data.name = this.brand_name;
					} else {
						data.name = this.arena_name;
					}
					data = JSON.stringify(data);
					localStorage.setItem("sinfo", data);
					setTimeout(() => {
						this.$router.push("/studio/verifying");
					}, 1000);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
section {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 48px);
	margin-top: 48px;
	background-color: #f5f5f5;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		margin: 0 auto;
		.left {
			width: 210px;
		}
		.main-content {
			width: 1200px;
			padding: 10px 25px;
			box-sizing: border-box;
			background-color: #fff;
			.title-top {
				margin: 40px 0 !important;
			}
			.edit-box {
				.avatar {
					& > p {
						padding: 10px 0;
						font-size: 14px;
						color: rgba(0, 0, 0, 0.85);
					}
					.box {
						display: flex;
						align-items: center;
						.avatar-img {
							width: 85px;
							height: 85px;
							margin-right: 20px;
							border-radius: 50%;
						}
						.btn-box {
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							height: 80px;
							.btn-upload {
								width: 110px;
								height: 30px;
								line-height: 30px;
								text-align: center;
								border-radius: 2px;
								color: rgba(0, 0, 0, 0.6);
								border: 1px solid rgba(0, 0, 0, 0.15);
							}
							.tip-class {
								font-size: 14px;
								color: rgba(0, 0, 0, 0.45);
							}
						}
					}
				}
			}
			// 标题
			.title {
				margin: 30px 0;
				font-size: 16px;
				font-weight: 600;
				color: #000;
			}
			.studio-box,
			.super-box {
				width: 100%;
			}
			// 厂牌
			.band-more {
				h4 {
					width: 100px;
					margin: 30px 0 20px;
					font-weight: 500;
				}
				.el-button {
					color: #d30404;
				}
				.artist-list {
					display: flex;
					flex-wrap: wrap;
					margin: 20px 0;
					.item {
						position: relative;
						margin: 0 30px 10px 0;
						& > img {
							width: 65px;
							height: 65px;
							border-radius: 50%;
						}
						& > p {
							width: 70px;
							padding-top: 10px;
							font-size: 12px;
							text-align: center;
							color: rgba(0, 0, 0, 0.55);
						}
						& > i {
							position: absolute;
							top: 0;
							right: 0;
							font-size: 13px;
							color: rgba(0, 0, 0, 0.65);
							border-radius: 50%;
							border: 1px solid #000;
							cursor: pointer;
						}
					}
				}
				/deep/.el-popover__reference-wrapper {
					.el-input {
						width: 370px;
						margin: 15px 15px 0 0;
						.el-input__inner {
							border: 1px solid #d9d9d9;
						}
					}
				}
			}
			// 场馆
			.area-more {
				.flex-on {
					display: flex;
					align-items: baseline;
					.el-button {
						color: #d30404;
					}
				}
				& > div {
					h4 {
						margin: 30px 20px 20px 0;
						font-weight: 600;
					}
				}
			}

			// 信息框模板
			.info-item {
				display: flex;
				width: 100%;
				margin-top: 20px;
				& > div {
					width: 100%;
					p {
						margin-bottom: 10px;
						font-size: 14px;
						color: rgba(0, 0, 0, 0.85);
					}
				}
				.el-select {
					width: 90%;
				}
				.el-input {
					width: 90%;
				}
				.el-cascader {
					width: 90%;
				}
				.input-ban {
					width: 45%;
				}
			}

			.btn-submit {
				margin: 30px 0;
				border-radius: 2px;
				background-color: #d30404;
			}

			::v-deep.el-checkbox__inner:hover {
				border-color: #d30404;
			}
			::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
				color: #d30404;
			}
			::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
			::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				border-color: #d30404;
				background-color: #d30404;
			}
			::v-deep.el-checkbox__input.is-focus .el-checkbox__inner {
				border-color: #d30404;
			}
		}
	}
}
#editor-container {
	all: initial;
	margin: 0 !important;
	padding: 0 !important;
	background-color: #fafafa !important;
	#div1,
	#div2 {
		margin-top: 20px;
		font-size: 14px !important;
		font-family: "微软雅黑" !important;
	}
	h1 {
		font-size: 2em;
		margin: 0.67em 0;
	}
	h2 {
		font-size: 1.5em;
		margin: 0.75em 0;
	}
	h3 {
		font-size: 1.17em;
		margin: 0.83em 0;
	}
}
.map-box {
	.area-view {
		margin: 10px 0 20px;
	}
	::v-deep.el-dialog__header {
		padding: 0;
	}
	.el-input {
		width: 40%;
		margin-right: 20px;
	}
}
</style>
