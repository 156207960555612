<template>
	<div>
		<nav-top></nav-top>
		<section>
			<div class="main">
				<div class="left">
					<index-left :classId="navId"></index-left>
				</div>
				<div class="content">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '../order' }">
							我的订单
						</el-breadcrumb-item>
						<el-breadcrumb-item>订单详情</el-breadcrumb-item>
					</el-breadcrumb>
					<h3>订单详情页</h3>
					<div class="order-info">
						<div class="header">
							商品清单
						</div>
						<div class="info-box">
							<div class="tit-list">
								<span style="width: 40%;">商品</span>
								<span class="td-style">单价</span>
								<span class="td-style">优惠</span>
								<span class="td-style">数量</span>
								<span class="td-style">小计</span>
							</div>
							<div class="order-data">
								<div class="title" style="width: 40%;">
									<img :src="orderData.image" alt="" />
									<div>
										<p class="text-two">{{ orderData.ticketFullName }}</p>
										<p>{{ orderData.orderType == 1 ? "电子票" : "纸质票" }}</p>
									</div>
								</div>
								<div class="td-style price">￥{{ orderData.price }}</div>
								<div class="td-style">￥{{ orderData.discountAmount }}</div>
								<div class="td-style">{{ orderData.number }}</div>
								<div class="td-style total">￥{{ orderData.totalAmount }}</div>
							</div>
							<div class="price-box">
								<p>
									<span>共计{{ orderData.number }}件商品，总价：</span>
									<span>￥{{ orderData.totalAmount }}</span>
								</p>
								<p>
									<span>运费：</span>
									<span>￥{{ orderData.mailAmount }}</span>
								</p>
								<p>
									<span>优惠：</span>
									<span>￥{{ orderData.discountAmount }}</span>
								</p>
								<p>
									<span>实付款：</span>
									<span>￥{{ orderData.payAmount }}</span>
								</p>
							</div>
						</div>
					</div>
					<div class="order-info">
						<div class="header">
							订单信息
						</div>
						<div class="info-box">
							<p style="float: right;">创建时间：{{ createTime }}</p>
							<p>订单号：{{ order_code }}</p>
							<p>支付时间：{{ payTime ? payTime : "未付款" }}</p>
							<p v-if="freeFlag">支付方式：{{ freeFlag ? "免费票" : "" }}</p>
							<p v-else>
								支付方式：在线支付（{{
									payType
										? payType == 1
											? "支付宝支付"
											: "微信支付"
										: "未付款"
								}}）
							</p>
							<p>
								收货信息：
								{{ address_info.name }}
								{{ address_info.mobile }}
								<span v-if="orderData.orderType != 1">
									{{ address_info.province }}
									{{ address_info.city }}
									{{ address_info.area }}
									{{ address_info.address }}
								</span>
							</p>
						</div>
					</div>
					<div class="order-info" v-if="0">
						<div class="header">
							物流信息
						</div>
						<div class="info-box">
							<div class="logistics">
								<div class="active">
									<p>您的订单在【青岛胶州分拣中心】分拣完成</p>
									<p>2020-11-07 15:00:00</p>
								</div>
								<div>
									<p>您的订单在【青岛胶州分拣中心】分拣完成</p>
									<p>2020-11-07 15:00:00</p>
								</div>
								<div>
									<p>您的订单在【青岛胶州分拣中心】分拣完成</p>
									<p>2020-11-07 15:00:00</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: "orderinfo",
	data() {
		return {
			navId: 4, // 侧边导航
			orderData: "",

			order_code: "",
			createTime: "",
			payTime: "",
			payType: "",
			freeFlag: "",
			address_info: {},

			user_id: "",
		};
	},
	mounted() {
		// 获取orderid
		let id = this.$route.query.id;
		// 获取用户信息
		let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
		if (userinfo) {
			this.user_id = userinfo.userId;
		}
		// 获取订单详情
		this.getOrderDetail(id);
	},
	methods: {
		// 获取订单详情
		getOrderDetail(id) {
			this.$http({
				url: "/api/order/getOrderDetail",
				method: "POST",
				params: {
					orderId: id,
					requestFrom: 4,
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let data = res.data.data;
					this.orderData = data;
					this.order_code = data.orderCode || "";
					this.createTime = data.createTime || "";
					this.payTime = data.payTime || "";
					this.payType = data.payType || "";
					this.freeFlag = data.freeFlag || "";
					this.address_info = data.defaultAddress || "";
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
section {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 48px);
	margin-top: 48px;
	background-color: #fff;
	.main {
		position: relative;
		display: flex;
		width: 1440px;
		margin: 0 auto;
		box-sizing: border-box;
		.left {
			width: 210px;
		}
		.content {
			width: 1200px;
			height: 100%;
			padding: 15px 25px;
			box-sizing: border-box;
			& > h3 {
				margin: 25px 0 15px;
				font-weight: bold;
			}
		}
		.order-info {
			width: 95%;
			margin-bottom: 25px;
			.header {
				width: 100%;
				height: 40px;
				padding-left: 25px;
				font-size: 14px;
				line-height: 40px;
				letter-spacing: 1px;
				color: #333;
				box-sizing: border-box;
				background-color: #fafafa;
				border-left: 2px solid #ef4033;
			}
			.info-box {
				padding: 25px;
				box-sizing: border-box;
				border: 1px solid #dcdee3;
			}
			.info-box > p {
				font-size: 14px;
				line-height: 2.4;
				color: #333;
			}
			.logistics {
				padding-left: 10px;
				border-left: 3px solid #f0f0f0;
				& > div {
					position: relative;
					margin-bottom: 20px;
					line-height: 25px;
					&::before {
						content: "";
						position: absolute;
						top: 18px;
						left: -17px;
						padding: 3px;
						border-radius: 50%;
						border: 2px solid #dadada;
					}
					& > p {
						font-size: 14px;
						color: #999;
					}
				}
				.active {
					&::before {
						border-color: #ef4033 !important;
					}
					& > p {
						color: #333;
					}
				}
			}
			.tit-list {
				display: flex;
				font-size: 14px;
				color: #333;
			}
			.td-style {
				width: 15%;
				text-align: center;
			}
			.order-data {
				display: flex;
				align-items: center;
				padding: 25px 0;
				font-size: 14px;
				color: #333;
				border-bottom: 1px dashed #e5e5e5;
				.title {
					display: flex;
					& > img {
						width: 75px;
						height: 100px;
						margin-right: 15px;
						border-radius: 5px;
					}
					& > div {
						display: flex;
						flex-direction: column;
						justify-content: space-evenly;
						font-size: 12px;
						color: #333;
						& > p {
							line-height: 18px;
						}
					}
				}
				.price {
					font-size: 16px;
					font-weight: bold;
				}
				.total {
					font-size: 16px;
					color: #d30404;
				}
			}
			.price-box {
				padding: 30px 40px 0 0;
				text-align: right;
				& > p {
					margin-bottom: 12px;
					& > span:first-child {
						font-size: 12px;
						color: #666;
					}
					& > span:last-child {
						display: inline-block;
						width: 100px;
						font-size: 16px;
						color: #d30404;
					}
				}
			}
		}
	}
}
</style>
