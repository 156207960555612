<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="main-content">
          <!-- 导航 -->
          <div class="header-nav">
            <div class="tit-nav">
              <div
                :class="{ active: index == c_id }"
                v-for="(item, index) in tit_nav"
                :key="index"
                @click="saveChange(index)"
              >
                {{ item }}
              </div>
            </div>
            <el-button
              class="btn-style"
              type="danger"
              icon="el-icon-plus"
              size="small"
              @click="initActivity"
              >创建演出活动
            </el-button>
          </div>
          <div class="box">
            <!-- 我创建的 -->
            <div class="table-box">
              <div class="box">
                <el-table
                  v-if="c_id == 0"
                  border
                  :data="tableData"
                  fit
                  :header-cell-style="{
                    color: '#333',
                    background: 'rgba(0, 0, 0, 0.04)',
                  }"
                  style="width: 90%"
                >
                  <el-table-column
                    prop="activityPoster"
                    label="海报"
                    align="center"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <img
                        :src="scope.row.activityPoster"
                        width="75px"
                        height="100px"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="activityName"
                    label="演出名称"
                    align="center"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="siteCount" label="场次" align="center">
                    <template slot-scope="scope">
                      <span v-if="scope.row.siteCount > 1">
                        系列演出({{ scope.row.siteCount }}场)
                      </span>
                      <span v-else-if="scope.row.siteCount == 1">
                        单场次
                      </span>
                      <span v-else>
                        暂无场次
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="pushCount"
                    label="状态"
                    align="center"
                    width="160"
                  >
                    <template slot-scope="scope">
                      <p v-if="scope.row.examineCount">
                        {{ scope.row.examineCount }}场待审核
                      </p>
                      <p v-if="scope.row.failedCount" style="color:#d30404;">
                        {{ scope.row.failedCount }}场未通过
                      </p>
                      <p v-if="scope.row.pushCount" style="color:#67c23a;">
                        {{ scope.row.pushCount }}场已发布
                      </p>
                      <p v-if="scope.row.endCount" style="color:#909399;">
                        {{ scope.row.endCount }}场已结束
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="activityId"
                    label="操作"
                    align="center"
                    width="140"
                  >
                    <template slot-scope="scope">
                      <span
                        @click="operationTo(scope.row.activityId)"
                        style="color: #285af8; cursor: pointer"
                        >查看详情
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table
                  v-else
                  border
                  :data="aboutData"
                  fit
                  :header-cell-style="{
                    color: '#333',
                    background: 'rgba(0, 0, 0, 0.04)',
                  }"
                  style="width: 90%"
                >
                  <el-table-column
                    prop="activityPoster"
                    label="海报"
                    align="center"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <img
                        :src="scope.row.activityPoster"
                        width="75px"
                        height="100px"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="activityName"
                    label="演出名称"
                    align="center"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="地点"
                    align="center"
                    width="280"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.province }}
                      {{ scope.row.city }}
                      {{ scope.row.area }}
                      {{ scope.row.address }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="startTime"
                    label="时间"
                    align="center"
                    width="260"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.startTime }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- 分页 -->
              <div class="pagination">
                <el-pagination
                  background
                  layout="
                  prev,
                  pager,
                  jumper,
                  next"
                  :total="total"
                  :page-size="page_count"
                  prev-text="前一页"
                  next-text="下一页"
                  @current-change="currentChange"
                  @prev-click="prevClick"
                  @next-click="nextClick"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "setting",
  data() {
    return {
      navId: 2,
      c_id: 0,
      tit_nav: ["我创建的", "与我有关"],
      page: 1,
      page_count: 15,
      total: null,
      tableData: [], // 我创建的
      aboutData: [], // 与我有关
    };
  },
  created() {
    // 获取工作室id
    this.studio_id = localStorage.getItem("sid");
    // 获取场次数据
    this.getSomeList(0);
  },
  methods: {
    // 导航切换
    saveChange(id) {
      this.page = 1;
      this.c_id = id;
      this.getSomeList(id);
    },
    // 获取场次数据
    getSomeList(type) {
      this.$showLoading("loading...");
      this.$http({
        url: "/api/newActivity/v1.0/getStudioActivity",
        params: {
          studioId: this.studio_id,
          type: type,
          pageNumber: this.page,
          pageSize: this.page_count
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.total;
          if (type == 1) {
            // 关于我的
            this.aboutData = res.data.rows;
          } else {
            // 我创建的
            this.tableData = res.data.rows;
          }
        } else {
          this.$message.warning({
            message: res.data.msg,
            duration: 2000,
          });
        }
        this.$hideLoading();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      });
    },
    // 上一页
    prevClick(e) {
      this.page = e;
      this.getSomeList(this.c_id);
    },
    // 下一页
    nextClick(e) {
      this.page = e;
      this.getSomeList(this.c_id);
    },
    // 页码
    currentChange(e) {
      // 上下重复调用
      if (this.page == e) {
        console.log("重复");
      } else {
        this.page = e;
        this.getSomeList(this.c_id);
      }
    },
    // 创建新活动
    initActivity() {
      localStorage.removeItem("aid");
      this.$router.push("activity/edit");
    },
    // 跳转操作
    operationTo(id) {
      localStorage.setItem("aid", id);
      this.$router.push("activity/edit");
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    min-height: calc(100vh - 48px);
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .left {
      width: 210px;
    }
    .main-content {
      width: 1190px;
      padding: 40px 25px;
      margin: 0 15px;
      box-sizing: border-box;
      background-color: #ffffff;
      .header-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: 32px;
      }
      .tit-nav {
        display: flex;
        & > div {
          width: 80px;
          height: 30px;
          margin-right: 20px;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #7b7b7b;
          cursor: pointer;
        }
        .active {
          position: relative;
          color: #282828;
          font-size: 16px;
          font-weight: 600;
          &::after {
            position: absolute;
            bottom: -2px;
            left: 15px;
            display: inline-block;
            width: 55%;
            border-bottom: 2px solid #d30404;
            content: "";
          }
        }
      }
      // 表格
      .table-box {
        margin-top: 20px;
        .box {
          min-height: 400px;
        }
      }
    }
  }
}

.pagination {
  margin-top: 80px;
  text-align: center;
}
</style>
