import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue";
import liveinfo from "../views/liveinfo.vue";
import profile from "../views/profile/index.vue";
import edit from "../views/profile/edit.vue";
import notice from "../views/notice.vue";
import order from "../views/order.vue";
import orderInfo from "../views/orderInfo.vue";
import more from "../views/more.vue";
import people from "../views/people.vue";
import search from "../views/search.vue";
import privacy from "../views/privacy.vue";
import terms from "../views/terms.vue";

import studioIndex from "../views/studio/index.vue";
import inspect from "../views/studio/inspect.vue";
import sprofile from "../views/studio/profile/index.vue";
import profileEdit from "../views/studio/profile/edit.vue";
import speople from "../views/studio/people.vue";
import activity from "../views/studio/activity/index.vue";
import activityedit from "../views/studio/activity/edit.vue";
import capital from "../views/studio/capital.vue";
import capitalInfo from "../views/studio/capitalInfo.vue";
import capitalOrder from "../views/studio/capitalOrder.vue";
import trend from "../views/studio/trend/index.vue";
import trendInfo from "../views/studio/trend/info.vue";
import jurisdiction from "../views/studio/jurisdiction.vue";
import snotice from "../views/studio/notice.vue";
import openstudio from "../views/studio/openstudio.vue";
import verifying from "../views/studio/verifying.vue";
import bankcard from "../views/studio/bankcard.vue";
import noticeIndex from "../views/studio/notice/index.vue";
import noticeInfo from "../views/studio/notice/info.vue";
import noActivity from "../views/noActivity.vue";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // index
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页 - RockGuys"
    }
  },
  // 活动详情
  {
    path: "/live-info",
    name: "liveinfo",
    component: liveinfo
  },
  // 个人资料
  {
    path: "/profile",
    name: "profile",
    component: profile,
    meta: {
      title: "个人资料 - RockGuys"
    }
  },
  // 编辑资料
  {
    path: "/profile/edit",
    name: "edit",
    component: edit,
    meta: {
      title: "编辑资料 - RockGuys"
    }
  },
  // 我的通知
  {
    path: "/notice",
    name: "notice",
    component: notice,
    meta: {
      title: "我的通知 - RockGuys"
    }
  },
  // 我的订单
  {
    path: "/order",
    name: "order",
    component: order,
    meta: {
      title: "我的订单 - RockGuys"
    }
  },
  // 我的订单详情
  {
    path: "/orderInfo",
    name: "orderInfo",
    component: orderInfo,
  },
  // 更多设置
  {
    path: "/more",
    name: "more",
    component: more,
    meta: {
      title: "更多设置 - RockGuys"
    }
  },
  // 其他人的资料
  {
    path: "/people",
    name: "people",
    component: people
  },
  // 搜索
  {
    path: "/search",
    name: "search",
    component: search,
    meta: {
      title: "搜索结果 - RockGuys"
    }
  },
  // 隐私政策
  {
    path: "/privacy",
    name: "privacy",
    component: privacy,
    meta: {
      title: "隐私政策 - RockGuys"
    }
  },
  // 隐私政策
  {
    path: "/legal/terms",
    name: "terms",
    component: terms,
    meta: {
      title: "使用条款 - RockGuys"
    }
  },
  // 扫码验票
  {
    path: "/studio/inspect",
    name: "inspect",
    component: inspect,
    meta: {
      title: "扫码验票 - RockGuys"
    }
  },
  // 资料设置
  {
    path: "/studio/profile",
    name: "sprofile",
    component: sprofile,
    meta: {
      title: "工作室资料 - RockGuys"
    }
  },
  // 资料修改
  {
    path: "/studio/profile/edit",
    name: "profileEdit",
    component: profileEdit,
    meta: {
      title: "修改资料 - RockGuys"
    }
  },
  // 资料修改
  {
    path: "/speople",
    name: "speople",
    component: speople
  },
  // 活动管理
  {
    path: "/studio/activity",
    name: "activity",
    component: activity,
    meta: {
      title: "活动管理 - RockGuys"
    }
  },
  // 活动编辑
  {
    path: "/studio/activity/edit",
    name: "activityedit",
    component: activityedit,
    meta: {
      title: "活动编辑 - RockGuys"
    }
  },
  // 资金管理
  {
    path: "/studio/capital",
    name: "capital",
    component: capital,
    meta: {
      title: "资金管理 - RockGuys"
    }
  },
  // 资金管理
  {
    path: "/studio/capital/month",
    name: "capitalInfo",
    component: capitalInfo,
    meta: {
      title: "资金列表 - RockGuys"
    }
  },
  // 订单详情
  {
    path: "/studio/capital/info",
    name: "capitalOrder",
    component: capitalOrder,
    meta: {
      title: "账单详情 - RockGuys"
    }
  },
  // 数据报表
  {
    path: "/studio/trend",
    name: "trend",
    component: trend,
    meta: {
      title: "数据报表 - RockGuys"
    }
  },
  // 数据报表详情
  {
    path: "/studio/trend/info",
    name: "trendInfo",
    component: trendInfo,
    meta: {
      title: "数据报表 - RockGuys"
    }
  },
  // 权限管理
  {
    path: "/studio/jurisdiction",
    name: "jurisdiction",
    component: jurisdiction,
    meta: {
      title: "权限管理 - RockGuys"
    }
  },
  // 通知设置
  {
    path: "/studio/setting",
    name: "snotice",
    component: snotice,
    meta: {
      title: "通知 - RockGuys"
    }
  },
  // 工作室
  {
    path: "/studio",
    name: "studioIndex",
    component: studioIndex,
    meta: {
      title: "工作室 - RockGuys"
    }
  },
  // 开设工作室
  {
    path: "/studio/open",
    name: "openstudio",
    component: openstudio,
    meta: {
      title: "开设工作室 - RockGuys"
    }
  },
  // 审核完成
  {
    path: "/studio/verifying",
    name: "verifying",
    component: verifying,
  },
  // 添加银行卡
  {
    path: "/bank",
    name: "bankcard",
    component: bankcard
  },
  // 通知列表
  {
    path: "/studio/notice",
    name: "noticeIndex",
    component: noticeIndex,
    meta: {
      title: "通知列表 - RockGuys"
    }
  },
  // 通知列表详情
  {
    path: "/studio/notice/info",
    name: "noticeInfo",
    component: noticeInfo
  },
  {
    path: "/noActivity",
    name: "noActivity",
    component: noActivity
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  mode: "history",
  routes,
});

// 未登录
router.beforeEach((to, from, next) => {
  const argList = [
    "liveinfo",
    "profile",
    "edit",
    "notice",
    "order",
    "orderInfo",
    "more",
    "people",
    "search",
    "studioIndex",
    "inspect",
    "sprofile",
    "profileEdit",
    "speople",
    "activity",
    "activityedit",
    "capital",
    "capitalInfo",
    "capitalOrder",
    "trend",
    "trendInfo",
    "jurisdiction",
    "snotice",
    "openstudio",
    "verifying",
    "bankcard",
    "noticeIndex",
    "noticeInfo",
    "noActivity",
  ];

  if (argList.includes(to.name)) {
    next("/");
  } else {
    next();
  }
  // if (!to.name) {
  // 	next("/");
  // }
  // // token
  // let logon = localStorage.getItem("token");
  // // 工作室id
  // let sid = localStorage.getItem("sid");
  // const pathList = [
  //   "profile",
  //   "notice",
  //   "order",
  //   // "orderInfo",
  //   "more",
  //   "openstudio",
  //   "inspect",
  //   "sprofile",
  //   "profileEdit",
  //   "activity",
  //   "activityedit",
  //   "capital",
  //   "trend",
  //   "trendInfo",
  //   "jurisdiction",
  //   "snotice",
  // ];
  // const pathList_studio = [
  //   "inspect",
  //   "sprofile",
  //   "profileEdit",
  //   "activity",
  //   "activityedit",
  //   "capital",
  //   "trend",
  //   "trendInfo",
  //   "jurisdiction",
  //   "snotice",
  // ];


  // if (!logon) {
  //   if (pathList.includes(to.name)) {
  //     Vue.prototype.$auth.auth = 1;
  //     next("/");
  //   } else {
  //     next();
  //   }
  // } else {
  //   if (pathList_studio.includes(to.name)) {
  //     Vue.prototype.$auth.changeStudio = 1;
  //     if (!sid) {
  //       Vue.prototype.$auth.changeStudio = 2;
  //     } else {
  //       next();
  //     }
  //   } else {
  //     next();
  //   }
  // }
});


export default router;