<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="content">
          <div class="header">
            <div class="date-box">
              <div
                v-for="(item, index) in dateList"
                :key="index"
                :class="{ 'btn-style': index == n_id }"
                @click="saveIndex(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="box">
              <div>
                <p>票房（元）</p>
                <p>{{ totalAmount }}</p>
              </div>
              <div class="ui-cut"></div>
              <div>
                <p>票房转化率（整体）</p>
                <p>{{ conversionRatio }}%</p>
              </div>
            </div>
            <div class="op-list">
              <div v-for="(item, index) in opList" :key="index">
                <div class="cube">
                  <div>
                    <p>{{ item.num }}</p>
                    <p>{{ item.name }}</p>
                  </div>
                  <span class="icon-ui" :class="item.icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="session-box">
            <h4>各场演出数据</h4>
            <div class="item" v-for="(item, index) in perforList" :key="index">
              <img :src="item.poster" alt="" />
              <div>
                <p class="text-two">{{ item.performanceName }}</p>
                <p>{{ item.siteTitle }}</p>
                <p>
                  核销统计
                  {{ item.destroyedCount }} / {{ item.destroyCount }} 张
                </p>
              </div>
              <el-button
                class="btn-info"
                type="text"
                @click="
                  gotoInfo(item.id, item.performanceName + item.siteTitle)
                "
              >
                查看详情
              </el-button>
            </div>
            <div class="no-data-ui" v-if="!perforList.length">
              暂无数据
            </div>
            <!-- 分页 -->
            <div class="pagination" v-if="perforList.length">
              <el-pagination
                background
                layout="
                  prev,
                  pager,
                  jumper,
                  next"
                :total="total"
                :page-size="page_count"
                prev-text="前一页"
                next-text="下一页"
                @current-change="currentChange"
                @prev-click="prevClick"
                @next-click="nextClick"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "trend",
  data() {
    return {
      navId: 4,
      dateList: ["今天", "7天", "30天"],
      n_id: 0,

      totalAmount: 0,
      conversionRatio: "",

      opList: [
        {
          name: "支付笔数",
          num: "0",
          icon: "icon-data",
        },
        {
          name: "核销次数",
          num: "0",
          icon: "icon-seal",
        },
        {
          name: "访问人数",
          num: "0",
          icon: "icon-group",
        },
        {
          name: "访问次数",
          num: "0",
          icon: "icon-foot",
        },
        // {
        //   name: "新增关注人数",
        //   num: "0",
        //   icon: "icon-star",
        // },
        // {
        //   name: "互动人数",
        //   num: "0",
        //   icon: "icon-msg",
        // },
      ],
      perforList: [],

      studioId: "",

      total: 0,
      page: 1,
      page_count: 20,
    };
  },
  mounted() {
    // 获取id
    this.studioId = localStorage.getItem("sid") || "";
    // 查询商户数据报表
    this.getDataReport();
    // 获取商家未结束场次（待核销）数据列表
    this.getStudioUnEndSites();
  },
  methods: {
    // 查询商户数据报表
    getDataReport(type) {
      this.$http({
        url: "/api/wallet/getDataReport",
        params: {
          studioId: this.studioId,
          type: type,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.opList[0].num = data.orderCount;
          this.opList[1].num = data.destroyedCount;
          this.opList[2].num = data.visitingUserVolume;
          this.opList[3].num = data.visitingVolume;
          this.totalAmount = data.totalAmount;
          this.conversionRatio = data.conversionRatio;
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    // 获取商家未结束场次（待核销）数据列表
    getStudioUnEndSites() {
      this.$showLoading("loading...", ".session-box");
      this.$http({
        url: "/api/wallet/getSitesReportList",
        params: {
          pageNo: this.page,
          pageSize: this.page_count,
          studioId: this.studioId,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.perforList = res.data.data.list;
          this.total = res.data.data.count;
        } else {
          console.log(res.data);
        }
        this.$hideLoading();
      });
    },
    // 上一页
    prevClick(e) {
      this.page = e;
      this.getStudioUnEndSites();
    },
    // 下一页
    nextClick(e) {
      this.page = e;
      this.getStudioUnEndSites();
    },
    // 页码
    currentChange(e) {
      // 上下重复调用
      if (this.page == e) {
        console.log("重复");
      } else {
        this.page = e;
        this.getStudioUnEndSites();
      }
    },
    // 跳转
    gotoInfo(id, title) {
      this.$router.push({
        path: "/studio/trend/info",
        query: { id: id, title: title },
      });
    },
    saveIndex(id) {
      this.n_id = id;
      this.getDataReport(parseInt(id + 1));
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 48px);
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    min-height: calc(100vh - 48px);
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .content {
      width: 1200px;
      height: calc(100vh - 65px);
      height: 100%;
      margin: 15px 15px 0;
      box-sizing: border-box;
      // overflow-y: scroll;
      & > div {
        border-radius: 5px;
        background-color: #ffffff;
      }
      .header {
        .date-box {
          display: flex;
          align-items: center;
          padding: 15px 25px;
          box-sizing: border-box;
          border-bottom: 2px solid #f2f2f2;
          & > div {
            width: 95px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 2px;
            color: #333;
            background-color: #fdf2f2;
            cursor: pointer;
          }
        }
        .box {
          display: flex;
          padding: 25px 30px;
          box-sizing: border-box;
          & > div {
            p:first-child {
              margin-bottom: 15px;
              font-size: 14px;
              color: #666;
            }
            p:last-child {
              font-size: 22px;
              font-weight: 600;
            }
          }
          .ui-cut {
            width: 2px;
            height: 50px;
            margin: 0 40px;
            background-color: #f5f5f5;
          }
        }
        .op-list {
          display: flex;
          padding: 0 0 25px 25px;
          .cube {
            display: flex;
            justify-content: space-between;
            width: 165px;
            height: 100px;
            padding: 25px;
            margin-right: 15px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: rgba(253, 242, 242, 0.65);
            & > div {
              p:first-child {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 600;
              }
              p:last-child {
                font-size: 14px;
                color: #666;
              }
            }
          }
        }
      }
      .session-box {
        padding: 30px;
        height: 100%;
        margin-top: 15px;
        box-sizing: border-box;
        h4 {
          font-weight: 600;
        }
        .item {
          display: flex;
          align-items: flex-end;
          padding: 30px 0 25px;
          border-bottom: 2px solid #f5f5f5;
          & > img {
            width: 88px;
            height: 120px;
            margin-right: 20px;
            border-radius: 8px;
          }
          & > div {
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            color: #666;
            font-size: 14px;
            & > p:first-child {
              flex-shrink: 0;
              height: 50px;
              padding: 5px 0 15px;
              font-size: 18px;
              line-height: 23px;
              color: #333;
              box-sizing: border-box;
            }
          }
          .btn-info {
            margin-left: auto;
            color: #ef4033;
          }
        }
      }
    }
  }
}
.pagination {
  margin: 40px 0 20px;
  text-align: center;
}
</style>
