<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div class="header">
            <div
              class="hitem"
              :class="{ active: tid == index }"
              v-for="(item, index) in navList"
              :key="index"
              @click="clickIndex(index)"
            >
              {{ item }}
            </div>
            <el-input v-model="search_val" size="small" placeholder="商品名称">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <!-- 演出订单 -->
          <div class="live-order-box" v-show="tid == 0">
            <div class="order-tits">
              <span style="width: 40%;">商品</span>
              <span style="width: 20%;">订单总额</span>
              <span style="width: 20%;">订单状态</span>
              <span style="width: 20%;">操作</span>
            </div>
            <div
              v-infinite-scroll="loadMore"
              infinite-scroll-disabled="busy"
              infinite-scroll-distance="30"
              class="infinite-scroll"
            >
              <div
                class="order-list"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="tag">
                  <span>订单号：{{ item.orderCode }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="order-info">
                  <div style="width: 40%;">
                    <img :src="item.image" alt="" />
                    <div class="title">
                      <p class="text-two">{{ item.ticketFullName }}</p>
                      <p>{{ item.ticketTitle }}</p>
                    </div>
                    <span class="num">* {{ item.number }}</span>
                  </div>
                  <div class="order-td">
                    <div>
                      <p class="price-all">{{ item.totalAmount }}</p>
                      <p v-if="!item.totalAmount">免费票</p>
                      <p v-else>
                        {{ item.payType == 1 ? "支付宝支付" : "微信支付" }}
                      </p>
                    </div>
                  </div>
                  <div class="order-td order-type">
                    <div>
                      <p v-if="item.orderStatus == 0" style="color: #E6A23C;">
                        待支付
                      </p>
                      <p
                        v-else-if="item.orderStatus == 1"
                        style="color: #E6A23C;"
                      >
                        已付款待出票
                      </p>
                      <p
                        v-else-if="item.orderStatus == 2"
                        style="color: #E6A23C;"
                      >
                        出票失败待退款
                      </p>
                      <p
                        v-else-if="item.orderStatus == 3"
                        style="color: #67C23A;"
                      >
                        出票成功
                      </p>
                      <p v-else-if="item.orderStatus == 4">待收货</p>
                      <p v-else-if="item.orderStatus == 5">收货完成</p>
                      <p v-else-if="item.orderStatus == 6">已退款（自动）</p>
                      <p v-else-if="item.orderStatus == 7">已核销</p>
                      <p
                        v-else-if="item.orderStatus == 8"
                        style="color: #F56C6C;"
                      >
                        演出异常手动退款
                      </p>
                      <p v-else style="color: #F56C6C;">交易关闭</p>
                      <p @click="gotoInfo(item.id)">订单详情</p>
                    </div>
                  </div>
                  <div class="order-td">
                    <div>
                      操作
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-data-ui" v-if="!orderList.length">
                暂无订单
              </div>
              <p class="bottom-style" v-show="busy && !noMore">
                <i class="el-icon-loading"></i>loading...
              </p>
              <p class="bottom-style" v-show="noMore && orderList.length">
                <i class="el-icon-hot-water"></i>没有更多了
              </p>
            </div>
          </div>
          <div class="good-order-box" v-show="tid == 1">
            商品订单
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "order",
  data() {
    return {
      navId: 2, // 侧边导航
      navList: ["演出订单", "商品订单"],
      tid: 0,
      search_val: "",
      page: 1,
      busy: true,
      noMore: false,
      orderList: [],
    };
  },
  mounted() {
    // 获取基本信息
    if (localStorage.getItem("userinfo")) {
      this.user_id = JSON.parse(localStorage.getItem("userinfo")).userId;
    }

    this.getMyOrder();
  },
  methods: {
    // 加载更多
    loadMore() {
      this.busy = true;
      this.page++;
      this.getMyOrder();
    },
    // 获取我的订单列表
    getMyOrder() {
      this.$http({
        url: "/api/order/getMyOrder",
        method: "POST",
        params: {
          pageNo: this.page,
          pageSize: 10,
          status: 1,
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          if (this.orderList.length) {
            this.orderList = this.orderList.concat(res.data.data.list);
          } else {
            this.orderList = res.data.data.list;
          }
          if (res.data.data.list.length) {
            this.busy = false;
          } else {
            this.busy = true;
            this.noMore = true;
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //
    clickIndex(index) {
      this.tid = index;
    },
    gotoInfo(id) {
      this.$router.push({
        path: "orderInfo",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 1200px;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
      .header {
        position: fixed;
        display: flex;
        align-items: center;
        width: 1200px;
        height: 65px;
        padding: 0 90px;
        box-sizing: border-box;
        border: solid #dadada;
        border-width: 0 1px 1px 0;
        background-color: #fff;
        .el-input {
          width: 30%;
          margin-left: auto;
        }
      }
      .hitem {
        margin-right: 90px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
      .active {
        position: relative;
        color: #000;
        &::after {
          content: "";
          position: absolute;
          bottom: -25px;
          left: 0;
          width: 100%;
          border-bottom: 2px solid #ef4033;
        }
      }
      .live-order-box {
        width: 95%;
        padding: 25px 25px 0;
        margin-top: 65px;
        box-sizing: border-box;
        .order-tits {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 55px;
          border-radius: 2px;
          background-color: #fafafa;
          & > span {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: #000;
          }
        }
        .infinite-scroll {
          max-height: calc(100vh - 200px);
          overflow-y: scroll;
        }
        .order-list {
          margin: 10px 0 25px;
          font-size: 12px;
          color: #333;
          .tag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 0 25px;
            box-sizing: border-box;
            border-left: 2px solid #ef4033;
            background-color: rgba(0, 0, 0, 0.02);
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
          }
          .order-info {
            display: flex;
            .order-td {
              width: 20%;
              text-align: center;
              & > div {
                margin: 0 auto;
              }
            }
            .order-type {
              p {
                margin-bottom: 5px;
                cursor: pointer;
                &:hover {
                  color: #666;
                }
              }
            }
            .price-all {
              margin-bottom: 5px;
              font-size: 16px;
              font-weight: bold;
              color: #333;
            }
            & > div {
              display: flex;
              align-items: center;
              height: 120px;
              border: 1px solid rgba(0, 0, 0, 0.02);
              & > img {
                width: 75px;
                height: 100px;
                margin: 0 15px 0 20px;
                border-radius: 5px;
              }
              .title {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 55%;
                height: 100%;
                letter-spacing: 2px;
                line-height: 15px;
                font-size: 12px;
                color: #333;
              }
              .num {
                flex-shrink: 0;
                width: 80px;
                margin-left: auto;
                text-align: center;
                font-size: 12px;
                color: #333;
              }
            }
          }
        }
      }
      .good-order-box {
        width: 95%;
        padding: 25px 25px 0;
        margin-top: 65px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
