<template>
	<div>
		<el-popover
			placement="bottom"
			popper-class="search-popover"
			width="310"
			trigger="click"
			:visible-arrow="false"
			v-if="show == 1"
		>
			<div class="search-title">
				<span class="tit">最近</span>
				<span class="btn-cursor" @click="closeAll">删除全部</span>
			</div>
			<div class="item" v-for="(item, index) in historyList" :key="index">
				<span @click="gotoSearch(item)" class="btn-cursor">
					{{ item }}
				</span>
				<i
					class="el-icon-close"
					style="cursor: pointer"
					@click="closeItem(index)"
				></i>
			</div>
			<el-input
				prefix-icon="el-icon-search"
				v-model="searchval"
				placeholder="搜索"
				@keyup.native.enter="searchInfo"
				slot="reference"
			></el-input>
		</el-popover>
		<div class="hot-box">
			<div class="recom">
				<h3>热门推荐</h3>
				<p
					v-for="(item, index) in hotList.slice(0, 5)"
					:key="index"
					class="text-two"
					@click="gotoInfo(item.activityId, item.siteId)"
				>
					<span class="title">
						{{ item.activityName }}{{ item.siteName }}
						<span v-if="index == 0" class="tag-hot">热</span>
					</span>
				</p>
			</div>
			<div class="follow">
				<h3>推荐关注</h3>
				<div class="fans" v-for="(item, index) in fansList" :key="index">
					<el-avatar
						:src="item.photo"
						size="medium"
						style="cursor: pointer"
						icon="el-icon-user-solid"
						@click.native="goUserInfo(item.studioId, item.type)"
					></el-avatar>
					<span class="name text-one">{{ item.name }}</span>
					<el-button
						:class="item.follow == 1 ? 'btn-style' : 'btn-follow'"
						@click="following(item.studioId, index)"
					>
						{{ item.follow == 1 ? "正在关注" : "关注" }}
					</el-button>
				</div>
			</div>
			<div class="right-bottom">
				<p>
					<a
						style="margin-right: 10px;"
						href="https://pc.rockguys.cn/privacy"
						target="_blank"
						>隐私政策</a
					>
					<a href="https://pc.rockguys.cn/legal/terms" target="_blank">使用条款</a>
				</p>
				<p>
					<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
						>鲁ICP备17051135</a
					>
					| © 2022 ROCKGUYS Co.,Ltd.
				</p>
				<p id="tjdom" v-show="0"></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "hotright",
	props: ["show"],
	data() {
		return {
			hotList: [],
			fansList: [],
			is_follow: false,
			user_id: null,
			token: null,
			// 搜索
			searchval: "",
			historyList: [],
		};
	},
	mounted() {
		let userinfo = JSON.parse(localStorage.getItem("userinfo"));
		if (userinfo) {
			this.user_id = userinfo.userId;
		}
		this.token = localStorage.getItem("token");
		let history = sessionStorage.historyList;
		if (history) {
			this.historyList = JSON.parse(history);
		}

		this.getHotRight();
		this.getHotStudio();
		this.setDom();
	},
	methods: {
		// 获取热门推荐
		getHotRight() {
			this.$http("/api/ticket/v1.0/hotActivity").then((res) => {
				if (res.data.code == 200) {
					this.hotList = res.data.data;
				} else {
					console.log(res.data.msg);
				}
			});
		},
		// 获取推荐工作室
		getHotStudio() {
			this.$http
				.get("/api/ticket/v1.0/hotStudio", { params: { userId: this.user_id } })
				.then((res) => {
					if (res.data.code == 200) {
						this.fansList = res.data.data;
					} else {
						console.log(res.data.msg);
					}
				});
		},
		// 搜索活动
		searchInfo() {
			if (!this.searchval.trim()) {
				this.$message.warning("请输入搜索内容!");
				return;
			}
			this.gotoSearch(this.searchval.trim());
			if (this.historyList.indexOf(this.searchval.trim()) != -1) {
				console.log("重复");
				return;
			}
			this.historyList.push(this.searchval.trim());
			sessionStorage.setItem("historyList", JSON.stringify(this.historyList));
		},
		// 跳转搜索
		gotoSearch(value) {
			this.$router.push({
				path: "/search",
				query: { val: value },
			});
		},

		// 删除单个数据
		closeItem(index) {
			this.historyList.splice(index, 1);
			sessionStorage.setItem("historyList", JSON.stringify(this.historyList));
		},
		// 清除历史记录
		closeAll() {
			this.historyList = [];
			sessionStorage.removeItem("historyList");
		},
		// 关注
		following(id, index) {
			if (!this.token) {
				this.$message.warning("请先登录!");
				return;
			}
			// console.log(this.fansList[index].follow);
			if (parseInt(this.fansList[index].follow)) {
				this.fansList[index].follow = 0;
				this.followUpload(id, this.fansList[index].follow);
			} else {
				this.fansList[index].follow = 1;
				this.followUpload(id, this.fansList[index].follow);
			}
		},
		followUpload(id, type) {
			this.$http({
				url: "/api/authentication/v1.0/studioFollow",
				method: "POST",
				data: {
					studioId: id,
					follow: type,
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					console.log(res.data);
				} else {
					console.log("关注", res.data);
				}
			});
		},
		gotoInfo(aid, site) {
			// let studioId = localStorage.getItem("sid");
			if (this.$route.path == "/live-info") {
				this.$router.push({
					path: "/live-info",
					query: { aid: aid, site: site },
				});
				window.location.reload();
			} else {
				this.$router.push({
					path: "/live-info",
					query: { aid: aid, site: site },
				});
			}
		},
		goUserInfo(id, type) {
			if (this.$route.path == "/speople") {
				this.$router.push({
					path: "/speople",
					query: { id: id, type: type },
				});
				window.location.reload();
			} else {
				this.$router.push({
					path: "/speople",
					query: { id: id, type: type },
				});
			}
		},
		// 设置dom
		setDom() {
			const script = document.createElement("script");
			script.src =
				"https://s4.cnzz.com/z_stat.php?id=1280213050&web_id=1280213050";
			script.language = "JavaScript";
			document.getElementById("tjdom").appendChild(script);
			const script1 = document.createElement("script");
			script1.src = "https://c.cnzz.com/core.php?web_id=1280213050&t=z";
			script1.language = "JavaScript";
			document.getElementById("tjdom").appendChild(script1);
			const a = document.createElement("a");
			a.href = "https://www.cnzz.com/stat/website.php?web_id=1280213050";
			a.text = " 站长统计";
			a.title = "站长统计";
      a.target = "_blank";
			document.getElementById("tjdom").appendChild(a);
		},
	},
};
</script>

<style lang="less" scoped>
.hot-box {
	position: fixed;
	width: 335px;
	height: 100%;
	& > div {
		z-index: 10;
		position: relative;
		padding: 20px;
		margin-top: 15px;
		border-radius: 15px;
		background-color: #f7f9fa;
		& > h3 {
			font-weight: 600;
			margin-bottom: 30px;
		}
	}
	.recom {
		& > p {
			margin-bottom: 12px;
			color: #666;
			font-size: 14px;
			line-height: 18px;
			cursor: pointer;
			&:hover {
				color: #333;
			}
		}
		.tag-hot {
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-left: 5px;
			text-align: center;
			line-height: 18px;
			font-size: 12px;
			color: #fff;
			border-radius: 50% 50% 50% 0;
			background: linear-gradient(224deg, #ef4033 0%, #e70030 100%);
		}
	}
	.follow {
		.fans {
			display: flex;
			align-items: center;
			margin: 15px 0;
			.el-avatar {
				flex-shrink: 0;
			}
			.name {
				margin-left: 5px;
				color: #777;
				font-size: 14px;
				line-height: 22px;
			}
			.el-button {
				height: 26px;
				margin-left: auto;
				line-height: 2px;
				font-size: 14px;
				border-radius: 15px;
			}
			.btn-follow {
				color: #ff4d4f;
				background-color: #fff;
				border: 1px solid #ff4d4f;
			}
		}
	}
}
.right-bottom {
	padding-top: 10px !important;
	font-size: 12px;
	color: #a7afbe;
	background-color: transparent !important;
	& > p {
		margin-bottom: 5px;
		a {
			color: #a7afbe;
			cursor: pointer;
			&:hover {
				color: #666;
			}
		}
	}
}
/deep/.el-popover__reference-wrapper {
	position: fixed;
	top: 50px;
	.el-input {
		width: 335px;
		margin-top: 15px;
		.el-input__inner {
			border-radius: 20px;
			border: none;
			background-color: #f7f9fa;
			// &:focus {
			//   border: 1px solid #EF4033;
			// }
		}
	}
}
</style>
<style>
.search-popover {
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}
.search-popover .tit {
	color: #1d2023;
	font-size: 16px;
	font-weight: bold;
}
.search-popover .btn-cursor {
	color: #606972;
	font-size: 14px;
	cursor: pointer;
}
.search-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	height: 40px;
	line-height: 40px;
	box-sizing: border-box;
}
.search-popover .item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	height: 50px;
	line-height: 50px;
	box-sizing: border-box;
	border-top: 1px solid #efeff0;
}
</style>
