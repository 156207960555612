<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="indexId"></nav-left>
        </div>
        <div class="main-content">
          <div class="content">
            <h3 class="title">服务列表</h3>
            <div class="no-data">
              <p>
                <i
                  class="el-icon-receiving"
                  style="color: #d9d9d9; font-size: 50px"
                ></i>
              </p>
              <p class="tip1">暂无该业务</p>
              <p class="tip2">请到 App 端完成扫码验票操作</p>
            </div>
          </div>
          <div class="right-list">
            <p class="title">服务列表</p>
            <div class="no-list">暂无服务</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "inspect",
  data() {
    return {
      indexId: 0,
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    background-color: #f5f5f5;
    .left {
      width: 210px;
    }
    .main-content {
      display: flex;
      width: 1190px;
      padding-top: 25px;
      .title {
        padding: 20px;
        font-weight: 500;
      }
      .content {
        width: 840px;
        min-height: 600px;
        margin: 0 15px;
        background-color: #fff;
        .no-data {
          margin-top: 200px;
          text-align: center;
          .tip1 {
            margin: 15px 0;
            font-weight: 600;
            font-size: 16px;
          }
          .tip2 {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
      .right-list {
        width: 350px;
        height: 270px;
        background-color: #fff;
        .no-list {
          margin-top: 80px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
