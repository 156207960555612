<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <img src="../assets/images/hidden.png" alt="hidden">
          <p class="font14 lightGray">演出未发布或被藏匿于星际角落</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "noActivity",
  data() {
    return {
      navId: 0, // 侧边导航
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 100%;
      padding-top: 20vh;
      box-sizing: border-box;
      text-align: center;
      border-right: 1px solid #e5e5e5;
      & > img {
        width: 260px;
        height: 260px;
      }
    }
  }
}
</style>
