<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div
            class="header-box"
            :style="{
              background: backgroundImage
                ? 'url( ' + backgroundImage + ')'
                : '#d5d5d5',
            }"
          >
            <i v-if="!backgroundImage" class="el-icon-camera-solid"></i>
            <div class="edit-box">
              <div
                class="btn-default btn-edit"
                @click="$router.push('profile/edit')"
              >
                编辑资料
              </div>
              <el-upload
                :limit="1"
                ref="upload"
                name="multipartFile"
                :show-file-list="false"
                :http-request="uploadSuccess"
                :before-upload="uploadPreview"
                action="none"
                accept="image/png,image/jpg,image/jpeg"
              >
                <!-- <span>change</span> -->
              </el-upload>
            </div>
            <upload-cropper
              ref="cropper"
              :cropper="cropper"
              @getAvatar="getChildInfo"
            ></upload-cropper>
            <div class="info-box">
              <el-avatar
                class="avatar"
                shape="circle"
                :src="avatar"
                :size="125"
              ></el-avatar>
              <div class="box">
                <div class="header">
                  <p>{{ nickname }}</p>
                  <p>{{ remark ? remark : "这家伙很懒， 啥都没写" }}</p>
                </div>
                <div class="info">
                  <div>
                    <p>
                      <i
                        class="el-icon-location-outline"
                        style="margin-right: 5px;"
                      ></i>
                      {{ province }} ·
                      {{ city ? city : area }}
                    </p>
                    <!-- <p>
                      <span>关注了 6512</span>
                      <span>关注你 6512</span>
                    </p> -->
                    <!-- <p class="flex-right">
                      <span><i class="el-icon-document"></i>详细介绍</span>
                      <i class="el-icon-arrow-right"></i>
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div class="nav-box">
              <div
                :class="{ active: n_id == index }"
                v-for="(item, index) in navList"
                :key="index"
                @click="saveIndex(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="mine-box" v-if="n_id == 0">
              <div class="no-data-ui">暂无动态</div>
            </div>
            <div class="mine-box" v-else-if="n_id == 1">
              <a-item :activityList="loveList"></a-item>
              <div class="no-data-ui" v-if="!loveList.length">
                暂无喜欢!
              </div>
              
            </div>
            <div class="mine-box ticket-box" v-else>
              <div
                class="timeline"
                v-for="(item, index) in ticketHistory"
                :key="index"
              >
                <span class="year">{{ index }}</span>
                <div class="item" v-for="(aitem, aindex) in item" :key="aindex">
                  <span>{{ aitem.createTime }}</span>
                  <div>
                    <p class="session">{{ aitem.siteName }}</p>
                    <p class="tips" @click="gotoInfo(aitem.activityId, aitem.siteId)">{{ aitem.activityName }}</p>
                  </div>
                </div>
              </div>
              <div class="no-data-ui" v-show="!ticketHistory">暂无观演经历</div>
            </div>
          </div>
        </div>
        <hot-right></hot-right>
      </div>
    </section>
  </div>
</template>

<script>
// 裁切
import uploadCropper from "../../components/uploadCropper";
export default {
  name: "profile",
  components: {
    "upload-cropper": uploadCropper,
  },
  data() {
    return {
      navId: 1, // 侧边导航
      typeId: 0, // 0 艺术家 || 1 场馆 || 2 厂牌
      n_id: 0, // 顶部
      navList: ["动态", "喜欢", "观演经历"],

      // 资料
      avatar: "",
      nickname: "用户名",
      backgroundImage: "",
      remark: "这个家伙很懒，啥都没写",

      city: "",
      area: "",
      province: "",

      ticketHistory: [],
      loveList: [],

      user_id: "",
      cropper: {},
    };
  },
  mounted() {
    // 获取用户信息
    let userinfo = JSON.parse(localStorage.getItem("userinfo")) || "";
    if (userinfo) {
      this.user_id = userinfo.userId;
    }
    // 获取资料
    this.getInformation();
  },
  methods: {
    saveIndex(id) {
      this.$showLoading("loading...", ".content-box");
      this.n_id = id;
      if (id == 0) {
        console.log(1);
      } else if (id == 1) {
        this.getLove();
      } else if (id == 2) {
        this.getTicketHistory();
      }
      setTimeout(() => {
        this.$hideLoading();
      }, 1000)
    },
    // 选择文件 进行判断
    uploadPreview(file) {
      const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      const SIZE = file.size / 1024 / 1024 < 5;
      if (!TYPE) {
        this.$message.error({
          message: "请选择正确的图片！",
          center: true,
          duration: 2000,
        });
        return TYPE;
      }
      if (!SIZE) {
        this.$message.warning({
          message: "上传头像图片大小不能超过 5MB!",
          center: true,
          duration: 2000,
        });
        return SIZE;
      }
      return TYPE && SIZE;
    },
    // 获取上传信息
    uploadSuccess(data) {
      this.$showLoading("loading", ".header-box");
      this.cropper.is_cropper = true;
      this.cropper.temp_name = data.file.name;
      this.cropper.fixedNumber = [16, 9];
      this.cropper.autoCropWidth = 855;
      this.cropper.autoCropHeight = 270;
      this.cropper.temp_img = URL.createObjectURL(data.file);
      this.$refs.cropper.getInfo(this.cropper);
    },
    // 获取图片链接
    getChildInfo(val) {
      this.$refs.upload.clearFiles();
      if (val == "cancel") {
        console.log("cancel");
      } else {
        this.backgroundImage = val;
        this.changeBgimg();
      }
    },
    // 更换背景图
    changeBgimg() {
      this.$http({
        url: "/api/user/v1.0/upBackgroundImage",
        method: "POST",
        data: {
          image: this.backgroundImage,
          pid: this.user_id,
        },
      }).then((res) => {
        console.log(res);
      });
    },
    // 获取喜欢
    getLove() {
      this.$http({
        url: "/api/ticket/v1.0/getLove",
        params: {
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.loveList = res.data.rows;
        } else {
          console.log(res.data);
        }
      });
    },
    // 观演经历
    getTicketHistory() {
      this.$http({
        url: "/api/user/v1.0/ticketHistory",
        params: {
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.ticketHistory = res.data.data;
          // this.ticketHistory = JSON.stringify(res.data.data) == "{}" ? [] : res.data.data;
        } else {
          console.log("观演经历", res.data);
        }
      });
    },
    // 获取资料
    getInformation() {
      this.$http({
        url: "/api/information/v1.0/getInformation",
        params: {
          userId: this.user_id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.avatar = data.photo;
          this.nickname = data.nickName;
          this.backgroundImage = data.backgroundImage;

          this.city = data.city;
          this.area = data.area;
          this.remark = data.remark;
          this.province = data.province;
        } else {
          console.log("资料获取", res.data.msg);
        }
      });
    },
    gotoInfo(aid,site) {
      this.$router.push({
        path: "live-info",
        query: { aid: aid, site: site }
      })
    }
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 48px);
  height: 100%;
  margin-top: 48px;
  box-sizing: border-box;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: calc(100vh - 48px);
    max-height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 855px;
      height: 100%;
      margin-right: 15px;
      overflow: auto;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
      .el-icon-camera-solid {
        position: absolute;
        top: 40%;
        left: 50%;
        color: #fff;
        font-size: 40px;
      }
      .header-box {
        position: relative;
        width: 100%;
        height: 270px;
        padding-top: 215px;
        box-sizing: border-box;
        background-size: 100% auto !important;
        background-repeat: no-repeat !important;
        .edit-box {
          position: absolute;
          right: 30px;
          top: 60px;
          width: 200px;
          text-align: right;
          .btn-default {
            display: inline-block;
            width: 100px;
            height: 30px;
            margin-bottom: 10px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            border-radius: 20px;
            cursor: pointer;
          }
          .btn-edit {
            color: #ef4033;
            background-color: #fff;
            border: 1px solid #ef4033;
          }
        }
        .info-box {
          position: relative;
          display: flex;
          width: 100%;
          box-sizing: border-box;
          .avatar {
            position: absolute;
            margin-left: 40px;
            border: 5px solid #fff;
          }
          .box {
            width: 100%;
            box-sizing: border-box;
            .header {
              width: 100%;
              padding: 0 20px 10px;
              box-sizing: border-box;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.25) 100%
              );
              & > p {
                margin-left: 180px;
                color: #fff;

                &:first-child {
                  font-size: 18px;
                  font-weight: 600;
                }
                &:last-child {
                  margin-top: 10px;
                  font-size: 16px;
                }
              }
            }
            .info {
              padding: 15px 20px;
              & > div {
                margin-left: 180px;
                & > p {
                  margin-bottom: 15px;
                  font-size: 14px;
                  color: #666;

                  span {
                    margin-right: 30px;
                  }
                }
                .flex-right {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 500px;
                }
              }
            }
          }
        }
      }
      .content-box {
        width: 100%;
        text-align: center;
        .nav-box {
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 15px 120px;
          margin-top: 100px;
          box-sizing: border-box;
          border-bottom: 2px solid #f1f1f1;
          & > div {
            font-size: 18px;
            font-weight: 500;
            color: #818181;
            cursor: pointer;
          }
          .active {
            position: relative;
            color: #000;
            &::after {
              content: "";
              position: absolute;
              left: -20px;
              bottom: -17px;
              width: 100%;
              padding: 0 20px;
              border-bottom: 2px solid #ef4033;
            }
          }
        }
      }
    }
  }
}
.mine-box {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  text-align: left;
}
.ticket-box {
  padding: 40px 20px;
}
.timeline {
  .year {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    color: #3f454b;
    font-weight: bold;
    text-align: center;
    border-right: 2px solid #d6d9de;
  }
  .item {
    display: flex;
    align-items: center;
    & > span {
      position: relative;
      width: 100px;
      height: 100px;
      font-size: 14px;
      line-height: 100px;
      text-align: center;
      border-right: 2px solid #d6d9de;
      &::after {
        content: "";
        position: absolute;
        right: -6px;
        top: 43px;
        width: 10px;
        height: 10px;
        padding: 3px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #ef4033;
      }
    }
    & > div {
      padding-left: 40px;
      box-sizing: border-box;
    }
    .session {
      width: 70px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      color: #606972;
      border-radius: 20px;
      background-color: #f2f2f2;
    }
    .tips {
      margin-top: 10px;
      font-size: 15px;
      color: #3f454b;
      cursor: pointer;
      &:hover {
        color: #999;
      }
    }
  }
}
</style>
