<template>
  <div>
    <navs-top></navs-top>
    <section>
      <div class="main">
        <div class="left">
          <nav-left :classId="navId"></nav-left>
        </div>
        <div class="main-content">
          <p class="title title-top">工作室资料</p>
          <!-- 头像上传 -->
          <div class="edit-box">
            <div class="avatar">
              <p>头像</p>
              <div class="box">
                <el-avatar
                  shape="circle"
                  :size="85"
                  :src="img_url"
                  v-if="img_url"
                ></el-avatar>
                <el-avatar
                  shape="circle"
                  :size="85"
                  src="http://rockguys.oss-cn-beijing.aliyuncs.com/APP/1611220618278.png"
                  v-else
                ></el-avatar>
                <el-upload
                  action="/api/public/v1.0/upload"
                  name="multipartFile"
                  ref="upload"
                  :http-request="uploadSuccess"
                  :before-upload="uploadPreview"
                  :limit="1"
                  :show-file-list="false"
                >
                  <div class="btn-box">
                    <div class="btn-upload">
                      <i class="el-icon-upload2"></i>
                      上传头像
                    </div>
                    <div slot="tip" class="tip-class">
                      支持图片类型：png, jpg, jpeg
                    </div>
                  </div>
                </el-upload>
                <up-cropper
                  ref="cropper"
                  :cropper="cropper"
                  @getAvatar="getChildInfo"
                ></up-cropper>
              </div>
            </div>
          </div>
          <!-- 资料选择 -->
          <div class="studio-box">
            <!-- 1 -->
            <div class="info-item">
              <div>
                <p>工作室类别</p>
                <el-select
                  v-model="studio_type"
                  size="small"
                  @change="changeStudio"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in studioType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-if="studioId == 3">
                <p>场馆名称</p>
                <el-input
                  v-model="arena_name"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div v-else>
                <p>地区</p>
                <el-cascader
                  placeholder="请选择"
                  size="small"
                  v-model="address"
                  :options="addressList"
                  :props="areaProps"
                  clearable
                  ref="myAddress1"
                  @change="selectAddress"
                ></el-cascader>
              </div>
            </div>
            <!-- 2 -->
            <div class="info-item" v-if="studioId == 1">
              <div>
                <p>艺术家名称</p>
                <el-input
                  v-model="artist_name"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <p>音乐风格</p>
                <el-cascader
                  placeholder="可搜索"
                  size="small"
                  v-model="music_type"
                  :options="musicType"
                  :props="musicProps"
                  clearable
                  filterable
                  @change="selectMusicType"
                ></el-cascader>
              </div>
            </div>
            <!-- 3 -->
            <div class="info-item">
              <div v-if="studioId == 2">
                <p>厂牌名称</p>
                <el-input
                  v-model="brand_name"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <p>一句话认识你</p>
                <el-input
                  v-model="introduction"
                  size="small"
                  placeholder="我来自大海的故乡"
                  :class="[studio_type != 1 ? 'input-ban' : '']"
                ></el-input>
              </div>
            </div>
          </div>
          <!-- 场馆地址 -->
          <div class="venue-box" v-if="studioId == 3">
            <p class="title title-mid">场馆地址</p>
            <div class="info-item">
              <div>
                <p>选择地区</p>
                <el-cascader
                  placeholder="请选择"
                  size="small"
                  v-model="arean_area"
                  :options="addressList"
                  :props="areaProps"
                  clearable
                  ref="myAddress"
                  @change="selectArean"
                ></el-cascader>
              </div>
              <div>
                <p>详细地址</p>
                <el-input
                  v-model="address_info"
                  size="small"
                  placeholder="街道门牌信息"
                ></el-input>
              </div>
            </div>
          </div>
          <!-- 主体资质 -->
          <div class="main-quali" v-if="studioId != 1">
            <p class="title title-mid">主体资质</p>
            <div class="info-item">
              <div>
                <p>主体类型</p>
                <el-select
                  v-model="main_type"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in mainType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <p>主体名称</p>
                <el-input
                  v-model="main_name"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div class="info-item">
              <div>
                <p>证件号码</p>
                <el-input
                  v-model="main_ID"
                  size="small"
                  style="width: 45%"
                  placeholder="请输入社会统一信用代码"
                ></el-input>
              </div>
            </div>
          </div>
          <!-- 超级管理员信息 -->
          <div class="super-box">
            <p class="title title-mid">超级管理员信息</p>
            <div class="info-item">
              <div>
                <p>姓名</p>
                <el-input
                  v-model="name"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <p>证件类型</p>
                <el-select v-model="ID_type" size="small" placeholder="请选择">
                  <el-option
                    v-for="item in certificateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div>
                <p>证件号码</p>
                <el-input
                  v-model="ID_number"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <p>联系电话</p>
                <el-input
                  v-model="mobile"
                  size="small"
                  oninput="if(value.length>11)value=value.slice(0,11)"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div class="info-item">
              <div>
                <p>电子邮箱</p>
                <el-input
                  v-model="email"
                  size="small"
                  style="width: 45%"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <up-IDcard @getIDcardInfo="getIDcardInfo(arguments, IDInfo)" :card_type="1">
            </up-IDcard>
          </div>
          <!-- 信息提示板 -->
          <div class="message-tips">
            <i class="el-icon-warning" style="color: #faad14"></i>
            <div>
              <p>
                1.请您确保所填写为真实有效信息，并拥有合理的相关权利，如有侵权可能会被追究相关法律责任。
              </p>
              <p>
                2.我方深知你的身份信息拥有高度敏感性和保密性，我方将确保您的敏感信息只作用我方鉴赏您身份的真实性
              </p>
              <p>
                3.工作室资料部分内容可在审核通过后继续更改，并可以为您的工作室添加更多管理人员，超级管理员信息审核通过后不可以更改/转让。
              </p>
              <p>
                4.审核期间您可能收到我方的审核电话，审核周期将在几分钟或几小时内。
              </p>
            </div>
            <i class="el-icon-close" style="color: #999; cursor: pointer"></i>
          </div>
          <!-- 协议 -->
          <div>
            <el-checkbox v-model="is_read">我已阅读并同意</el-checkbox>
            <span class="agreement">《关于艺人等商业账户使用协议》</span>
          </div>
          <el-button
            class="btn-submit"
            type="danger"
            size="small"
            @click="submitPass"
            >提交审核</el-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import area_format from "../../assets/area.json";
// 裁切
import uploadCropper from "../../components/uploadCropper";
// 身份认证扫描
import uploadCard from "../../components/uploadCard";
export default {
  name: "openstudio",
  components: {
    "up-cropper": uploadCropper,
    "up-IDcard": uploadCard,
  },
  data() {
    return {
      navId: null,
      user_id: "",
      studioId: 1, // 1 艺术家 || 2 厂牌 || 3 场馆
      // avatar_src: "", // 头像
      img_url: "",

      studio_type: "", // 工作室类别
      studioType: [], // 工作室全部类别

      address: "", // 地点
      addressList: [],
      areaProps: {
        value: "code",
        label: "name",
        children: "areaList",
      },

      artist_name: "", // 艺术家名称

      music_type: "", // 音乐风格
      musicType: [], //
      music_type_up: null,

      musicProps: {
        value: "id",
        label: "name",
        children: "style",
      },

      introduction: "", // 简介
      name: "", // 姓名
      brand_name: "", // 厂牌名称
      arena_name: "", // 场馆名称
      arean_area: "", // 场馆地址
      address_info: "", // 场馆详细地址

      main_type: "", // 主体类型
      mainType: [], // 主体
      main_name: "", // 主体名称
      main_ID: "", // 证件号码

      // 身份
      ID_type: "", // 证件类型
      ID_number: "", // 证件号码
      certificateList: [
        {
          id: 1,
          label: "身份证",
        },
      ],

      IDInfo: null,
      ID_info_f: "", // 信息
      ID_info_r: "",
      ID_url_f: "", // 身份证照地址
      ID_url_r: "",

      mobile: "", // 手机号
      email: "", // 电子邮箱
      is_read: false, // 阅读协议

      isSuccess: 0, // 状态

      // 地址搜索问题待解决  地址详情文字
      myArea: [], // 地区
      sessionArea: [], // 场馆
      cropper: {},

      authenticationId: null,
    };
  },
  computed: {},
  mounted() {
    // 获取userid
    if (localStorage.getItem("userinfo")) {
      this.user_id = JSON.parse(localStorage.getItem("userinfo")).userId;
    }
    /**
     * 开设工作室
     */
    let info = this.$route.params.item;
    if (info) {
      console.log(info);
      this.img_url = info.photo;
      this.studio_type = info.type;
      this.authenticationId = info.id;
      this.studioId = parseInt(info.type) + 1;
      if (info.type == 0) {
        this.artist_name = info.name;
      } else if (info.type == 1) {
        this.brand_name = info.name;
      } else {
        this.arena_name = info.name;
      }
    }
    // 获取本地地址数据
    this.addressList = area_format || [];
    // 获取工作室类别
    this.getStudioType("studio");
    this.getStudioType("subject_type");
    // 音乐风格
    this.getMusicType();
  },
  methods: {
    // 选择文件 进行判断
    uploadPreview(file) {
      const TYPE = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      const SIZE = file.size / 1024 / 1024 < 5;
      if (!TYPE) {
        this.$message.error({
          message: "请选择正确的图片！",
          center: true,
          duration: 2000,
        });
        return TYPE;
      }
      if (!SIZE) {
        this.$message.warning({
          message: "上传头像图片大小不能超过 5MB!",
          center: true,
          duration: 2000,
        });
        return SIZE;
      }
      return TYPE && SIZE;
    },
    // 上传成功
    uploadSuccess(data) {
      this.$showLoading("loading", ".edit-box");
      this.cropper.is_cropper = true;
      this.cropper.temp_name = data.file.name;
      this.cropper.temp_img = URL.createObjectURL(data.file);
      this.$refs.cropper.getInfo(this.cropper);
    },
    // 获取头像链接
    getChildInfo(val) {
      this.$refs.upload.clearFiles();
      if (val == "cancel") {
        console.log("cancel");
      } else {
        this.img_url = val;
      }
    },
    // 切换工作室
    changeStudio() {
      this.studioId = parseInt(this.studio_type) + 1;
      console.log(this.studioId);
    },
    // 获取  艺术家||厂牌  地址
    selectAddress() {
      let address = this.$refs.myAddress1.getCheckedNodes()[0].pathLabels;
      if (
        address[1] == "市辖区" ||
        address[1] == "省辖区" ||
        address[1] == "辖区"
      ) {
        this.myArea[0] = address[0];
        this.myArea[1] = "";
        this.myArea[2] = address[2];
      } else {
        this.myArea = address;
      }
      console.log(this.myArea);
    },
    // 选择场馆地址
    selectArean() {
      let address = this.$refs.myAddress.getCheckedNodes()[0].pathLabels;
      if (
        address[1] == "市辖区" ||
        address[1] == "省辖区" ||
        address[1] == "辖区"
      ) {
        this.sessionArea[0] = address[0];
        this.sessionArea[1] = "";
        this.sessionArea[2] = address[2];
      } else {
        this.sessionArea = address;
      }
      console.log(this.sessionArea);
    },
    // 获取工作室类别
    getStudioType(type) {
      this.$http({
        url: "/api/public/v1.0/getStudioType",
        params: {
          type: type,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          if (type == "studio") {
            this.studioType = res.data.data;
          } else {
            this.mainType = res.data.data;
          }
        }
      });
    },
    // 选择音乐风格
    selectMusicType() {
      let str = "";
      if (!this.music_type[1]) {
        str = this.music_type[0];
      } else {
        str = this.music_type[0] + "," + this.music_type[1];
      }
      this.music_type_up = str;
      // console.log(str);
    },
    // 获取音乐风格
    getMusicType() {
      this.$http({
        url: "/api/authentication/v1.0/getMusicStyle",
      }).then((res) => {
        if (res.data.code == 200) {
          this.musicType = res.data.data;
        }
      });
    },
    // 获取身份证件照信息
    getIDcardInfo(IDInfo) {
      console.log(IDInfo);
      if (IDInfo[2] == 1) {
        this.ID_info_f = IDInfo[0];
        this.ID_url_f = IDInfo[1];
        this.ID_type = 1;
        this.name = this.ID_info_f.name;
        this.ID_number = this.ID_info_f.num;
      } else {
        this.ID_info_r = IDInfo[0];
        this.ID_url_r = IDInfo[1];
      }
    },
    // 提交审核
    submitPass() {
      if (!this.img_url.length) {
        this.$message.error({
          message: "请先上传头像！",
          duration: 2000,
        });
        return;
      }
      // 选择工作室类别
      if (!(parseInt(this.studio_type) + 1)) {
        this.$message.error({
          message: "请先选择工作室类别！",
          duration: 2000,
        });
        return;
      }
      // 艺术家
      if (this.studioId == 1) {
        if (!this.address[0]) {
          this.$message.error({
            message: "请选择地区！",
            duration: 2000,
          });
          return;
        }
        if (!this.artist_name.trim().length) {
          this.$message.error({
            message: "请输入艺术家名称！",
            duration: 2000,
          });
          return;
        }
        if (!this.music_type) {
          this.$message.error({
            message: "请选择音乐风格！",
            duration: 2000,
          });
          return;
        }
        //
      } else if (this.studioId == 2) {
        if (!this.address[0]) {
          this.$message.error({
            message: "请选择地区！",
            duration: 2000,
          });
          return;
        }
        if (!this.brand_name.trim()) {
          this.$message.error({
            message: "请填写厂牌名称！",
            duration: 2000,
          });
          return;
        }
      } else if (this.studioId == 3) {
        if (!this.arena_name.trim()) {
          this.$message.error({
            message: "请填写场馆名称！",
            duration: 2000,
          });
          return;
        }
        if (!this.arean_area[2]) {
          this.$message.error({
            message: "请选择场馆地址！",
            duration: 2000,
          });
          return;
        }
        if (!this.address_info.trim()) {
          this.$message.error({
            message: "请填写场馆详细地址！",
            duration: 2000,
          });
          return;
        }
      }
      // 主体资质
      if (this.studioId != 1) {
        if (!(parseInt(this.main_type) + 1)) {
          this.$message.error({
            message: "请选择主体类型！",
            duration: 2000,
          });
          return;
        }
        if (!this.main_name.trim()) {
          this.$message.error({
            message: "请填写主体名称！",
            duration: 2000,
          });
          return;
        }
        if (
          !/(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(
            this.main_ID
          )
        ) {
          this.$message.error({
            message: "请输入正确的资质证件号码！",
            duration: 2000,
          });
          return;
        }
      }
      // 超级管理员
      if (!this.name.trim()) {
        this.$message.error({
          message: "请填写正确姓名！",
          duration: 2000,
        });
        return;
      }
      if (!this.ID_type) {
        this.$message.error({
          message: "请选择证件类型！",
          duration: 2000,
        });
        return;
      }
      // 选择证件类型判断
      if (this.ID_type == 1) {
        if (
          !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.ID_number
          )
        ) {
          console.log(this.ID_number);
          this.$message.error({
            message: "请输入正确的证件号！",
            duration: 2000,
          });
          return;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message.error({
          message: "请输入正确的手机号！",
          duration: 2000,
        });
        return;
      }
      if (
        !/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(
          this.email
        )
      ) {
        this.$message.error({
          message: "请填写正确的邮箱！",
          duration: 2000,
        });
        return;
      }
      if (!this.ID_url_f) {
        this.$message.warning("请上传身份证件照！");
        return;
      }
      if (!this.ID_url_r) {
        this.$message.warning("请上传身份证件照！");
        return;
      }
      if (!this.is_read) {
        this.$message.warning({
          message: "请阅读并同意协议！",
          duration: 2000,
        });
        return;
      }
      // 艺术家
      if (this.studioId == 1) {
        let data = {
          type: parseInt(this.studioId) - 1,
          name: this.artist_name,
          musicStyle: this.music_type_up,
          remark: this.introduction,
          // 管理
          adminName: this.name,
          adminCard: this.ID_number,
          adminMobile: this.mobile,
          adminEmail: this.email,
          cardImage: this.ID_url_f + "," + this.ID_url_r,

          areaId: this.address[2],
          province: this.myArea[0],
          city: this.myArea[1],
          area: this.myArea[2],

          isSuccess: this.isSuccess,
          userId: this.user_id,
          photo: this.img_url,
          id: this.authenticationId,
        };
        this.pushData(data);
        // 厂牌
      } else if (this.studioId == 2) {
        let data = {
          type: parseInt(this.studioId) - 1,
          areaId: this.address[2],
          province: this.myArea[0],
          city: this.myArea[1],
          area: this.myArea[2],

          name: this.brand_name,
          remark: this.introduction,
          // 主体
          subjectType: this.main_type,
          subjectName: this.main_name,
          subjectCard: this.main_ID,
          // 管理员
          adminName: this.name,
          adminCard: this.ID_number,
          adminMobile: this.mobile,
          adminEmail: this.email,
          cardImage: this.ID_url_f + "," + this.ID_url_r,

          isSuccess: this.isSuccess,
          userId: this.user_id,
          photo: this.img_url,
          id: this.authenticationId,
        };
        this.pushData(data);

        // 场馆
      } else {
        let data = {
          type: parseInt(this.studioId) - 1,

          areaId: this.arean_area[2],
          province: this.sessionArea[0],
          city: this.sessionArea[1],
          area: this.sessionArea[2],
          address: this.address_info,
          // this.brand_name,

          name: this.arena_name,
          remark: this.introduction,
          // 主体
          subjectType: this.main_type,
          subjectName: this.main_name,
          subjectCard: this.main_ID,
          // 管理员
          adminName: this.name,
          adminCard: this.ID_number,
          adminMobile: this.mobile,
          adminEmail: this.email,
          cardImage: this.ID_url_f + "," + this.ID_url_r,

          isSuccess: this.isSuccess,
          userId: this.user_id,
          photo: this.img_url,
          id: this.authenticationId,
        };
        this.pushData(data);
      }
    },
    // 提交审核
    pushData(data) {
      this.$http({
        url: "/api/authentication/v1.0/authentication",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success({
            message: "操作成功，请耐心等待审核！",
            duration: 3000,
          });
          setTimeout(() => {
            this.$router.push("/studio/verifying");
          }, 3000);
        } else {
          this.$message.error({
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 48px;
  background-color: #f5f5f5;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    margin: 0 auto;
    .left {
      width: 210px;
    }
    .main-content {
      width: 1200px;
      padding: 24px;
      box-sizing: border-box;
      background-color: #fff;
      .title-top {
        margin: 0 0 40px !important;
      }
      .edit-box {
        .avatar {
          & > p {
            padding: 10px 0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
          .box {
            display: flex;
            align-items: center;
            .btn-box {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              height: 80px;
              .btn-upload {
                width: 110px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                color: rgba(0, 0, 0, 0.6);
                border: 1px solid rgba(0, 0, 0, 0.15);
              }
              .tip-class {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
              }
            }
            .el-avatar {
              margin-right: 20px;
            }
          }
        }
      }
      // 标题
      .title {
        margin: 40px 0;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
      .studio-box {
        width: 100%;
      }
      .super-box {
        width: 100%;
      }
      // 信息框模板
      .info-item {
        display: flex;
        width: 100%;
        margin-top: 24px;
        & > div {
          width: 100%;
          p {
            margin-bottom: 10px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .el-select {
          width: 90%;
        }
        .el-input {
          width: 90%;
        }
        .el-cascader {
          width: 90%;
        }
        .input-ban {
          width: 45%;
        }
      }
      .message-tips {
        display: flex;
        align-items: baseline;
        justify-content: space-around;
        width: 60%;
        padding: 15px 0;
        margin: 25px 0;
        border-radius: 8px;
        border: 1px solid #ffe58f;
        background-color: #fffbe6;
        & > div {
          width: 90%;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          & > p {
            margin-top: 2px;
            line-height: 20px;
            letter-spacing: 1px;
          }
        }
      }
      .agreement {
        margin-left: 10px;
        font-size: 13px;
        color: #1890ff;
        border-bottom: 1px solid #1890ff;
        cursor: pointer;
      }
      .btn-submit {
        margin-top: 25px;
        border-radius: 2px;
        background-color: #d30404;
      }
      ::v-deep.el-checkbox__inner:hover {
        border-color: #d30404;
      }
      ::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
        color: #d30404;
      }
      ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
      ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: #d30404;
        background-color: #d30404;
      }
      ::v-deep.el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #d30404;
      }
    }
  }
}
</style>
