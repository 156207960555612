<template>
  <div>
    <nav-top></nav-top>
    <section>
      <div class="main">
        <div class="left">
          <index-left :classId="navId"></index-left>
        </div>
        <div class="content">
          <div class="search-header">
            <i class="el-icon-back btn-back" @click="$router.back(-1)"></i>
            <el-input
              prefix-icon="el-icon-search"
              placeholder="搜索"
              v-model="searchval"
              @keyup.native.enter="searchInfo"
            ></el-input>
          </div>
          <div class="nav-box">
            <div
              v-for="(nav, n_id) in navList"
              :class="{ active: nid == n_id }"
              :key="n_id"
              @click="saveIndex(n_id)"
            >
              {{ nav }}
            </div>
          </div>
          <!-- 最佳结果 -->
          <div class="row" v-if="nid == 0">
            <h4>
              演出<i class="el-icon-arrow-right" @click="saveIndex(1)"></i>
            </h4>
            <div
              class="item optimal-box"
              v-for="(aitem, aid) in allList.activitys"
              :key="aid + 'a'"
            >
              <img
                class="poster"
                :src="aitem.poster"
                @click="gotoInfo(aitem.activityId, aitem.siteId, 'activity')"
                alt=""
              />
              <div>
                <p v-html="aitem.name"></p>
                <p>{{ aitem.startTime }}{{ aitem.week }}</p>
                <p>{{ aitem.city }} {{ aitem.area }} · {{ aitem.venueName }}</p>
                <p>{{ aitem.ticketPrice }}</p>
              </div>
            </div>
            <h4>
              工作室<i class="el-icon-arrow-right" @click="saveIndex(2)"></i>
            </h4>
            <div
              class="item"
              v-for="(sitem, sid) in allList.studios"
              :key="sid + 's'"
            >
              <el-avatar
                :size="44"
                :src="sitem.photo"
                @click.native="gotoInfo(sitem.studioId, sitem.type, 'studio')"
              ></el-avatar>
              <div>
                <p class="title1" v-html="sitem.studioName"></p>
                <p class="title2">{{ sitem.address }}</p>
              </div>
            </div>
            <h4>
              用户<i class="el-icon-arrow-right" @click="saveIndex(3)"></i>
            </h4>
            <div
              class="item"
              v-for="(uitem, uid) in allList.users"
              :key="uid + 'u'"
            >
              <el-avatar
                :size="44"
                :src="uitem.photo"
                @click.native="gotoInfo(uitem.userId, 0, 'user')"
              ></el-avatar>
              <div>
                <p class="title1" v-html="uitem.nickName"></p>
                <p class="title2">
                  {{ uitem.remark ? uitem.remark : "这个人很懒，啥都没写！" }}
                </p>
              </div>
            </div>
          </div>
          <!-- 演出 -->
          <div class="row" v-if="nid == 1">
            <div
              class="item optimal-box"
              v-for="(a_item, a_id) in activeList"
              :key="a_id"
            >
              <img
                class="poster"
                :src="a_item.poster"
                @click="gotoInfo(a_item.activityId, a_item.siteId, 'activity')"
                alt=""
              />
              <div>
                <p v-html="a_item.name"></p>
                <p>{{ a_item.startTime }}{{ a_item.week }}</p>
                <p>
                  {{ a_item.city }} {{ a_item.area }} · {{ a_item.venueName }}
                </p>
                <p>{{ a_item.ticketPrice }}</p>
              </div>
            </div>
          </div>
          <!-- 工作室 -->
          <div class="row" v-if="nid == 2">
            <div
              class="item"
              v-for="(s_item, s_id) in studioList"
              :key="s_id + 's'"
            >
              <el-avatar
                :size="44"
                :src="s_item.photo"
                @click.native="gotoInfo(s_item.studioId, s_item.type, 'studio')"
              ></el-avatar>
              <div>
                <p class="title1" v-html="s_item.studioName"></p>
                <p class="title2">{{ s_item.address }}</p>
              </div>
            </div>
          </div>
          <!-- 用户 -->
          <div class="row" v-if="nid == 3">
            <div
              class="item"
              v-for="(u_item, u_id) in userList"
              :key="u_id + 'u'"
            >
              <el-avatar :size="44" :src="u_item.photo"></el-avatar>
              <div>
                <p class="title1" v-html="u_item.nickName"></p>
                <p class="title2">
                  {{ u_item.remark ? u_item.remark : "这个人很懒，啥都没写！" }}
                </p>
              </div>
            </div>
          </div>
          <!-- 媒体 -->
          <div class="row" v-if="nid == 4"></div>
          <!-- 商品 -->
          <div class="row" v-if="nid == 5"></div>
          <!-- 分页 -->
          <div class="no-data-ui" v-if="!listLength">
            暂无数据
          </div>
          <div class="pagination" v-if="nid != 0">
            <el-pagination
              background
              layout="
                  prev,
                  pager,
                  jumper,
                  next"
              :total="total"
              :page-size="page_count"
              :current-page="page"
              prev-text="前一页"
              next-text="下一页"
              @current-change="currentChange"
              @prev-click="prevClick"
              @next-click="nextClick"
            >
            </el-pagination>
          </div>
        </div>

        <hot-right></hot-right>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      navId: 1,
      nid: 0,
      navList: ["最佳结果", "演出", "工作室", "用户", "媒体", "商品"],
      searchval: "",
      type: "",

      total: 0,
      page: 1,
      page_count: 20,
      allList: "",
      activeList: [],
      studioList: [],
      userList: [],
      listLength: 0,
    };
  },
  mounted() {
    this.searchval = this.$route.query.val;
    this.type = this.$route.query.type || 1;
    this.nid = this.type - 1;
    this.getTicketInfo(this.type); // 获取搜索信息
  },
  watch: {
    $route(to) {
      this.searchval = to.query.val;
      this.type = to.query.type;
      location.reload();
    }
  },
  methods: {
    // 获取搜索信息
    getTicketInfo(type) {
      this.$showLoading("loading...", ".content");
      this.$http({
        url: "/api/ticket/v1.0/getTicketType",
        params: {
          name: this.searchval,
          pageNo: this.page,
          pageSize: this.page_count,
          type: type,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          if (type == 1) {
            this.allList = res.data.data.optimums;
            this.changeSearchVal(
              this.allList,
              ["name", "studioName", "nickName"],
              this.searchval
            );
            this.total = 1;
            this.listLength = 1;
          } else if (type == 2) {
            this.activeList = res.data.data.ticketListDTOS;
            this.listLength = this.activeList.length;
            if (this.listLength) {
              this.total = parseInt(this.activeList[0].rows);
              this.changeSearchVal(this.activeList, "name", this.searchval);
            }
          } else if (type == 3) {
            this.studioList = res.data.data.studioDTOS;
            this.listLength = this.studioList.length;
            if (this.listLength) {
              this.total = parseInt(this.studioList[0].rows);
              this.changeSearchVal(
                this.studioList,
                "studioName",
                this.searchval
              );
            }
          } else if (type == 4) {
            this.userList = res.data.data.userDTOS;
            this.listLength = this.userList.length;
            if (this.listLength) {
              this.total = parseInt(this.userList[0].rows);
              this.changeSearchVal(this.userList, "nickName", this.searchval);
            }
          } else if (type == 5) {
            this.listLength = 0;
          } else if (type == 6) {
            this.listLength = 0;
          }
        } else {
          this.listLength = 0;
          console.log(res.data);
        }

        // console.log(this.allList);
        this.$hideLoading();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      });
    },
    // 页码
    currentChange(e) {
      // 上下重复调用
      if (this.page == e) {
        console.log("重复");
      } else {
        this.page = e;
        this.getTicketInfo(this.type);
      }
    },
    // 上一页
    prevClick(e) {
      this.page = e;
      this.getTicketInfo(this.type);
    },
    // 下一页
    nextClick(e) {
      this.page = e;
      this.getTicketInfo(this.type);
    },
    // 搜索
    searchInfo() {
      this.page = 1;
      console.log(this.type);
      this.$router.push({
        path: "/search",
        query: { val: this.searchval, type: this.type },
      });
    },
    // 切换导航
    saveIndex(index) {
      this.nid = index;
      this.page = 1;
      this.total = 0;
      this.type = index + 1;
      this.$router.push({
        path: "/search",
        query: { val: this.searchval, type: this.type },
      });
    },
    // 关键字
    changeSearchVal(list, sp, text) {
      if (Object.prototype.toString.call(list) === "[object Object]") {
        let listName = Object.keys(list);
        // console.log(text);
        for (let i = 0; i < listName.length; i++) {
          // console.log(i);

          for (let j = 0; j < list[listName[i]].length; j++) {
            if (i == 0) {
              list[listName[i]][j].name = list[listName[i]][j].name.replace(
                text,
                `<span style="color: #ef4033;">${text}</span>`
              );
            } else if (i == 1) {
              list[listName[i]][j].nickName = list[listName[i]][
                j
              ].nickName.replace(
                text,
                `<span style="color: #ef4033;">${text}</span>`
              );
            } else if (i == 2) {
              list[listName[i]][j].studioName = list[listName[i]][
                j
              ].studioName.replace(
                text,
                `<span style="color: #ef4033;">${text}</span>`
              );
            }
          }
        }
      } else {
        // console.log(list, sp, text);
        for (let i = 0; i < list.length; i++) {
          // console.log(list[i][sp]);
          list[i][sp] = list[i][sp].replace(
            text,
            `<span style="color: #ef4033;">${text}</span>`
          );
        }
      }
    },
    // 跳转
    gotoInfo(id, type, tip) {
      // console.log(id, type, tip);
      if (tip == "activity") {
        this.$router.push({
          path: "/live-info",
          query: { aid: id, site: type },
        });
      } else if (tip == "studio") {
        this.$router.push({
          path: "/speople",
          query: { id: id, type: type },
        });
      } else {
        console.log("没有用户详情！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
section {
  display: flex;
  width: 100%;
  margin-top: 48px;
  background-color: #fff;
  .main {
    position: relative;
    display: flex;
    width: 1440px;
    height: 100%;
    min-height: calc(100vh - 48px);
    margin: 0 auto;
    box-sizing: border-box;
    .left {
      width: 210px;
    }
    .content {
      position: relative;
      width: 855px;
      margin-right: 15px;
      box-sizing: border-box;
      border-right: 1px solid #e5e5e5;
    }
  }
}
.search-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  /deep/.el-input__inner {
    width: 90%;
    border-radius: 20px;
    border: none;
    background-color: #f7f9fa;
  }
}
.nav-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  margin: 0 auto;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  & > div {
    color: #606972;
    font-size: 14px;
    cursor: pointer;
  }
  .active {
    position: relative;
    color: #1d2023;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      border-bottom: 2px solid #1d2023;
    }
  }
}

.row {
  height: auto;
  box-sizing: border-box;
  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 15px;
    font-weight: bold;
    i {
      color: #ccc;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border-bottom: 1px solid #e5e5e5;
  .el-avatar {
    margin-right: 10px;
  }
}
.optimal-box {
  & > div {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > p {
      font-size: 12px;
      color: #606972;
    }
    & > p:first-child {
      font-size: 14px;
      color: #1d2023;
    }
    & > p:last-child {
      font-size: 14px;
      color: #1d2023;
      font-weight: bold;
    }
  }
}
.poster {
  width: 75px;
  height: 100px;
  margin-right: 10px;
  border-radius: 5px;
}
.title1 {
  font-size: 14px;
  color: #333;
}
.title2 {
  margin-top: 10px;
  font-size: 12px;
  color: #606972;
}
.no-data-ui {
  line-height: 50vh;
}
.pagination {
  margin: 40px 0;
  text-align: center;
}
</style>
