<template>
	<div>
		<!-- 导航栏 -->
		<header>
			<nav>
				<img
					src="../assets/images/icon/logo.png"
					class="logo"
					@click="logoClick"
				/>
				<div class="menu">
					<p class="posi-notice">
						<!-- :class="{ 'is-dot-ui': notice_count }" -->
						<el-badge
							:value="notice_count"
							class="item-count"
							v-if="notice_count"
						>
							<i class="el-icon-bell" @click.stop="showNotice"></i>
						</el-badge>
						<i v-else class="el-icon-bell" @click.stop="showNotice"></i>
						<!-- 通知 -->
						<transition name="el-fade-in-linear">
							<div class="notice-box" v-show="is_notice">
								<div class="header" v-if="notice_count">
									<span>通知</span>
									<el-badge :value="notice_count"></el-badge>
								</div>
								<div class="header" v-else>
									<span>通知</span>
								</div>
								<div
									class="nlist"
									v-for="(nitem, nid) in noticeList"
									:key="nid"
									@click="gotoInfo(nitem.id)"
								>
									<p :class="{ 'is-dot-ui-': !nitem.isRead }">
										{{ nitem.title }}
									</p>
									<p>{{ nitem.content }}</p>
								</div>
								<div class="all" @click="$router.push('/studio/notice')">
									查看全部
								</div>
							</div>
						</transition>
					</p>
					<el-avatar
						class="avatar"
						icon="el-icon-user-solid"
						:size="25"
						style="cursor: pointer"
						@click.native="showLogin"
						v-if="!userphoto"
					></el-avatar>
					<img
						class="avatar"
						:src="userphoto"
						alt="头像"
						@click="showLogin"
						v-else
					/>
					<span @click="showLogin" :title="username" class="user-name text-one">
						{{ username }}
					</span>
					<i
						v-if="!is_change"
						class="el-icon-sort"
						@click.stop="changeStudio"
					></i>
					<i v-if="token" class="el-icon-switch-button" @click="clickOut"></i>
				</div>
				<!-- 工作室 -->
				<transition name="el-fade-in-linear">
					<div class="studio-select" v-if="show_studio">
						<div
							v-for="(item, index) in studioList"
							:key="index"
							@click="toStudio(item)"
						>
							<img :src="item.photo" alt="" />
							<span class="text-one">{{ item.name }}</span>
							<span class="tag-dot" v-if="item.messageCount">
								{{ item.messageCount }}
							</span>
						</div>
						<p class="add-studio" @click="gotoStudio">
							<i class="el-icon-plus"></i>创建新工作室
						</p>
					</div>
				</transition>
			</nav>
		</header>
		<!-- 登录注册 -->
		<div class="mask" v-if="mask_id">
			<!-- 登录框 -->
			<div class="mask-box">
				<i class="el-icon-close" v-if="mask_id != 2" @click="closeMask"></i>
				<!-- 手机登录 -->
				<div class="phone-login" v-if="mask_id == 1">
					<div class="header">
						<p class="title">Hi，RockGuys</p>
						<img
							src="../assets/images/codesp.png"
							@click="changeType(3)"
							alt=""
						/>
					</div>
					<div class="box">
						<div class="edit-box">
							<p>手机号</p>
							<input
								v-model="phone_num"
								oninput="if(value.length>11)value=value.slice(0,11)"
							/>
							<p>验证码</p>
							<div>
								<input
									type="text"
									v-model="code_num"
									maxlength="6"
									placeholder="请输入验证码"
								/>
								<button
									class="btn-code"
									:class="[is_sendcode ? 'wait-code' : '']"
									@click="getCode()"
									@keyup.enter="login"
									:disabled="is_sendcode"
								>
									{{
										is_sendcode ? time_count + "后可以重新发送" : "获取验证码"
									}}
								</button>
							</div>
						</div>
						<el-button @click="login" class="btn-login" type="danger">
							登录
						</el-button>
					</div>
					<div class="wx-login">
						<p>或者使用第三方账号登录</p>
						<p class="iconfont icon-weixin" @click="changeWechat"></p>
					</div>
				</div>
				<!-- 绑定手机号 -->
				<div class="binding" v-else-if="mask_id == 2">
					<p class="title">绑定手机号</p>
					<div>
						<p>手机号</p>
						<input
							v-model="bind_phone_num"
							oninput="if(value.length>11)value=value.slice(0,11)"
						/>
						<p>验证码</p>
						<div>
							<input
								type="text"
								v-model="bindCode"
								maxlength="6"
								placeholder="请输入验证码"
							/>
							<button
								class="btn-code"
								@click="getBindCode()"
								:disabled="is_sendcode"
							>
								{{ is_sendcode ? time_count + "后可以重新发送" : "获取验证码" }}
							</button>
						</div>
					</div>
					<el-button class="btn-login" type="danger" @click="bindPhone"
						>绑定</el-button
					>
				</div>
				<!-- APP登录 -->
				<div class="app-login" v-else-if="mask_id > 2">
					<div class="header">
						<p>RockGuys App扫码登录</p>
						<img
							src="../assets/images/computer.png"
							@click="changeType(1)"
							alt=""
						/>
					</div>
					<div class="code-box" v-show="mask_id == 3">
						<div class="qrcode" ref="qrcodeUrl"></div>
						<p>请使用RockGuys App扫码登录</p>
					</div>
					<div class="success-box" v-show="mask_id == 4">
						<img src="../assets/images/success.png" alt="" />
						<p>扫码成功！</p>
					</div>
					<p class="tips" v-show="mask_id == 4">请在手机上确认登录</p>
				</div>
			</div>
		</div>
		<!-- 创建工作室 -->
		<el-dialog
			title="提示"
			style="margin-top: 18vh;"
			:visible="is_change == 2"
			width="30%"
			@close="is_change = 1"
			center
		>
			<p style="width:100%; text-align: center;font-size: 16px;">
				您好，您还没有工作室哦，快去创建吧！
			</p>
			<p
				style="width:100%; text-align: center; margin-top: 20px;font-size: 12px;"
			>
				<el-tag type="warning">如已开通，请耐心等待审核！</el-tag>
			</p>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="$router.push('/')"
					>暂时不开设</el-button
				>
				<el-button size="small" type="primary" @click="is_change = 1">
					开设工作室
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// 生成二维码
import QRCode from "qrcodejs2";
export default {
	name: "navtop",
	data() {
		return {
			username: "用户名",
			userphoto: "",
			useradmin: "", // 权限
			phone_num: "", // 手机号
			code_num: "", // 验证码

			bind_phone_num: "", // 绑定手机号
			bindCode: "", // 验证码

			open_id: "", // 微信id

			mask_id: 0, // 弹窗状态 0关闭 | 1登录 | 2绑定 | 3 APP | 4 app suc
			timer: null, //
			is_sendcode: false, // 60s
			time_count: 60, // 倒计时
			show_studio: "", // 显示工作室
			studioList: [], // 工作室列表
			is_notice: false, // 消息
			noticeList: [], // 消息列表
			notice_count: 0, // 未读数量

			only_play: 0, // 登录获取一次工作室信息
			sancing: null,
			status: 2,
			token: "",
			is_change: false,
		};
	},
	created() {},
	mounted() {
		// 获取url
		let code = this.$route.query.code;
		let path = this.$route.path;
		if (code && path != "/studio/capital") {
			this.wxlogin(code);
		}

		let that = this;
		// 设置登录限制
		Object.defineProperty(that.$auth, "auth", {
			set: function(val) {
				that.mask_id = val;
			},
		});
		Object.defineProperty(that.$auth, "changeStudio", {
			set: function(val) {
				that.is_change = val;
			},
		});
		// 获取基本信息
		this.token = localStorage.getItem("token");
		let userinfo = JSON.parse(localStorage.getItem("userinfo"));
		if (userinfo) {
			this.user_id = userinfo.userId;
			this.username = userinfo.nickName;
			this.userphoto = userinfo.photo;
		}

		// 获取工作室信息
		let studioinfo = JSON.parse(localStorage.getItem("sinfo"));
		if (studioinfo) {
			this.username = studioinfo.name;
			this.userphoto = studioinfo.photo;
			// this.admin = studioinfo.admin;
		}
		this.useradmin = localStorage.getItem("sadmin");

		// 默认登录获取一次工作室
		this.only_play = localStorage.getItem("only_play") || 0;
		this.clickListener();

		// 热更新工作室信息
		if (this.token) {
			this.getUserStudio();
			this.getStudioInfo();
			this.getNotice();
		}
	},
	methods: {
		// 监听click点击事件
		clickListener() {
			document.addEventListener("click", (e) => {
				if (this.is_notice) {
					if (e.target.className != "notice-box") {
						this.is_notice = false;
					}
				}
				if (this.show_studio) {
					if (e.target.className != "studio-select") {
						this.show_studio = false;
					}
				}
			});
		},
		// 显示登陆框
		showLogin() {
			let token = localStorage.getItem("token");
			if (token) {
				console.log("login");
			} else {
				this.mask_id = 1;
			}
		},
		// 显示通知
		showNotice() {
			this.is_notice = !this.is_notice;
			this.show_studio = false;
		},
		// 获取通知
		getNotice() {
			this.$http({
				url: "/api/wallet/getStudioMessage",
				params: {
					pageNo: 1,
					pageSize: 3,
					userId: this.user_id,
					studioId: localStorage.getItem("sid"),
				},
			}).then((res) => {
				if (res.data.code == 200) {
					this.noticeList = res.data.data.messages;
					this.notice_count = res.data.data.count;
					var link =
						document.querySelector("link[rel*='icon']") ||
						document.createElement("link");
					if (this.notice_count > 0) {
						// link.type = 'image/x-icon';
						// link.rel = 'shortcut icon';
						link.href = "/favicon1.ico";
						document.getElementsByTagName("head")[0].appendChild(link);
					} else {
						// link.type = 'image/x-icon';
						// link.rel = 'shortcut icon';
						link.href = "/favicon.ico";
						document.getElementsByTagName("head")[0].appendChild(link);
					}
				} else {
					console.log(res.data);
				}
			});
		},
		// 切换登录框
		changeType(id) {
			this.mask_id = id;
			if (id == 3) {
				this.getWebCode();
			} else {
				this.$refs.qrcodeUrl.innerHTML = "";
			}
		},
		// 获取app token
		getWebCode() {
			this.$http.get("/api/user/v1.0/getWebCode").then((res) => {
				if (res.data.code == 200) {
					let token = res.data.data.token;
					this.qrcode(token);
				}
			});
		},
		// 生成二维码
		qrcode(text) {
			var qrcode = new QRCode(this.$refs.qrcodeUrl, {
				// eslint-disable-line no-unused-vars
				text: "rockguys_" + text, // 需要转换为二维码的内容
				width: 220,
				height: 220,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H,
			});
			if (qrcode) {
				this.getWebStatus(text);
			}
		},
		// 轮询登录状态
		getWebStatus(token) {
			this.sancing = setInterval(() => {
				this.$http({
					url: "/api/user/v1.0/getWebStatus",
					params: {
						token: token,
						status: this.status,
					},
				}).then((res) => {
					if (res.data.data == 2021) {
						this.mask_id = 4;
						this.status = null;
					} else if (res.data.code == 200) {
						this.mask_id = 0; // 关闭弹窗
						// 储存信息
						let userinfo = JSON.stringify(res.data.data);
						localStorage.setItem("userinfo", userinfo);
						// token
						localStorage.setItem("token", res.data.data.token);
						window.location.reload();
						this.getInformation(res.data.data.userId);
						this.getUserStudio(); // 获取工作室id
						clearInterval(this.sancing);
					} else {
						console.log(res.data);
					}
				});
			}, 2000);
		},
		// 关闭弹窗
		closeMask() {
			this.mask_id = 0;
			clearInterval(this.sancing);
		},
		// 获取工作室
		changeStudio() {
			this.is_notice = false;
			this.show_studio = !this.show_studio;
		},
		// 获取工作室信息
		getStudioInfo() {
			this.$http({
				url: "/api/authentication/v1.0/getStudio",
				params: {
					studioId: localStorage.getItem("sid"),
					type: localStorage.getItem("stype"),
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let data = {};
					data.name = res.data.data.name || "";
					data.photo = res.data.data.photo || "";
					data = JSON.stringify(data);
					localStorage.setItem("sinfo", data);
				} else {
					console.log("获取工作室信息", res.data.msg);
				}
			});
		},

		// 退出登录
		clickOut() {
			this.$confirm("是否退出登录?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				center: true,
			})
				.then(() => {
					localStorage.removeItem("sid");
					localStorage.removeItem("aid");
					localStorage.removeItem("token");
					localStorage.removeItem("stype");
					localStorage.removeItem("sinfo");
					localStorage.removeItem("user_id");
					localStorage.removeItem("userinfo");
					localStorage.removeItem("only_play");
					this.logoClick();
				})
				.catch();
		},

		// 获取工作室列表
		getUserStudio() {
			this.$http({
				url: "/api/auth/v1.0/getUserStudio",
				params: {
					type: 1,
					userId: this.user_id,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					let info = res.data.data;
					if (res.data.data.length) {
						localStorage.setItem("only_play", 1);
						let sid = info[0].id || "";
						let type = info[0].type || "";
						let data = {};
						data.name = info[0].name || "";
						data.photo = info[0].photo || "";
						data = JSON.stringify(data);
						if (!this.useradmin) {
							this.useradmin = info[0].roleKey || "";
							localStorage.setItem("sadmin", this.useradmin);
						}
						// console.log(data);
						if (this.only_play == 0) {
							localStorage.setItem("sid", sid);
							localStorage.setItem("stype", type);
							localStorage.setItem("sinfo", data);
							this.username = info[0].name || "";
							this.userphoto = info[0].photo || "";
							if (sid) {
								this.$router.push("/studio/inspect");
							}
						}
						this.studioList = info;
					}
				} else {
					console.log(res.data);
				}
			});
		},

		// 切换工作室
		toStudio(item) {
			let t_id = localStorage.getItem("sid") || "";
			if (t_id == item.id) {
				this.$message.info({
					message: "已经是当前工作室！",
					duration: 2000,
				});
			} else {
				localStorage.setItem("sid", item.id);
				localStorage.setItem("stype", item.type);
				localStorage.setItem("sadmin", item.roleKey);

				let data = {};
				data.name = item.name || "";
				data.photo = item.photo || "";
				data = JSON.stringify(data);

				localStorage.setItem("sinfo", data);
				this.$message.success({
					message: "切换成功！",
					duration: 2000,
				});
				this.$router.push("/studio/inspect");
				setTimeout(function() {
					window.location.reload();
				}, 200);
			}
			this.show_studio = false;
			// let routeData = this.$router.resolve({
			//   path: "/studio/profile/edit",
			//   query: { id: id, type: type },
			// });
			// window.open(routeData.href, "_blank");
		},
		// 跳转微信登录
		changeWechat() {
			this.$http("/api/user/v1.0/getQRCodeUrl").then((res) => {
				if (res.data.code == 200) {
					window.location.href = res.data.msg;
				} else {
					this.$message({
						message: res.data.msg,
						type: "warning",
						duration: 2000,
					});
				}
			});
		},
		// 发送验证码
		getCode() {
			if (/^1[3456789]\d{9}$/.test(this.phone_num)) {
				this.getSmsCode(); // 请求验证码
			} else {
				this.$message({
					message: "请填写正确手机号",
					type: "error",
					duration: 2000,
				});
			}
		},
		// 获取验证码
		getSmsCode() {
			if (/^1[3456789]\d{9}$/.test(this.phone_num)) {
				this.$http({
					method: "GET",
					url: "/api/user/v1.0/sendSms",
					params: {
						mobile: this.phone_num,
						smsCode: "SMS_204980092",
					},
				}).then((res) => {
					if (res.data.code == 200) {
						this.getSendCode(); // 运行倒计时
					} else {
						this.$message({
							message: res.data.msg,
							type: "warning",
							duration: 2000,
						});
					}
				});
			} else {
				this.$message({
					message: "请填写正确手机号",
					type: "error",
					duration: 2000,
				});
			}
		},

		// 获取绑定验证码
		getBindCode() {
			if (/^1[3456789]\d{9}$/.test(this.bind_phone_num)) {
				this.$http({
					method: "GET",
					url: "/api/user/v1.0/sendSms",
					params: {
						mobile: this.bind_phone_num,
						smsCode: "SMS_204980092",
					},
				}).then((res) => {
					if (res.data.code == 200) {
						this.getSendCode(); // 运行倒计时
					} else {
						this.$message({
							message: res.data.msg,
							type: "warning",
							duration: 2000,
						});
					}
				});
			} else {
				this.$message({
					message: "请填写正确手机号",
					type: "error",
					duration: 2000,
				});
			}
		},
		// 60s
		getSendCode() {
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.time_count = TIME_COUNT;
				this.is_sendcode = true;
				this.timer = setInterval(() => {
					if (this.time_count > 0 && this.time_count <= TIME_COUNT) {
						this.time_count--;
					} else {
						this.is_sendcode = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}
		},
		// 登录
		login() {
			if (!/^1[3456789]\d{9}$/.test(this.phone_num)) {
				this.$message({
					message: "请填写正确手机号",
					type: "error",
					duration: 2000,
				});
				return;
			}
			if (!(this.code_num.length == 6)) {
				this.$message({
					message: "请填写正确验证码",
					type: "error",
					duration: 2000,
				});
				return;
			}
			let data = {
				code: this.code_num,
				mobile: this.phone_num,
				nationalCode: "+86",
				source: 2,
			};
			this.$http({
				method: "POST",
				url: "/api/user/v1.0/smsLogin",
				data: data,
			}).then((res) => {
				if (res.data.code == 200) {
					// console.log(res);
					this.mask_id = 0; // 关闭弹窗

					// 储存信息
					let userinfo = JSON.stringify(res.data.data);
					localStorage.setItem("userinfo", userinfo);

					// 登录
					localStorage.setItem("token", res.data.data.token);

					this.downForce(res.data.data.userId);
					this.getUserStudio(res.data.data.userId); // 获取工作室id
					clearInterval(this.timer);

					setTimeout(() => {
						window.location.reload();
					}, 500);
				} else {
					this.$message({
						message: res.data.msg,
						type: "error",
						duration: 2000,
					});
				}
			});
		},

		// 微信登陆
		wxlogin(code) {
			this.$http({
				url: "/api/user/v1.0/weChatLogin",
				method: "POST",
				params: {
					code: code,
					source: 2,
				},
			})
				.then((res) => {
					if (res.data.code == 200) {
						// 判断是否注册
						if (res.data.data.bind == 1) {
							this.mask_id = 2;
							this.open_id = res.data.data.unionId || "";
						} else {
							this.mask_id = 0; // 关闭弹窗
							localStorage.setItem("userinfo", JSON.stringify(res.data.data)); // 存储信息
							localStorage.setItem("token", res.data.data.token); // 存储信息
							this.downForce(res.data.data.userId);
							clearInterval(this.timer);

							// 去掉code 跳转
							let url = window.location.href;
							if (url.indexOf("code") !== -1) {
								history.pushState("", "", "https://pc.rockguys.cn/");
								setTimeout(() => {
									window.location.reload();
								}, 500);
							}
						}
					} else {
						this.$message({
							message: "意外错误，请重新登录！",
							type: "error",
							duration: 2000,
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},

		// 微信登陆绑定手机号
		bindPhone() {
			this.$http({
				url: "/api/user/v1.0/wechatBind",
				method: "POST",
				data: {
					source: 2,
					code: this.bindCode,
					mobile: this.bind_phone_num,
					nationalCode: "+86",
					unionId: this.open_id,
					flag: 1,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					console.log(res);
					this.mask_id = 0; // 关闭弹窗
					localStorage.setItem("userinfo", JSON.stringify(res.data.data)); // 存储信息
					localStorage.setItem("token", res.data.data.token); // 存储信息
					this.downForce(res.data.data.userId);
					clearInterval(this.timer);

					// 去掉code 跳转
					let url = window.location.href;
					if (url.indexOf("code") !== -1) {
						history.pushState("", "", "https://pc.rockguys.cn/");
						setTimeout(() => {
							window.location.reload();
						}, 500);
					}
				} else if (res.data.code == 1017) {
					this.$confirm(
						`监测到该手机号已注册昵称为 "${this.userinfo.nickName}" 的账号，是否与当前账号合并为同一账号？`,
						"提示",
						{
							confirmButtonText: "合并",
							cancelButtonText: "不合并",
							type: "warning",
							center: true,
						}
					)
						.then(() => {
							// 合并账号
							this.mergeAccount();
						})
						.catch(() => {
							this.$alert("不合并请绑定其他手机号", "提示", {
								confirmButtonText: "知道了",
								type: "warning",
								center: true,
							});
						});
				} else {
					this.$message({
						message: res.data.msg,
						type: "error",
						duration: 2000,
					});
				}
			});
		},
		// 合并账号
		mergeAccount() {
			this.$http({
				url: "/api/user/v1.0/mergeAccount",
				method: "POST",
				data: {
					mobile: this.bind_phone_num,
					userId: this.userinfo.userId,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					// console.log(res);
					this.wxlogin(this.$route.query.code);
				} else {
					this.$message.warning(res.data.msg);
				}
			});
		},

		// logo跳转
		logoClick() {
			console.log(this.$route.path);
			if (this.$route.path == "/") {
				window.location.reload();
			} else {
				this.$router.push("/");
				window.location.reload();
			}
		},

		// 强制下线通知
		downForce(id) {
			this.$http({
				url: "/api/user/v1.0/force",
				method: "POST",
				data: {
					type: 1,
					userId: id ? id : this.userinfo.userId,
				},
			}).then((res) => {
				if (res.data.code == 200) {
					console.log(res);
				} else {
					console.log(res.data.msg);
				}
			});
		},

		// 跳转详情
		gotoInfo(id) {
			this.$router.push({
				path: "/studio/notice/info",
				query: { mid: id },
			});
		},
		// gotoStudio
		gotoStudio() {
			let url =
				this.$mcallurl +
				"studio?id=" +
				encodeURIComponent(encodeURIComponent("洛克人")) +
				this.user_id;
			window.open(url, "_blank");
		},
	},
};
</script>

<style lang="less" scoped>
header {
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #222222;
}
nav {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1440px;
	width: 100%;
	height: 48px;
	padding-left: 50px;
	margin: 0 auto;
	box-sizing: border-box;
	.logo {
		height: 26px;
		cursor: pointer;
	}
	.menu {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		i {
			position: relative;
			margin-right: 30px;
			font-size: 14px;
			color: #fff;
			cursor: pointer;
		}
		.posi-notice {
			position: relative;
		}
		::v-deep.item-count {
			// right: 5px;
			.el-badge__content.is-fixed {
				right: 35px;
			}
			.el-badge__content {
				padding: 0 5px;
				height: 15px;
				line-height: 15px;
				border: none;
			}
		}
		.is-dot-ui {
			&::after {
				content: "";
				position: absolute;
				top: -2px;
				right: -2px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #d30404;
			}
		}
		.avatar {
			width: 25px;
			height: 25px;
			margin-right: 8px;
			border-radius: 50%;
			cursor: pointer;
		}
		.user-name {
			display: inline-block;
			width: 50px;
			margin-right: 10px;
			color: #fff;
			font-size: 14px;
			font-weight: 400;
			cursor: pointer;
		}
		.el-icon-sort {
			transform: rotate(90deg);
			cursor: pointer;
		}
	}
	.studio-select {
		position: absolute;
		right: 70px;
		top: 48px;
		width: 180px;
		border-radius: 4px;
		box-sizing: border-box;
		background-color: #fff;
		box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
			0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
		div {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 5px 15px;
			line-height: 30px;
			box-sizing: border-box;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.65);
			cursor: pointer;
			&:hover {
				background: linear-gradient(90deg, #fff6f6 0%, #fcf5f5 100%);
			}
			& > img {
				width: 25px;
				height: 25px;
				margin-right: 10px;
				border-radius: 50%;
			}
		}
		.add-studio {
			padding: 10px 20px;
			font-size: 14px;
			color: #f56c6c;
			box-sizing: border-box;
			cursor: pointer;
			i {
				margin-right: 15px;
				font-weight: bold;
			}
		}
	}
	.notice-box {
		position: absolute;
		top: 33px;
		right: 27px;
		width: 300px;
		border-radius: 0 0 5px 5px;
		box-sizing: border-box;
		background-color: #fff;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
		.header {
			display: flex;
			align-items: center;
			height: 50px;
			padding: 0 20px;
			box-sizing: border-box;
			border-bottom: 1px solid #f0f0f0;
			& > span {
				font-size: 14px;
				font-weight: bold;
				color: #333;
			}
			.el-badge {
				margin-left: 10px;
			}
		}
		.nlist {
			width: 100%;
			padding: 10px 20px;
			color: #333;
			box-sizing: border-box;
			border-bottom: 1px solid #f0f0f0;
			&:hover {
				opacity: 0.5;
			}
			cursor: pointer;
			& > p:first-child {
				position: relative;
				font-size: 14px;
			}
			.is-dot-ui- {
				&::before {
					content: "";
					position: absolute;
					top: 3px;
					left: -10px;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #d30404;
				}
			}
			& > p:last-child {
				margin-top: 5px;
				font-size: 12px;
				line-height: 18px;
				color: #444;
			}
		}
		.all {
			padding: 13px 0;
			font-size: 15px;
			font-weight: bold;
			text-align: center;
			color: #666;
			cursor: pointer;
			&:hover {
				color: #333;
			}
		}
	}
}
.mask {
	z-index: 1001;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	.mask-box {
		position: relative;
		width: 440px;
		min-height: 440px;
		margin: 20vh auto 0;
		border-radius: 20px;
		background-color: #fff;
		.el-icon-close {
			position: absolute;
			top: -25px;
			right: -25px;
			padding: 2px;
			color: #fff;
			border-radius: 50%;
			box-sizing: border-box;
			border: 2px solid #fff;
			cursor: pointer;
		}
		.phone-login {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			.header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 50px;
				box-sizing: border-box;
				.title {
					padding: 40px 0 0 50px;
					color: #000;
					font-size: 20px;
					font-weight: 600;
					box-sizing: border-box;
				}
				& > i {
					cursor: pointer;
				}
				& > img {
					width: 50px;
					height: 50px;
					cursor: pointer;
				}
			}
			.box {
				width: 100%;
				padding: 0 50px;
				box-sizing: border-box;
				.title {
					color: #000;
					font-size: 20px;
					font-weight: 600;
				}
				.edit-box {
					width: 100%;
					margin-top: 40px;
					& > p {
						padding: 10px 0;
						color: #999;
						font-size: 13px;
					}
					& > input {
						width: 100%;
						padding: 5px 0;
						margin-bottom: 15px;
						font-size: 16px;
						border-bottom: 1px solid #999;
					}
					& > div {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						padding: 5px 0;
						margin-bottom: 15px;
						border-bottom: 1px solid #999;
						& > input {
							padding: 5px 0;
							font-size: 16px;
						}
						.btn-code {
							flex-shrink: 0;
							font-size: 14px;
							color: #ef4033;
							cursor: pointer;
						}
					}
				}
				.btn-login {
					width: 100%;
					height: 40px;
					margin-top: 50px;
					line-height: 15px;
					border-radius: 20px;
					background: linear-gradient(223deg, #ef4033 0%, #e70030 100%);
				}
			}
			.wx-login {
				width: 100%;
				margin: 40px 0;
				text-align: center;
				font-size: 14px;
				color: #b7b7b7;
				.icon-weixin {
					display: inline-block;
					margin-top: 20px;
					font-size: 30px;
					color: #1bb723;
					cursor: pointer;
				}
			}
		}
		.binding {
			width: 100%;
			padding: 70px;
			box-sizing: border-box;
			.title {
				color: #000;
				font-size: 20px;
				font-weight: 600;
			}
			& > div {
				width: 100%;
				margin-top: 40px;
				& > p {
					padding: 10px 0;
					color: #999;
					font-size: 13px;
				}
				& > input {
					width: 100%;
					padding: 5px 0;
					margin-bottom: 15px;
					font-size: 16px;
					border-bottom: 1px solid #999;
				}
				& > div {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					padding: 5px 0;
					margin-bottom: 15px;
					border-bottom: 1px solid #999;
					& > input {
						padding: 5px 0;
						font-size: 16px;
					}
					.btn-code {
						flex-shrink: 0;
						font-size: 14px;
						color: #ef4033;
						cursor: pointer;
					}
				}
			}
			.btn-login {
				width: 100%;
				height: 40px;
				margin-top: 50px;
				line-height: 15px;
				border-radius: 20px;
				background: linear-gradient(223deg, #ef4033 0%, #e70030 100%);
			}
		}
		.app-login {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			.header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 50px;
				& > p {
					padding-left: 30px;
					font-size: 20px;
					font-weight: 600;
					color: #000;
				}
				& > img {
					width: 50px;
					height: 50px;
					cursor: pointer;
				}
			}
			.code-box {
				width: 100%;
				margin-top: 50px;
				text-align: center;
				.qrcode {
					width: 220px;
					height: 220px;
					margin: 20px auto;
				}
				& > p {
					font-size: 15px;
					font-weight: 500;
					color: #000;
				}
			}
			.success-box {
				width: 100%;
				margin-top: 70px;
				text-align: center;
				& > img {
					width: 90px;
					height: 90px;
				}
				& > p {
					margin: 10px 0 70px;
					font-size: 20px;
					color: #000;
				}
			}
			.tips {
				font-size: 14px;
				color: #000;
				text-align: center;
			}
		}
	}
}
.wait-code {
	color: #999 !important;
}
::v-deep.dialog-footer {
	width: 100%;
}
.tag-dot {
	flex-shrink: 0;
	height: 20px;
	padding: 0px 5px;
	margin-left: auto;
	line-height: 20px;
	font-size: 12px;
	color: #fff;
	border-radius: 10px;
	background-color: #ef4033;
}
</style>
